import PropTypes from 'prop-types'

import { Stack, Typography } from '@mui/material'

export default function DetailStack({ label, value, size, renderValue, ...rest }) {
  return (
    <Stack {...rest}>
      <Typography variant="caption" color="grey.500" fontWeight={600}>
        {label}
      </Typography>
      {renderValue && renderValue(value)}
      {!renderValue && (
        <Typography variant="body1" color="black" fontSize={size} whiteSpace="pre-line">
          {value === null || value === undefined || value === '' ? '--' : `${value}`}
        </Typography>
      )}
    </Stack>
  )
}

DetailStack.defaultProps = {
  value: undefined,
  size: 16,
  renderValue: undefined,
}

DetailStack.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  size: PropTypes.number,
  renderValue: PropTypes.func,
}
