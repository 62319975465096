import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import AuditLogTab from '@portal/UI/components/AuditLogTab'

export default function OutagesAuditLog({ open, onClose, outageId }) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ sx: { minHeight: '700px' } }}
    >
      <DialogTitle sx={{ fontSize: '1.7rem' }}>Outage Audit Log</DialogTitle>
      <DialogContent>
        <AuditLogTab excludeDateFilter objectId={outageId} filterDrawOpen={false} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

OutagesAuditLog.defaultProps = {
  outageId: undefined,
}

OutagesAuditLog.propTypes = {
  outageId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
