import PropTypes from 'prop-types'

import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  backgroundColor: 'white',
  border: '2px solid #000',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
}

const buttonBoxStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: 2,
}

function DeleteModal({ open, onConfirmDelete, onCancelDelete, error }) {
  return (
    <Modal open={open}>
      <Box sx={boxStyle}>
        <Typography variant="h6">Are you sure you want to delete this item?</Typography>
        {error && (
          <Alert severity="error" sx={{ mt: '8px' }}>
            {error}
          </Alert>
        )}
        <Box sx={buttonBoxStyle}>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>
            Delete
          </Button>
          <Button onClick={onCancelDelete}>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  )
}

DeleteModal.defaultProps = {
  onCancelDelete: () => {},
  error: '',
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onConfirmDelete: PropTypes.func.isRequired,
  onCancelDelete: PropTypes.func,
}

export default DeleteModal
