import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { AccessTimeRounded, Delete, QuestionMarkRounded } from '@mui/icons-material'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'

import { ThemeProvider } from '@emotion/react'
import { DateTime } from 'luxon'

import {
  ClickableCell,
  MobileList,
  MobileListCardRow,
  MobileListDefaultCard,
} from '@common/components'
import { SmokeIcon } from '@common/icons'
import { formatCurrency, parseApiErrors, useSmallScreen } from '@common/utils'
import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'
import propertyUrls from '@portal/pages/Properties/urls'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import unitUrls from '@portal/pages/Units/urls'
import { EventTypeCell, GridCellExpand } from '@portal/UI/components/cells'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import List from '@portal/UI/components/ProList'
import { darkTheme } from '@portal/UI/Theme'

import EventViewModal from '../../EventModal/EventViewModal'
import AdditionalOccurenciesModal from './AdditionalOccurenciesModal'
import AuditLogModal from './AuditLogModal'
import EventListRowDetail from './EventListRowDetail'
import ScenarioOptions from './ScenarioOptions'

function isBoolean(value) {
  return value === true || value === false
}

function EventsList({ eventsList }) {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [scenarioFormOpen, setScenarioFormOpen] = useState(false)
  const [scenarioData, setScenarioData] = useState({})
  const [auditLogOpen, setAuditLogOpen] = useState(false)
  const [smokeFeedbackId, setSmokeFeedbackId] = useState('')
  const [additionalOccurenciesData, setAdditionalOccurenciesData] = useState(null)
  const [rowIdToRemove, setRowIdToRemove] = useState(null)

  const {
    isAtLeastDataScience,
    eventsListRaw: { ordering = [] },
    eventsListIsLoading,
    smokeAdjustmentReasons,
    doEventsListSetPage,
    doEventsListSetPageSize,
    doEventsListSetOrdering,
    doEventsListSetSearch,
    doMarkEventsListAsOutdated,
    doScenarioCreateFromReadings,
    doDeleteEvent,
    doShowSnackbar,
    queryObject,
    doUpdateQuery,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'selectEventsListRaw',
    'selectEventsListIsLoading',
    'selectSmokeAdjustmentReasons',
    'doEventsListSetPage',
    'doEventsListSetPageSize',
    'doEventsListSetOrdering',
    'doEventsListSetSearch',
    'doMarkEventsListAsOutdated',
    'doScenarioCreateFromReadings',
    'doDeleteEvent',
    'doShowSnackbar',
    'selectQueryObject',
    'doUpdateQuery',
  )

  const isSmallScreen = useSmallScreen()

  const detailRowContent = useCallback(
    ({ row }) =>
      row.childCount === 0 ? null : (
        <EventListRowDetail
          row={row}
          setScenarioData={setScenarioData}
          setScenarioFormOpen={setScenarioFormOpen}
        />
      ),
    [eventsList],
  )

  const detailRowHeight = useCallback(() => 'auto', [])

  useEffect(() => {
    if (queryObject?.grafanaEvent) {
      setSelectedEvent(queryObject.grafanaEvent)
    }
  }, [queryObject])

  const onScenarioFormClose = () => {
    setScenarioFormOpen(false)
    doMarkEventsListAsOutdated()
  }

  const onAuditLogClose = () => {
    setAuditLogOpen(false)
    setSmokeFeedbackId('')
  }

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  const handleEventDelete = useCallback(async () => {
    try {
      const response = await doDeleteEvent([{ id: rowIdToRemove }])
      if (response?.error) throw response

      setRowIdToRemove(null)
      doMarkEventsListAsOutdated()
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [rowIdToRemove])

  const getDetectedOnTime = useCallback(({ createdOn, endTime, timeZone }) => {
    const createdLuxon = DateTime.fromISO(createdOn, { zone: timeZone })
    const endLuxon = endTime
      ? DateTime.fromISO(endTime, { zone: timeZone })
      : DateTime.now()

    const formattedCreatedOn = createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)
    const endTimeFormat = createdLuxon.hasSame(endLuxon, 'day')
      ? DateTime.TIME_SIMPLE
      : DateTime.DATETIME_MED
    const formattedEndTime = endLuxon.toLocaleString(endTimeFormat)

    return [formattedCreatedOn, formattedEndTime]
  }, [])

  const LastModifiedItem = useCallback(
    ({ row, formattedValue }) => {
      let modifiedOn = null
      if (row.eventClass === 'NOISE') {
        modifiedOn = row.noiseFeedback?.modifiedOn
      } else if (row.eventClass === 'SMOKE' && row.feedbackOn) {
        modifiedOn = row.smokeFeedback?.modifiedOn || row.feedbackOn
      }

      let createdLuxon = null
      if (modifiedOn) {
        createdLuxon = DateTime.fromISO(modifiedOn, {
          zone: row.timeZone,
        })
      }
      return (
        <Stack textAlign={isSmallScreen ? 'end' : 'start'}>
          <Typography
            variant="body1"
            fontSize={isSmallScreen ? 12 : 14}
            whiteSpace="pre-line"
          >
            {row.smokeFeedback?.autocharge && formattedValue === 'N/A'
              ? 'Autocharge'
              : formattedValue}
          </Typography>
          {createdLuxon && (
            <Typography variant="caption" color="grey.500" fontWeight={600}>
              {createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)}
            </Typography>
          )}
        </Stack>
      )
    },
    [isSmallScreen],
  )

  const netChargeValueGetter = useCallback((row) => {
    if (
      row.smokeFeedback?.netChargeAmount === null &&
      row.smokeFeedback?.chargeAmount === null
    ) {
      return [null, null]
    }
    return [
      Number(row.smokeFeedback?.netChargeAmount),
      Number(row.smokeFeedback?.chargeAmount),
    ]
  }, [])

  const netChargeValueFormatter = useCallback(({ value }) => {
    const formattedValue = formatCurrency(value[0], '-')
    const asterisk = !Number.isNaN(value[1]) && value[1] !== value[0] ? '*' : ''
    return `${formattedValue}${asterisk}`
  }, [])

  const NetChargeItem = useCallback(
    ({ row, formattedValue }) => {
      const isAutochargePending =
        row.smokeFeedback?.autochargeMetadata?.chargeStatus === 'pending'

      return (
        <Box display="flex" alignItems="center">
          {isAutochargePending && (
            <Tooltip title="Auto-charge pending">
              <AccessTimeRounded
                fontSize="small"
                sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                color="warning"
              />
            </Tooltip>
          )}
          {isBoolean(row.smokeFeedback?.autocharge) &&
            (row.smokeFeedback.autocharge ? (
              <Tooltip title="Auto-charge successful">
                <AutoFixHighIcon
                  fontSize="small"
                  sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                  color="success"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Auto-charge failed">
                <AutoFixOffIcon
                  fontSize="small"
                  sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                  color="error"
                />
              </Tooltip>
            ))}
          <Tooltip title="View smoke feedback audit log" placement="top-end">
            <Box>
              <Typography
                onClick={() => {
                  setAuditLogOpen(true)
                  setSmokeFeedbackId(row.smokeFeedback.id)
                }}
                variant={isSmallScreen ? 'caption' : 'body2'}
                color="primary"
                align="right"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {formattedValue}
              </Typography>
              <Typography variant="caption" color="grey.500" align="right">
                {reasonsById?.[row.smokeFeedback?.adjustmentReason] ?? ''}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      )
    },
    [isSmallScreen],
  )

  const columns = [
    {
      field: 'eventType',
      sortable: false,
      headerName: '',
      flex: 0.01,
      renderCell: ({ row }) => <EventTypeCell type={row.eventType} />,
    },
    {
      field: 'eventClass',
      sortField: 'eventClass',
      headerName: 'Type',
      flex: 0.2,
      sortable: true,
      renderCell: ({ row }) => {
        switch (row.eventClass) {
          case 'NOISE':
            return (
              <Tooltip title="Noise">
                <VolumeUpIcon color="action" />
              </Tooltip>
            )
          case 'SMOKE':
            return (
              <Tooltip title="Smoke">
                <SmokeIcon color="action" style={{ transform: 'rotate(-90deg)' }} />
              </Tooltip>
            )
          default:
            return null
        }
      },
    },
    {
      field: 'unitName',
      sortField: 'unit__name',
      headerName: 'Unit',
      flex: 0.2,
      minWidth: 100,
      sortable: true,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.unitName}
          url={unitUrls.entity.replace(':id', row.unit)}
        />
      ),
    },
    {
      field: 'propertyName',
      sortField: 'property__name',
      headerName: 'Property',
      flex: 0.5,
      minWidth: 200,
      sortable: true,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.propertyName}
          url={propertyUrls.entity.replace(':id', row.property)}
        />
      ),
    },
    {
      field: 'createdOn',
      sortable: true,
      headerName: 'Detected On (Property Time)',
      flex: 0.7,
      renderCell: ({ row: { createdOn, endTime, timeZone } }) => {
        const [start, end] = getDetectedOnTime({ createdOn, endTime, timeZone })
        return (
          <Tooltip title={`${start} - ${end}`} placement="top">
            <Typography fontSize={isSmallScreen ? 12 : 14}>{start}</Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'comments',
      sortable: false,
      maxWidth: 200,
      headerName: 'Comments',
      flex: 1,
      valueGetter: (_, row) =>
        row.smokeFeedback?.comments ?? row.noiseFeedback?.comments,
      renderCell: GridCellExpand,
      renderMobile: ({ row }) =>
        row.smokeFeedback?.comments ?? row.noiseFeedback?.comments,
    },
    {
      field: 'feedback.modifiedByName',
      sortable: false,
      headerName: 'Last Modified',
      flex: 0.4,
      minWidth: 200,
      valueGetter: (_, row) =>
        row.eventClass === 'NOISE'
          ? row.noiseFeedback?.modifiedByName
          : row.smokeFeedback?.modifiedByName,
      valueFormatter: (value) => value ?? 'N/A',
      renderCell: (params) => (
        <LastModifiedItem row={params.row} formattedValue={params.formattedValue} />
      ),
    },
    {
      field: 'feedback.netChargeAmount',
      headerName: 'Net Charge',
      sortable: false,
      type: 'number',
      minWidth: 100,
      flex: 0.4,
      valueGetter: (_, row) => netChargeValueGetter(row),
      valueFormatter: (value) => netChargeValueFormatter({ value }),
      renderCell: (params) =>
        params.row.smokeFeedback?.id && (
          <NetChargeItem row={params.row} formattedValue={params.formattedValue} />
        ),
    },
    {
      field: 'extra_actions',
      sortable: false,
      headerName: 'Event/Scenario',
      headerAlign: 'center',
      width: isAtLeastDataScience ? 225 : 100,
      renderCell: (params) => (
        <ScenarioOptions
          row={params.row}
          setScenarioData={setScenarioData}
          setScenarioFormOpen={setScenarioFormOpen}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      display: 'flex',
      width: 40,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Remove"
          onClick={() => setRowIdToRemove(row.id)}
          showInMenu
        />,
      ],
    },
  ]

  const MobileItemHeader = useCallback(({ row }) => {
    let EventIcon = QuestionMarkRounded
    let label = 'Unknown'
    if (row.eventClass === 'NOISE') {
      EventIcon = VolumeUpIcon
      label = 'Noise'
    } else if (row.eventClass === 'SMOKE') {
      EventIcon = SmokeIcon
      label = 'Smoke'
    }
    return (
      <Box display="flex" sx={{ '&&': { mb: 1 } }}>
        <Box display="flex" alignItems="center">
          <EventTypeCell type={row.eventType} />
          <EventIcon
            color="action"
            sx={{ fontSize: 18, ml: 0.5 }}
            style={row.eventClass === 'SMOKE' ? { transform: 'rotate(-90deg)' } : null}
          />
          <Typography variant="caption" fontWeight="bold" ml={0.5}>
            {label} Event
          </Typography>
        </Box>
        {row.eventType !== 'EXTERNAL' && (
          <Typography variant="caption" ml={0.5} fontStyle="italic">
            (INTERNAL)
          </Typography>
        )}
      </Box>
    )
  }, [])

  const MobileItemFooter = useCallback(
    ({ row }) => (
      <>
        <MobileListCardRow
          label="Additional Occurencies"
          value={
            row.childCount ? (
              <Typography
                variant="caption"
                color="primary"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setAdditionalOccurenciesData(row)}
              >
                View
              </Typography>
            ) : null
          }
        />
        <Box sx={{ '&&': { mt: 1, mb: 0.5 } }}>
          <ScenarioOptions
            fullWidth
            row={row}
            setScenarioData={setScenarioData}
            setScenarioFormOpen={setScenarioFormOpen}
          />
        </Box>
      </>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['eventType', 'eventClass', 'actions', 'extra_actions'],
        header: <MobileItemHeader row={row} />,
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  const actionsLabels = { delete: 'Remove' }

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={selectedEvent}
          entity="event"
          onClose={() => {
            doUpdateQuery({}, { maintainScrollPosition: true })
            setSelectedEvent(null)
          }}
        />
      </ThemeProvider>
      <AuditLogModal
        open={auditLogOpen}
        label="Smoke event feedback"
        onClose={onAuditLogClose}
        smokeFeedbackId={smokeFeedbackId}
      />
      <AdditionalOccurenciesModal
        open={!!additionalOccurenciesData}
        onClose={() => setAdditionalOccurenciesData(null)}
        setScenarioData={setScenarioData}
        setScenarioFormOpen={setScenarioFormOpen}
      />
      <ConfirmationDialog
        title="Remove Event"
        message={`Are you sure you want to remove this event?
        This will mark the event as ‘removed.’ The event will not be billed and will no longer be visible to users in the dashboard, but will still be visible in portal.`}
        open={!!rowIdToRemove}
        onConfirm={handleEventDelete}
        onCancel={() => setRowIdToRemove(null)}
      />
      <Box sx={{ pb: 5 }}>
        <ScenarioForm
          open={scenarioFormOpen}
          onClose={onScenarioFormClose}
          onSave={doScenarioCreateFromReadings}
          instance={scenarioData}
        />
        {isSmallScreen ? (
          <MobileList
            title="Events"
            rows={eventsList.results}
            actions={{ delete: setRowIdToRemove }}
            actionsLabels={actionsLabels}
            itemBuilder={MobileListItem}
            page={eventsList.current ?? 0}
            pageChange={doEventsListSetPage}
            pageSize={eventsList.pageSize}
            pageSizeChange={doEventsListSetPageSize}
            rowCount={eventsList?.count ?? 0}
            setSearch={doEventsListSetSearch}
            pageSizeOptions={[25]}
            loading={eventsListIsLoading}
            hideFooter={(eventsList?.numPages ?? 0) <= 1}
          />
        ) : (
          <List
            columnsAutosize
            columns={columns}
            rows={eventsList.results}
            page={eventsList.current ?? 0}
            pageChange={doEventsListSetPage}
            pageSize={eventsList.pageSize}
            pageSizeChange={doEventsListSetPageSize}
            rowCount={eventsList?.count ?? 0}
            sortChange={doEventsListSetOrdering}
            setSearch={doEventsListSetSearch}
            actionsLabels={actionsLabels}
            pageSizeOptions={[25]}
            disableColumnMenu
            autoHeight
            loading={eventsListIsLoading}
            hideFooter={(eventsList?.numPages ?? 0) <= 1}
            getDetailPanelContent={detailRowContent}
            getDetailPanelHeight={detailRowHeight}
            currentOrdering={ordering}
          />
        )}
      </Box>
    </>
  )
}

export default EventsList

EventsList.defaultProps = {
  eventsList: {},
}

EventsList.propTypes = {
  eventsList: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    current: PropTypes.number,
    pageSize: PropTypes.number,
    count: PropTypes.number,
    numPages: PropTypes.number,
    organizationName: PropTypes.string,
  }),
}
