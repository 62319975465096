import PropTypes from 'prop-types'

import { styled, Tooltip, tooltipClasses } from '@mui/material'

const CustomizedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(232, 232, 232, 1)',
    color: 'rgba(0, 0, 0, 0.8)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '0.2em 0.4em',
  },
}))

export default function ListTextTooltipWrapper({ tooltip, children }) {
  return (
    <CustomizedTooltip enterNextDelay={1000} title={tooltip}>
      {children}
    </CustomizedTooltip>
  )
}

ListTextTooltipWrapper.defaultProps = {
  tooltip: undefined,
  children: undefined,
}

ListTextTooltipWrapper.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
