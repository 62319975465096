import PropTypes from 'prop-types'

import { KeyboardTabOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'

import {
  baseArrowStyles,
  leftArrowStyles,
  leftEventDrawerArrowStyles,
  rightArrowStyles,
  rightEventDrawerArrowStyles,
} from '@portal/pages/Sidebar/Sidebar.styles'

export function RightDrawerButton({ handleDrawer, leftOpen }) {
  return (
    <IconButton
      size="small"
      onClick={handleDrawer}
      sx={
        leftOpen
          ? { ...baseArrowStyles, ...rightArrowStyles }
          : { ...baseArrowStyles, ...rightEventDrawerArrowStyles }
      }
    >
      <KeyboardTabOutlined fontSize="small" sx={{ color: 'white' }} />
    </IconButton>
  )
}

export function LeftDrawerButton({ handleDrawer, leftOpen }) {
  return (
    <IconButton
      size="small"
      onClick={handleDrawer}
      sx={
        leftOpen
          ? { ...baseArrowStyles, ...leftArrowStyles }
          : { ...baseArrowStyles, ...leftEventDrawerArrowStyles }
      }
    >
      <KeyboardTabOutlined sx={{ rotate: '180deg', color: 'white' }} fontSize="small" />
    </IconButton>
  )
}

RightDrawerButton.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  leftOpen: PropTypes.bool.isRequired,
}

LeftDrawerButton.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  leftOpen: PropTypes.bool.isRequired,
}
