import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Group } from 'react-konva'

import { noop } from '@common/utils'

import { DeleteButton, EditButton } from './Buttons'
import { RECT_HORIZONTAL_ORIGIN } from './constants'
import { getRectWidth, Rect } from './Rects'

const HoverRectOverlay = forwardRef(
  (
    {
      editorWidth,
      top,
      bottom,
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onEdit,
      onDelete,
      visible,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        y={top}
        draggable
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <Rect editorWidth={editorWidth} top={0} bottom={bottom - top} color={color} />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={(bottom - top) / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={(bottom - top) / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)

HoverRectOverlay.defaultProps = {
  onMouseLeave: noop,
  onTouchStart: noop,
  onTouchEnd: noop,
  editorWidth: undefined,
}

HoverRectOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editorWidth: PropTypes.number,
}

export default HoverRectOverlay
