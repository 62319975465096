import { createListBundle, defaultInitialState } from '@common/utils'

import userUrls from './urls'

const entityName = 'user'

const userListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/users/', params, { cancelationPrefix: entityName }),
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (url) => url === userUrls.list,
})

export default userListBundle
