import { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Autocomplete,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { hoursOfTheDay } from '@rest/Utils/constants'

export default function Step2({
  dialogText,
  onClose,
  setStep,
  profileData,
  setProfileData,
}) {
  const [endTime, setQuietEnd] = useState(hoursOfTheDay[6])
  const [startTime, setQuietStart] = useState(hoursOfTheDay[21])
  const [errorMessage, setErrorMessage] = useState()

  const handleNextClick = () => {
    if (endTime === startTime) {
      return setErrorMessage('Quiet Hours must be less than 24 hours.')
    }
    setStep('three')
    return setProfileData({
      ...profileData,
      endTime: hoursOfTheDay.indexOf(endTime),
      startTime: hoursOfTheDay.indexOf(startTime),
    })
  }

  return (
    <Stack>
      <Divider />
      <Typography variant="body2" fontWeight="600" mt={2.5} mb={1.5}>
        {dialogText.stepTwoHeader}
      </Typography>
      <Typography variant="body2">{dialogText.stepTwoBody}</Typography>
      <Autocomplete
        options={hoursOfTheDay}
        value={startTime}
        onChange={(e, newValue) => {
          setQuietStart(newValue)
        }}
        sx={{ minWidth: '150px', mt: 2.5 }}
        disableClearable
        renderInput={(params) => <TextField {...params} label="Quiet Hours Start" />}
      />
      <Autocomplete
        options={hoursOfTheDay}
        value={endTime}
        onChange={(e, newValue) => {
          setQuietEnd(newValue)
          if (errorMessage) setErrorMessage(null)
        }}
        sx={{ minWidth: '150px', my: 2.5 }}
        disableClearable
        renderInput={(params) => <TextField {...params} label="Quiet Hours End" />}
      />
      {errorMessage && (
        <Typography variant="button" color="error" sx={{ textAlign: 'center' }}>
          {errorMessage}
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{ my: 2.5, borderRadius: '8px' }}
        onClick={handleNextClick}
        size="large"
      >
        Next
      </Button>
      <Button
        variant="contained"
        color="cancel"
        sx={{ mb: 4, borderRadius: '8px', ':hover': { bgcolor: 'grey.400' } }}
        onClick={() => setStep('one')}
        size="large"
      >
        Back
      </Button>
      <Button onClick={onClose} sx={{ mb: 4 }}>
        Cancel
      </Button>
    </Stack>
  )
}

Step2.propTypes = {
  dialogText: PropTypes.shape({
    stepTwoHeader: PropTypes.string,
    stepTwoBody: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  profileData: PropTypes.shape({}).isRequired,
  setProfileData: PropTypes.func.isRequired,
}
