import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { OpenInFullRounded } from '@mui/icons-material'
import { Box, Card, Typography } from '@mui/material'

import { titleize } from 'inflection'

import FullscreenMetricCard from './components/FullscreenMetricCard'
import MetricChart from './MetricChart'
import MetricTable from './MetricTable'
import getMetricTypesMeta from './metricTypesMeta'

export default function MetricCard({
  metric,
  data,
  dataInterval,
  requestedDateRange,
  selectedView,
  showTotal,
  sx,
}) {
  const { reportMetrics } = useConnect('selectReportMetrics')

  const [showFullscreenButton, setShowFullscreenButton] = useState(false)
  const [openFullscreenCard, setOpenFullscreenCard] = useState(false)

  const systemMetric = reportMetrics.find((obj) => obj.key === metric)
  const cardTitle = systemMetric?.name ?? titleize(metric)
  const dataTypes = getMetricTypesMeta(data)

  return (
    <>
      <FullscreenMetricCard
        open={openFullscreenCard}
        onClose={() => setOpenFullscreenCard(false)}
        cardData={{
          title: cardTitle,
          metric,
          data,
          dataTypes,
          dataInterval,
          requestedDateRange,
          showTotal,
        }}
      />
      <Card
        key={metric}
        sx={sx}
        onMouseEnter={() => setShowFullscreenButton(true)}
        onMouseLeave={() => setShowFullscreenButton(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={40}
          px={2}
          pt={2}
        >
          <Typography variant="body2">{cardTitle}</Typography>
          <Box
            onClick={() => setOpenFullscreenCard(true)}
            sx={{
              display: showFullscreenButton ? 'block' : 'none',
              cursor: 'pointer',
            }}
          >
            <OpenInFullRounded sx={{ fontSize: 16 }} />
          </Box>
        </Box>
        {selectedView === 'graph' ? (
          <MetricChart
            id={metric}
            data={data}
            dataTypes={dataTypes}
            dataInterval={dataInterval}
            requestedDateRange={requestedDateRange}
            showTotal={showTotal}
          />
        ) : (
          <MetricTable data={data} groupBy="metric" />
        )}
      </Card>
    </>
  )
}

MetricCard.defaultProps = {
  sx: {},
}

MetricCard.propTypes = {
  metric: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  dataInterval: PropTypes.shape({
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selectedView: PropTypes.string.isRequired,
  requestedDateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTotal: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
}
