import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  BookOutlined,
  DateRangeRounded,
  Refresh,
  RestartAlt,
  Settings,
  SettingsRounded,
  WarningAmberRounded,
  ZoomOut,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

import { useTheme } from '@emotion/react'
import { titleize } from 'inflection'
import { DateTime, Interval } from 'luxon'

import { DateSelectionModal } from '@common/components'
import { Chart, MemoChartMinimap } from '@common/components/chart'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import {
  formatScenarioName,
  parseApiErrors,
  useLocalStorage,
  useWindowSize,
} from '@common/utils'
import { days, hours } from '@common/utils/durations'
import processEventData, {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_MODEL_SCORES,
  CATEGORY_PM,
  CATEGORY_PM_RATIOS,
  CATEGORY_SOUND_LEVELS,
} from '@common/utils/grafanaViewDataProcessor'
import propertyUrls from '@portal/pages/Properties/urls'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import unitUrls from '@portal/pages/Units/urls'

import {
  eventTypesColorsPool,
  getEventTypesMeta,
  GRAFANA_DARK_BLUE,
  GRAFANA_DARK_GREEN,
  GRAFANA_GREEN,
  GRAFANA_RED,
} from './eventTypesMeta'
import HeaderItem from './HeaderItem'

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="up" ref={ref} {...props} />
))

const ALARM_OPENED = 'alarm_opened'
const ALARM_REJECTED = 'alarm_rejected'
const RESERVATIONS = 'reservations'
const SCENARIOS = 'scenarios'
const NOISE = 'noise'

const areaSwitchers = [ALARM_OPENED, ALARM_REJECTED, NOISE, RESERVATIONS, SCENARIOS]
const areaTooltipDateFormat = 'yyyy-MM-dd HH:mm:ss'

const refreshTimeInMillis = 30000

export default function EventViewModal({
  id,
  entity,
  unit,
  initialTimeRange,
  onClose,
}) {
  const availableDateOptions = [
    { title: '1H', value: 1 * hours },
    { title: '3H', value: 3 * hours },
    { title: '6H', value: 6 * hours },
    { title: '12H', value: 12 * hours },
    { title: '1D', value: 1 * days },
    { title: '2D', value: 2 * days },
    { title: '3D', value: 3 * days },
    { title: '7D', value: 7 * days },
    { title: '30D', value: 30 * days },
    { title: 'Custom', value: 'custom' },
  ]

  const categories = [
    CATEGORY_PM,
    CATEGORY_SOUND_LEVELS,
    CATEGORY_MODEL_SCORES,
    CATEGORY_PM_RATIOS,
    CATEGORY_ENVIRONMENT,
    CATEGORY_CO2_TVOC,
  ]

  const {
    isAtLeastDataScience,
    eventModalData,
    eventModalChartData,
    eventModalIsLoading,
    eventModalDataError,
    eventModalChartDataError,
    systemDataTypes,
    systemSmokeProfiles,
    systemTimeZones,
    doFetchEventModalData,
    doFetchEventModalChartData,
    doFetchEventModalUnitDevices,
    doResetEventModalState,
    doScenarioCreateFromReadings,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'selectEventModalData',
    'selectEventModalChartData',
    'selectEventModalIsLoading',
    'selectEventModalDataError',
    'selectEventModalChartDataError',
    'selectSystemDataTypes',
    'selectSystemSmokeProfiles',
    'selectSystemTimeZones',
    'doFetchEventModalData',
    'doFetchEventModalChartData',
    'doFetchEventModalUnitDevices',
    'doResetEventModalState',
    'doScenarioCreateFromReadings',
    'doShowSnackbar',
  )

  const defaultDateTimeData = { custom: null, option: 'custom', range: null }

  const theme = useTheme()

  const refreshInterval = useRef(null)

  const [refreshing, setRefreshing] = useState(false)
  const [modalCoreData, setModalCoreData] = useState(null)
  const [selectedData, setSelectedData] = useState({ id, entity })

  const [chartData, setChartData] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [selectedTimezone, setSelectedTimezone] = useState(null)
  const [selectedDateTimeData, setSelectedDateTimeData] = useState(defaultDateTimeData)
  const [scenarioFormData, setScenarioFormData] = useState(null)

  const [selectorId, setSelectorId] = useState(null)
  const [selectorStart, setSelectorStart] = useState(null)
  const [selectorEnd, setSelectorEnd] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const selectedAreaHistory = useRef([])

  const [cursor, setCursor] = useState(null)

  const [autoRefresh, setAutoRefresh] = useLocalStorage('grafanaAutoRefresh', true)
  const [timeframePopoverAnchor, setTimeframePopoverAnchor] = useState(null)
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState(null)
  const [openDateSelectionModal, setOpenDateSelectionModal] = useState(false)
  const [typesPopoverData, setTypesPopoverData] = useState(null)
  const [typeVisibilitySettings, setTypeVisibilitySettings] = useLocalStorage(
    'eventsTypeVisibility',
    {},
  )
  const [visibleAreas, setVisibleAreas] = useLocalStorage(
    'eventsVisibleAreas',
    areaSwitchers.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  )

  const fetchData = useCallback(async (payload) => {
    try {
      await doFetchEventModalData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const fetchChartData = useCallback(async (payload) => {
    try {
      await doFetchEventModalChartData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const fetchUnitBasedData = useCallback(async () => {
    if (unit) {
      try {
        const unitDevices = await doFetchEventModalUnitDevices(unit)
        const firstDeviceId = unitDevices[0].id
        setSelectedData({ id: firstDeviceId, entity: 'device', unitDevices })
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [unit])

  useEffect(() => {
    if (autoRefresh && !eventModalIsLoading) {
      refreshInterval.current = setInterval(() => {
        const now = DateTime.now().setZone(selectedTimezone)
        const endTime = selectedDateTimeData?.range?.[1]

        if (endTime && now) {
          const diff = Interval.fromDateTimes(endTime, now)
          const diffMinutes = diff.length('minutes')

          if (diffMinutes <= 5) {
            setSelectedDateTimeData((prev) => ({
              ...prev,
              range: prev.range.map((date) =>
                date.plus({ seconds: refreshTimeInMillis / 1000 }),
              ),
            }))
          }
        }
      }, refreshTimeInMillis)
    }

    return () => clearInterval(refreshInterval.current)
  }, [eventModalIsLoading, autoRefresh])

  const handleZoomOut = () => {
    selectedAreaHistory.current.splice(-1)
    const latestHistoryArea = selectedAreaHistory.current.at(-1)
    setSelectedArea(
      latestHistoryArea ? { ...latestHistoryArea, updateBrush: true } : null,
    )
  }

  const handleZoomReset = () => {
    selectedAreaHistory.current.length = 0
    setSelectedArea(null)
  }

  const [width] = useWindowSize()
  const isSmallScreen = width <= 1650

  const cleanUp = useCallback(() => {
    doResetEventModalState()
    handleZoomReset()
    setChartData(null)
    setChartData(null)
    setSelectedDateTimeData(defaultDateTimeData)
    setSelectedData({})
    clearInterval(refreshInterval.current)
  }, [])

  useEffect(() => {
    setSelectedData({ id, entity })
  }, [id])

  useEffect(() => {
    fetchUnitBasedData()
  }, [unit])

  useEffect(() => {
    if (!id && !unit) {
      cleanUp()
    }
  }, [id, unit])

  useEffect(() => {
    if (selectedData.id) {
      // make sure that newly added areas will be enabled by default
      setVisibleAreas((prev) => {
        const updated = { ...prev }
        areaSwitchers.forEach((key) => {
          if (updated[key] === undefined) {
            updated[key] = true
          }
        })
        return updated
      })

      // make all smoke profiles always selected by default
      setTypeVisibilitySettings((prev) => {
        const copy = { ...prev }
        systemSmokeProfiles.forEach((profile) => {
          Object.assign(copy, { [profile.id]: true })
        })
        return copy
      })

      fetchData({
        id: selectedData.id,
        entity: selectedData.entity,
      })
    }
  }, [selectedData.id])

  useEffect(() => {
    if (eventModalData) {
      setModalCoreData(eventModalData)
    }
  }, [eventModalData])

  useEffect(() => {
    if (refreshing) {
      if (selectedDateTimeData.option !== 'custom') {
        setSelectedDateTimeData((prev) => {
          const now = DateTime.now().setZone(selectedTimezone)
          const range = [now.minus({ milliseconds: prev.option }), now]
          return { ...prev, range }
        })
      } else {
        fetchChartData({
          event: modalCoreData.event,
          device: modalCoreData.device,
          selectedArea,
          selectedTimezone,
          selectedDateTimeData,
        })
      }

      setRefreshing(false)
      return
    }

    const eventData = modalCoreData?.event
    const deviceData = modalCoreData?.device

    const timeNow = DateTime.now()
    const timezone = eventData?.timeZone ?? deviceData?.timezone

    let start = timeNow.minus({ days: 1 })
    let end = timeNow
    if (eventData) {
      start = DateTime.fromISO(eventData.createdOn)
      end = eventData.endTime ? DateTime.fromISO(eventData.endTime) : timeNow
    } else if (deviceData && initialTimeRange) {
      ;[start, end] = initialTimeRange
    }

    setSelectedTimezone(timezone)
    setSelectedDateTimeData(
      eventData || deviceData
        ? { custom: !eventData, option: 'custom', range: [start, end] }
        : defaultDateTimeData,
    )
  }, [modalCoreData])

  useEffect(() => {
    if (modalCoreData && selectedDateTimeData.range) {
      fetchChartData({
        event: modalCoreData.event,
        device: modalCoreData.device,
        selectedArea,
        selectedTimezone,
        selectedDateTimeData,
      })
    }
  }, [modalCoreData, selectedDateTimeData, selectedArea])

  useEffect(() => {
    setSelectedDateTimeData((prev) => {
      if (prev.range) {
        return {
          ...prev,
          range: prev.range.map((date) => date.setZone(selectedTimezone)),
        }
      }
      return prev
    })
  }, [selectedTimezone])

  useEffect(() => {
    if (eventModalChartData) {
      if (!selectedArea) {
        setChartData(eventModalChartData)
      }
      setCurrentData(eventModalChartData)
    }
  }, [eventModalChartData])

  const categoriesPosition = categories.reduce(
    (acc, category, index) => ({ ...acc, [category]: index }),
    {},
  )

  const eventTypesMeta = getEventTypesMeta(systemDataTypes, systemSmokeProfiles)

  const deviceDataByCategory = useMemo(() => {
    const processData = (name, value) => {
      if (value.category === CATEGORY_MODEL_SCORES) {
        return {
          name,
          ...value,
          visible:
            typeVisibilitySettings[value.key] ??
            systemSmokeProfiles.map((profile) => profile.id).includes(name),
        }
      }
      return {
        ...value,
        name,
        visible: typeVisibilitySettings[value.key] ?? value.visible,
      }
    }

    return Object.entries(eventTypesMeta).reduce(
      (acc, [name, value]) => ({
        ...acc,
        [value.category]:
          value.category in acc
            ? [...acc[value.category], processData(name, value)]
            : [processData(name, value)],
      }),
      {},
    )
  }, [eventTypesMeta, systemSmokeProfiles])

  const handleDateTimeChange = (dateRange, option) => {
    const zonedDateRange = dateRange.map((date) =>
      date.setZone(selectedTimezone, { keepLocalTime: true }),
    )
    setSelectedDateTimeData({ custom: true, option, range: zonedDateRange })
    setOpenDateSelectionModal(false)
    setTimeframePopoverAnchor(null)
    handleZoomReset()
  }

  const handleTimeframeChange = (_, value) => {
    if (value === 'custom') {
      setOpenDateSelectionModal(true)
      return
    }

    const now = DateTime.now().setZone(selectedTimezone)
    const range = [now.minus({ milliseconds: value }), now]
    handleDateTimeChange(range, value)
  }

  const processedData = useMemo(
    () =>
      categories.reduce(
        (acc, category) => ({
          ...acc,
          [category]: {
            current: processEventData(chartData?.data, category, selectedTimezone),
            zoomed: processEventData(currentData?.data, category, selectedTimezone),
          },
        }),
        {},
      ),
    [currentData],
  )

  const minimapData = useMemo(() => {
    const data = Object.values(processedData).find(
      (dt) => dt.current?.length > 0 && dt.current[0].time,
    )
    if (data?.current?.length > 0) {
      return data.current
    }
    return null
  }, [processedData])

  const getCardTitle = (category) => {
    switch (category) {
      case CATEGORY_PM:
        return 'PM (subtracted)'
      case CATEGORY_PM_RATIOS:
        return 'PM Ratios'
      case CATEGORY_ENVIRONMENT:
        return 'Environment'
      case CATEGORY_CO2_TVOC:
        return 'CO2/TVOC'
      default:
        return titleize(category)
    }
  }

  const formatEventDateRange = () => {
    const format = 'LLL dd, yyyy hh:mma'
    const formattedStart = DateTime.fromISO(modalCoreData.event.createdOn, {
      zone: selectedTimezone,
    }).toFormat(format)
    const formattedEnd = modalCoreData.event.endTime
      ? DateTime.fromISO(modalCoreData.event.endTime, {
          zone: selectedTimezone,
        }).toFormat(format)
      : 'Ongoing'
    return `${formattedStart} - ${formattedEnd}`
  }

  const formatDateTooltip = () => {
    if (selectedDateTimeData.range) {
      const format = 'LLL dd, yyyy hh:mma'

      const [start, end] = selectedDateTimeData.range
      return `${start.toFormat(format)} - ${end.toFormat(format)}`
    }
    return ''
  }

  const getAreaStyle = useCallback(
    ({ color }) => ({
      dashedBackground: false,
      filledBackground: true,
      area: { color, opacity: 0.1 },
      handle: { color, dashArray: [4, 4], opacity: 0.6 },
    }),
    [],
  )

  const alertsAreas = useMemo(
    () =>
      currentData?.alarms
        .filter((alarm) => {
          const isRejected = alarm.rejections
          const showOpened = visibleAreas[ALARM_OPENED]
            ? !alarm.rejections
            : alarm.rejections
          const showRejected = visibleAreas[ALARM_REJECTED]
            ? alarm.rejections
            : !alarm.rejections
          const dataTypeVisible = !alarm.tag || typeVisibilitySettings[alarm.tag]

          return (
            dataTypeVisible &&
            ((isRejected && showRejected) || (!isRejected && showOpened))
          )
        })
        .map((alarm) => {
          const formattedLabel = `${alarm.rejections ? 'REJECTED' : ''} Alarm ${
            alarm.id
          }`
          const formattedStart = DateTime.fromISO(alarm.start)
            .setZone(selectedTimezone)
            .toFormat(areaTooltipDateFormat)
          const formattedEnd =
            alarm.end && !alarm.ongoing
              ? DateTime.fromISO(alarm.end)
                  .setZone(selectedTimezone)
                  .toFormat(areaTooltipDateFormat)
              : 'Ongoing'

          const profile = systemSmokeProfiles.find((item) => item.id === alarm.tag)

          return {
            id: alarm.id,
            start: alarm.start,
            end: alarm.end,
            payload: {
              label: formattedLabel,
              start: formattedStart,
              end: formattedEnd,
              modelTag: profile ? `${profile.modelKey} - ${profile.name}` : null,
              rejections: alarm.rejections,
              metadata: JSON.stringify(alarm.metadata, null, 2),
            },
            style: getAreaStyle({
              color: alarm.rejections ? GRAFANA_RED : GRAFANA_GREEN,
            }),
            priority: alarm.rejections ? 20 : 30,
          }
        }),
    [
      currentData,
      visibleAreas[ALARM_OPENED],
      visibleAreas[ALARM_REJECTED],
      typeVisibilitySettings,
    ],
  )

  const noiseAreas = useMemo(
    () =>
      visibleAreas[NOISE]
        ? currentData?.noiseEvents.map((event) => {
            const formattedStart = event.createdOn
              .setZone(selectedTimezone)
              .toFormat(areaTooltipDateFormat)
            const formattedEnd =
              event.endTime && !event.ongoing
                ? event.endTime
                    .setZone(selectedTimezone)
                    .toFormat(areaTooltipDateFormat)
                : 'Ongoing'

            return {
              id: event.id,
              start: event.createdOn,
              end: event.endTime,
              payload: {
                label: 'Noise Event',
                start: formattedStart,
                end: formattedEnd,
              },
              style: getAreaStyle({ color: GRAFANA_DARK_GREEN }),
              priority: 20,
            }
          })
        : [],
    [currentData, visibleAreas[NOISE]],
  )

  const reservationsAreas = useMemo(
    () =>
      visibleAreas[RESERVATIONS]
        ? currentData?.reservations.map((reservation) => ({
            id: reservation.id,
            start: reservation.checkIn,
            end: reservation.checkOut,
            payload: {
              label: 'Reservation',
              checkIn: DateTime.fromISO(reservation.checkIn)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              checkOut: DateTime.fromISO(reservation.checkOut)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
            },
            style: getAreaStyle({ color: GRAFANA_DARK_BLUE }),
            priority: 0,
          }))
        : [],
    [currentData, visibleAreas[RESERVATIONS]],
  )

  const scenariosAreas = useMemo(
    () =>
      visibleAreas[SCENARIOS]
        ? currentData?.scenarios.map((scenario) => ({
            id: scenario.id,
            start: DateTime.fromISO(scenario.readingStart),
            end: DateTime.fromISO(scenario.readingEnd),
            payload: {
              label: `Scenario - ${scenario.name}`,
              createdOn: DateTime.fromISO(scenario.createdOn)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              start: DateTime.fromISO(scenario.readingStart)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              end: DateTime.fromISO(scenario.readingEnd)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              ...scenario.labels.reduce(
                (acc, label, index) => ({
                  ...acc,
                  [index !== 0 ? `label_${index}_start` : 'labelStart']:
                    DateTime.fromISO(label.start)
                      .setZone(selectedTimezone)
                      .toFormat(areaTooltipDateFormat),
                  [index !== 0 ? `label_${index}_end` : 'labelEnd']: DateTime.fromISO(
                    label.end,
                  )
                    .setZone(selectedTimezone)
                    .toFormat(areaTooltipDateFormat),
                }),
                {},
              ),
            },
            style: getAreaStyle({ color: '#5CBFB0' }),
            priority: 10,
          }))
        : [],
    [currentData, visibleAreas[SCENARIOS]],
  )

  const reduceGraph = (acc, [key, dataTypes], columns) => {
    const categoryHasData = processedData[key].zoomed.find(
      (d) => d.time !== null && d.time !== undefined,
    )

    // get rid of unused types and update colors only for available models
    const processedDataTypes =
      categoryHasData && key === CATEGORY_MODEL_SCORES
        ? dataTypes
            .filter((type) => currentData.data.find((data) => data.tag === type.key))
            .map((type, i) => ({
              ...type,
              color: eventTypesColorsPool[i % eventTypesColorsPool.length],
            }))
        : dataTypes

    return [
      ...acc,
      <Card
        key={`${key}_data`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: `${100 / columns}%`,
          m: 1,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02))',
        }}
      >
        <Box width="100%" display="flex" position="relative" justifyContent="center">
          <Typography m={2}>{getCardTitle(key)}</Typography>
          {categoryHasData && (
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                m: 1,
                color: theme.palette.text.secondary,
              }}
              onClick={(event) =>
                setTypesPopoverData({ category: key, anchor: event.currentTarget })
              }
            >
              <Settings />
            </IconButton>
          )}
        </Box>
        {categoryHasData ? (
          <Chart
            id={key}
            data={processedData[key].current}
            zoomedData={processedData[key].zoomed}
            highlightedAreas={[
              ...alertsAreas,
              ...reservationsAreas,
              ...scenariosAreas,
              ...noiseAreas,
            ]}
            dataTypes={processedDataTypes}
            noiseThresholdRules={
              key === CATEGORY_SOUND_LEVELS ? currentData.thresholdRules : null
            }
            yMin={key === CATEGORY_MODEL_SCORES ? 0 : null}
            yMax={key === CATEGORY_MODEL_SCORES ? 100 : null}
            cursorState={[cursor, setCursor]}
            zoomState={{
              selectorIdState: [selectorId, setSelectorId],
              selectorStartState: [selectorStart, setSelectorStart],
              selectorEndState: [selectorEnd, setSelectorEnd],
              selectedAreaState: [selectedArea, setSelectedArea],
              historyRef: selectedAreaHistory,
            }}
            settings={{
              showNotAvailableInTooltip: true,
              tooltip: {
                notAvailableText: key !== CATEGORY_MODEL_SCORES ? 'N/A' : 'null',
              },
            }}
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            m={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" color="text.secondary" textAlign="center">
              No {titleize(key)} data to display.
            </Typography>
          </Box>
        )}
      </Card>,
    ]
  }

  const buildGraphsRow = useCallback(
    (chartCategories) =>
      Object.entries(deviceDataByCategory)
        .filter((entry) => chartCategories.includes(entry[0]))
        .sort(([a], [b]) => categoriesPosition[a] - categoriesPosition[b])
        .reduce((acc, arr) => reduceGraph(acc, arr, chartCategories.length), []),
    [deviceDataByCategory],
  )

  const deviceOption = useCallback(
    (option) => (
      <Box>
        <Typography variant="h6">{option.mainMac}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          fontSize="0.7rem"
        >
          {option.propertyName} - {option.unitName}
        </Typography>
      </Box>
    ),
    [],
  )

  const propertyLink = useMemo(() => {
    const propertyId =
      modalCoreData?.[selectedData.entity]?.property ??
      modalCoreData?.[selectedData.entity]?.propertyId
    return propertyId ? propertyUrls.entity.replace(':id', propertyId) : null
  }, [modalCoreData, selectedData])

  const unitLink = useMemo(() => {
    const unitId =
      modalCoreData?.[selectedData.entity]?.unit ??
      modalCoreData?.[selectedData.entity]?.unitId
    return unitId ? unitUrls.entity.replace(':id', unitId) : null
  }, [modalCoreData, selectedData])

  return (
    <>
      <DateSelectionModal
        open={openDateSelectionModal}
        onCancel={() => setOpenDateSelectionModal(false)}
        onSave={(range) => handleDateTimeChange(range, 'custom')}
      />
      <ScenarioForm
        open={!!scenarioFormData}
        onClose={(result) => {
          setScenarioFormData(null)
          if (result === true) {
            fetchChartData({
              event: modalCoreData.event,
              device: modalCoreData.device,
              selectedArea,
              selectedTimezone,
              selectedDateTimeData,
            })
          }
        }}
        onSave={doScenarioCreateFromReadings}
        instance={scenarioFormData}
      />
      <Popover
        open={!!timeframePopoverAnchor}
        onClose={() => setTimeframePopoverAnchor(null)}
        anchorEl={timeframePopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="row" px={2} py={2}>
          <ToggleButtonGroup
            exclusive
            onChange={handleTimeframeChange}
            size="small"
            variant="outlined"
          >
            {availableDateOptions.map((option) => {
              const selected = selectedDateTimeData.option === option.value
              const tooltipText = selected ? formatDateTooltip() : null
              return (
                <Tooltip key={option.value} title={tooltipText}>
                  <ToggleButton value={option.value} selected={selected}>
                    {option.title}
                  </ToggleButton>
                </Tooltip>
              )
            })}
          </ToggleButtonGroup>
        </Stack>
      </Popover>
      <Popover
        open={!!filterPopoverAnchor}
        onClose={() => setFilterPopoverAnchor(null)}
        anchorEl={filterPopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack>
          <Typography variant="h6" px={2} pt={1} sx={{ fontWeight: 'bold' }}>
            Filters
          </Typography>
          <Stack direction="row" gap={1} pl={2}>
            {areaSwitchers.map((key) => (
              <FormControlLabel
                key={key}
                label={titleize(key)}
                labelPlacement="end"
                slotProps={{ typography: { fontSize: 12 } }}
                control={
                  <Checkbox
                    checked={visibleAreas[key]}
                    size="small"
                    onChange={() =>
                      setVisibleAreas((areas) => ({
                        ...areas,
                        [key]: !areas[key],
                      }))
                    }
                  />
                }
              />
            ))}
          </Stack>
          <Typography variant="h6" px={2} sx={{ fontWeight: 'bold' }}>
            Settings
          </Typography>
          <FormControlLabel
            label="Auto-refresh"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                checked={autoRefresh}
                size="small"
                onChange={() => setAutoRefresh((value) => !value)}
              />
            }
            sx={{ pl: 2, pb: 0.5 }}
          />
        </Stack>
      </Popover>
      <Popover
        open={!!typesPopoverData}
        onClose={() => setTypesPopoverData(null)}
        anchorEl={typesPopoverData?.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {typesPopoverData?.category &&
          deviceDataByCategory[typesPopoverData.category]?.map((dataType) => {
            if (typesPopoverData.category === CATEGORY_MODEL_SCORES) {
              const profileData = systemSmokeProfiles.find(
                (profile) => profile.id === dataType.key,
              )
              if (!profileData) {
                return null
              }
              if (!currentData.data.find((data) => data.tag === profileData.id)) {
                return null
              }
            }
            return (
              <Stack direction="column" gap={2} px={1.5}>
                <FormControlLabel
                  key={`${typesPopoverData.category}_${dataType.tooltipLabel}`}
                  label={dataType.tooltipLabel}
                  labelPlacement="end"
                  slotProps={{ typography: { fontSize: 14 } }}
                  control={
                    <Checkbox
                      checked={typeVisibilitySettings[dataType.key] ?? dataType.visible}
                      size="small"
                      onChange={() =>
                        setTypeVisibilitySettings((settings) => ({
                          ...settings,
                          [dataType.key]:
                            settings[dataType.key] !== undefined
                              ? !settings[dataType.key]
                              : !dataType.visible,
                        }))
                      }
                    />
                  }
                />
              </Stack>
            )
          })}
      </Popover>
      <Dialog
        fullScreen
        open={!!id || !!unit}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundImage: 'unset',
            boxShadow: 'none',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box>
            {selectedData.entity && (
              <Typography
                variant="body2"
                color={theme.palette.muted}
                fontSize={10}
              >{`${selectedData.entity} id: ${selectedData.id}`}</Typography>
            )}
            {modalCoreData && selectedData.id && (
              <Stack direction="row" gap={2} mt={2} alignItems="center">
                <DynamicSelect
                  getFullEntity
                  disableClearable
                  useValueInRequest={false}
                  label="Device"
                  endpoint="devices"
                  value={modalCoreData.device}
                  onChange={(device) => {
                    setSelectedData({ id: device.id, entity: 'device' })
                  }}
                  disabled={eventModalIsLoading}
                  primaryTextAttr="mainMac"
                  optionRender={deviceOption}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 206 }}
                />
                <HeaderItem
                  label="Property"
                  value={modalCoreData[selectedData.entity]?.propertyName ?? 'N/A'}
                  linkTo={propertyLink}
                />
                <HeaderItem
                  label="Unit"
                  value={modalCoreData[selectedData.entity]?.unitName ?? 'N/A'}
                  linkTo={unitLink}
                />
                {modalCoreData?.event && (
                  <HeaderItem label="Event Range" value={formatEventDateRange()} />
                )}
                {chartData && (
                  <Tooltip title="Refresh">
                    <IconButton
                      disabled={eventModalIsLoading}
                      onClick={() => {
                        setRefreshing(true)
                        fetchData({
                          id: selectedData.id,
                          entity: selectedData.entity,
                        })
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                )}
                {chartData && (
                  <Tooltip title={formatDateTooltip()}>
                    <IconButton
                      disabled={eventModalIsLoading}
                      onClick={(event) =>
                        setTimeframePopoverAnchor(event.currentTarget)
                      }
                    >
                      <DateRangeRounded />
                    </IconButton>
                  </Tooltip>
                )}
                {chartData && (
                  <Button
                    variant="outlined"
                    startIcon={<SettingsRounded />}
                    disabled={eventModalIsLoading}
                    onClick={(event) => setFilterPopoverAnchor(event.currentTarget)}
                  >
                    Settings
                  </Button>
                )}
                {chartData && (
                  <StaticSelect
                    disableClearable
                    label="Timezone"
                    options={['UTC', ...systemTimeZones]}
                    value={selectedTimezone}
                    disabled={eventModalIsLoading}
                    onChange={(zone) => {
                      setSelectedTimezone(zone)
                      fetchChartData({
                        event: modalCoreData.event,
                        device: modalCoreData.device,
                        selectedArea,
                        selectedTimezone: zone,
                        selectedDateTimeData,
                      })
                    }}
                    size="small"
                    sx={{ minWidth: 300 }}
                  />
                )}
                {chartData && currentData && isAtLeastDataScience && (
                  <Button
                    disabled={
                      !modalCoreData.device ||
                      currentData.scenarios.length > 0 ||
                      eventModalIsLoading
                    }
                    variant="outlined"
                    startIcon={<BookOutlined />}
                    onClick={() => {
                      setScenarioFormData({
                        name: formatScenarioName(
                          modalCoreData[selectedData.entity]?.propertyName,
                          modalCoreData[selectedData.entity]?.unitName,
                          modalCoreData[selectedData.entity]?.createdOn ??
                            selectedArea?.start ??
                            selectedDateTimeData.range[0],
                        ),
                        sourceDevice: modalCoreData.device.id,
                        deviceModel: modalCoreData.device.model,
                        readingStart:
                          selectedArea?.start ?? selectedDateTimeData.range[0],
                        readingEnd: selectedArea?.end ?? selectedDateTimeData.range[1],
                      })
                    }}
                  >
                    Save Scenario
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {!chartData && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            {eventModalIsLoading && <CircularProgress />}
            {!eventModalIsLoading &&
              (eventModalDataError || eventModalChartDataError) && (
                <Stack alignItems="center">
                  <WarningAmberRounded sx={{ fill: 'grey', fontSize: 48 }} />
                  <Typography variant="h4" color="grey">
                    Oops. Something went wrong
                  </Typography>
                </Stack>
              )}
          </Box>
        )}
        <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {eventModalIsLoading && chartData && (
            <CircularProgress sx={{ position: 'absolute' }} />
          )}
          <Box
            sx={{
              width: '100%',
              transitionProperty: 'opacity',
              transitionDuration: '300ms',
              opacity: eventModalIsLoading ? 0.35 : 1,
              pointerEvents: eventModalIsLoading ? 'none' : 'initial',
            }}
          >
            <Box m={2}>
              <Box
                display="flex"
                gap={1}
                sx={{
                  transitionProperty: 'height, margin',
                  transitionDuration: '300ms',
                  overflow: 'hidden',
                  height: selectedArea ? 36.5 : 0,
                  mb: selectedArea ? 2 : 0,
                }}
              >
                <Button
                  startIcon={<ZoomOut />}
                  variant="outlined"
                  onClick={handleZoomOut}
                >
                  Zoom Out
                </Button>
                <Button
                  startIcon={<RestartAlt />}
                  variant="outlined"
                  onClick={handleZoomReset}
                >
                  Reset Zoom
                </Button>
              </Box>
              {chartData && minimapData && (
                <Box sx={{ mb: 1 }}>
                  <MemoChartMinimap
                    id="chart_minimap"
                    data={minimapData}
                    selectedArea={selectedArea}
                    onChange={(area) => {
                      setSelectedArea(area)
                      selectedAreaHistory.current.push(area)
                      setSelectorStart(null)
                      setSelectorEnd(null)
                    }}
                    onMove={(area) => {
                      setSelectorStart(area.start)
                      setSelectorEnd(area.end)
                    }}
                  />
                </Box>
              )}
              {chartData && (
                <Box display="flex">
                  {buildGraphsRow([CATEGORY_PM, CATEGORY_SOUND_LEVELS])}
                </Box>
              )}
              {chartData && (
                <Box display="flex">
                  {buildGraphsRow([CATEGORY_MODEL_SCORES, CATEGORY_ENVIRONMENT])}
                </Box>
              )}
              {chartData && !isSmallScreen && (
                <Box display="flex">
                  {buildGraphsRow([CATEGORY_PM_RATIOS, CATEGORY_CO2_TVOC])}
                </Box>
              )}
              {chartData && isSmallScreen && (
                <Box display="flex">
                  {buildGraphsRow([CATEGORY_PM_RATIOS, CATEGORY_CO2_TVOC])}
                </Box>
              )}
              {chartData && isSmallScreen && (
                <Box display="flex">{buildGraphsRow([CATEGORY_ENVIRONMENT])}</Box>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

EventViewModal.defaultProps = {
  id: null,
  unit: undefined,
  initialTimeRange: undefined,
  onClose: () => {},
}

EventViewModal.propTypes = {
  id: PropTypes.string,
  entity: PropTypes.oneOf(['event', 'device']).isRequired,
  unit: PropTypes.string,
  initialTimeRange: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
}
