import { uniq } from 'ramda'

import { createListBundle, defaultInitialState } from '@common/utils'

import propertySmokeProfilesUrls from './urls'

const PROPERTY_BULK_SET_ALERTING_PROFILE_STARTED =
  'PROPERTY_BULK_SET_ALERTING_PROFILE_STARTED'
const PROPERTY_BULK_SET_ALERTING_PROFILE_SUCCEEDED =
  'PROPERTY_BULK_SET_ALERTING_PROFILE_SUCCEEDED'
const PROPERTY_BULK_SET_ALERTING_PROFILE_FAILED =
  'PROPERTY_BULK_SET_ALERTING_PROFILE_FAILED'

const PROPERTY_BULK_CHANGE_SHADOW_PROFILES_STARTED =
  'PROPERTY_BULK_CHANGE_SHADOW_PROFILES_STARTED'
const PROPERTY_BULK_CHANGE_SHADOW_PROFILES_SUCCEEDED =
  'PROPERTY_BULK_CHANGE_SHADOW_PROFILES_SUCCEEDED'
const PROPERTY_BULK_CHANGE_SHADOW_PROFILES_FAILED =
  'PROPERTY_BULK_CHANGE_SHADOW_PROFILES_FAILED'

const entityName = 'propertySmokeProfile'

const propertySmokeProfileListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/properties/smoke_profiles/', params, { cancelationPrefix: entityName }),
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (url) => url === propertySmokeProfilesUrls.list,
})

export default {
  ...propertySmokeProfileListBundle,
  doPropertySetAlertingProfile:
    ({ ids, profileId }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: PROPERTY_BULK_SET_ALERTING_PROFILE_STARTED })

        const result = await apiFetch(
          '/properties/smoke_profiles/',
          ids.map((id) => ({ id, alertingSmokeProfile: profileId })),
          { method: 'PUT', cancelationPrefix: entityName },
        )

        dispatch({ type: PROPERTY_BULK_SET_ALERTING_PROFILE_SUCCEEDED, data: result })
        return result
      } catch (err) {
        dispatch({ type: PROPERTY_BULK_SET_ALERTING_PROFILE_FAILED })
        return err
      }
    },
  doPropertyChangeShadowProfiles:
    ({ properties, profile, operation }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: PROPERTY_BULK_CHANGE_SHADOW_PROFILES_STARTED })

        let payload = null
        if (operation === 'add') {
          payload = properties.map((property) => ({
            id: property.id,
            shadowSmokeProfiles: uniq([...property.shadowSmokeProfiles, profile]),
          }))
        } else if (operation === 'remove') {
          payload = properties.map((property) => ({
            id: property.id,
            shadowSmokeProfiles: property.shadowSmokeProfiles.filter(
              (item) => item !== profile,
            ),
          }))
        }
        if (!payload) {
          throw Error(`Unsupported operation: ${operation}`)
        }

        const result = await apiFetch('/properties/smoke_profiles/', payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        })

        dispatch({ type: PROPERTY_BULK_CHANGE_SHADOW_PROFILES_SUCCEEDED, data: result })
        return result
      } catch (err) {
        dispatch({ type: PROPERTY_BULK_CHANGE_SHADOW_PROFILES_FAILED })
        return err
      }
    },
}
