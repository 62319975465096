import PropTypes from 'prop-types'

import { Box, Divider, Stack, Typography } from '@mui/material'

export default function MetricsDetailItem({ metrics }) {
  return (
    <Stack sx={{ width: 'fit-content' }}>
      <Divider sx={{ my: 0.2 }} />
      <Box display="flex" gap={3}>
        {metrics.map(({ name, value }) => (
          <Stack key={`${name}_${value}`} direction="column">
            <Typography variant="caption" color="grey.500" fontWeight={600}>
              {name}
            </Typography>
            <Typography variant="body1" color="black" whiteSpace="pre-line">
              {value}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Stack>
  )
}

MetricsDetailItem.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
