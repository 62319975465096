import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import { Box, CircularProgress, Menu, MenuItem, Stack, Typography } from '@mui/material'

import { MobileDetailRow } from '@common/components'
import { isAbortError, parseApiErrors } from '@common/utils'
import {
  getMetricFormattedName,
  getMetricPosition,
  METRICS_30_DAYS_INTERVAL,
  METRICS_MTD_INTERVAL,
  METRICS_PREV_MONTH_INTERVAL,
} from '@common/utils/propertyDetailMetricsUtils'

import MetricsDetailItem from './MetricsDetailItem'

const intervalOptions = [
  METRICS_30_DAYS_INTERVAL,
  METRICS_MTD_INTERVAL,
  METRICS_PREV_MONTH_INTERVAL,
]

export default function MetricsDetailStack({ property, mobileLayout, ...rest }) {
  const [interval, setInterval] = useState(METRICS_30_DAYS_INTERVAL)
  const [menuAnchor, setMenuAnchor] = useState(null)

  const {
    propertyDetailsMetrics,
    propertyDetailsMetricsIsLoading,
    doFetchPropertyMetrics,
    doShowSnackbar,
  } = useConnect(
    'selectPropertyDetailsMetrics',
    'selectPropertyDetailsMetricsIsLoading',
    'doFetchPropertyMetrics',
    'doShowSnackbar',
  )

  const processedMetrics = useMemo(
    () =>
      Object.entries(propertyDetailsMetrics ?? {})
        .reduce(
          (acc, [key, value]) => [
            ...acc,
            {
              metric: key,
              value,
              position: getMetricPosition(key),
              name: getMetricFormattedName(key),
            },
          ],
          [],
        )
        .sort((a, b) => a.position - b.position),
    [propertyDetailsMetrics],
  )

  const fetchMetricsData = useCallback(async () => {
    try {
      await doFetchPropertyMetrics({ property, interval })
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [property, interval])

  useEffect(() => {
    fetchMetricsData()
  }, [property, interval])

  const SelectorArrow = menuAnchor ? ArrowDropUpRounded : ArrowDropDownRounded

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        {intervalOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setInterval(option)
              setMenuAnchor(null)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      {mobileLayout ? (
        <>
          <Box
            display="flex"
            alignItems="end"
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ cursor: 'pointer' }}
          >
            <MobileDetailRow
              key={interval}
              label={interval}
              bold={!!processedMetrics}
              sx={{ mt: processedMetrics ? 1.5 : 0 }}
            />
            <SelectorArrow />
          </Box>
          {propertyDetailsMetricsIsLoading && (
            <CircularProgress size={24} sx={{ mt: 2 }} />
          )}
          {processedMetrics &&
            processedMetrics.map(({ name, value }) => (
              <MobileDetailRow key={name} label={name} value={value} />
            ))}
        </>
      ) : (
        <Stack {...rest}>
          <Box
            display="flex"
            alignItems="end"
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant="caption" color="grey.500" fontWeight={600}>
              {interval}
            </Typography>
            <SelectorArrow sx={{ color: 'grey.500' }} />
          </Box>
          {propertyDetailsMetricsIsLoading ? (
            <CircularProgress size={20} sx={{ m: 1.8 }} />
          ) : (
            <MetricsDetailItem metrics={processedMetrics} />
          )}
        </Stack>
      )}
    </>
  )
}

MetricsDetailStack.defaultProps = {
  mobileLayout: false,
}

MetricsDetailStack.propTypes = {
  property: PropTypes.string.isRequired,
  mobileLayout: PropTypes.bool,
}
