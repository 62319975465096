import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel } from '@mui/material'

export default function CheckboxControl({ label, ...rest }) {
  const { checked, onChange, size } = rest
  return (
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      checked={checked ?? false}
      onChange={onChange}
      size={size}
      role="checkbox"
    />
  )
}

CheckboxControl.propTypes = {
  label: PropTypes.string.isRequired,
}
