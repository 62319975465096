import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import { humanize } from 'inflection'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

const billingAccountTypeOptions = ['DIRECT', 'HIGHGATE', 'EKHO', 'LEGACY']
export default function PropertyBillingForm({ open, onClose, instance }) {
  const { doPropertySave, propertySaveStatus } = useConnect(
    'doShowSnackbar',
    'doPropertySave',
    'selectPropertySaveStatus',
  )

  const initialValues = {
    id: '',
    stripeCustomerId: '',
    salesforceAccountId: '',
    billingAccountType: '',
  }

  const validationSchema = yup.object().shape({
    id: yup.string(),
    stripeCustomerId: yup.string(),
    salesforceAccountId: yup.string(),
    billingAccountType: yup.string().required('Please select billing type'),
  })

  if (instance?.id) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const sharedStyles = { flex: 1 }

  const clean = (payload) =>
    Object.entries(payload).reduce((acc, [key, value]) => {
      const cleanedValue = value === null || value === undefined ? '' : value
      return { ...acc, [key]: cleanedValue }
    }, {})

  const saveForm = async (params) =>
    doPropertySave({
      ...instance,
      ...clean(params),
    })

  return (
    <FormDialog
      label="Property Billing Configuration"
      open={open}
      onSave={saveForm}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={propertySaveStatus === 'loading'}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box display="flex" gap="2rem">
          <Field
            component={TextField}
            label="SalesForce Account ID"
            name="salesforceAccountId"
            variant="standard"
            sx={sharedStyles}
          />
          <Field
            component={TextField}
            label="Stripe Customer ID"
            name="stripeCustomerId"
            variant="standard"
            sx={sharedStyles}
          />
        </Box>
        <Field
          component={StaticSelect}
          label="Billing Type"
          name="billingAccountType"
          options={billingAccountTypeOptions}
          optionLabelFormatter={(option) => humanize(option)}
          variant="standard"
          sx={sharedStyles}
        />
      </Box>
    </FormDialog>
  )
}

PropertyBillingForm.defaultProps = {
  instance: {},
}

PropertyBillingForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
  }),
}
