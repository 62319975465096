import PropTypes from 'prop-types'

import {
  CancelOutlined as CancelIcon,
  CheckCircleOutline as CheckIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'

export default function BooleanCell(props) {
  const { row, field } = props
  return (
    <Box width="100%" display="flex" justifyContent="center">
      {row?.[field] === undefined && <HelpIcon color="info" />}
      {row?.[field] === false && <CancelIcon color="warning" />}
      {row?.[field] === true && <CheckIcon color="success" />}
    </Box>
  )
}

BooleanCell.propTypes = {
  row: PropTypes.shape({ active: PropTypes.bool }).isRequired,
  field: PropTypes.string.isRequired,
}
