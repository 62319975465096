import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

import { capitalize } from 'inflection'

export default function CategoryLabel({ category, subcategory }) {
  return (
    <Box>
      <Typography>{capitalize(category)}</Typography>
      {subcategory && <Typography variant="caption">{subcategory}</Typography>}
    </Box>
  )
}

CategoryLabel.defaultProps = {
  subcategory: null,
}

CategoryLabel.propTypes = {
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.string,
}
