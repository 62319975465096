import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { is } from 'ramda'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'

export default function LivePreviewModal({ open, onClose, content }) {
  const [htmlString, setHtmlString] = useState('')
  const [header, setHeader] = useState('')
  const [err, setErr] = useState(null)

  useEffect(() => {
    if (content) {
      try {
        const contentStr = is(Object, content) ? JSON.stringify(content) : content
        const contentObj = JSON.parse(contentStr)
        setHtmlString(contentObj?.html_message || contentObj?.message || '')
        setHeader(contentObj?.subject || '')
      } catch (e) {
        setErr(e)
      }
    }
    return () => {
      setHtmlString('')
      setHeader('')
      setErr(null)
    }
  }, [content])

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h5" mb={2} align="center" noWrap>
          {header}
        </Typography>
        {err && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {err.message}
          </Alert>
        )}
        <Box dangerouslySetInnerHTML={{ __html: htmlString }} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mx: 1 }}>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LivePreviewModal.defaultProps = {
  content: '',
}

LivePreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string,
}
