import { memo, useState } from 'react'

import PropTypes from 'prop-types'
import { equals, pipe, replace, toUpper, trim } from 'ramda'

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import { Button, Menu, MenuItem, Stack, ToggleButton, Typography } from '@mui/material'

import { humanize, pluralize, titleize } from 'inflection'

import { StaticMultiSelect } from '@common/components/Selects'

const formatEntity = pipe(replace(/([A-Z])/g, ' $1'), toUpper, trim)

const EntitySelector = memo(
  ({
    entityMap,
    currentEntity,
    disabled,
    onEntityTypeChange,
    onEntitiesChange,
    onOptionChanged,
    onSave,
    onOpen,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const MIN_WIDTH = 400

    const getButtonTitle = (ent) => pluralize(formatEntity(ent))

    return (
      <>
        <Menu
          id="entity-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ vertical: -6, horizontal: 'left' }}
        >
          {Object.keys(entityMap).map((option) => (
            <MenuItem
              key={option}
              selected={option === currentEntity}
              disabled={disabled}
              onClick={() => {
                onEntityTypeChange(option)
                setAnchorEl(null)
              }}
              sx={{ width: anchorEl?.offsetWidth }}
            >
              {humanize(getButtonTitle(option))}
            </MenuItem>
          ))}
        </Menu>

        <Stack direction="column" spacing={1}>
          <ToggleButton
            selected={anchorEl}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={disabled}
            sx={{ py: 0.4, fontSize: 12 }}
          >
            {getButtonTitle(currentEntity)}
            {anchorEl ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
          </ToggleButton>
          <StaticMultiSelect
            size="small"
            required
            disableSaveOnClear // stops refetching/setting old data when cleared
            label={titleize(pluralize(formatEntity(currentEntity)))}
            onSave={onSave}
            onOpen={onOpen}
            onChange={onEntitiesChange}
            options={
              entityMap[currentEntity].filter
                ? entityMap[currentEntity].current?.filter(
                    entityMap[currentEntity].filter,
                  )
                : entityMap[currentEntity].current
            }
            value={entityMap[currentEntity].selected || []}
            groupBy={entityMap[currentEntity].groupBy}
            sortByFields={entityMap[currentEntity].sortByFields}
            disabled={disabled}
            sx={{ minWidth: MIN_WIDTH }}
            onlyButton
            renderGroup={(params) => (
              <li key={params.key}>
                <Typography
                  variant="h6"
                  sx={{
                    px: 2,
                    py: 1,
                    display: 'flex',
                    '&:hover > button': { visibility: 'visible' },
                    backgroundColor: 'primary.main',
                    color: 'white',
                  }}
                >
                  {params.group}
                  <Button
                    variant="text"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      entityMap[currentEntity].setSelected(
                        entityMap[currentEntity].current.filter((ent) =>
                          equals(entityMap[currentEntity].groupBy(ent), params.group),
                        ),
                      )
                      onOptionChanged()
                    }}
                    sx={{
                      marginLeft: 'auto',
                      visibility: 'hidden',
                      color: 'white',
                    }}
                  >
                    only
                  </Button>
                </Typography>
                <ul style={{ padding: 0 }}>{params.children}</ul>
              </li>
            )}
            limitTags={1}
          />
        </Stack>
      </>
    )
  },
)

EntitySelector.propTypes = {
  entityMap: PropTypes.shape({
    filter: PropTypes.func,
    current: PropTypes.arrayOf(),
    fetch: PropTypes.func,
    selected: PropTypes.arrayOf(),
    setSelected: PropTypes.func,
    groupBy: PropTypes.func,
  }).isRequired,
  currentEntity: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEntityTypeChange: PropTypes.func.isRequired,
  onEntitiesChange: PropTypes.func.isRequired,
  onOptionChanged: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
}

export default EntitySelector
