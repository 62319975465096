import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'

function TabPanel({ children, value, index, ...other }) {
  return (
    <Box
      role="tabpanel"
      style={{ width: '100%' }}
      hidden={value !== index}
      id={`{tabpanel-${index}}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export default function VerticalTabPanel({ tabs, onTabSelect, ...rest }) {
  const isSmallScreen = useSmallScreen()

  const [value, setValue] = useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
    onTabSelect(newValue)
  }

  const enabledTabs = useMemo(() => tabs.filter((tab) => !tab?.disabled), [tabs])

  return (
    <Stack
      data-testid="vertical_tab_panel"
      direction={isSmallScreen ? 'column' : 'row'}
      spacing={2}
    >
      {isSmallScreen ? (
        <FormControl fullWidth size="small" sx={{ my: 1 }}>
          <Select
            value={value}
            IconComponent={KeyboardArrowDown}
            onChange={(event) => handleChange(null, event.target.value)}
            disabled={enabledTabs.length <= 1}
          >
            {enabledTabs.map((tab, index) => (
              <MenuItem key={tab.label} value={index}>
                {tab.label} View
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="vertical tab panel"
          sx={{
            minWidth: 224,
            width: 224,
            '.Mui-selected': {
              borderRadius: '7px',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          TabIndicatorProps={{ sx: { display: 'none' } }}
        >
          {enabledTabs.map((tab, index) => (
            <Tab
              label={tab.label}
              key={tab.label}
              component={Typography}
              sx={{ alignItems: 'flex-start', textTransform: 'none' }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      )}
      {enabledTabs.map((tab, index) => (
        <TabPanel value={value} key={tab.label} index={index}>
          <Box component={tab.component} {...rest} />
        </TabPanel>
      ))}
    </Stack>
  )
}

VerticalTabPanel.defaultProps = {
  onTabSelect: () => {},
}

VerticalTabPanel.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
  onTabSelect: PropTypes.func,
}
