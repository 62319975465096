import PropTypes from 'prop-types'

import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

export default function NoiseComponent({ margin, gap }) {
  return (
    <DetailContainer margin={margin} gap={gap}>
      <DetailItem label="Noise Enabled" value="Yes" />
    </DetailContainer>
  )
}

NoiseComponent.defaultProps = {
  margin: 0,
  gap: 0,
}

NoiseComponent.propTypes = {
  margin: PropTypes.number,
  gap: PropTypes.number,
}
