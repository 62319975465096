import PropTypes from 'prop-types'

import { Card, Typography } from '@mui/material'

import MetricTable from './MetricTable'

export default function EntityCard({ dataKey, data, selectedMetrics, sx }) {
  const cardTitle = dataKey?.split(':').at(-1) ?? '--'

  return (
    <Card key={dataKey} sx={sx}>
      <Typography variant="body2" ml={2} mt={2}>
        {cardTitle}
      </Typography>
      <MetricTable data={data} groupBy="entity" selectedMetrics={selectedMetrics} />
    </Card>
  )
}

EntityCard.defaultProps = {
  sx: {},
}

EntityCard.propTypes = {
  dataKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  selectedMetrics: PropTypes.shape({}).isRequired,
  sx: PropTypes.shape({}),
}
