import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Rect } from 'react-konva'

import { HANDLE_HEIGHT, RECT_HORIZONTAL_ORIGIN, VERTICAL_GRID_SPACE } from './constants'
import { getRectWidth } from './Rects'
import { roundToNearestHour, setCursorStyle } from './utils'

const ResizeHandle = forwardRef(
  ({ editorWidth, y, onDragEnd, onDragStart, dragBoundFunc, draggable }, ref) => (
    <Rect
      x={RECT_HORIZONTAL_ORIGIN}
      y={y}
      ref={ref}
      height={HANDLE_HEIGHT}
      width={getRectWidth(editorWidth)}
      draggable={draggable}
      onMouseEnter={(e) => setCursorStyle(e, 'ns-resize')}
      onMouseLeave={(e) => setCursorStyle(e, 'default')}
      onDragStart={onDragStart}
      onDragEnd={(e) => onDragEnd(roundToNearestHour(e.target.y()))}
      dragDistance={VERTICAL_GRID_SPACE / 4}
      dragBoundFunc={(pos) => dragBoundFunc(pos, ref)}
    />
  ),
)

ResizeHandle.defaultProps = {
  editorWidth: undefined,
}

ResizeHandle.propTypes = {
  editorWidth: PropTypes.number,
  y: PropTypes.number.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  dragBoundFunc: PropTypes.func.isRequired,
  draggable: PropTypes.bool.isRequired,
}

export default ResizeHandle
