import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Circle as CircleIcon,
  CopyAll,
  Delete,
  DoubleArrowRounded,
  Edit,
  NotificationsPaused as NotificationsPausedIcon,
  PlayArrow,
} from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import sensorTestingUrls from '@portal/pages/SensorTesting/urls'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import DeleteModal from '@portal/UI/components/DeleteModal'
import EventLogTab from '@portal/UI/components/EventLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import MoveEntityModal from '@portal/UI/components/MoveEntityModal'

import UnitTab from './Tabs/Unit'
import UnitForm from './UnitForm'
import unitUrls from './urls'

export default function UnitDetail() {
  const [tabValue, setTabValue] = useState(0)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [moveFormOpen, setMoveFormOpen] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(null)
  const [snoozeConfirmationOpen, setSnoozeConfirmationOpen] = useState(false)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const {
    doUnitDelete,
    doUnitFetch,
    doShowSnackbar,
    doUnitListBulkUpdateSnooze,
    doUpdateUrl,
    hashObject,
    routeParams: { id: unitId },
    unitFetch: unit,
    unitFetchStatus,
    isAtLeastAdmin,
    isAtLeastDataScience,
    isSuperuser,
  } = useConnect(
    'doUnitDelete',
    'doUnitFetch',
    'doShowSnackbar',
    'doUnitListBulkUpdateSnooze',
    'doUpdateUrl',
    'selectHashObject',
    'selectRouteParams',
    'selectUnitFetch',
    'selectUnitFetchStatus',
    'selectIsAtLeastAdmin',
    'selectIsAtLeastDataScience',
    'selectIsSuperuser',
  )

  const isSmallScreen = useSmallScreen()

  const tabs = [
    { label: 'Unit', component: <UnitTab />, hash: 'unit' },

    { label: 'Event Log', component: <EventLogTab entity="Unit" />, hash: 'eventLog' },
    {
      label: 'Audit Log',
      component: <AuditLogTab objectId={unitId} />,
      hash: 'auditLog',
    },
  ]

  const fetchUnit = useCallback(async (id) => {
    try {
      await doUnitFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchUnit(unitId)
  }, [])

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  const handleStartTestClick = () => {
    doUpdateUrl(`${sensorTestingUrls.testing}?unit=${unit.id}`)
  }

  const handleEditClick = () => {
    setEditFormOpen(true)
    setCurrentUnit(unit)
  }

  const handleDeleteClick = () => {
    setDeleteFormOpen(true)
    setCurrentUnit(unit)
  }

  const handleMoveClick = () => {
    setMoveFormOpen(true)
    setCurrentUnit(unit)
  }

  const handleSnoozeConfirm = async () => {
    await doUnitListBulkUpdateSnooze({ ids: [unit?.id], snooze: !unit?.snooze })
    fetchUnit(unitId)
    setSnoozeConfirmationOpen(false)
  }

  const deleteUnit = async () => {
    try {
      await doUnitDelete(currentUnit)
      doShowSnackbar('Successfully deleted unit')
      setDeleteFormOpen(false)
      doUpdateUrl(unitUrls.list)
    } catch (error) {
      setDeleteError(error)
    }
  }

  const StatusChip = useCallback(
    () => (
      <Chip
        icon={<CircleIcon />}
        variant="outlined"
        size="small"
        label={unit?.deletedOn ? 'Inactive' : 'Active'}
        color={unit?.deletedOn ? 'error' : 'success'}
      />
    ),
    [unit],
  )

  if (unitFetchStatus === 'failed') {
    return <Typography>An error occurred while retrieving unit.</Typography>
  }

  if (unitFetchStatus === 'loading') {
    return <Loading />
  }

  const snoozeStatus = unit?.snooze ? 'Un-Snooze' : 'Snooze'

  return (
    <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
      <ConfirmationDialog
        fullWidth={false}
        title={snoozeStatus}
        message={`Are you sure you want to ${snoozeStatus} this unit`}
        open={snoozeConfirmationOpen}
        onConfirm={handleSnoozeConfirm}
        onCancel={() => setSnoozeConfirmationOpen(false)}
      />
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Units', href: unitUrls.list },
          { label: unit?.name || 'Loading...' },
        ]}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="caption" color="text.secondary">
          id: {unitId}
        </Typography>
        <CopyAll
          sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
          onClick={() => {
            navigator.clipboard.writeText(unitId)
            doShowSnackbar('ID copied to clipboard')
          }}
        />
      </Box>
      {isSmallScreen ? (
        <Box mb={1}>
          <ListPageTitle
            title={unit?.name}
            menuItems={[
              ...(unit?.deviceCount > 0 && isAtLeastDataScience
                ? [{ label: 'Start Test', onClick: handleStartTestClick }]
                : []),
              ...(isAtLeastAdmin
                ? [
                    ...(isSuperuser
                      ? [{ label: 'Move', onClick: handleMoveClick }]
                      : []),
                    {
                      label: snoozeStatus,
                      onClick: () => setSnoozeConfirmationOpen(true),
                    },
                    { label: 'Edit', onClick: handleEditClick },
                    { label: 'Delete', onClick: handleDeleteClick },
                  ]
                : []),
            ]}
          />
          <Box display="flex" mt={0.5} gap={0.5}>
            {unit?.snooze && <NotificationsPausedIcon color="primary" />}
            <StatusChip />
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h3" mb={1} mr={3}>
              {unit?.name}
            </Typography>
            {unit?.snooze && <NotificationsPausedIcon color="primary" />}
            <StatusChip />
          </Box>
          <Box>
            {unit?.deviceCount > 0 && isAtLeastDataScience && (
              <Button
                startIcon={<PlayArrow />}
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={handleStartTestClick}
              >
                Start Test
              </Button>
            )}
            {isAtLeastAdmin && (
              <>
                {isSuperuser && (
                  <Button
                    startIcon={<DoubleArrowRounded />}
                    variant="outlined"
                    sx={{ mr: 1 }}
                    onClick={handleMoveClick}
                  >
                    Move
                  </Button>
                )}
                <Button
                  startIcon={<NotificationsPausedIcon />}
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onClick={() => setSnoozeConfirmationOpen(true)}
                >
                  {snoozeStatus} Unit
                </Button>
                <Button
                  startIcon={<Edit />}
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
                <Button
                  startIcon={<Delete />}
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteClick}
                >
                  Delete
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <UnitForm
        open={editFormOpen}
        onClose={() => {
          setCurrentUnit(null)
          fetchUnit(currentUnit)
          setEditFormOpen(false)
        }}
        instance={currentUnit}
      />
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={deleteUnit}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
          setCurrentUnit(null)
        }}
      />
      <MoveEntityModal
        open={moveFormOpen}
        entity="unit"
        entityData={
          currentUnit && {
            id: currentUnit?.id,
            parentId: currentUnit?.property,
          }
        }
        onClose={(success) => {
          setMoveFormOpen(false)
          setCurrentUnit(null)

          if (success === true) {
            fetchUnit(unitId)
          }
        }}
      />
      <TabComponent
        tabs={tabs}
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </Box>
  )
}
