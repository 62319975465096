import { useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Divider, Stack } from '@mui/material'

import { TabComponent } from '@common/components'
import { useSmallScreen } from '@common/utils'
import MembershipList from '@portal/pages/Memberships/Memberships'
import NotificationsList from '@portal/pages/Notifications/Notifications'
import ScheduledReportPreferences from '@portal/pages/ScheduledReportPreferences/ScheduledReportPreferences'
import DetailItem from '@portal/UI/components/DetailItem'

export default function UserTab({ user }) {
  const [tabValue, setTabValue] = useState(0)
  const isSmallScreen = useSmallScreen()

  const tabs = [
    {
      label: 'Memberships',
      component: <MembershipList renderAsTab userId={user.id} />,
    },
    {
      label: 'Report Preferences',
      component: <ScheduledReportPreferences renderAsTab user={user} />,
    },
    {
      label: 'Notifications',
      component: <NotificationsList renderAsTab />,
    },
  ]

  return (
    <Box>
      <Stack
        my={isSmallScreen ? 1 : 3}
        gap={isSmallScreen ? 1.5 : 10}
        direction={isSmallScreen ? 'column' : 'row'}
      >
        <DetailItem label="Email" value={user.email} />
        <DetailItem label="Phone" value={user.phoneNumber || '(no phone on file)'} />
      </Stack>
      <Divider />
      <TabComponent
        tabs={tabs}
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </Box>
  )
}

UserTab.defaultProps = {
  user: {},
}
UserTab.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
}
