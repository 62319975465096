import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material'

import { formatCurrency, parseApiErrors } from '@common/utils'

function ChargeSummary() {
  const { propertyEventList, doFetchPropertyChargeSummary } = useConnect(
    'selectPropertyEventList',
    'doFetchPropertyChargeSummary',
  )

  const [error, setError] = useState(null)
  const [summary, setSummary] = useState(null)

  const fetchChargeSummary = useCallback(async () => {
    setError(null)
    try {
      const result = await doFetchPropertyChargeSummary()
      setSummary(result)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      setError(parsedError || 'Oops. Something went wrong')
    }
  }, [])

  useEffect(() => {
    fetchChargeSummary()
  }, [propertyEventList])

  return (
    <Card data-testid="charge_summary_card">
      <CardContent
        sx={{
          '&.MuiCardContent-root': { p: 2 },
        }}
      >
        <Stack direction="column">
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography variant="h6">Net Charges</Typography>
            <Stack data-testid="selected_dates_stack" direction="column">
              <Typography variant="subtitle2">Selected Dates</Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.netCharges, '--')}
              </Typography>
            </Stack>
            <Stack data-testid="all_time_stack" direction="column">
              <Typography variant="subtitle2">All Time</Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.allTimeCharges, '--')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {error && (
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
            <Box display="flex" alignItems="center">
              <ErrorOutlineRounded
                sx={{ fontSize: 18, color: 'error.main', mr: 0.5 }}
              />
              <Typography variant="caption" color="error">
                {error}
              </Typography>
            </Box>
            <Button size="small" onClick={() => fetchChargeSummary()}>
              Retry
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default ChargeSummary
