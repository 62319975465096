import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Edit, FlagOutlined } from '@mui/icons-material'
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import {
  getApiFetch,
  isAbortError,
  parseApiErrors,
  useSmallScreen,
} from '@common/utils'

import FeatureFlagsConfiguration from './FeatureFlagConfiguration'

export default function DetailsFeatureFlagsRow({ entity, entityType, onEditSuccess }) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const [featureFlagsFormOpen, setFeatureFlagsFormOpen] = useState(false)

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [loading, setLoading] = useState(false)
  const [availableFlags, setAvailableFlags] = useState(null)

  const isSmallScreen = useSmallScreen()

  const enabledFlags = useMemo(() => entity?.flags ?? [], [entity])
  const effectiveFlags = useMemo(
    () => (entity?.expandedFlags ?? []).filter((flag) => !entity.flags.includes(flag)),
    [entity],
  )

  const fetchFlags = useCallback(async () => {
    try {
      setLoading(true)
      const flagsResponse = await apiFetch(
        `/flags/`,
        { pageSize: 999 },
        { method: 'GET', cancelationPrefix: 'flags_row' },
      )
      setAvailableFlags(flagsResponse?.results)
      setLoading(false)
    } catch (err) {
      if (!isAbortError(err)) {
        const errMessage = parseApiErrors(err?.response)
        doShowSnackbar(errMessage, 'error')

        setLoading(false)
      }
    }
  }, [entity, entityType])

  useEffect(() => {
    if (entity) {
      fetchFlags()
    }
  }, [entity])

  const sectionStyle = {
    variant: 'body2',
    color: 'text.secondary',
    textTransform: 'uppercase',
    fontSize: isSmallScreen ? 12 : 14,
    mt: 2,
  }

  const noDataStyle = {
    variant: 'body2',
    color: 'text.secondary',
    mt: 1,
  }

  return (
    <>
      {entity && (
        <FeatureFlagsConfiguration
          entityType={entityType}
          entity={entity}
          open={featureFlagsFormOpen}
          onClose={(success) => {
            setFeatureFlagsFormOpen(false)
            if (success === true && onEditSuccess) {
              onEditSuccess()
            }
          }}
        />
      )}

      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6" sx={{ fontSize: isSmallScreen ? 16 : 20 }}>
          Feature Flags
        </Typography>
        <IconButton
          color="primary"
          size="small"
          onClick={() => setFeatureFlagsFormOpen(true)}
        >
          <Edit sx={{ fontSize: isSmallScreen ? 18 : 24 }} />
        </IconButton>
      </Stack>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" p={1}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <>
          <Typography {...sectionStyle}>Direct Feature Flags</Typography>
          {isEmpty(enabledFlags) && (
            <Typography
              {...noDataStyle}
            >{`This ${entityType} does not have directly enabled feature flags`}</Typography>
          )}
          {availableFlags && !isEmpty(enabledFlags) && (
            <Box display="flex" flexWrap="wrap" gap={1} mb={2} mt={1.5}>
              {availableFlags
                .filter((flag) => enabledFlags.includes(flag.key))
                .map((flag) => (
                  <Tooltip key={flag.key} arrow title={flag.description}>
                    <Chip
                      icon={<FlagOutlined />}
                      label={flag.key}
                      variant="outlined"
                      sx={{ cursor: 'default' }}
                    />
                  </Tooltip>
                ))}
            </Box>
          )}
        </>
      )}

      {entityType !== 'organization' && !loading && (
        <>
          <Typography {...sectionStyle}>Inherited Feature Flags</Typography>
          {isEmpty(effectiveFlags) && (
            <Typography
              {...noDataStyle}
            >{`This ${entityType} does not have inherited feature flags`}</Typography>
          )}
          {availableFlags && !isEmpty(effectiveFlags) && (
            <Box display="flex" flexWrap="wrap" gap={1} mb={2} mt={1.5}>
              {availableFlags
                .filter((flag) => effectiveFlags.includes(flag.key))
                .map((flag) => (
                  <Tooltip key={flag.key} arrow title={flag.description}>
                    <Chip
                      icon={<FlagOutlined />}
                      label={flag.key}
                      variant="outlined"
                      sx={{ cursor: 'default' }}
                    />
                  </Tooltip>
                ))}
            </Box>
          )}
        </>
      )}
    </>
  )
}

DetailsFeatureFlagsRow.defaultProps = {
  entity: null,
  onEditSuccess: null,
}

DetailsFeatureFlagsRow.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    flags: PropTypes.arrayOf(PropTypes.string),
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
  }),
  entityType: PropTypes.oneOf(['organization', 'account', 'property']).isRequired,
  onEditSuccess: PropTypes.func,
}
