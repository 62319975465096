import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { OpenInNew } from '@mui/icons-material'
import { IconButton, Link, List, ListItem, Stack, Typography } from '@mui/material'

import { configureUrls } from '@rest/pages/Configure'
import { AlertDialog } from '@rest/UI/components'

export default function GettingStarted({ open, onClose }) {
  const OrderedListItem = useCallback(
    ({ step, children }) => (
      <ListItem disablePadding>
        <Typography variant="body2">
          <Stack direction="row" spacing={1}>
            <Typography variant="body2">{step}.</Typography>
            <Typography variant="body2">{children}</Typography>
          </Stack>
        </Typography>
      </ListItem>
    ),
    [],
  )

  return (
    <AlertDialog
      open={open}
      label="Reservations"
      showButtons={false}
      onClose={onClose}
      sx={{ pr: 0 }}
    >
      <Stack spacing={1} mb={4} pt={3}>
        <Typography variant="body2">
          Reservations are required to enable{' '}
          <span style={{ fontWeight: 'bold' }}>Automated Guest Messaging</span>. Once a
          reservation is saved, guests will be automatically notified when noise levels
          for that property are above the threshold during their stay.
        </Typography>
        <Stack>
          <Typography variant="h6">How to add a reservation</Typography>
          <List disablePadding>
            <OrderedListItem step={1}>
              Click <span style={{ fontWeight: 'bold' }}>ADD</span> to start a new
              reservation.
            </OrderedListItem>
            <OrderedListItem step={2}>Select a property from the list.</OrderedListItem>
            <OrderedListItem step={3}>(If applicable) Select a unit.</OrderedListItem>
            <OrderedListItem step={4}>
              Select the check in/out date and time.
            </OrderedListItem>
            <OrderedListItem step={5}>
              Enter the guest&apos;s mobile number (a valid mobile number is required
              for SMS messaging).
            </OrderedListItem>
            <OrderedListItem step={6}>
              Enter the guest&apos;s name (optional).
            </OrderedListItem>
          </List>
        </Stack>
        <Stack>
          <Typography variant="h6">Where did my reservation go?</Typography>
          <Typography variant="body2">
            To keep things current and clean, reservations are removed automatically
            when expired.
          </Typography>
          <Stack direction="row" alignItems="center" mt={2}>
            <Link variant="body2" href={configureUrls.messaging}>
              Learn more about Guest Messaging
            </Link>
            <IconButton href={configureUrls.messaging} target="_blank" rel="noopener">
              <OpenInNew />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </AlertDialog>
  )
}

GettingStarted.defaultProps = {
  open: false,
  onClose: () => {},
}

GettingStarted.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
