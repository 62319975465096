import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import { FilterList, MoreVertRounded } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function ListPageTitle({ title, onFilterPressed, menuItems, ...props }) {
  const isSmallScreen = useSmallScreen()

  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenuClose = useCallback(() => setMenuAnchor(null), [])

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" {...props}>
      <Typography variant={isSmallScreen ? 'h6' : 'h3'}>{title}</Typography>
      <Box display="flex" flexDirection="row" gap={1}>
        {onFilterPressed && (
          <IconButton color="primary" size="small" onClick={onFilterPressed}>
            <FilterList fontSize="12" />
          </IconButton>
        )}
        {menuItems?.length ? (
          <>
            <IconButton
              color="primary"
              size="small"
              onClick={(event) => setMenuAnchor(event.currentTarget)}
            >
              <MoreVertRounded fontSize="12" />
            </IconButton>

            <Menu
              id="title_menu"
              anchorEl={menuAnchor}
              open={!!menuAnchor}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {menuItems.map((item) => {
                if (item.render) {
                  return item.render({
                    key: item.label,
                    label: item.label,
                    onClick: item.onClick,
                    onClose: handleMenuClose,
                  })
                }
                return (
                  <MenuItem
                    key={item.label}
                    onClick={(e) => {
                      item.onClick(e)
                      handleMenuClose()
                    }}
                  >
                    {item.label}
                  </MenuItem>
                )
              })}
            </Menu>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

ListPageTitle.defaultProps = {
  onFilterPressed: undefined,
  menuItems: undefined,
}

ListPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onFilterPressed: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      render: PropTypes.func,
    }),
  ),
}
