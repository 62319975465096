import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { DragHandleOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import { formatItemCount, formatSubitemCount } from '@rest/Utils/formatMenuStrings'

export default function AccountsMenu({
  currentAccount,
  currentAccountDetails,
  doSetCurrentAccount,
  availableAccounts,
}) {
  const { doSetCurrentProperty } = useConnect('doSetCurrentProperty')
  const [acctsAnchor, setAcctsAnchor] = useState(null)
  const openAcctsMenu = Boolean(acctsAnchor)

  const handleAccountClick = (id) => {
    setAcctsAnchor(null)
    doSetCurrentAccount(id)
    doSetCurrentProperty(null)
  }

  if (!currentAccount) {
    return null
  }

  return (
    <>
      <Typography color="grey.300">/</Typography>
      <Button
        data-testid="account-button"
        onClick={(e) => setAcctsAnchor(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        sx={{ p: 0, px: '3px', mx: 1, color: 'white' }}
      >
        {currentAccountDetails.name}
      </Button>
      <Menu
        disableScrollLock
        data-testid="account-menu"
        open={openAcctsMenu}
        anchorEl={acctsAnchor}
        onClose={() => setAcctsAnchor(null)}
        sx={{ '& .MuiMenu-list': { minWidth: '300px', maxHeight: '400px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="caption" color="text.secondary" ml={2}>
          {formatItemCount('Account', availableAccounts.length)}
        </Typography>
        {availableAccounts.map((acct) => (
          <MenuItem
            key={acct.id}
            selected={acct.id === currentAccount}
            onClick={() => {
              handleAccountClick(acct.id)
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <DragHandleOutlined color="text.secondary" />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Typography>{acct.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatSubitemCount('Property', acct.propertyCount)}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

AccountsMenu.propTypes = {
  currentAccount: PropTypes.string,
  currentAccountDetails: PropTypes.shape({ name: PropTypes.string }),
  doSetCurrentAccount: PropTypes.func.isRequired,
  availableAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      organization: PropTypes.string,
      name: PropTypes.string,
      propertyCount: PropTypes.number,
      unitCount: PropTypes.number,
      deviceCount: PropTypes.number,
      flags: PropTypes.arrayOf(PropTypes.string),
      roles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
}

AccountsMenu.defaultProps = {
  currentAccount: null,
  currentAccountDetails: {},
}
