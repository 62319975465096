import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import {
  createListBundle,
  defaultInitialState,
  getEntityFromUrl,
  isAbortError,
} from '@common/utils'

import propertyUrls from './urls'

const PROPERTY_LIST_FETCH_ALL = 'PROPERTY_LIST_FETCH_ALL'

const entityName = 'property'

const propertyListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/properties/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['deviceCount', 'desc'],
  },
  urlTest: (url, _, hash) =>
    url === propertyUrls.list || ['organization', 'account'].includes(hash),
})

export default {
  ...propertyListBundle,
  reducer: reduceReducers(propertyListBundle.reducer, (state, action) => {
    switch (action.type) {
      case PROPERTY_LIST_FETCH_ALL:
        return { ...state, allProperties: action.payload }
      default:
        return state
    }
  }),
  doPropertyListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/properties/`,
          { ordering: 'account__name,name', pageSize: 500, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({ type: PROPERTY_LIST_FETCH_ALL, payload: response?.results })
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
  selectAllProperties: createSelector(
    'selectPropertyListRaw',
    (propListRaw) => propListRaw?.allProperties,
  ),
}
