import PropTypes from 'prop-types'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'

import { StaticSelect } from '@common/components/Selects'

export default function GroupChangeDialog({
  thresholdOptions,
  setSelectedProfile,
  selectedProfile,
  setConfirmGroupChange,
  open,
  onClose,
}) {
  return (
    <Dialog open={open} onClose={onClose} disableScrollLock maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        Change Profile
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ py: 0, px: 4 }}>
        <StaticSelect
          options={thresholdOptions}
          value={selectedProfile}
          label="Select New Threshold Profile"
          sx={{ my: 2 }}
          onChange={setSelectedProfile}
        />
        <DialogActions sx={{ px: 0, mb: 2.5 }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="cancel"
            sx={{ '&:hover': { bgcolor: 'grey.200' } }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setConfirmGroupChange(true)
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

GroupChangeDialog.defaultProps = {
  selectedProfile: '',
}

GroupChangeDialog.propTypes = {
  thresholdOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedProfile: PropTypes.func.isRequired,
  selectedProfile: PropTypes.string,
  setConfirmGroupChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
