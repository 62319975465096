import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

export default function AutoChargeTestErrorModal({ error, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{ marginY: 'auto', maxHeight: '1200px' }}
    >
      <DialogTitle color="primary" fontSize="1.5rem">
        Autocharge Test Failed
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5, px: 4 }}>
        <pre
          style={{ backgroundColor: 'lightgray', padding: '20px', borderRadius: '5px' }}
        >
          {error}
        </pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

AutoChargeTestErrorModal.propTypes = {
  error: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
