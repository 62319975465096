import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Menu, NavigateNext as NavigateNextIcon } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  IconButton,
  Link,
  Typography,
} from '@mui/material'

import { Project } from '@common/config'
import { noop, useSmallScreen } from '@common/utils'

function BreadcrumbsBase({ links, style }) {
  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon sx={{ fontSize: 16 }} />}
      aria-label="breadcrumb"
      style={style}
    >
      {links.map((link) =>
        link?.href ? (
          <Link
            href={link.href}
            key={link.label}
            underline="none"
            color="text.secondary"
            variant="caption"
          >
            {link.label}
          </Link>
        ) : (
          <Typography key={link.label} color="text.primary" variant="caption">
            {link.label}
          </Typography>
        ),
      )}
    </MuiBreadcrumbs>
  )
}

function Breadcrumbs({ links }) {
  const isSmallScreen = useSmallScreen()
  const { isRest } = Project

  const { doOpenDrawer } = useConnect(...(isRest ? [] : ['doOpenDrawer']))

  if (isRest) {
    return <BreadcrumbsBase links={links} style={{ marginBottom: 8 }} />
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ marginBottom: isSmallScreen ? 10 : 25 }}
    >
      {isSmallScreen && (
        <IconButton size="small" onClick={doOpenDrawer ?? noop} sx={{ mr: 1 }}>
          <Menu sx={{ fontSize: 18 }} />
        </IconButton>
      )}
      <BreadcrumbsBase links={links} />
    </Box>
  )
}

BreadcrumbsBase.defaultProps = {
  style: undefined,
}

BreadcrumbsBase.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  style: PropTypes.shape({}),
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
}

export default Breadcrumbs
