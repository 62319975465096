import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import {
  Checkbox,
  Dialog,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function PropertyListColumnsPopover({
  anchorEl,
  currentColumns,
  onClose,
  onSave,
}) {
  const isSmallScreen = useSmallScreen()
  const [columns, setColumns] = useState(currentColumns)

  useEffect(() => {
    setColumns(currentColumns)
  }, [currentColumns])

  const Content = useCallback(
    () => (
      <Stack direction="column" px={2} py={0.5}>
        <Typography variant="h6">
          Available {isSmallScreen ? 'Rows' : 'Columns'}
        </Typography>
        {columns.map((column, index) => {
          let checked = true
          if ('visible' in column) {
            checked = column.visible
          }

          return (
            <FormControlLabel
              key={column.field}
              slotProps={{ typography: { fontSize: 14 } }}
              control={
                <Checkbox
                  checked={checked}
                  size="small"
                  onChange={() => {
                    setColumns((prev) => {
                      const updatedColumns = [...prev]
                      updatedColumns[index] = {
                        ...column,
                        visible: 'visible' in column ? !column.visible : false,
                      }
                      return updatedColumns
                    })
                  }}
                />
              }
              label={column.headerName}
            />
          )
        })}
      </Stack>
    ),
    [columns, isSmallScreen],
  )

  return isSmallScreen ? (
    <Dialog open={!!anchorEl} onClose={onClose}>
      <Content />
    </Dialog>
  ) : (
    <Popover
      open={!!anchorEl}
      onClose={(event, reason) => {
        onClose(event, reason)
        if (!equals(currentColumns, columns)) {
          onSave(columns)
        }
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 40, horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Content />
    </Popover>
  )
}

PropertyListColumnsPopover.defaultProps = {
  anchorEl: undefined,
}

PropertyListColumnsPopover.propTypes = {
  anchorEl: PropTypes.shape({}),
  currentColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}
