import PropTypes from 'prop-types'

import { Avatar, Box, Divider } from '@mui/material'

export default function Step({ label, showLine, sx, children }) {
  return (
    <Box display="flex" sx={sx}>
      <Box display="flex" alignItems="center" flexDirection="column" sx={{ mr: 3 }}>
        <Avatar
          sx={{
            height: 30,
            width: 30,
            color: 'white',
            bgcolor: 'primary.main',
            pb: 0.3,
            fontSize: '1rem',
            fontWeight: '600',
            fontFamily: 'Degular',
          }}
        >
          {label}
        </Avatar>
        {showLine ? (
          <Divider
            orientation="vertical"
            sx={{ borderRightWidth: 3, bgcolor: 'grey.50' }}
          />
        ) : null}
      </Box>
      <Box sx={{ height: '100%', overflow: 'hidden', width: '100%' }}>{children}</Box>
    </Box>
  )
}

Step.defaultProps = {
  showLine: true,
  sx: {},
}

Step.propTypes = {
  label: PropTypes.string.isRequired,
  showLine: PropTypes.bool,
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
}
