import { path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError, isDateRangeOverlap } from '@common/utils'

const EVENT_MODAL_DATA_LOADING = 'EVENT_MODAL_DATA_LOADING'
const EVENT_MODAL_DATA_LOADED = 'EVENT_MODAL_DATA_LOADED'
const EVENT_MODAL_DATA_FAILED = 'EVENT_MODAL_DATA_FAILED'

const EVENT_MODAL_CHART_LOADING = 'EVENT_MODAL_CHART_LOADING'
const EVENT_MODAL_CHART_SUCCESS = 'EVENT_MODAL_CHART_SUCCESS'
const EVENT_MODAL_CHART_FAILED = 'EVENT_MODAL_CHART_FAILED'

const EVENT_MODAL_UNIT_DEVICES_LOADING = 'EVENT_MODAL_UNIT_DEVICES_LOADING'
const EVENT_MODAL_UNIT_DEVICES_LOADED = 'EVENT_MODAL_UNIT_DEVICES_LOADED'
const EVENT_MODAL_UNIT_DEVICES_FAILED = 'EVENT_MODAL_UNIT_DEVICES_FAILED'

const EVENT_MODAL_RESET_STATE = 'EVENT_MODAL_RESET_STATE'

const defaultState = {}

const entityName = 'eventModal'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === EVENT_MODAL_RESET_STATE) {
      return defaultState
    }
    if (action.type === EVENT_MODAL_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === EVENT_MODAL_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === EVENT_MODAL_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_LOADING) {
      return { ...state, chart: { ...action.meta, payload: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_SUCCESS) {
      return { ...state, chart: { ...action.meta, data: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_FAILED) {
      return { ...state, chart: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectEventModalDataIsLoading: ({ eventModal }) => {
    const status = path(['data', 'status'], eventModal)
    return status === 'loading'
  },
  selectEventModalChartDataIsLoading: ({ eventModal }) => {
    const status = path(['chart', 'status'], eventModal)
    return status === 'loading'
  },
  selectEventModalIsLoading: ({ eventModal }) => {
    const dataStatus = path(['data', 'status'], eventModal)
    const chartStatus = path(['chart', 'status'], eventModal)
    return dataStatus === 'loading' || chartStatus === 'loading'
  },
  selectEventModalData: ({ eventModal }) => path(['data', 'data'], eventModal),
  selectEventModalChartData: ({ eventModal }) => path(['chart', 'data'], eventModal),
  selectEventModalDataError: ({ eventModal }) => path(['data', 'error'], eventModal),
  selectEventModalChartDataError: ({ eventModal }) =>
    path(['chart', 'error'], eventModal),
  doResetEventModalState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: EVENT_MODAL_RESET_STATE }),
  doFetchEventModalData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { id, entity } = payload

        const eventResponse =
          entity === 'event'
            ? await apiFetch(
                `/events/${id}`,
                { includeChildEvents: true },
                { cancelationPrefix: entityName },
              )
            : null
        const deviceResponse = await apiFetch(
          `/devices/${entity === 'event' ? eventResponse.device : id}`,
          null,
          { cancelationPrefix: entityName },
        )

        const data = {
          event: eventResponse,
          device: deviceResponse,
        }

        dispatch({
          type: EVENT_MODAL_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchEventModalChartData:
    (payload) =>
    async ({ store, dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_CHART_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { event, device, selectedArea, selectedTimezone, selectedDateTimeData } =
          payload

        const timeZone = event?.timeZone ?? device.timezone

        const { custom, range } = selectedDateTimeData
        const [selectedStart, selectedEnd] = range

        const zone = selectedTimezone ?? timeZone
        const unit = event?.unit ?? device?.unitId

        const timeNow = DateTime.now().setZone(zone)
        const start = selectedStart.setZone(zone)
        const end = selectedEnd.setZone(zone)

        const timeOffset = { hours: custom ? 0 : 12 }
        const dataResolution = 1000

        let apiStart = start.minus(timeOffset).toISO()
        let apiEnd =
          end.plus(timeOffset) > timeNow
            ? timeNow.toISO()
            : end.plus(timeOffset).toISO()

        if (selectedArea) {
          apiStart = selectedArea.start
          apiEnd = selectedArea.end
        }

        const deviceId = event?.device ?? device?.id
        const chartResponse = await apiFetch(
          `/devices/${deviceId}/chart/`,
          {
            start: apiStart,
            end: apiEnd,
            resolution: dataResolution,
            dataTypes: [
              'mc_1p0_mean',
              'mc_2p5_mean',
              'mc_4p0_mean',
              'mc_10p0_mean',
              'tpm_mean',
              'dba_mean',
              'nrs',
              'hum_mean',
              'temp_mean',
              'eco2_mean',
              'tvoc_mean',
            ],
          },
          { cancelationPrefix: entityName },
        )

        const smokeProfilesIds = store
          .selectSystemSmokeProfiles()
          .map((profile) => profile.id)

        let probabilityResponse = null
        if (smokeProfilesIds.length) {
          probabilityResponse = await apiFetch(
            `/devices/${deviceId}/probability_chart/`,
            {
              start: apiStart,
              end: apiEnd,
              resolution: dataResolution,
              profiles: smokeProfilesIds,
            },
            { cancelationPrefix: entityName },
          )
        }

        const noiseEventsResponse = await apiFetch(
          `/events/`,
          {
            device: deviceId,
            createdOnAfter: apiStart,
            createdOnBefore: apiEnd,
            eventClass: ['NOISE'],
            includeDeletedEvents: false,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedNoiseEvents = noiseEventsResponse?.results?.map((eventData) => ({
          ...eventData,
          createdOn: DateTime.fromISO(eventData.createdOn, { zone }),
          endTime: eventData.endTime
            ? DateTime.fromISO(eventData.endTime, { zone })
            : timeNow,
          ongoing: !eventData.endTime,
        }))

        const alarmsResponse = await apiFetch(
          `/alarms/`,
          {
            deviceId,
            startAfter: apiStart,
            startBefore: apiEnd,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedAlarms = alarmsResponse?.results?.map((alarm) => ({
          ...alarm,
          start: DateTime.fromISO(alarm.start, { zone }),
          end: alarm.end ? DateTime.fromISO(alarm.end, { zone }) : timeNow,
          ongoing: !alarm.end,
        }))

        const reservationsResponse = await apiFetch(
          `/reservations/`,
          {
            unit,
            pageSize: 999,
          },
          { cancelationPrefix: entityName },
        )
        const processedReservations = reservationsResponse?.results
          ?.map((reservation) => ({
            ...reservation,
            checkIn: DateTime.fromISO(reservation.checkIn, { zone }),
            checkOut: DateTime.fromISO(reservation.checkOut, { zone }),
          }))
          .filter((reservation) =>
            isDateRangeOverlap(
              { start: reservation.checkIn, end: reservation.checkOut },
              { start: apiStart, end: apiEnd },
            ),
          )

        const scenariosResponse = await apiFetch(
          `/scenarios/`,
          {
            sourceDevice: deviceId,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedScenarios = scenariosResponse?.results?.filter((scenario) =>
          isDateRangeOverlap(
            { start: scenario.readingStart, end: scenario.readingEnd },
            { start: apiStart, end: apiEnd },
          ),
        )

        let data = chartResponse
        if (probabilityResponse) {
          data = data.concat(probabilityResponse)
        }
        const finalResult = {
          data,
          alarms: processedAlarms ?? [],
          reservations: processedReservations ?? [],
          scenarios: processedScenarios ?? [],
          noiseEvents: processedNoiseEvents ?? [],
        }

        dispatch({
          type: EVENT_MODAL_CHART_SUCCESS,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_CHART_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchEventModalUnitDevices:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_UNIT_DEVICES_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const unitDevicesResponse = await apiFetch(
          '/devices/',
          {
            pageSize: 9999,
            unit: payload,
          },
          { cancelationPrefix: entityName },
        )
        const devices = unitDevicesResponse?.results ?? []

        dispatch({
          type: EVENT_MODAL_UNIT_DEVICES_LOADED,
          payload: devices,
          meta: { status: 'succeeded' },
        })
        return devices
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_UNIT_DEVICES_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
