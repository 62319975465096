import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { DescriptionOutlined } from '@mui/icons-material'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { Box, Button, Tooltip } from '@mui/material'

import { DateTime } from 'luxon'

import { formatScenarioName } from '@common/utils'
import scenarioUrls from '@portal/pages/Scenarios/urls'

function ScenarioOptions({ row, setScenarioData, setScenarioFormOpen, fullWidth }) {
  const {
    isAtLeastDataScience,
    doUpdateUrl,
    doUpdateQuery,
    doShowSnackbar,
    doDeviceFetch,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'doUpdateUrl',
    'doUpdateQuery',
    'doShowSnackbar',
    'doDeviceFetch',
  )
  const {
    id: eventId,
    device: deviceId,
    createdOn,
    endTime,
    unitName,
    propertyName,
  } = row

  const handleViewEvent = () => {
    doUpdateQuery(
      { grafanaEvent: eventId, grafanaDevice: deviceId },
      { maintainScrollPosition: true },
    )
  }

  const handleSaveScenario = async () => {
    if (row.scenario) doUpdateUrl(scenarioUrls.entity.replace(':id', row.scenario))

    setScenarioFormOpen(true)
    let device = null
    try {
      device = await doDeviceFetch(deviceId)
    } catch (err) {
      setScenarioFormOpen(false)
      doShowSnackbar('Failed to get device information, please try again later.')
      return err
    }
    const readingEnd = endTime ?? DateTime.now().toISO()

    setScenarioData({
      sourceDevice: device?.id,
      deviceModel: device?.model,
      event: row.id,
      name: formatScenarioName(propertyName, unitName, createdOn),
      readingStart: createdOn,
      readingEnd,
    })
    return null
  }

  return (
    <Box flex={1} display="flex" gap={2} justifyContent="center">
      <Tooltip placement="top" title="View Event">
        <span style={fullWidth ? { width: '100%' } : null}>
          <Button
            fullWidth={fullWidth}
            size="small"
            startIcon={<QueryStatsIcon />}
            variant="outlined"
            onClick={() => handleViewEvent()}
            disabled={!row.device}
          >
            View
          </Button>
        </span>
      </Tooltip>
      {isAtLeastDataScience && (
        <Tooltip placement="top" title={`${row.scenario ? 'View' : 'Save'} Scenario`}>
          <span style={fullWidth ? { width: '100%' } : null}>
            <Button
              fullWidth={fullWidth}
              size="small"
              startIcon={<DescriptionOutlined />}
              variant="outlined"
              disabled={!row.device}
              onClick={() => handleSaveScenario()}
            >
              {row.scenario ? 'View' : 'Save'}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  )
}

ScenarioOptions.defaultProps = {
  fullWidth: false,
}

ScenarioOptions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    scenario: PropTypes.string,
    device: PropTypes.string,
    createdOn: PropTypes.string,
    endTime: PropTypes.string,
    unitName: PropTypes.string,
    propertyName: PropTypes.string,
    timeZone: PropTypes.string,
  }).isRequired,
  setScenarioData: PropTypes.func.isRequired,
  setScenarioFormOpen: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}

export default ScenarioOptions
