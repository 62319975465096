import { useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { InfoOutlined, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid2,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'

import { Breadcrumbs } from '@common/components'
import { isRequestError, parseApiErrors, useSmallScreen } from '@common/utils'
import configureUrls from '@rest/pages/Configure/urls'
import { PageTitle } from '@rest/UI/components'

import ConfigureNav, { CONFIGURE_NAV_MESSAGING, navOptions } from '../ConfigureNav'
import GuestMessagingInfo from './GuestMessagingInfo'
import MessageInput from './MessageInput'
import Step from './Step'

const defaultMessage =
  'Hello {{guest_name}}. We have noticed elevated noise at your rental property. For the comfort of your neighbors, please maintain reasonable noise levels.'

export default function Messaging({ level }) {
  const isMain = level === 'global'

  const FIRST_MESSAGE_KEY = 'ABN_GUEST_FIRST_ESCALATION'
  const SECOND_MESSAGE_KEY = 'ABN_GUEST_SECOND_ESCALATION'

  const [showInfo, setShowInfo] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [messages, setMessages] = useState({
    firstEscalation: '',
    secondEscalation: '',
  })

  const hasIntegration = false // TODO: not implemented yet

  const firstApiMessageData = useRef()
  const secondApiMessageData = useRef()

  const {
    routeInfo: { pattern },
    customNotificationTemplateList,
    customNotificationTemplateListIsLoading,
    doAccountFetch,
    currentAccount,
    currentOrganizationDetails: organization,
    doShowSnackbar,
    doCustomNotificationTemplateSave,
    doCustomNotificationTemplateCreate,
    doMarkCustomNotificationTemplateListAsOutdated,
    doUpdateUrl,
  } = useConnect(
    'selectRouteInfo',
    'selectCurrentAccount',
    'selectCustomNotificationTemplateList',
    'selectCustomNotificationTemplateListIsLoading',
    'doAccountFetch',
    'selectCurrentOrganizationDetails',
    'doShowSnackbar',
    'doCustomNotificationTemplateSave',
    'doCustomNotificationTemplateCreate',
    'doMarkCustomNotificationTemplateListAsOutdated',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  const stepperTitleStyle = { variant: isSmallScreen ? 'h6' : 'h5' }
  const stepperSubtitleStyle = {
    variant: isSmallScreen ? 'body2' : 'body1',
    sx: { color: 'text.secondary', my: 1 },
  }

  const isBodyDisabled = hasIntegration || customNotificationTemplateListIsLoading

  const checkCanSave = (updatedMessages) => {
    const checkResult =
      updatedMessages.firstEscalation !== firstApiMessageData.current?.textBody ||
      updatedMessages.secondEscalation !== secondApiMessageData.current?.textBody
    if (checkResult !== canSave) {
      setCanSave(checkResult)
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        await doMarkCustomNotificationTemplateListAsOutdated()
        await doAccountFetch(currentAccount)
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    if (customNotificationTemplateList.count) {
      firstApiMessageData.current = customNotificationTemplateList.results.find(
        (data) => data.account === currentAccount && data.key === FIRST_MESSAGE_KEY,
      )
      secondApiMessageData.current = customNotificationTemplateList.results.find(
        (data) => data.account === currentAccount && data.key === SECOND_MESSAGE_KEY,
      )
      const updatedMessages = {
        firstEscalation: firstApiMessageData.current?.textBody || '',
        secondEscalation: secondApiMessageData.current?.textBody || '',
      }
      setMessages(updatedMessages)
      checkCanSave(updatedMessages)
    }
  }, [customNotificationTemplateList])

  const saveCustomMessage = async ({ initialMessageData, message, key }) => {
    if (initialMessageData) {
      const response = await doCustomNotificationTemplateSave({
        ...initialMessageData,
        textBody: message || defaultMessage,
      })
      return response
    }
    const response = await doCustomNotificationTemplateCreate({
      key,
      textBody: message || defaultMessage,
    })
    return response
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      const result = await Promise.all([
        saveCustomMessage({
          initialMessageData: firstApiMessageData.current,
          message: messages.firstEscalation,
          key: FIRST_MESSAGE_KEY,
        }),
        saveCustomMessage({
          initialMessageData: secondApiMessageData.current,
          message: messages.secondEscalation,
          key: SECOND_MESSAGE_KEY,
        }),
      ])

      const isError = result.some((item) => isRequestError(item))

      if (isError) {
        const errorMessage = result?.find((item) => item.error?.response?.textBody)
          ?.error?.response?.textBody
        doShowSnackbar(errorMessage, 'error')
      } else {
        doShowSnackbar('Saved!')
        doMarkCustomNotificationTemplateListAsOutdated()
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setIsSaving(false)
    }
  }

  const handleRevertToDefault = () => {
    setMessages({
      firstEscalation: firstApiMessageData.current?.textBody || '',
      secondEscalation: secondApiMessageData.current?.textBody || '',
    })
    setCanSave(false)
  }

  const showNavigation = useMemo(
    () => isMain && pattern === configureUrls.messaging,
    [isMain, pattern],
  )

  return (
    <>
      <GuestMessagingInfo open={showInfo} onClose={() => setShowInfo(false)} />
      {isMain && (
        <Breadcrumbs
          links={[
            { label: organization?.name || '--' },
            { label: 'Configure' },
            { label: 'Guest Messaging' },
          ]}
        />
      )}
      {showNavigation && isSmallScreen && (
        <Select
          fullWidth
          variant="outlined"
          size="small"
          value={CONFIGURE_NAV_MESSAGING}
          onChange={(e) => {
            doUpdateUrl(navOptions.find((opt) => opt.value === e.target.value).href)
          }}
          MenuProps={{ disableScrollLock: true }}
          IconComponent={KeyboardArrowDown}
          sx={{ mb: 2, mt: 1 }}
        >
          {navOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <Grid2 container spacing={3.5} sx={{ pt: isMain ? 1 : 0 }}>
        {showNavigation && !isSmallScreen && (
          <ConfigureNav selected={CONFIGURE_NAV_MESSAGING} />
        )}
        <Grid2 size={{ xs: 12, md: isMain ? 9.5 : 12 }} height="100%">
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            justifyContent="space-between"
          >
            <Box data-testid="header_container" display="flex" alignItems="center">
              <PageTitle>Guest Messaging</PageTitle>
              <InfoOutlined
                fontSize="small"
                sx={{
                  fontSize: '1.5rem',
                  m: 1,
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
                onClick={() => setShowInfo(true)}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              sx={{ my: isSmallScreen ? 2 : 0 }}
            >
              <Button
                fullWidth={isSmallScreen}
                variant="outlined"
                onClick={handleRevertToDefault}
                sx={{ mr: 1 }}
                disabled={!canSave || hasIntegration || isSaving}
              >
                REVERT TO DEFAULT
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!canSave || hasIntegration || isSaving}
              >
                SAVE
              </Button>
            </Box>
          </Stack>
          <Typography
            variant={isSmallScreen ? 'body2' : 'body1'}
            sx={{ maxWidth: isSmallScreen ? '100%' : '70%' }}
          >
            The following messages will be sent via SMS to all guests whose reservation
            has been uploaded, when the noise level is above the threshold
          </Typography>
          {hasIntegration && (
            <Card
              sx={{
                display: 'flex',
                backgroundColor: '#f7f5f0',
                px: 4,
                py: 3,
                my: 4,
              }}
            >
              <Typography>
                Your guest messaging is currently being managed by&nbsp;
              </Typography>
              <Link href="https://app.breezeway.io/signin" target="_blank">
                Breezeway
              </Link>
            </Card>
          )}
          <Box
            display="flex"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {customNotificationTemplateListIsLoading ? (
              <CircularProgress sx={{ position: 'absolute' }} />
            ) : null}
            <Box
              sx={{
                transitionProperty: 'opacity',
                transitionDuration: '300ms',
                opacity: isBodyDisabled ? 0.35 : 1,
                pointerEvents: isBodyDisabled ? 'none' : 'initial',
              }}
            >
              <Step data-testid="step_1" label="1" sx={{ mt: 4 }}>
                <Typography {...stepperTitleStyle}>1st Escalation Message</Typography>
                <Typography {...stepperSubtitleStyle}>
                  A friendly message to the guest sent 10 minutes after continuous noise
                  over the threshold.
                </Typography>
                <MessageInput
                  data-testid="first_message_input"
                  initialValue={messages.firstEscalation}
                  placeholder={defaultMessage}
                  onChange={(value) => {
                    const updatedData = { ...messages, firstEscalation: value }
                    checkCanSave(updatedData)
                    setMessages(updatedData)
                  }}
                  sx={{ mb: 4 }}
                />
              </Step>
              <Step data-testid="step_2" label="2">
                <Typography {...stepperTitleStyle}>2nd Escalation Message</Typography>
                <Typography {...stepperSubtitleStyle}>
                  A firm message to the guest sent 10 minutes after the first alert if
                  the noise is still over the threshold.
                </Typography>
                <MessageInput
                  data-testid="second_message_input"
                  initialValue={messages.secondEscalation}
                  placeholder={defaultMessage}
                  onChange={(value) => {
                    const updatedData = { ...messages, secondEscalation: value }
                    checkCanSave(updatedData)
                    setMessages(updatedData)
                  }}
                  sx={{ mb: 4 }}
                />
              </Step>
              <Step data-testid="step_3" label="3" showLine={false} sx={{ mb: 4 }}>
                <Typography {...stepperSubtitleStyle}>
                  {`If the noise continues for another 10 minutes, you will receive a
                    notification of the guest's continual noise. It is up to you how you would like to proceed,
                    but we recommend a call to the guest or a visit to the property when possible.
                    No further messages will be automated at this point.`}
                </Typography>
              </Step>
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </>
  )
}

Messaging.defaultProps = {
  level: 'global',
}

Messaging.propTypes = {
  level: PropTypes.oneOf(['global', 'tab']),
}
