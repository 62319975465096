import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { DateTime } from 'luxon'

import { StaticMultiSelect } from '@common/components/Selects'
import { downloadFile, formatCurrency, getApiFetch } from '@common/utils'
import Picker from '@portal/UI/components/Picker'
import { arrayToCsv } from '@portal/Utils/csv'

export default function PropertyInvoiceForm({ open, onClose }) {
  const currentDate = DateTime.now()
  const startOfCurrentMonth = currentDate.startOf('month')
  const endOfPreviousMonth = startOfCurrentMonth.minus({ days: 1 })
  const startOfPreviousMonth = endOfPreviousMonth.startOf('month')

  const initValues = { dateAfter: startOfPreviousMonth, dateBefore: endOfPreviousMonth }
  const [dateRange, setDateRange] = useState(initValues)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedProperties, setSelectedProperties] = useState([])
  const [invoiceData, setInvoiceData] = useState([])

  const { doPropertyListFetchAll, allProperties } = useConnect(
    'doPropertyListFetchAll',
    'selectAllProperties',
  )

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const maxDate = dateRange?.dateAfter ? dateRange.dateAfter.plus({ days: 31 }) : null
  const properties = allProperties?.map((p) => ({ id: p.id, label: p.name })) || []

  const columns = [
    { field: 'propertyName', headerName: 'Property Name', sortable: false, flex: 1 },
    { field: 'numberOfEvents', headerName: 'Events Total', sortable: false, flex: 1 },
    {
      field: 'overallChargedAmount',
      headerName: 'Invoice total',
      renderCell: ({ row }) => formatCurrency(row.overallChargedAmount),
      sortable: false,
      flex: 1,
    },
    {
      field: 'contractTerms',
      headerName: 'Contract Terms',
      renderCell: ({ row }) => (
        <Stack height="100%" flexDirection="column" justifyContent="center">
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
            {row.contractTerms.join('\n')}
          </Typography>
        </Stack>
      ),
      sortable: false,
      flex: 1,
    },
  ]

  const fetchInvoiceData = async () => {
    setError(null)
    setLoading(true)
    const { dateAfter, dateBefore } = dateRange
    const payload = {
      start: dateAfter.toISODate(),
      end: dateBefore.toISODate(),
      properties: selectedProperties.map((p) => p.id),
    }

    try {
      const response = await apiFetch('/properties/invoice_dry_run/', payload, {
        cancelationPrefix: 'property_invoice_form',
      })
      if (response?.length > 0) setInvoiceData(response)
    } catch (e) {
      setError(e.message || JSON.stringify(e))
    }
    setLoading(false)
  }

  const handleExport = () => {
    const headers = columns.map((c) => c.field)
    const rows = invoiceData.map((row) => [
      row.propertyName || '',
      row.numberOfEvents || '',
      row.overallChargedAmount || '',
      row.contractTerms.join('\n') || '',
    ])

    const data = arrayToCsv([headers, ...rows])
    const formattedDate = DateTime.now().toFormat('yyyyMMdd')

    downloadFile({
      data: [data],
      fileName: `stripe_billing_preview_${formattedDate}.csv`,
      fileType: 'text/csv',
    })
  }

  useEffect(() => {
    doPropertyListFetchAll({
      billingEnabled: true,
      active: true,
      ordering: 'name,account__name',
    })
  }, [])

  useEffect(() => {
    setError(null)
    setInvoiceData([])
    setDateRange(initValues)
    setSelectedProperties(properties)
  }, [open])

  const dataWithIds = invoiceData.map((row, index) => ({ ...row, id: index }))

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: '1.7rem', textAlign: 'center' }}>
        Stripe Billing Preview
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" gap={2} mt={1} mb={3}>
          <StaticMultiSelect
            hideLabel
            options={properties}
            label="Properties"
            placeholder="Select properties"
            size="small"
            value={selectedProperties}
            onChange={setSelectedProperties}
            limitTags={5}
          />
          <Picker
            range
            sx={{ width: '45%', maxWidth: '200px' }}
            type="date"
            label="Created"
            disableMaskedInput
            conditionSeparator=""
            filterName="date"
            lowerCondition="After"
            upperCondition="Before"
            onChange={setDateRange}
            value={dateRange}
            maxDate={maxDate}
          />
        </Box>
        {error && (
          <Alert sx={{ mb: 3 }} key={error} severity="error">
            <Typography fontSize={14}>{error}</Typography>
          </Alert>
        )}
        <DataGridPro
          hideFooter
          autoHeight
          disableColumnFilter
          disableRowSelectionOnClick
          loading={loading}
          density="compact"
          sortingMode="server"
          rows={dataWithIds}
          columns={columns}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
        <Button onClick={handleExport}>Export</Button>
        <Box>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            color="secondary"
            onClick={fetchInvoiceData}
            disabled={
              !dateRange.dateAfter ||
              !dateRange.dateBefore ||
              isEmpty(selectedProperties)
            }
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

PropertyInvoiceForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
