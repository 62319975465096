import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Apartment as ApartmentIcon,
  Bungalow as BungalowIcon,
  Circle,
  CopyAll,
  Delete,
  DoubleArrowRounded,
  Edit,
  Home as HomeIcon,
} from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Stack, Typography } from '@mui/material'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import MembershipsList from '@portal/pages/Memberships/Memberships'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import DeleteModal from '@portal/UI/components/DeleteModal'
import EventLogTab from '@portal/UI/components/EventLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import MoveEntityModal from '@portal/UI/components/MoveEntityModal'

import PropertyForm from './Form/PropertyForm'
import Installations from './Tabs/Installations'
import Integrations from './Tabs/Integrations/Integrations'
import MetricsBlock from './Tabs/Property/MetricsBlock'
import PropertyTab from './Tabs/Property/Property'
import propertyUrls from './urls'

function PropertyIcon({ propertyType }) {
  const iconSize = 'small'
  switch (propertyType) {
    case 'SFH':
      return <HomeIcon fontSize={iconSize} />
    case 'APT':
      return <ApartmentIcon fontSize={iconSize} />
    case 'OTHER':
    default:
      return <BungalowIcon fontSize={iconSize} />
  }
}

PropertyIcon.propTypes = {
  propertyType: PropTypes.string.isRequired,
}

export default function SingleProperty() {
  const [detailsData, setDetailsData] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [moveFormOpen, setMoveFormOpen] = useState(false)
  const [currentProperty, setCurrentProperty] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState(null)

  const isSmallScreen = useSmallScreen()

  const {
    propertyDetails,
    propertyDetailsIsLoading,
    propertyDetailsError,
    doPropertyDelete,
    doFetchPropertyDetails,
    doShowSnackbar,
    doUpdateUrl,
    routeParams: { id: propertyId },
    hashObject,
    isAtLeastAdmin,
    isSuperuser,
  } = useConnect(
    'selectPropertyDetails',
    'selectPropertyDetailsIsLoading',
    'selectPropertyDetailsError',
    'doPropertyDelete',
    'doFetchPropertyDetails',
    'doShowSnackbar',
    'doUpdateUrl',
    'selectHashObject',
    'selectRouteParams',
    'selectIsAtLeastAdmin',
    'selectIsSuperuser',
  )

  const tabs = [
    {
      label: 'Property',
      component: <PropertyTab propertyDetails={detailsData} />,
      hash: 'property',
    },
    {
      label: 'Memberships',
      component: <MembershipsList renderAsTab />,
      hash: 'memberships',
    },
    {
      label: 'Event Log',
      component: <EventLogTab entity="Property" />,
      hash: 'eventLog',
    },
    {
      label: 'Audit Log',
      component: (
        <AuditLogTab showObjectName objectId={propertyId} include="smokeprofile" />
      ),
      hash: 'auditLog',
    },
    { label: 'Installations', component: <Installations />, hash: 'installations' },
    {
      label: 'Integrations',
      component: <Integrations property={detailsData?.property} />,
      hash: 'integrations',
    },
  ]

  const fetchPropertyDetails = useCallback(async (id) => {
    try {
      await doFetchPropertyDetails(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchPropertyDetails(propertyId)
  }, [])

  useEffect(() => {
    if (propertyDetails) {
      setDetailsData(propertyDetails)
    }
  }, [propertyDetails])

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  const handleEditClick = () => {
    setEditFormOpen(true)
    setCurrentProperty(detailsData?.property)
  }

  const handleDeleteClick = () => {
    setDeleteFormOpen(true)
    setCurrentProperty(detailsData?.property)
  }

  const handleMoveClick = () => {
    setMoveFormOpen(true)
    setCurrentProperty(detailsData?.property)
  }

  const deleteProperty = async () => {
    try {
      await doPropertyDelete(currentProperty.id)
      doShowSnackbar('Successfully deleted property')
      setDeleteFormOpen(false)
      doUpdateUrl(propertyUrls.list)
    } catch (error) {
      const apiError = parseApiErrors(error?.response) || 'Ooops. Something went wrong'
      setDeleteError(apiError)
    }
  }

  const PropertyDetailChips = useCallback(
    () => (
      <>
        <Chip
          icon={<PropertyIcon propertyType={detailsData?.property.propertyType} />}
          variant="outlined"
          size="small"
          label={detailsData?.property.propertyType}
          sx={{ mx: 1, paddingLeft: 0.5 }}
        />
        <Chip
          icon={<Circle />}
          variant="outlined"
          size="small"
          label={detailsData?.property.deletedOn ? 'Inactive' : 'Active'}
          color={detailsData?.property.deletedOn ? 'error' : 'success'}
        />
      </>
    ),
    [detailsData?.property],
  )

  if (propertyDetailsIsLoading && !detailsData) {
    return <Loading />
  }

  if (propertyDetailsError && !detailsData) {
    return <Typography>Invalid ID or something or other</Typography>
  }

  if (!detailsData || detailsData?.property.id !== propertyId) {
    return <Loading />
  }

  return (
    <>
      {currentProperty && (
        <PropertyForm
          open={editFormOpen}
          onClose={() => {
            setCurrentProperty(null)
            fetchPropertyDetails(currentProperty.id)
            setEditFormOpen(false)
          }}
          instance={currentProperty}
        />
      )}
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={deleteProperty}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
          setCurrentProperty(null)
          setDeleteError(null)
        }}
      />
      <MoveEntityModal
        open={moveFormOpen}
        entity="property"
        entityData={
          currentProperty && {
            id: currentProperty?.id,
            parentId: currentProperty?.account,
          }
        }
        onClose={(success) => {
          setMoveFormOpen(false)
          setCurrentProperty(null)

          if (success === true) {
            fetchPropertyDetails(propertyId)
          }
        }}
      />

      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {propertyDetailsIsLoading ? (
          <CircularProgress sx={{ position: 'absolute' }} />
        ) : null}
        <Box
          sx={{
            width: 1,
            px: isSmallScreen ? 0 : 2,
            m: isSmallScreen ? 3 : 4,
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: propertyDetailsIsLoading ? 0.35 : 1,
            pointerEvents: propertyDetailsIsLoading ? 'none' : 'initial',
          }}
        >
          <Breadcrumbs
            links={[
              { label: 'Home', href: homeUrls.home },
              { label: 'Properties', href: propertyUrls.list },
              { label: detailsData?.property.name || 'Loading...' },
            ]}
          />
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="caption" color="text.secondary">
              id: {propertyId}
            </Typography>
            <CopyAll
              sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
              onClick={() => {
                navigator.clipboard.writeText(propertyId)
                doShowSnackbar('ID copied to clipboard')
              }}
            />
          </Box>
          {isSmallScreen ? (
            <Box mb={1}>
              <ListPageTitle
                title={detailsData?.property.name}
                menuItems={[
                  ...(isAtLeastAdmin
                    ? [
                        ...(isSuperuser
                          ? [{ label: 'Move', onClick: handleMoveClick }]
                          : []),
                        { label: 'Edit', onClick: handleEditClick },
                        { label: 'Delete', onClick: handleDeleteClick },
                      ]
                    : []),
                ]}
              />
              <Box display="flex" mt={0.5} gap={0.5}>
                <PropertyDetailChips />
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Typography variant="h3" mb={2}>
                  {detailsData?.property.name}
                </Typography>
                <PropertyDetailChips />
              </Stack>
              {isAtLeastAdmin && (
                <Box>
                  {isSuperuser && (
                    <Button
                      startIcon={<DoubleArrowRounded />}
                      variant="outlined"
                      sx={{ mr: 1 }}
                      onClick={handleMoveClick}
                    >
                      Move
                    </Button>
                  )}
                  <Button
                    startIcon={<Edit />}
                    variant="outlined"
                    sx={{ mr: 1 }}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          )}
          <MetricsBlock property={detailsData?.property} />
          <TabComponent
            tabs={tabs}
            externalState={{ value: tabValue, setValue: setTabValue }}
            contentSx={{ py: 1 }}
          />
        </Box>
      </Box>
    </>
  )
}
