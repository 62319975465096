import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

export default function AssignedEntities({
  open,
  onClose,
  currentThreshold,
  hierarchy,
}) {
  const property = hierarchy.propertyGroups[0]?.properties[0]
  const unitGroups = property?.unitGroups ?? []

  const assignedToProperty = !!property?.explicit

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        {currentThreshold.name}
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ mx: 3 }} />
      <DialogContent sx={{ px: 3 }}>
        {assignedToProperty && (
          <Stack direction="column">
            <Typography variant="body2">Property:</Typography>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                py: 2,
                px: 0,
                borderBottom: 1,
                borderColor: 'grey.300',
                '&:last-child': { border: 0, mb: 2 },
              }}
            >
              <KeyboardArrowRightOutlinedIcon sx={{ color: 'grey.600' }} />
              <Typography color="primary" fontWeight="600" ml={2}>
                {property.name}
              </Typography>
            </Stack>
          </Stack>
        )}
        {!isEmpty(unitGroups) && (
          <>
            <Typography variant="body2">Unit Groups:</Typography>
            {unitGroups.map((unitGroup) => (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  py: 2,
                  borderBottom: 1,
                  borderColor: 'grey.300',
                  '&:last-child': { border: 0, mb: 2 },
                }}
              >
                <KeyboardArrowRightOutlinedIcon sx={{ color: 'grey.600' }} />
                <Typography color="primary" fontWeight="600" ml={2}>
                  {unitGroup.name}
                </Typography>
              </Stack>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

AssignedEntities.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentThreshold: PropTypes.shape({
    name: PropTypes.string,
    assignedUnits: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  hierarchy: PropTypes.shape({
    propertyGroups: PropTypes.arrayOf(
      PropTypes.shape({
        properties: PropTypes.arrayOf(
          PropTypes.shape({
            unitGroups: PropTypes.arrayOf(PropTypes.shape({})),
          }),
        ),
      }),
    ),
  }).isRequired,
}
