import PropTypes from 'prop-types'

import { Circle } from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
} from '@mui/material'

export default function IntegrationCard({
  title,
  subheader,
  actions,
  enabled,
  content,
  cardStyles,
  contentStyles,
}) {
  return (
    <Card elevation={3} sx={{ width: 400, ...cardStyles, borderRadius: 1, p: 1 }}>
      <CardHeader
        action={
          <Chip
            size="small"
            icon={<Circle />}
            variant="outlined"
            label={enabled ? 'Enabled' : 'Disabled'}
            color={enabled ? 'success' : 'error'}
          />
        }
        title={title}
        subheader={subheader}
      />
      <Divider light variant="middle" />
      <CardContent sx={{ py: 2, ...contentStyles }}>{content}</CardContent>
      <Divider light variant="middle" />
      <CardActions sx={{ pt: 2 }}>{actions}</CardActions>
    </Card>
  )
}

IntegrationCard.defaultProps = {
  enabled: false,
  subheader: '',
  cardStyles: {},
  contentStyles: {},
}

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  enabled: PropTypes.bool,
  actions: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  cardStyles: PropTypes.shape(),
  contentStyles: PropTypes.shape(),
}
