import { useState } from 'react'

import PropTypes from 'prop-types'

import { Grid2 } from '@mui/material'

import SubNav from './SubNav'
import ThresholdDisplay from './ThresholdProfiles/ThresholdDisplay'

export default function PropertyConfigure({ property }) {
  const [selected, setSelected] = useState('threshold')

  return (
    <Grid2 container spacing={3.5}>
      <SubNav selected={selected} setSelected={setSelected} />
      <Grid2 size={{ xs: 12, md: 9.5 }}>
        {selected === 'threshold' && <ThresholdDisplay property={property} />}
      </Grid2>
    </Grid2>
  )
}

PropertyConfigure.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}
