import PropTypes from 'prop-types'

import { Group } from '@visx/group'

export default function GraphContainer({
  children,
  width,
  height,
  left,
  top,
  fill,
  shadowMultiplayer,
}) {
  return (
    <>
      <rect
        x={left}
        y={top}
        width={width}
        height={height}
        fill={fill}
        style={{
          filter: `drop-shadow(0px 3px 1px rgba(0, 0, 0, ${0.2 * shadowMultiplayer}))
                drop-shadow(0px 2px 2px rgba(0, 0, 0, ${0.14 * shadowMultiplayer}))
                drop-shadow(0px 1px 5px rgba(0, 0, 0, ${0.12 * shadowMultiplayer}))`,
        }}
        pointerEvents="none"
      />
      <Group>{children}</Group>
    </>
  )
}

GraphContainer.defaultProps = {
  shadowMultiplayer: 1,
}

GraphContainer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  shadowMultiplayer: PropTypes.number,
}
