import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Form, Formik } from 'formik'

export default function FormDialog({
  'data-testid': dataTestId,
  children,
  label,
  initialValues,
  maxWidth,
  onClose,
  onSave,
  open,
  validationSchema,
  successMessage,
  saveButtonText,
  bypassDirtyState,
  isSaveEnabled,
  saveTooltip,
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (values) => {
    setIsSaving(true)
    try {
      const result = await onSave({ id: initialValues?.id, ...values })
      if (result) {
        doShowSnackbar(successMessage, 'success')
        onClose(true)
      }
    } catch (error) {
      captureException(error)
      doShowSnackbar('Unable to save information', 'error')
    }
    setIsSaving(false)
  }

  return (
    <div>
      <Dialog
        data-testid={dataTestId}
        fullWidth
        maxWidth={maxWidth ?? 'sm'}
        open={open}
        onClose={onClose}
        disableScrollLock
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {label}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ mx: 3 }} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <DialogContent>{children}</DialogContent>
              <DialogActions
                sx={{
                  paddingBottom: '25px',
                  paddingRight: '25px',
                  paddingTop: '0px',
                }}
              >
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Tooltip title={saveTooltip}>
                  <span style={{ paddingLeft: 10 }}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={
                        !isSaveEnabled ||
                        !isValid ||
                        (!bypassDirtyState && !dirty) ||
                        isSaving
                      }
                    >
                      {saveButtonText}
                    </Button>
                  </span>
                </Tooltip>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

FormDialog.defaultProps = {
  'data-testid': undefined,
  maxWidth: 'sm',
  saveButtonText: 'Submit',
  successMessage: 'Successfully saved information',
  bypassDirtyState: false,
  isSaveEnabled: true,
  saveTooltip: undefined,
}

FormDialog.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  initialValues: PropTypes.PropTypes.shape().isRequired,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  validationSchema: PropTypes.shape().isRequired,
  successMessage: PropTypes.string,
  saveButtonText: PropTypes.string,
  bypassDirtyState: PropTypes.bool,
  isSaveEnabled: PropTypes.bool,
  saveTooltip: PropTypes.string,
}
