import PropTypes from 'prop-types'

import { formatErrors } from '@common/components/Form/utils'

import DynamicSelect from './DynamicSelect'

export default function FormikDynamicSelect({
  field,
  form,
  label,
  endpoint,
  multiple,
  onChange,
  secondaryTextAttr,
  ...rest
}) {
  const meta = form.getFieldMeta(field?.name)
  const [hasFormikError, formikErrorText] = formatErrors({ field, form })
  const showError = form?.dirty && meta?.touched && hasFormikError

  return (
    <DynamicSelect
      multiple={multiple}
      label={label}
      endpoint={endpoint}
      primaryTextAttr="name"
      secondaryTextAttr={secondaryTextAttr}
      variant="standard"
      onChange={(acc) => {
        form.setFieldValue(field?.name, acc || '')
        onChange(acc)
      }}
      value={form?.values?.[field?.name]}
      showError={showError}
      error={formikErrorText}
      {...rest}
    />
  )
}

FormikDynamicSelect.defaultProps = {
  multiple: false,
  onChange: () => {},
  secondaryTextAttr: undefined,
}

FormikDynamicSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    values: PropTypes.shape(),
    getFieldMeta: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  secondaryTextAttr: PropTypes.string,
}
