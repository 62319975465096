import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { EditOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid2,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'

import { Loading } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { getApiFetch, isAbortError, parseApiErrors } from '@common/utils'
import AssignThreshold from '@rest/pages/Configure/ThresholdProfiles/AssignThreshold'
import EditThreshold from '@rest/pages/Configure/ThresholdProfiles/EditThreshold'
import urls from '@rest/pages/Configure/urls'
import { formatSubitemCount } from '@rest/Utils/formatMenuStrings'

import AssignedEntities from './AssignedEntities'
import ThresholdCard from './ThresholdCard'

export default function ThresholdDisplay({ property }) {
  const [currentThreshold, setCurrentThreshold] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const [profileChoice, setProfileChoice] = useState({})
  const [openAssignedEntities, setOpenAssignedEntities] = useState(false)

  const [profileLoading, setProfileLoading] = useState(false)
  const [profileToAssign, setProfileToAssign] = useState(null)

  const [hierarchy, setHierarchy] = useState(null)
  const [thresholds, setThresholds] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    routeParams: { id: propertyId },
    doShowSnackbar,
  } = useConnect('selectRouteParams', 'doShowSnackbar')

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const fetchInitialData = useCallback(async () => {
    setLoading(true)
    try {
      const availableProfiles = await apiFetch(
        `/threshold_profiles/?pageSize=999&property=${propertyId}`,
      )
      const propertyThreshold = availableProfiles.results.find(
        (profile) => profile.id === property.effectiveNoiseThresholdProfile,
      )
      if (propertyThreshold) {
        const hierarchyData = await apiFetch(
          `/threshold_profiles/${propertyThreshold.id}/assignment_hierarchy/`,
        )
        setHierarchy(hierarchyData)
      }

      setThresholds(availableProfiles?.results ?? [])
      setCurrentThreshold(propertyThreshold)
      setProfileChoice({
        value: property.effectiveNoiseThresholdProfile,
        label: property.effectiveNoiseThresholdProfileName,
      })

      setLoading(false)
    } catch (err) {
      if (!isAbortError) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
        setLoading(false)
      }
    }
  }, [propertyId])

  useEffect(() => {
    fetchInitialData()
  }, [])

  const thresholdOptions = useMemo(
    () =>
      thresholds.map((profile) => ({
        value: profile.id,
        label: profile.name,
      })),
    [thresholds],
  )

  const getThresholdCard = useCallback(
    (style) => {
      const card = currentThreshold?.rules
        .filter((rule) => rule.deviceStyle === style)
        .map((item, i) => (
          <ThresholdCard key={item.name} item={item} sx={{ mt: i !== 0 ? 1 : 0 }} />
        ))
      return card
    },
    [currentThreshold],
  )

  const handleApply = useCallback(async () => {
    try {
      setProfileLoading(true)
      const result = await apiFetch(`/threshold_profiles/${profileChoice.value}/`)
      if (result?.error) {
        throw result.error
      }

      setProfileToAssign(result)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setProfileLoading(false)
    }
  }, [profileChoice, propertyId])

  if (!currentThreshold && !loading) {
    return (
      <>
        <Typography variant="h6">
          There is no threshold profile currently set for this property.
        </Typography>
        <Link href={urls.thresholdsList}>Add one.</Link>
      </>
    )
  }

  if (loading && !currentThreshold) {
    return <Loading />
  }

  return (
    <>
      <EditThreshold
        open={editOpen}
        onClose={(success) => {
          setEditOpen(false)
          if (success === true) {
            fetchInitialData()
          }
        }}
        editId={currentThreshold.id}
        profileToEdit={currentThreshold}
      />
      {profileToAssign && (
        <AssignThreshold
          open={!!profileToAssign}
          profile={profileToAssign}
          onClose={(success) => {
            setProfileToAssign(null)
            if (success === true) {
              fetchInitialData()
              doShowSnackbar('Threshold profile successfully updated.', 'success')
            }
          }}
        />
      )}
      {openAssignedEntities && (
        <AssignedEntities
          open={openAssignedEntities}
          onClose={() => setOpenAssignedEntities(false)}
          currentThreshold={currentThreshold}
          hierarchy={hierarchy}
        />
      )}
      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {loading && <CircularProgress sx={{ position: 'absolute' }} />}
        <Box
          sx={{
            width: '100%',
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: loading ? 0.35 : 1,
            pointerEvents: loading ? 'none' : 'initial',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row">
              <StaticSelect
                options={thresholdOptions}
                sx={{ width: '300px' }}
                label="Current Profile"
                size="small"
                value={profileChoice}
                onChange={setProfileChoice}
                disableClearable
              />
              <Button
                variant="contained"
                disabled={profileLoading}
                sx={{ px: 4.5, ml: 2 }}
                onClick={handleApply}
              >
                Apply
              </Button>
            </Stack>
            <Link href={urls.thresholdsList}>Manage All Thresholds</Link>
          </Stack>
          <Paper elevation={2} sx={{ mt: 4, p: 4 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography variant="caption" color="grey.600">
                  Applied to:
                </Typography>
                <Box>
                  <Button
                    variant="text"
                    sx={{ textTransform: 'capitalize', pl: 0 }}
                    onClick={() => setOpenAssignedEntities(true)}
                  >
                    {`${formatSubitemCount(
                      'Property',
                      hierarchy?.propertyGroups[0]?.properties[0]?.explicit ? 1 : 0,
                    )} ${formatSubitemCount(
                      'Unit Group',
                      hierarchy?.propertyGroups[0]?.properties[0]?.unitGroups.length,
                    )}`}
                  </Button>
                </Box>
              </Stack>
              <Button
                variant="outlined"
                startIcon={<EditOutlined />}
                onClick={() => {
                  setEditOpen(true)
                }}
              >
                Edit
              </Button>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              mt={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Grid2 size={{ xs: 6 }} sx={{ borderRight: '1px solid grey.500' }}>
                <Typography variant="h6" mb={1}>
                  Indoor Threshold
                </Typography>
                {getThresholdCard('INDOOR')}
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="h6" mb={1}>
                  Outdoor Threshold
                </Typography>
                {getThresholdCard('OUTDOOR')}
              </Grid2>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

ThresholdDisplay.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    effectiveNoiseThresholdProfile: PropTypes.string,
    effectiveNoiseThresholdProfileName: PropTypes.string,
  }).isRequired,
}
