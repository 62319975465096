import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MuiTextField from '@mui/material/TextField'

import { Project } from '@common/config'
import { debounce, useSmallScreen } from '@common/utils'

const debouncedSearch = debounce((term, onSetSearch) => onSetSearch(term), 400)

export default function SearchBox({
  title,
  onSetSearch,
  initialValue,
  minLength,
  sx,
  height,
  ...rest
}) {
  const [search, setSearch] = useState(initialValue ?? '')

  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    setSearch(initialValue)
  }, [initialValue])

  const handleClear = () => {
    setSearch('')
    onSetSearch('')
  }

  const handleChange = (e) => {
    const searchTerm = e.target.value
    if (searchTerm === '') handleClear()
    setSearch(searchTerm)
    if (searchTerm?.length > minLength || searchTerm?.length === 0)
      debouncedSearch(searchTerm, onSetSearch)
  }

  const conditionalClear = search.length > 0 && (
    <InputAdornment position="end">
      <IconButton onClick={handleClear}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )

  const { isRest } = Project

  return (
    <Box
      sx={{
        height,
        display: 'flex',
        alignItems: 'center',
        width: isSmallScreen ? '100%' : null,
      }}
    >
      <MuiTextField
        data-testid="searchbox"
        name="search"
        value={search}
        onChange={handleChange}
        placeholder={title ? `Search in ${title}` : undefined}
        sx={{
          ...(isSmallScreen
            ? { width: '100%', mt: isRest ? 0 : 1 }
            : { width: '400px', marginRight: '12px' }),
          ...sx,
        }}
        margin={isRest ? 'none' : 'normal'}
        variant="outlined"
        size="small"
        hiddenLabel
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: conditionalClear,
        }}
        {...rest}
      />
    </Box>
  )
}

SearchBox.defaultProps = {
  initialValue: '',
  sx: {},
  height: 'initial',
  minLength: 3,
}

SearchBox.propTypes = {
  onSetSearch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  minLength: PropTypes.number,
  sx: PropTypes.shape({}),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
