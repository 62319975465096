import {
  appTimeBundle,
  composeBundlesRaw,
  createDebugBundle,
  createReactorBundle,
  createUrlBundle,
} from 'redux-bundler'
import online from 'redux-bundler/dist/online-bundle'

import cache from '@common/bundles/cache'
import idle from '@common/bundles/idle'
import snackbar from '@common/bundles/snackbar'
import {
  accountBundle,
  customNotificationTemplateBundle,
  customNotificationTemplateListBundle,
  thresholdListBundle,
} from '@rest/pages/Configure'
import {
  eventFeedbackBundle,
  noiseEventFeedbackBundle,
  propertyBundle,
  propertyDetailBundle,
  propertyDeviceListBundle,
  propertyEventListBundle,
  propertyListBundle,
  propertyUnitListBundle,
  propertyUserListBundle,
  thresholdsBundle,
  unitBundle,
  unitListBundle,
} from '@rest/pages/Properties'
import { deviceDataModalBundle } from '@rest/pages/Properties/PropertyDevices/DeviceDataModal'
import {
  reservationBundle,
  reservationHelperBundle,
  reservationListBundle,
} from '@rest/pages/Reservations'
import { smokeMetricBundle } from '@rest/pages/SmokeMetrics'
import {
  unitDetailsBundle,
  unitDeviceListBundle,
  unitEventListBundle,
} from '@rest/pages/Units'
import { adjustmentSummaryBundle } from '@rest/UI/components'

import auth from './auth'
import header from './header'
import me from './me'
import routes from './routes'
import system from './system'

export default composeBundlesRaw(
  // redux bundler stuff
  appTimeBundle,
  cache,
  createDebugBundle({
    logSelectors: false,
    logState: false,
    actionFilter: (action) => action && action.type !== 'APP_IDLE',
  }),
  createReactorBundle(),
  createUrlBundle(),
  online,
  routes,
  // app bundles
  auth,
  system,
  me,
  snackbar,
  header,
  idle,
  // property bundles
  propertyBundle,
  propertyDetailBundle,
  propertyListBundle,
  propertyUserListBundle,
  propertyDeviceListBundle,
  propertyEventListBundle,
  propertyUnitListBundle,
  thresholdsBundle,
  eventFeedbackBundle,
  unitBundle,
  unitListBundle,
  noiseEventFeedbackBundle,
  deviceDataModalBundle,
  // unit bundles
  unitDetailsBundle,
  unitEventListBundle,
  unitDeviceListBundle,
  // threshold bundles
  thresholdListBundle,
  // guest messaging bundles
  customNotificationTemplateListBundle,
  customNotificationTemplateBundle,
  accountBundle,
  // reservation bundles
  reservationBundle,
  reservationListBundle,
  reservationHelperBundle,
  // smoke metric budles
  smokeMetricBundle,
  // adjustment summary
  adjustmentSummaryBundle,
)
