import PropTypes from 'prop-types'

import { SvgIcon } from '@mui/material'

const path1 = `M 534.22 968.27 c -236.15 0 -428.27 -192.12 -428.27 -428.27 s 192.12 -428.27 428.27 -428.27 S 962.49 303.85 962.49 540 S 770.37 968.27 534.22 968.27 Z M 534.22 136.89 c -222.27 0 -403.11 180.83 -403.11 403.11 s 180.84 403.11 403.11 403.11 S 937.32 762.28 937.32 540 S 756.49 136.89 534.22 136.89 Z`
const path2 = `M 434.78 732.52 c -1.07 21.18 5.9 40.74 18.09 55.94 l 43.01 61.3 c 0.61 0.87 1.61 1.39 2.68 1.39 l 0 0 c 2.61 0 4.16 -2.9 2.72 -5.08 c -7.95 -12.02 -12.57 -26.43 -12.54 -41.93 c 0.07 -40.9 33.22 -74.83 74.11 -75.81 c 25.2 -0.61 47.69 11.07 61.94 29.46 c 1.06 1.37 3.02 1.65 4.45 0.66 l 0.23 -0.16 c 1.48 -1.04 1.84 -3.08 0.8 -4.56 L 554.1 645.12 c -21.61 -30.8 -21.09 -71.98 1.29 -102.23 l 75.39 -101.94 c 3.91 -5.29 3.99 -12.49 0.2 -17.86 l -135.1 -191.4 c -2.14 -3.04 -6.34 -3.76 -9.38 -1.62 v 0 c -3.04 2.14 -3.76 6.34 -1.62 9.38 l 41.7 59.08 c 21.57 30.56 21.32 71.46 -0.63 101.75 l -74.93 103.42 c -3.82 5.27 -3.87 12.38 -0.14 17.7 l 97.22 138.61 c -10.35 -4.16 -21.71 -6.31 -33.62 -5.9 C 471.82 655.6 436.93 689.89 434.78 732.52 Z`

export default function NoiseAware(props) {
  const { width, height, fill, viewBox } = props
  return (
    <SvgIcon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path fill="#466E6E" d={path1} />
      <path fill="#466E6E" d={path2} />
    </SvgIcon>
  )
}

NoiseAware.defaultProps = {
  width: '100',
  height: '100',
  fill: 'none',
  viewBox: '0 0 980 980',
}

NoiseAware.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
}
