import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

export default function AccountForm({ open, onClose, instance }) {
  const { doAccountSave } = useConnect('doAccountSave')

  const validationSchema = yup.object().shape({
    name: yup.string().max(30),
    organization: yup.string().max(40),
  })

  const initialValues = {
    id: '',
    name: '',
    organization: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const sharedStyles = { flex: 1 }

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Account`}
      open={open}
      onSave={doAccountSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
        <Field sx={sharedStyles} component={TextField} label="Name" name="name" />
        <Field
          sx={sharedStyles}
          component={FormikDynamicSelect}
          label="Organization"
          name="organization"
          endpoint="organizations"
          disabled={!!instance?.organization}
        />
      </Box>
    </FormDialog>
  )
}

AccountForm.defaultProps = {
  instance: null,
}

AccountForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    organization: PropTypes.string,
    organizationName: PropTypes.string,
  }),
}
