import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Tooltip, Typography } from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { ClickableCell, MobileList, MobileListDefaultCard } from '@common/components'
import { DynamicSelect } from '@common/components/Selects'
import { useSmallScreen } from '@common/utils'
import { days } from '@common/utils/durations'
import accUrls from '@portal/pages/Accounts/urls'
import deviceUrls from '@portal/pages/Devices/urls'
import orgUrls from '@portal/pages/Organizations/urls'
import propUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import userUrls from '@portal/pages/Users/urls'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import Picker from '@portal/UI/components/Picker'

import { TooltipCell } from './cells'

export default function AuditLogTab({
  objectId,
  modifiedBy,
  filterDrawOpen,
  excludeDateFilter,
  include,
  showObjectName,
  showModifiedBy,
}) {
  const {
    auditLogList,
    auditLogListApiParams,
    auditLogListIsLoading,
    doAuditLogListSetFilter,
    doAuditLogListSetOrdering,
    doAuditLogListSetSearch,
    doAuditLogListClearParams,
    doAuditLogListSetPage,
    doAuditLogListSetPageSize,
  } = useConnect(
    'selectAuditLogList',
    'selectAuditLogListApiParams',
    'selectAuditLogListIsLoading',
    'doAuditLogListSetFilter',
    'doAuditLogListSetOrdering',
    'doAuditLogListClearParams',
    'doAuditLogListSetPage',
    'doAuditLogListSetPageSize',
  )

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const handleInitialParams = () => {
    const end = new Date()
    const start = new Date(end - 365 * days)
    const dateFilters = {
      tsAfter: start.toISOString(),
      tsBefore: end.toISOString(),
    }
    doAuditLogListClearParams()
    doAuditLogListSetFilter({
      ...(objectId ? { objectId } : {}),
      ...(modifiedBy ? { modifiedBy } : {}),
      include,
      ...(excludeDateFilter ? {} : dateFilters),
    })
  }

  useEffect(() => {
    if (objectId || modifiedBy) handleInitialParams()
  }, [objectId, modifiedBy])

  const objectMap = {
    account: accUrls,
    device: deviceUrls,
    property: propUrls,
    organization: orgUrls,
    unit: unitUrls,
    user: userUrls,
  }

  const getObjType = (row) =>
    Object.keys(objectMap).find((o) => row.contentType?.includes(o)) ?? ''

  const getObjUrls = (row) => objectMap[getObjType(row)]

  const formatAuditData = (row) => {
    const changes = (
      <Box width="100%">
        {row.data?.map((data) => (
          <Typography
            key={`${data.value}_${data.field}`}
            variant="body2"
            textAlign={isSmallScreen ? 'end' : 'start'}
            sx={{ fontSize: '12px' }}
          >
            {data.action} <strong>{data.field}</strong>: {data.value}
          </Typography>
        ))}
      </Box>
    )

    return (
      <Tooltip
        placement="top"
        slotProps={{ tooltip: { sx: { maxWidth: '850px' } } }}
        title={changes}
      >
        {changes}
      </Tooltip>
    )
  }

  const columns = [
    {
      field: 'ts',
      headerName: 'Time Stamp',
      renderCell: ({ row }) => {
        const [date, time] = DateTime.fromISO(row.ts)
          .toLocaleString(DateTime.DATETIME_SHORT)
          .split(',')
        return (
          <Typography variant="body2">
            {time} <br /> {date}
          </Typography>
        )
      },
      renderMobile: ({ row }) =>
        DateTime.fromISO(row.ts).toLocaleString(DateTime.DATETIME_SHORT),
      sortField: 'ts',
      flex: 0.6,
    },
    ...(!objectId
      ? [
          {
            field: 'contentType',
            headerName: 'Content Type',
            renderCell: ({ row }) => <TooltipCell label={row.contentType} />,
            sortable: false,
            flex: 0.5,
          },
          {
            field: 'objectId',
            headerName: 'Object',
            renderCell: ({ row }) =>
              getObjUrls(row) ? (
                <ClickableCell
                  label={row.objectName}
                  url={getObjUrls(row).entity.replace(':id', row.objectId)}
                />
              ) : (
                <TooltipCell label={row.objectName} />
              ),
            sortable: false,
            flex: 1.1,
          },
        ]
      : []),
    ...(showModifiedBy
      ? [
          {
            field: 'modifiedByEmail',
            headerName: 'Modified By',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.modifiedByEmail}
                url={userUrls.entity.replace(':id', row.modifiedById)}
              />
            ),
            sortable: false,
            flex: 1,
          },
        ]
      : []),
    ...(showObjectName
      ? [
          {
            field: 'objectName',
            headerName: 'Object',
            sortable: false,
            flex: 1,
          },
        ]
      : []),
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => titleize(row.action),
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'data',
      headerName: 'Data',
      renderCell: ({ row }) => formatAuditData(row),
      sortable: false,
      flex: 1.7,
    },
    {
      field: 'source',
      headerName: 'Source',
      renderCell: ({ row }) => <TooltipCell label={row.source} />,
      renderMobile: ({ row }) => row.source,
      sortable: false,
      flex: 0.9,
      maxWidth: 300,
    },
  ]

  const rows = auditLogList?.results?.map((auditLog, i) => ({
    ts: auditLog.ts,
    id: `${i}_${auditLog.objectId}`,
    objectId: auditLog.objectId,
    objectName: auditLog.objectName,
    modifiedById: auditLog.modifiedBy,
    modifiedByEmail: auditLog.modifiedByEmail,
    modifiedBy: auditLog.modifiedBy,
    source: auditLog.source,
    action: auditLog.action,
    data: auditLog.data,
    contentType: auditLog.contentType,
  }))

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  return (
    <Box display="flex">
      <Box display="flex" marginRight={isSmallScreen ? 0 : 1} marginTop={1.5}>
        <Filter
          open={filterDrawOpen}
          mode={isSmallScreen ? 'modal' : 'drawer'}
          disabled={auditLogListIsLoading}
          apiParams={auditLogListApiParams}
          setApiParams={doAuditLogListSetFilter}
          immutableFilters={{
            ...(objectId ? { objectId } : {}),
            ...(modifiedBy ? { modifiedBy } : {}),
          }}
          dialogOpen={filterModalOpen}
          dialogOnClose={() => setFilterModalOpen(false)}
        >
          <Picker
            range
            type="dateTime"
            label="Timestamp"
            filterName="ts"
            lowerCondition="After"
            upperCondition="Before"
            conditionSeparator=""
          />
          {!modifiedBy && (
            <DynamicSelect label="Users" filterName="modifiedBy" endpoint="users" />
          )}
        </Filter>
      </Box>
      <Box flex={1} height="100%" overflow="hidden" sx={{ minHeight: '350px' }}>
        {isSmallScreen ? (
          <MobileList
            dynamicRowHeight
            title="Audit Log"
            loading={auditLogListIsLoading}
            showActions={false}
            itemBuilder={MobileListItem}
            onFilterPressed={() => setFilterModalOpen(true)}
            page={auditLogList.current || 1}
            pageChange={doAuditLogListSetPage}
            pageSize={auditLogList.pageSize}
            pageSizeChange={doAuditLogListSetPageSize}
            rows={rows}
            rowCount={auditLogList.count || 0}
            setSearch={doAuditLogListSetSearch}
          />
        ) : (
          <List
            columnsAutosize
            dynamicRowHeight
            title="Audit Log"
            loading={auditLogListIsLoading}
            showActions={false}
            showAddButton={false}
            columns={columns}
            page={auditLogList.current || 1}
            pageChange={doAuditLogListSetPage}
            pageSize={auditLogList.pageSize}
            pageSizeChange={doAuditLogListSetPageSize}
            rows={rows}
            rowCount={auditLogList.count || 0}
            setSearch={doAuditLogListSetSearch}
            sortChange={doAuditLogListSetOrdering}
          />
        )}
      </Box>
    </Box>
  )
}

AuditLogTab.defaultProps = {
  objectId: '',
  modifiedBy: '',
  filterDrawOpen: true,
  excludeDateFilter: false,
  include: undefined,
  showObjectName: false,
  showModifiedBy: true,
}

AuditLogTab.propTypes = {
  objectId: PropTypes.string,
  modifiedBy: PropTypes.string,
  filterDrawOpen: PropTypes.bool,
  excludeDateFilter: PropTypes.bool,
  include: PropTypes.string,
  showObjectName: PropTypes.bool,
  showModifiedBy: PropTypes.bool,
}
