/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { ascend, compose, descend, equals, prop, sortWith } from 'ramda'

import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledItem = styled('li')(() => ({
  '&:hover > button': {
    visibility: 'visible',
  },
}))

export default function StaticMultiSelect({
  'data-testid': dataTestId,
  label,
  options,
  value,
  onChange,
  onSave,
  onOpen,
  variant,
  required,
  hideLabel,
  chipMaxWidth,
  disableSaveOnClear,
  onlyButton,
  onlyButtonCallback,
  isOptionEqualToValue,
  sortByFields,
  sortOrder,
  sx,
  dynamicWidth,
  disableSort,
  disableClearable,
  error,
  ...rest
}) {
  const [open, setOpen] = useState(false)

  const latestSavedValue = useRef(value)

  const handleChange = (event, newValue) => {
    const isRemovePressed =
      event.type === 'keydown' || ['svg', 'path'].includes(event.target.tagName)
    onChange(newValue)
    if (isRemovePressed && !open && !disableSaveOnClear) {
      onSave(newValue)
      latestSavedValue.current = newValue
    }
  }

  const handleSave = () => {
    if (latestSavedValue.current !== value) {
      onSave(value)
    }
    latestSavedValue.current = value
  }

  const handleEqualityCheck = (opt, val) => equals(opt, val)

  const getOptionLabel = (option) => option.label ?? option.name ?? option

  const sortOrderFn = sortOrder === 'asc' ? ascend : descend
  const sortFn = sortWith(
    sortByFields
      ? sortByFields.map((field) =>
          sortOrderFn(
            compose(
              (x) => (typeof x === 'string' ? x.toLowerCase() : x ?? ''),
              prop(field),
            ),
          ),
        )
      : [
          sortOrderFn(
            compose(
              (x) => (typeof x === 'string' ? x.toLowerCase() : x ?? ''),
              prop(options.at(0)?.label ? 'label' : 'name'),
            ),
          ),
        ],
  )

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disableClearable={disableClearable}
      data-testid={dataTestId}
      open={open}
      options={disableSort ? options : sortFn(options)}
      value={value}
      onChange={handleChange}
      onOpen={() => {
        if (onOpen) onOpen()
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
        handleSave()
      }}
      isOptionEqualToValue={isOptionEqualToValue ?? handleEqualityCheck}
      getOptionLabel={getOptionLabel}
      sx={{
        ...(!dynamicWidth ? { minWidth: '400px', maxWidth: '400px' } : {}),
        '& .MuiOutlinedInput-root': {
          paddingRight: '20px!important',
        },
        ...sx,
      }}
      popupIcon={<ExpandMore />}
      slotProps={{
        popper: {
          style: {
            maxWidth: 'fit-content',
            ...(!dynamicWidth ? { minWidth: sx.minWidth ?? '400px' } : {}),
          },
          placement: 'bottom-start',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={hideLabel ? null : label}
          placeholder={hideLabel ? label : null}
          variant={variant}
          required={required}
          InputLabelProps={{ shrink: hideLabel ? !hideLabel : value?.length >= 1 }}
          error={!!error}
          helperText={error}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <StyledItem
          {...props}
          key={option.id}
          style={{
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
          }}
          {...(disableClearable && selected && value?.length === 1
            ? { onClick: null }
            : {})}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selected}
            disabled={disableClearable && selected && value?.length === 1}
          />
          <Stack direction="column" sx={{ overflow: 'hidden' }}>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {option.label ?? option.name}
            </span>
            {option.subtitle && (
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                  color: 'grey',
                }}
              >
                {option.subtitle}
              </span>
            )}
          </Stack>
          {onlyButton && (
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                onChange([option])
                onlyButtonCallback()
              }}
              sx={{
                marginLeft: 'auto',
                visibility: 'hidden',
              }}
            >
              only
            </Button>
          )}
        </StyledItem>
      )}
      renderTags={
        rest?.limitTags
          ? (tagValue, getTagProps) => {
              const numTags = tagValue.length
              const { limitTags } = rest

              return (
                <>
                  {sortFn(tagValue)
                    .slice(0, limitTags)
                    .map((option, index) => {
                      const tagProps = getTagProps({ index })
                      return (
                        <Tooltip key={option.id ?? Math.random()} title={option.label}>
                          <Chip
                            {...tagProps}
                            key={tagProps?.key}
                            label={option.label ?? option.name}
                            size="small"
                            style={{ maxWidth: chipMaxWidth }}
                            onDelete={null}
                          />
                        </Tooltip>
                      )
                    })}
                  {numTags > limitTags && ` +${numTags - limitTags}`}
                </>
              )
            }
          : null
      }
      {...rest}
    />
  )
}

StaticMultiSelect.defaultProps = {
  'data-testid': undefined,
  onChange: () => {},
  onSave: () => {},
  onOpen: () => {},
  options: [],
  required: false,
  value: null,
  variant: 'outlined',
  hideLabel: false,
  chipMaxWidth: 138,
  disableSaveOnClear: false,
  sx: {},
  onlyButtonCallback: () => {},
  onlyButton: false,
  label: undefined,
  isOptionEqualToValue: undefined,
  sortByFields: undefined,
  sortOrder: 'asc',
  dynamicWidth: false,
  disableSort: false,
  disableClearable: false,
  error: '',
}

StaticMultiSelect.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onOpen: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        name: PropTypes.string,
      }),
    ]),
  ),
  required: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  ),
  variant: PropTypes.string,
  hideLabel: PropTypes.bool,
  chipMaxWidth: PropTypes.number,
  disableSaveOnClear: PropTypes.bool,
  sx: PropTypes.shape({
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onlyButtonCallback: PropTypes.func,
  onlyButton: PropTypes.bool,
  isOptionEqualToValue: PropTypes.func,
  sortByFields: PropTypes.arrayOf(PropTypes.string),
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  dynamicWidth: PropTypes.bool,
  disableSort: PropTypes.bool,
  disableClearable: PropTypes.bool,
  error: PropTypes.string,
}
