import PropTypes from 'prop-types'

import { ClipPath } from '@visx/clip-path'
import { Area, LinePath } from '@visx/shape'

export default function VerticalThresholdedLine({
  id,
  data,
  x,
  y,
  yMax,
  yMin,
  defined,
  threshold,
  curve,
  color,
}) {
  return (
    <>
      <Area curve={curve} data={data} x={x} y1={threshold} defined={defined}>
        {({ path }) => {
          const belowPath = path.y0(yMax)(data)
          const abovePath = path.y0(yMin)(data)

          return (
            <g>
              <ClipPath id={`threshold-clip-below-${id}`}>
                <path d={belowPath || ''} />
              </ClipPath>
              <ClipPath id={`threshold-clip-above-${id}`}>
                <path d={abovePath || ''} />
              </ClipPath>
            </g>
          )
        }}
      </Area>
      <LinePath
        curve={curve}
        data={data}
        defined={defined}
        x={x}
        y={y}
        stroke={color}
        strokeWidth={2}
        fill="none"
        clipPath={`url(#threshold-clip-below-${id})`}
      />
      <LinePath
        curve={curve}
        data={data}
        defined={(d) => defined(d) && threshold(d) > -1}
        x={x}
        y={y}
        stroke="red"
        strokeWidth={2}
        fill="none"
        clipPath={`url(#threshold-clip-above-${id})`}
      />
    </>
  )
}

VerticalThresholdedLine.defaultProps = {
  data: [],
  color: undefined,
}

VerticalThresholdedLine.propTypes = {
  id: PropTypes.string.isRequired,
  curve: PropTypes.func.isRequired,
  yMax: PropTypes.number.isRequired,
  yMin: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      y: PropTypes.number,
      threshold: PropTypes.number,
    }),
  ),
  defined: PropTypes.func.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  threshold: PropTypes.func.isRequired,
  color: PropTypes.string,
}
