import { useState } from 'react'

import PropTypes from 'prop-types'

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material'

export default function Step1({
  dialogText,
  onClose,
  setStep,
  profileData,
  setProfileData,
  noiseLevels,
}) {
  const defaultNoiseLevels = {
    indoor: { normal: 50, sensitive: 40 },
    outdoor: { normal: 30, sensitive: 20 },
  }

  const finalNoiseLevels = {
    ...defaultNoiseLevels,
    ...(noiseLevels ?? {}),
  }

  const [noiseLevel, setNoiseLevel] = useState('normal')

  const handleNextClick = () => {
    const nrsIndoorValue =
      noiseLevel === 'normal'
        ? finalNoiseLevels.indoor.normal
        : finalNoiseLevels.indoor.sensitive
    const nrsOutdoorValue =
      noiseLevel === 'normal'
        ? finalNoiseLevels.outdoor.normal
        : finalNoiseLevels.outdoor.sensitive
    setProfileData({
      ...profileData,
      level: noiseLevel,
      nrsIndoorValue,
      nrsOutdoorValue,
    })
    setStep('two')
  }

  return (
    <Stack>
      <Divider />
      <Typography variant="body2" fontWeight="600" mt={2.5} mb={1.5}>
        {dialogText.stepOneHeader}
      </Typography>
      <Typography variant="body2">{dialogText.stepOneBody}</Typography>
      <Card
        sx={{ my: 2.5, bgcolor: noiseLevel === 'normal' && 'primary.contrastText' }}
      >
        <CardActionArea
          disableRipple
          onClick={() => {
            setNoiseLevel('normal')
          }}
        >
          <CardContent
            sx={{
              py: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body1">Allow Normal Noise</Typography>
              <Typography variant="body2">Recommended for most users</Typography>
            </Box>
            {noiseLevel === 'normal' && <CheckCircleOutlinedIcon color="success" />}
          </CardContent>
        </CardActionArea>
      </Card>
      <Card sx={{ mb: 6, bgcolor: noiseLevel === 'less' && 'primary.contrastText' }}>
        <CardActionArea disableRipple onClick={() => setNoiseLevel('less')}>
          <CardContent
            sx={{
              py: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body1">Allow Less Noise</Typography>
              <Typography variant="body2">
                Recommended for sensitive neighbors
              </Typography>
            </Box>
            {noiseLevel === 'less' && <CheckCircleOutlinedIcon color="success" />}
          </CardContent>
        </CardActionArea>
      </Card>
      <Button
        variant="contained"
        sx={{ mb: 2.5, borderRadius: '8px' }}
        onClick={handleNextClick}
        size="large"
      >
        next
      </Button>
      <Button
        variant="contained"
        color="cancel"
        sx={{ mb: 4, borderRadius: '8px', ':hover': { bgcolor: 'grey.400' } }}
        onClick={onClose}
        size="large"
      >
        cancel
      </Button>
    </Stack>
  )
}

Step1.defaultProps = {
  noiseLevels: {},
}

Step1.propTypes = {
  dialogText: PropTypes.shape({
    stepOneHeader: PropTypes.string,
    stepOneBody: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  profileData: PropTypes.shape({}).isRequired,
  setProfileData: PropTypes.func.isRequired,
  noiseLevels: PropTypes.shape({
    indoor: PropTypes.shape({
      normal: PropTypes.number.isRequired,
      sensitive: PropTypes.number.isRequired,
    }),
    outdoor: PropTypes.shape({
      normal: PropTypes.number.isRequired,
      sensitive: PropTypes.number.isRequired,
    }),
  }),
}
