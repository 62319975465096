import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { downloadFile } from '@common/utils'
import { getTemplate } from '@portal/Utils/csv'

export default function CSVPicker({
  entity,
  mode,
  title,
  startIcon,
  overwrite,
  onImport,
  onClose,
  customActions,
  uploadTitle,
  uploadToolTip,
  downloadToolTip,
  postAction,
}) {
  const [file, setFile] = useState(null)
  const [rows, setRows] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [errors, setErrors] = useState({})
  const [nonFieldErrors, setNonFieldErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const hasErrors = !isEmpty(errors) || !isEmpty(nonFieldErrors)

  const fileReader = new FileReader()
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const processCsv = (string) => {
    const headerRow = string.slice(0, string.indexOf('\n')).trim().split(',')
    const dataRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const processedRows = dataRows.filter(Boolean).map((i) => {
      const rowValues = i.trim().split(',')
      const obj = headerRow.reduce((acc, header, index) => {
        acc[header] = overwrite?.[header] || rowValues[index]
        return acc
      }, {})
      return obj
    })

    setRows(processedRows)
  }

  const readFile = () => {
    if (file) {
      fileReader.onload = (e) => processCsv(e.target.result)
      fileReader.readAsText(file)
    }
  }

  const handleOnChange = (e) => {
    setAnchorEl(null)
    setFile(e.target.files[0])
  }
  const handleClose = () => {
    setDialogOpen(false)
    setAnchorEl(null)
    setFile(null)
    setRows([])
    postAction()
  }

  const processErrors = (payload) =>
    Object.entries(payload).reduce((acc, [cell, message]) => {
      const rowNum = cell.split('-')[1]
      const attr = cell.split(' ')[0]
      return { ...acc, [rowNum]: { ...acc[rowNum], [attr]: message } }
    }, {})

  const handleOnImport = async () => {
    setLoading(true)
    const res = await onImport(rows)
    setLoading(false)

    if (res.error) {
      const { nonFieldErrors: generalErrors } = res.error.response

      if (generalErrors?.length) {
        setNonFieldErrors(generalErrors)
      } else {
        setErrors(processErrors(res.error.response))
      }
    } else {
      const msg =
        res.length > 1
          ? `Successfully created ${res.length} units`
          : `Successfully created ${res?.[0]?.name ?? 'unit'} `
      setDialogOpen(false)
      doShowSnackbar(msg)
      postAction()
      onClose()
    }
  }

  const primaryTypographyProps = {
    align: 'center',
    variant: 'body2',
    sx: { textTransform: 'uppercase' },
  }

  const headerKeys = rows.length ? Object.keys({ ...rows[0], ...overwrite }) : []

  const generateKey = (prefix) =>
    `${prefix}_${Number(new Date().getTime()) / Math.random()}`

  const renderCell = (val, idx, rowIdx) => {
    const errorMessage = errors[rowIdx + 1]?.[headerKeys[idx]]
    const validatedValue = val === '' ? errorMessage : val
    const Cell = (
      <TableCell key={generateKey(val)} align={idx ? 'right' : 'left'}>
        <Typography
          variant="body2"
          color={errorMessage ? 'error' : 'text.secondary'}
          fontSize={12}
          fontWeight={errorMessage ? 'bold' : 'regular'}
        >
          {validatedValue}
        </Typography>
      </TableCell>
    )

    return errorMessage ? (
      <Tooltip title={errorMessage} placement="top">
        {Cell}
      </Tooltip>
    ) : (
      Cell
    )
  }

  const toolTipStyles = { sx: { '& .MuiTooltip-tooltip': { width: '150px' } } }

  useEffect(() => {
    setErrors({})
    setNonFieldErrors([])
    readFile()
    if (file) setDialogOpen(true)
  }, [file])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={dialogOpen}
        onClose={handleClose}
        sx={{ maxHeight: '550px' }}
      >
        <DialogTitle>
          <Typography variant="h4" color="primary">
            {file?.name?.toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ fontSize: '10px' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headerKeys.map((key, idx) => (
                  <TableCell key={key} align={idx ? 'right' : 'left'}>
                    {key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIdx) => (
                <TableRow key={generateKey(row.name)}>
                  {Object.values(row).map((val, idx) => renderCell(val, idx, rowIdx))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {hasErrors && (
            <Box ml={5} flexGrow={1}>
              {nonFieldErrors?.length ? (
                nonFieldErrors.map((err) => (
                  <Typography key={err} variant="body2" color="error">
                    {err}
                  </Typography>
                ))
              ) : (
                <>
                  <Typography variant="body2" color="error">
                    {file?.name} contains errors in the fields indicated above.
                  </Typography>
                  <Typography variant="body2" color="error">
                    Please correct these errors and try again.
                  </Typography>
                </>
              )}
            </Box>
          )}

          <Box display="flex" gap={3}>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button disabled={hasErrors || loading} onClick={handleOnImport} autoFocus>
              Import
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {mode === 'menuItem' ? (
        <MenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
          {title || 'csv options'}
        </MenuItem>
      ) : (
        <Button
          startIcon={startIcon}
          variant="outlined"
          component="label"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {title || 'csv options'}
        </Button>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          postAction()
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ padding: 0 }}>
          {customActions?.map((action) => (
            <ListItem sx={{ padding: 0 }} key={action.title}>
              <ListItemButton
                onClick={() => {
                  action.call()
                  setAnchorEl(null)
                  postAction()
                }}
              >
                <ListItemText
                  primary={action.title}
                  primaryTypographyProps={primaryTypographyProps}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <Tooltip
            title={uploadToolTip}
            PopperProps={toolTipStyles}
            enterDelay={800}
            placement="left"
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemButton component="label">
                <ListItemText
                  primary={uploadTitle || `add ${entity} from csv`}
                  primaryTypographyProps={primaryTypographyProps}
                />
                <input hidden type="file" accept=".csv" onChange={handleOnChange} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Divider variant="middle" />
          <Tooltip
            title={downloadToolTip}
            PopperProps={toolTipStyles}
            enterDelay={800}
            placement="left"
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemButton
                onClick={() => {
                  downloadFile(getTemplate(entity.toLowerCase(), overwrite))
                  setAnchorEl(null)
                  postAction()
                }}
              >
                <ListItemText
                  primary="download template"
                  primaryTypographyProps={primaryTypographyProps}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Popover>
    </>
  )
}

CSVPicker.defaultProps = {
  overwrite: {},
  title: '',
  uploadTitle: '',
  startIcon: null,
  customActions: [],
  uploadToolTip: 'Upload a CSV file containing units in the format provided below.',
  downloadToolTip: `Download a blank CSV template. If an entity's UUID is provided, it must be the only UUID in that column.`,
  mode: 'button',
  postAction: () => {},
}

CSVPicker.propTypes = {
  entity: PropTypes.string.isRequired,
  onImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  customActions: PropTypes.arrayOf(PropTypes.shape({})),
  overwrite: PropTypes.shape({}),
  title: PropTypes.string,
  startIcon: PropTypes.element,
  uploadTitle: PropTypes.string,
  uploadToolTip: PropTypes.string,
  downloadToolTip: PropTypes.string,
  mode: PropTypes.oneOf(['button', 'menuItem']),
  postAction: PropTypes.func,
}
