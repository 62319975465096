import PropTypes from 'prop-types'

import { CheckCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'

import MuiPhoneNumber from 'material-ui-phone-number'

function PhoneInputCell({
  fieldName,
  value,
  isPhoneValidated,
  onUpdate,
  errors,
  ...rest
}) {
  const getStatusIcon = () => {
    if (errors.length > 0) {
      return <ErrorOutlineOutlined />
    }

    if (!isPhoneValidated) {
      return null
    }

    return <CheckCircleOutlined sx={{ color: (theme) => theme.palette.success.main }} />
  }

  return (
    <MuiPhoneNumber
      defaultCountry="us"
      name={fieldName}
      onChange={(val) => onUpdate(val.replace(/[()\s]/g, ''))}
      value={value}
      disableAreaCodes
      variant="outlined"
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ fontSize: 20 }}>
            {getStatusIcon()}
          </InputAdornment>
        ),
      }}
      error={errors.length > 0}
      helperText={errors.join('\\n') || ' '}
      {...rest}
    />
  )
}

export default PhoneInputCell

PhoneInputCell.defaultProps = {
  fieldName: '',
  value: '',
  isPhoneValidated: false,
  onUpdate: () => {},
  errors: [],
}

PhoneInputCell.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  isPhoneValidated: PropTypes.bool,
  onUpdate: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
}
