import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Button, Stack } from '@mui/material'

import { humanize } from 'inflection'
import { DateTime } from 'luxon'

import { useSmallScreen } from '@common/utils'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

export default function BillingComponent({ property, onEdit, margin, gap }) {
  const isSmallScreen = useSmallScreen()

  const formatDateTime = useCallback(
    (dt) => (dt ? DateTime.fromISO(dt).toLocaleString(DateTime.DATETIME_MED) : 'N/A'),
    [],
  )

  return (
    <Stack direction="row" alignItems="start">
      <DetailContainer margin={margin} gap={gap}>
        <DetailItem
          label="SalesForce Account ID"
          value={property?.salesforceAccountId}
        />
        <DetailItem label="Stripe Customer ID" value={property?.stripeCustomerId} />
        <DetailItem
          label="Billing Type"
          value={
            property?.billingAccountType ? humanize(property.billingAccountType) : null
          }
        />
        <DetailItem
          label="last billed on"
          value={formatDateTime(property?.lastBilledOn)}
        />
        <DetailItem
          label="Prev billing period end"
          value={formatDateTime(property?.prevBillingPeriodEnd)}
        />
        <DetailItem
          label="Prev billing period start"
          value={formatDateTime(property?.prevBillingPeriodStart)}
        />
      </DetailContainer>
      {onEdit && (
        <Button onClick={onEdit} sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}>
          Edit
        </Button>
      )}
    </Stack>
  )
}

BillingComponent.defaultProps = {
  property: undefined,
  onEdit: undefined,
  margin: 0,
  gap: 0,
}

BillingComponent.propTypes = {
  property: PropTypes.shape({
    salesforceAccountId: PropTypes.string,
    stripeCustomerId: PropTypes.string,
    billingAccountType: PropTypes.string,
    lastBilledOn: PropTypes.string,
    prevBillingPeriodStart: PropTypes.string,
    prevBillingPeriodEnd: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  margin: PropTypes.number,
  gap: PropTypes.number,
}
