import { createListBundle } from '@common/utils'
import eventUrls from '@portal/pages/EventDashboard/urls'
import { DASHBOARD_OUTAGES_HASH } from '@portal/pages/Home/Dashboard'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'auditLog'

const auditLogListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/audit_logs/', params, { cancelationPrefix: entityName }),
  urlTest: (url, pattern, hash, queryObject) =>
    ['auditLog', 'actions'].includes(hash) ||
    [eventUrls.list].includes(url) ||
    pattern === propertyUrls.entity ||
    (hash === DASHBOARD_OUTAGES_HASH && !!queryObject?.auditLog),
})

export default auditLogListBundle
