import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'

import { Field } from 'formik'
import * as Yup from 'yup'

import { PhoneNumber, Switch, TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(''),
  email: Yup.string().email('Please enter a valid email').required(),
  phoneNumber: Yup.string().matches(/\+$|\+[1-9]\d{1,14}/, {
    message: 'Please enter a valid phone number',
  }),
  portalRole: Yup.string().nullable(),
})

export default function UserForm({ open, onClose, instance }) {
  const { doUserSave, isSuperuser, systemPortalRoles } = useConnect(
    'doUserSave',
    'selectIsSuperuser',
    'selectSystemPortalRoles',
  )

  const initialValues = {
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    portalRole: '',
    smokeVpPerformanceReport: false,
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      const defaultValue = typeof initialValues[field] === 'boolean' ? false : ''
      initialValues[field] = instance[field] ?? defaultValue
    })
  }

  const handleUserSave = (values) => {
    const payload = values
    if (values.phoneNumber === '+') payload.phoneNumber = ''
    return doUserSave(payload)
  }

  return (
    <FormDialog
      validationSchema={validationSchema}
      onClose={onClose}
      initialValues={initialValues}
      label={`${initialValues?.id ? 'Edit' : 'Add'} User`}
      open={open}
      onSave={handleUserSave}
    >
      <Box sx={{ display: 'flex' }}>
        <FormGroup sx={{ m: 1 }}>
          <Field component={TextField} label="Name" name="name" />
          <Field component={TextField} label="Email" name="email" />
          <Field component={PhoneNumber} label="Phone" name="phoneNumber" />
          {isSuperuser && (
            <Field
              component={StaticSelect}
              options={systemPortalRoles?.map((item) => item.id)}
              optionLabelFormatter={(id) =>
                systemPortalRoles?.find((role) => role.id === id)?.label
              }
              variant="standard"
              label="Portal Role"
              name="portalRole"
            />
          )}
        </FormGroup>
        <FormGroup sx={{ m: 1 }}>
          <FormLabel>Reports</FormLabel>
          <FormControlLabel
            control={
              <Field
                type="checkbox"
                component={Switch}
                name="smokeVpPerformanceReport"
              />
            }
            label="VP Smoke Report"
          />
        </FormGroup>
      </Box>
    </FormDialog>
  )
}

UserForm.defaultProps = {
  instance: {},
}

UserForm.propTypes = {
  instance: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
