import PropTypes from 'prop-types'

import { Stack, Tooltip, Typography } from '@mui/material'

import { DateTime } from 'luxon'

import { formatDuration, useSmallScreen } from '@common/utils'

export default function EventTimeCell({ event }) {
  const isSmallScreen = useSmallScreen()

  const { createdOn, endTime, timeZone: zone } = event

  const parsedStart = DateTime.fromISO(createdOn, { zone })
  const parsedEnd = endTime ? DateTime.fromISO(endTime, { zone }) : DateTime.now()

  const formattedStart = parsedStart.toLocaleString(DateTime.DATETIME_MED)
  const endTimeFormat = parsedStart.hasSame(parsedEnd, 'day')
    ? DateTime.TIME_SIMPLE
    : DateTime.DATETIME_MED

  const formattedEnd = parsedEnd.toLocaleString(endTimeFormat)
  const duration = formatDuration(parsedStart, parsedEnd)

  return (
    <Tooltip title={`${formattedStart} - ${formattedEnd}`} placement="top">
      <Stack sx={{ overflow: 'hidden', textAlign: isSmallScreen ? 'end' : 'start' }}>
        <Typography noWrap variant="caption" color="grey.500" fontWeight={600}>
          {formattedStart}
        </Typography>
        <Typography
          noWrap
          variant="body1"
          color="primary"
          fontSize={isSmallScreen ? 12 : 14}
        >
          {`${duration}${endTime ? '' : ' (ongoing)'}`}
        </Typography>
      </Stack>
    </Tooltip>
  )
}

EventTimeCell.propTypes = {
  event: PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    endTime: PropTypes.string,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
}
