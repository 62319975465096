import PropTypes from 'prop-types'

import { Stack, Typography } from '@mui/material'

function LineCheck({ Icon, color, text, ...props }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} {...props}>
      <Icon color={color} />
      <Typography variant="h5">{text}</Typography>
    </Stack>
  )
}

LineCheck.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
}

LineCheck.defaultProps = {
  color: 'success',
}

export default LineCheck
