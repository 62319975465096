import PropTypes from 'prop-types'

import { Autocomplete, TextField } from '@mui/material'

/**
 * FreesSolo select is a select component that allows freesolo input.
 *  This component is designed to be used with formik.
 * @param {Object} form form supplied by formik.
 * @param {string} label The label to display in the input component (TextField).
 * @param {string} field Name of the formik field.
 * @param {string} altField The name of the formik field to store the freesolo value of the input. This keeps the id of the zone and the new zone name separate.
 * @param {Array} options An array of options for the select component.
 * @param {boolean} disabled Whether or not the input is disabled.
 */

export default function FreeSoloSelect({
  form,
  label,
  field,
  altField,
  options,
  disabled,
  sx,
}) {
  const handleChange = (event, value) => {
    if (!event || ['blur', 'keydown'].includes(event?.type)) return
    const parsedValue = value?.id || value
    form?.setFieldValue(field?.name, parsedValue)
    form?.setFieldValue(altField, '')
  }

  const handleInputChange = (event, value) => {
    if (!event || event?.type === 'click') return
    form?.setFieldValue(altField, value)
    form?.setFieldValue(field?.name, '')
  }

  const getValue = () => {
    const value = field?.value ?? ''
    const valueOption = options?.find((option) => option.id === value)
    return valueOption || value
  }

  const error = form?.errors?.[field?.name] && form?.touched?.[field?.name]
  const helperText =
    form?.errors?.[field?.name] &&
    form?.touched?.[field?.name] &&
    form?.errors?.[field?.name]

  return (
    <Autocomplete
      freeSolo
      id="autocomplete"
      disabled={disabled}
      value={getValue()}
      options={options ?? []}
      getOptionLabel={(option) => option?.label ?? option?.name ?? option}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant="standard"
          error={error}
          helperText={helperText}
          sx={{ m: 0 }}
        />
      )}
      sx={sx}
    />
  )
}

FreeSoloSelect.defaultProps = {
  form: '',
  field: '',
  disabled: false,
  sx: {},
}

FreeSoloSelect.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  altField: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sx: PropTypes.shape({}),
}
