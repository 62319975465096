import { useMemo } from 'react'

import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'

import { noop } from '@common/utils'

export default function List({
  'data-testid': dataTestId,
  cellClick,
  checkbox,
  columns,
  page,
  pageChange,
  pageSize,
  pageSizeChange,
  rows,
  rowCount,
  rowClick,
  sortChange,
  noValuePlaceholder,
  sx,
  currentOrdering,
  detailPanelTogglePosition,
  ...dataGridProps
}) {
  const currentSortModel = useMemo(() => {
    if (!currentOrdering?.length) {
      return undefined
    }

    const [sortField, sortDirection] = currentOrdering
    const column = columns.find(
      (col) => col.sortField === sortField || col.field === sortField,
    )
    if (column) {
      return [{ field: column.field, sort: sortDirection }]
    }
    return [{ field: sortField, sort: sortDirection }]
  }, [currentOrdering, columns])

  const handleSortChange = (sort) => {
    if (!sort.length) return sortChange(sort)
    const column = columns.find((col) => col.field === sort[0].field)
    const field = column.sortField ?? column.field
    return sortChange([field, sort[0].sort])
  }

  const modifiedColumns = columns.map((column) => ({
    display: column.renderCell ? 'flex' : 'block',
    valueGetter: (value) =>
      value === null || value === undefined || value === ''
        ? noValuePlaceholder
        : value,
    ...column,
  }))

  // set detail panel toggle position
  modifiedColumns.splice(detailPanelTogglePosition, 0, {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  })

  const onPaginationModelChange = (model) => {
    if (model.page !== page - 1) {
      pageChange(model.page + 1)
    }
    if (model.pageSize !== pageSize) {
      pageSizeChange(model.pageSize)
    }
  }

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .MuiDataGrid-root': {
          '--DataGrid-pinnedBackground': 'transparent !important',
          '--DataGrid-containerBackground': 'transparent !important',
        },
        '& .MuiDataGrid-container--top:after': { height: 0 },
        '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
          { border: 'none' },
      }}
    >
      <DataGridPro
        checkboxSelection={checkbox}
        columns={modifiedColumns}
        density="comfortable"
        disableColumnFilter
        disableRowSelectionOnClick
        paginationModel={{
          page: page - 1,
          pageSize,
        }}
        onPaginationModelChange={onPaginationModelChange}
        pagination
        paginationMode="server"
        onCellClick={cellClick}
        sortingOrder={['desc', 'asc']}
        sortingMode="server"
        onSortModelChange={handleSortChange}
        sortModel={currentSortModel}
        rows={rows}
        rowCount={rowCount}
        pageSizeOptions={[10, 25, 50, 100]}
        onRowClick={rowClick}
        sx={{
          '& .MuiDataGrid-columnSeparator': { display: 'none' },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
          '& .clickableCell': (theme) => ({
            cursor: 'pointer',
            color: 'primary.main',
            ...theme.typography.subtitle2,
          }),
          ...sx,
        }}
        {...dataGridProps}
      />
    </Box>
  )
}

List.defaultProps = {
  'data-testid': undefined,
  cellClick: noop,
  checkbox: false,
  pageSize: 25,
  rowClick: noop,
  pageChange: noop,
  pageSizeChange: noop,
  sortChange: noop,
  noValuePlaceholder: '',
  sx: {},
  currentOrdering: [],
  detailPanelTogglePosition: 0,
}

List.propTypes = {
  'data-testid': PropTypes.string,
  cellClick: PropTypes.func,
  checkbox: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeChange: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowCount: PropTypes.number.isRequired,
  rowClick: PropTypes.func,
  sortChange: PropTypes.func,
  noValuePlaceholder: PropTypes.string,
  sx: PropTypes.shape({}),
  currentOrdering: PropTypes.arrayOf(PropTypes.string),
  detailPanelTogglePosition: PropTypes.number,
}
