import PropTypes from 'prop-types'

import { Box, Stack } from '@mui/material'

import { getValueFromColumnDef } from './MobileList'
import MobileListCardRow from './MobileListCardRow'

export default function MobileListDefaultCard({
  row,
  columns,
  header,
  footer,
  ignoredFields,
  multiRowFields,
  rowStyleBuilder,
}) {
  const filteredColumns =
    multiRowFields || ignoredFields
      ? columns.filter(
          (column) =>
            ![
              ...(ignoredFields ?? []),
              ...(multiRowFields
                ? multiRowFields.reduce((acc, fields) => [...acc, ...fields], [])
                : []),
            ].includes(column.field),
        )
      : columns

  return (
    <Stack spacing={0.5}>
      {header}
      {filteredColumns.map((column) => {
        const value = getValueFromColumnDef({ row, column })
        return (
          <MobileListCardRow
            key={column.field}
            label={column.headerName}
            value={value}
            {...(rowStyleBuilder ? rowStyleBuilder({ row, column }) ?? {} : {})}
          />
        )
      })}
      {multiRowFields?.map((fields) => (
        <Box
          key={`row_${fields.join('_')}`}
          display={fields.length > 1 ? 'flex' : 'block'}
          justifyContent="space-between"
        >
          {columns
            .filter((column) => fields.includes(column.field))
            .map((column) => {
              const value = getValueFromColumnDef({ row, column })
              return (
                <MobileListCardRow
                  key={column.field}
                  label={column.headerName}
                  value={value}
                  {...(rowStyleBuilder ? rowStyleBuilder({ row, column }) ?? {} : {})}
                />
              )
            })}
        </Box>
      ))}
      {footer}
    </Stack>
  )
}

MobileListDefaultCard.defaultProps = {
  header: undefined,
  footer: undefined,
  ignoredFields: undefined,
  multiRowFields: undefined,
  rowStyleBuilder: undefined,
}

MobileListDefaultCard.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  ignoredFields: PropTypes.arrayOf(PropTypes.string),
  multiRowFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  rowStyleBuilder: PropTypes.func,
}
