import PropTypes from 'prop-types'

import { Dialog, DialogContent } from '@mui/material'

import EventListRowDetail from './EventListRowDetail'

export default function AdditionalOccurenciesModal({
  open,
  onClose,
  row,
  setScenarioData,
  setScenarioFormOpen,
}) {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <EventListRowDetail
          modalView
          row={row}
          setScenarioData={setScenarioData}
          setScenarioFormOpen={setScenarioFormOpen}
        />
      </DialogContent>
    </Dialog>
  )
}

AdditionalOccurenciesModal.defaultProps = {
  row: {},
}

AdditionalOccurenciesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.shape({}),
  setScenarioData: PropTypes.func.isRequired,
  setScenarioFormOpen: PropTypes.func.isRequired,
}
