import { useMemo } from 'react'

import PropTypes from 'prop-types'

import { ExpandMore } from '@mui/icons-material'
import { Autocomplete, Popper, TextField } from '@mui/material'

import { formatErrors } from '@common/components/Form/utils'

const styles = () => ({
  popper: { maxWidth: 'fit-content' },
})

function MyPopper(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />
}

export default function StaticSelect({
  multiple,
  label,
  field,
  filterName,
  form,
  options,
  optionsFilter,
  optionLabelFormatter,
  getFullEntity,
  value,
  onChange,
  placeholder,
  error,
  variant,
  hideLabel,
  size,
  sx,
  ...rest
}) {
  const isFormik = !!form?.values && !!field?.name

  const meta = form.getFieldMeta(field?.name)
  const [hasFormikError, formikErrorText] = formatErrors({ field, form })
  const hasError = hasFormikError || !!error?.trim()
  const showError = ((form?.dirty && meta?.touched) || !isFormik) && hasError

  const filteredOptions = useMemo(() => {
    if (optionsFilter) {
      return options.filter(optionsFilter)
    }
    return options
  }, [options, optionsFilter])

  const getValueToReturn = (newValue) => {
    if (multiple) {
      if (getFullEntity) {
        return newValue
      }
      return newValue?.map((item) => item?.id ?? item)
    }
    return newValue?.id && !getFullEntity ? newValue.id : newValue
  }

  const handleChange = (_, newValue) => {
    const valueToReturn = getValueToReturn(newValue)
    if (isFormik) {
      form.setFieldValue(field?.name, valueToReturn).then(() => {
        form.setFieldTouched(field?.name)
      })
    }
    if (onChange) {
      onChange(valueToReturn)
    }
  }

  const handleEqualityCheck = (opt, val) => {
    if (multiple && !getFullEntity) {
      return (opt?.id ?? opt) === (val?.id ?? val)
    }
    const objOtions = val?.id && opt?.id
    return objOtions ? opt.id === val.id : opt === val
  }

  const parsedValue = isFormik ? form?.getFieldProps(field?.name)?.value : value

  const buildHelperText = () => {
    if (showError) {
      return hasFormikError ? formikErrorText : error
    }
    return rest?.helperText
  }

  const defaultOptionFormatter = (option) => {
    if (typeof option === 'object') {
      return option.label ?? option.name
    }
    const opt = options.find((o) =>
      typeof o === 'string' ? o === option : o.id === option,
    )
    return opt?.label ?? opt?.name ?? option
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={filteredOptions}
      value={parsedValue || (multiple ? [] : null)}
      onChange={handleChange}
      getOptionLabel={optionLabelFormatter || defaultOptionFormatter}
      isOptionEqualToValue={handleEqualityCheck}
      size={size}
      sx={{ minWidth: '150px', ...sx }}
      PopperComponent={MyPopper}
      popupIcon={<ExpandMore />}
      renderInput={(params) => (
        <TextField
          {...params}
          required={rest.required}
          label={hideLabel ? null : label}
          error={showError}
          variant={variant}
          helperText={buildHelperText()}
          placeholder={placeholder}
          InputLabelProps={{ shrink: hideLabel ? false : undefined }}
        />
      )}
      {...rest}
    />
  )
}

StaticSelect.defaultProps = {
  multiple: undefined,
  field: null,
  form: {
    dirty: false,
    values: null,
    getFieldMeta: () => {},
    setFieldValue: () => {},
    setFieldTouched: () => {},
  },
  error: '',
  onChange: () => {},
  placeholder: '',
  value: null,
  options: [],
  variant: 'outlined',
  hideLabel: false,
  optionLabelFormatter: undefined,
  optionsFilter: undefined,
  getFullEntity: false,
  filterName: '',
  size: undefined,
  sx: {},
}

StaticSelect.propTypes = {
  multiple: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  filterName: PropTypes.string,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    values: PropTypes.shape(),
    getFieldMeta: PropTypes.func,
    getFieldProps: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  optionLabelFormatter: PropTypes.func,
  optionsFilter: PropTypes.func,
  getFullEntity: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  variant: PropTypes.string,
  hideLabel: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
}
