import PropTypes from 'prop-types'

import { AssignmentOutlined, Edit } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function OutageActions({ row, onEditPressed, onAuditLogPressed }) {
  const isSmallScreen = useSmallScreen()
  return (
    <Box flex={1} display="flex" gap={1} justifyContent="center">
      <Button
        fullWidth={isSmallScreen}
        size="small"
        startIcon={<Edit />}
        variant="outlined"
        onClick={() => onEditPressed(row)}
      >
        Edit
      </Button>
      <Button
        fullWidth={isSmallScreen}
        size="small"
        startIcon={<AssignmentOutlined />}
        variant="outlined"
        onClick={() => onAuditLogPressed(row)}
      >
        Audit Log
      </Button>
    </Box>
  )
}

OutageActions.propTypes = {
  row: PropTypes.shape({}).isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onAuditLogPressed: PropTypes.func.isRequired,
}
