import { useState } from 'react'

import PropTypes from 'prop-types'

import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

import Step1 from '@rest/pages/Configure/ThresholdProfiles/NewThresholdWizard/Step1'
import Step2 from '@rest/pages/Configure/ThresholdProfiles/NewThresholdWizard/Step2'
import { addThresholdProfileText } from '@rest/Utils/constants'

import Step3 from './Step3'

export default function AddThreshold({ open, onClose, property }) {
  const [step, setStep] = useState('one')
  const [profileData, setProfileData] = useState({})

  const handleClose = (success) => {
    setProfileData({})
    setStep('one')
    onClose(success)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableScrollLock
      maxWidth={step === 'three' ? 'xl' : 'xs'}
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 0, px: 4 }}
      >
        New Threshold Profile
        <IconButton onClick={handleClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlined fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ py: 0, px: 4, maxWidth: '1000px' }}>
        {step === 'one' && (
          <Step1
            dialogText={addThresholdProfileText}
            onClose={handleClose}
            setStep={setStep}
            profileData={profileData}
            setProfileData={setProfileData}
            noiseLevels={{
              indoor: { normal: 60, sensitive: 50 },
            }}
          />
        )}
        {step === 'two' && (
          <Step2
            dialogText={addThresholdProfileText}
            onClose={handleClose}
            setStep={setStep}
            profileData={profileData}
            setProfileData={setProfileData}
          />
        )}
        {step === 'three' && (
          <Step3
            onClose={handleClose}
            setStep={setStep}
            profileData={profileData}
            setProfileData={setProfileData}
            property={property}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

AddThreshold.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}
