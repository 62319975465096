import PropTypes from 'prop-types'

import { TextField } from '@mui/material'

import { StaticSelect } from '@common/components/Selects'

export default function StaticSelectCell({
  isNew,
  error,
  placeholder,
  preallocateHelperTextSpace,
  ...rest
}) {
  const errorMessage = error.trim()

  return (
    <StaticSelect
      sx={{ width: '100%' }}
      componentsProps={{ paper: { sx: { width: '150%' } } }}
      clearOnBlur
      clearOnEscape
      disableClearable
      hideLabel={rest?.hideLabel}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={rest?.hideLabel ? null : rest?.label}
          placeholder={placeholder}
          helperText={preallocateHelperTextSpace ? errorMessage || ' ' : errorMessage}
          error={errorMessage.length > 0}
          sx={
            isNew && !errorMessage.length && !rest?.isSmallScreen
              ? { border: '1px solid black', borderRadius: '4px' }
              : {}
          }
        />
      )}
      {...rest}
    />
  )
}

StaticSelectCell.defaultProps = {
  isNew: false,
  placeholder: '',
  error: ' ',
  preallocateHelperTextSpace: false,
}

StaticSelectCell.propTypes = {
  isNew: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  preallocateHelperTextSpace: PropTypes.bool,
}
