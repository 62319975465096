import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { pickBy } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'

import { Field, useFormikContext } from 'formik'
import { pluralize, titleize } from 'inflection'
import * as yup from 'yup'

import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

function FormikContextWrapper({ initialValues }) {
  const { values, setValues, setFieldValue } = useFormikContext()
  const [roleFilters, setRoleFilters] = useState({})
  const [membershipType, setMembershipType] = useState(null)
  const [showRoleField, setShowRoleField] = useState(false)
  const [showPropertyMembershipOptions, setShowPropertyMembershipOptions] =
    useState(false)

  const { organization, account, property } = values

  const membershipTypeMap = {
    organization: {
      filters: { organization, key: 'organization' },
    },
    account: {
      filters: { account, key: 'account' },
    },
    property: {
      filters: { property, key: 'account' },
    },
  }

  const membershipOptions = Object.keys(membershipTypeMap)
  const sharedStyles = { width: '90%' }

  const getSecondaryTextAttr = () => {
    if (organization) return 'organizationName'
    if (account || property) return 'accountName'
    return ''
  }

  const getCurrentMembership = () => {
    const hasMembership = (v, k) =>
      ['organization', 'account', 'property'].includes(k) && Boolean(v)

    const mship = pickBy(hasMembership, values)
    return Object.keys(mship)?.[0]
  }

  useEffect(() => {
    setShowRoleField(!!(organization || account || property))
    const currMembership = getCurrentMembership()
    if (currMembership) {
      const filters = membershipTypeMap[currMembership]?.filters
      setRoleFilters(filters)
    }
    setMembershipType(currMembership ?? membershipType)
  }, [values])

  useEffect(() => {
    Object.keys(initialValues).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
    setValues(initialValues)
    setShowPropertyMembershipOptions(membershipType === 'property')
  }, [membershipType])

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      flexDirection="column"
      gap={2}
    >
      <StaticSelect
        sx={sharedStyles}
        label="At which level do you wish to create this membership?"
        value={membershipType}
        variant="standard"
        getOptionLabel={(opt) => titleize(opt)}
        options={membershipOptions}
        onChange={(type) => setMembershipType(type)}
      />
      {membershipOptions.includes(membershipType) && (
        <Field
          sx={sharedStyles}
          name={membershipType}
          endpoint={pluralize(membershipType)}
          label={titleize(membershipType)}
          filters={{ active: true }}
          component={FormikDynamicSelect}
        />
      )}
      {showRoleField && (
        <Field
          name="role"
          label="Role"
          endpoint="roles"
          filters={roleFilters}
          component={FormikDynamicSelect}
          secondaryTextAttr={getSecondaryTextAttr()}
          sx={sharedStyles}
        />
      )}
      {showPropertyMembershipOptions && (
        <Box sx={sharedStyles}>
          <Typography variant="caption">Notifications</Typography>
          <Box>
            <FormControlLabel
              label="Outage Alert"
              labelPlacement="end"
              control={
                <Checkbox
                  size="small"
                  checked={!!values.outageAlert}
                  onChange={(event) =>
                    setFieldValue('outageAlert', event.target.checked)
                  }
                />
              }
            />
            <FormControlLabel
              label="Noise Alert"
              labelPlacement="end"
              control={
                <Checkbox
                  size="small"
                  checked={!!values.noiseAlert}
                  onChange={(event) =>
                    setFieldValue('noiseAlert', event.target.checked)
                  }
                />
              }
            />
            <FormControlLabel
              label="Smoke Alert"
              labelPlacement="end"
              control={
                <Checkbox
                  size="small"
                  checked={!!values.smokeAlert}
                  onChange={(event) =>
                    setFieldValue('smokeAlert', event.target.checked)
                  }
                />
              }
            />
            <FormControlLabel
              label="Smoke Digest"
              labelPlacement="end"
              control={
                <Checkbox
                  size="small"
                  checked={!!values.smokeDigest}
                  onChange={(event) =>
                    setFieldValue('smokeDigest', event.target.checked)
                  }
                />
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default function MembershipForm({ open, onClose, instance }) {
  const { doMembershipSave, doMarkMembershipListAsOutdated } = useConnect(
    'doMembershipSave',
    'doMarkMembershipListAsOutdated',
  )

  const validationSchema = yup.object().shape({
    organization: yup.string().max(40),
    account: yup.string().max(40),
    property: yup.string().max(40),
    role: yup.string().max(40),
    user: yup.string().max(40),
  })

  const initialValues = {
    id: '',
    role: '',
    organization: '',
    account: '',
    property: '',
    user: '',
    smokeAlert: false,
    smokeDigest: false,
    noiseAlert: false,
    outageAlert: false,
  }

  if (instance) {
    const boolFields = ['smokeAlert', 'smokeDigest', 'noiseAlert', 'outageAlert']
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] =
        instance[field] ?? (boolFields.includes(field) ? false : '')
    })
  }

  const handleClose = () => {
    doMarkMembershipListAsOutdated()
    onClose()
  }

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Membership`}
      open={open}
      onSave={doMembershipSave}
      onClose={handleClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormikContextWrapper initialValues={initialValues} />
    </FormDialog>
  )
}

MembershipForm.defaultProps = {
  instance: null,
}

MembershipForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    organization: PropTypes.string,
    organizationName: PropTypes.string,
  }),
}

FormikContextWrapper.propTypes = {
  initialValues: PropTypes.shape().isRequired,
}
