import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Card, Typography } from '@mui/material'

import { titleize } from 'inflection'

import MetricChart from './MetricChart'
import MetricTable from './MetricTable'
import getMetricTypesMeta from './metricTypesMeta'

export default function MetricCard({
  metric,
  data,
  dataInterval,
  requestedDateRange,
  selectedView,
  showTotal,
  sx,
}) {
  const { reportMetrics } = useConnect('selectReportMetrics')

  const systemMetric = reportMetrics.find((obj) => obj.key === metric)
  const cardTitle = systemMetric?.name ?? titleize(metric)
  const dataTypes = getMetricTypesMeta(data)

  return (
    <Card key={metric} sx={sx}>
      <Typography variant="body2" ml={2} mt={2}>
        {cardTitle}
      </Typography>
      {selectedView === 'graph' ? (
        <MetricChart
          id={metric}
          data={data}
          dataTypes={dataTypes}
          dataInterval={dataInterval}
          requestedDateRange={requestedDateRange}
          showTotal={showTotal}
        />
      ) : (
        <MetricTable id={metric} data={data} systemMetric={systemMetric} />
      )}
    </Card>
  )
}

MetricCard.defaultProps = {
  sx: {},
}

MetricCard.propTypes = {
  metric: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  dataInterval: PropTypes.shape({
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selectedView: PropTypes.string.isRequired,
  requestedDateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTotal: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
}
