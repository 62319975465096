import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { StaticMultiSelect } from '@common/components/Selects'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'

export default function ScenarioExportDialog({ open, onClose }) {
  const [selectedTags, setSelectedTags] = useState([])
  const { systemScenarioTags, doScenarioListExport } = useConnect(
    'selectSystemScenarioTags',
    'doScenarioListExport',
  )

  const options = useMemo(
    () => systemScenarioTags?.map((tag) => ({ id: tag, label: tag })) ?? [],
    [systemScenarioTags],
  )

  const onConfirm = () => {
    const tags = selectedTags.map((tag) => tag.id)
    doScenarioListExport({ tags })
    onClose()
  }

  return (
    <ConfirmationDialog
      title="Export scenarios by tags"
      content={
        <Box p={3}>
          <StaticMultiSelect
            sx={{ maxWidth: '100%' }}
            options={options}
            label="Tags"
            size="small"
            value={selectedTags}
            onChange={setSelectedTags}
            limitTags={10}
          />
        </Box>
      }
      open={open}
      onCancel={onClose}
      onConfirm={onConfirm}
    />
  )
}

ScenarioExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
