import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Card, Divider, Stack, Typography } from '@mui/material'

import { thresholdSensitivityValues } from '@rest/Utils/constants'

const colors = {
  95: '#44A797',
  90: '#429B8D',
  85: '#419184',
  80: '#3F857B',
  75: '#3E7A72',
  70: '#3D6F69',
  65: '#3B635F',
  60: '#3A5856',
  55: '#3DA0DA',
  50: '#3689C2',
  45: '#2F75AE',
  40: '#25568E',
  35: '#1E4078',
  30: '#E69B03',
  25: '#DB8608',
  20: '#D3720F',
  15: '#E85453',
}

export default function ThresholdCard({ item, sx }) {
  const formatHour = useCallback((hour) => {
    if (hour % 24 === 0) return '12 AM'
    if (hour % 24 === 12) return '12 PM'
    if (hour % 24 > 12) return `${hour - 12} PM`
    return `${hour % 24} AM`
  }, [])

  const getDurationLabel = useCallback(
    (start, end) => `${formatHour(start)} - ${formatHour(end)}`,
    [formatHour],
  )

  const getTime = (time) => {
    const timeArray = time.split(':')
    return timeArray[0] < 12
      ? parseInt(timeArray[0].charAt(1), 10)
      : parseInt(timeArray[0], 10)
  }

  const sensitivity = (value) =>
    thresholdSensitivityValues.find((level) => level.value === value).sensitivity

  return (
    <Card
      elevation={1}
      sx={{
        bgcolor: colors[item.highValue],
        color: 'muted.contrastText',
        borderRadius: '8px',
        px: 2,
        py: 1.2,
        ...sx,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Typography variant="button" lineHeight={1.5}>
            {item.name}
          </Typography>
          <Typography variant="h6" lineHeight={1.2}>
            {getDurationLabel(getTime(item.startTime), getTime(item.endTime))}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          mr={1.5}
        >
          <Stack>
            <Typography variant="h6" lineHeight={1}>
              {item.highValue}
            </Typography>
            <Typography variant="overline" lineHeight={1}>
              NRS
            </Typography>
          </Stack>
          <Typography variant="overline" sx={{ width: '73px', lineHeight: 1 }}>
            {sensitivity(item.highValue)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  )
}

ThresholdCard.defaultProps = {
  sx: {},
}

ThresholdCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    highValue: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  sx: PropTypes.shape({}),
}
