import PropTypes from 'prop-types'

import { Group, Line, Text } from 'react-konva'

import {
  CANVAS_WIDTH,
  RECT_HORIZONTAL_ORIGIN,
  TIMELINE_GRID_ORIGIN,
  TIMELINE_LABEL_PADDING,
  TIMELINE_LABEL_WIDTH,
  VERTICAL_GRID_SPACE,
} from './constants'
import { formatHour } from './utils'

// horizontal dimension of timeline
const getTimelineWidth = (width) => (width ?? CANVAS_WIDTH) - 54
function LabeledHorizontalLine({ width, x, y, text }) {
  const timelimeWidth = getTimelineWidth(width)
  return (
    <Group x={x + 0.5} y={y}>
      <Text
        x={0}
        text={text}
        width={TIMELINE_LABEL_WIDTH}
        fontFamily="Inter"
        fontSize={11}
        fill="#9e9e9e"
        align="right"
        y={-4}
      />
      <Line
        x={TIMELINE_LABEL_WIDTH + TIMELINE_LABEL_PADDING}
        points={[0, 0, RECT_HORIZONTAL_ORIGIN + timelimeWidth, 0]}
        stroke="#e0e0e0"
        strokeWidth={1}
      />
    </Group>
  )
}

LabeledHorizontalLine.defaultProps = {
  width: undefined,
}

LabeledHorizontalLine.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
}

export default function Timeline({ width }) {
  return (
    <Group x={1} y={TIMELINE_GRID_ORIGIN}>
      {Array.from({ length: 25 }, (_, i) => i).map((num) => (
        <LabeledHorizontalLine
          width={width}
          x={0}
          y={VERTICAL_GRID_SPACE * num}
          text={formatHour(num)}
          key={num}
        />
      ))}
    </Group>
  )
}

Timeline.defaultProps = {
  width: undefined,
}

Timeline.propTypes = {
  width: PropTypes.number,
}
