import PropTypes from 'prop-types'

import { Circle } from '@mui/icons-material'
import { Grid2 } from '@mui/material'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { getStatusColor } from '@portal/pages/Devices/DeviceDetails'
import deviceUrls from '@portal/pages/Devices/urls'

const formatDate = (date, format = DateTime.DATETIME_MED_WITH_SECONDS) =>
  date ? DateTime.fromISO(date).toLocaleString(format) : 'N/A'

function PhotoGrid({ results, openImage }) {
  return (
    <Grid2 container spacing={2}>
      {results.results.map((install) => (
        <Grid2 key={install.id} item size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
          <Card>
            <CardActionArea onClick={openImage(install)}>
              <CardMedia
                component="img"
                height="140"
                image={install.photoUrl}
                alt={install.deviceMac}
              />
            </CardActionArea>
            <CardContent sx={{ pb: 0, '&:last-child': { pb: 0 } }}>
              <Typography gutterBottom variant="h5" component="div">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span>{install.unitName}</span>
                  <Chip
                    icon={<Circle />}
                    variant="outlined"
                    size="small"
                    label={
                      install?.deviceStatus === 'RMA'
                        ? install.deviceStatus
                        : titleize(install?.deviceStatus || '')
                    }
                    color={getStatusColor(install?.deviceStatus)}
                  />
                </Stack>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <p>
                  <strong>Device Mac</strong>
                  <br />{' '}
                  <Link href={deviceUrls.entity.replace(':id', install.device)}>
                    {install.deviceMac}
                  </Link>
                </p>
                <p>
                  <strong>Photo Uploaded On</strong>
                  <br /> {formatDate(install.createdOn)}
                </p>
                <p>
                  <strong>Device Installed On</strong>
                  <br /> {formatDate(install.deviceInstalledOn)}
                </p>
                <p>
                  <strong>Reviewed</strong>
                  <br />{' '}
                  {install.reviewed
                    ? `By ${install.reviewedByName} on ${formatDate(
                        install.reviewedOn,
                        DateTime.DATETIME_SHORT,
                      )}`
                    : 'No'}
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  )
}

PhotoGrid.propTypes = {
  openImage: PropTypes.func.isRequired,
  results: PropTypes.shape({
    previous: PropTypes.number,
    next: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
}

export default PhotoGrid
