import PropTypes from 'prop-types'

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

export default function DataFilterModal({
  open,
  onClose,
  loading,
  visibleDataByCategory,
  data,
}) {
  if (!data) {
    return null
  }

  const { dataTypes, key, handleToggleDtVisibility } = data
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h6" textAlign="center" mb={2}>
          Data Filter
        </Typography>
        <Stack spacing={1}>
          {dataTypes.map(({ color, name, label }) => (
            <FormControlLabel
              sx={{ color, mr: 0 }}
              key={name}
              disabled={loading}
              label={
                <Box>
                  {label}
                  <Box sx={{ fontSize: 12 }}>{name}</Box>
                </Box>
              }
              control={
                <Checkbox
                  sx={{ color, '&.Mui-checked': { color } }}
                  checked={visibleDataByCategory[key][name]}
                  onChange={() => {
                    handleToggleDtVisibility(name, key)
                  }}
                />
              }
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

DataFilterModal.defaultProps = {
  data: null,
}

DataFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  visibleDataByCategory: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    dataTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    key: PropTypes.string.isRequired,
    handleToggleDtVisibility: PropTypes.func.isRequired,
  }),
}
