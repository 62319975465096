import PropTypes from 'prop-types'

import { Tooltip, Typography } from '@mui/material'

export default function TooltipCell({ label, props, toolTipProps }) {
  return (
    <Tooltip
      title={label}
      placement="top"
      slotProps={{ tooltip: { sx: { maxWidth: '850px' } } }}
      {...toolTipProps}
    >
      <Typography noWrap fontSize={14} {...props}>
        {label}
      </Typography>
    </Tooltip>
  )
}

TooltipCell.defaultProps = {
  props: {},
  toolTipProps: {},
}

TooltipCell.propTypes = {
  label: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
  toolTipProps: PropTypes.shape({}),
}
