/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import VerticalThresholdedLine from '../components/VerticalThresholdedLine'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    equals(prev.data, next.data) &&
    prev.color === next.color &&
    prev.yMin === next.yMin &&
    prev.yMax === next.yMax &&
    prev.graphWidth === next.graphWidth &&
    prev.yScale === next.yScale &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoVerticalThresholdedLine = memo(
  ({
    id,
    data,
    x,
    y,
    yMin,
    yMax,
    defined,
    threshold,
    curve,
    color,
    graphWidth,
    padding,
    yScale,
  }) => (
    <VerticalThresholdedLine
      id={id}
      data={data}
      x={x}
      y={y}
      yMin={yMin}
      yMax={yMax}
      defined={defined}
      threshold={threshold}
      curve={curve}
      color={color}
    />
  ),
  propsAreEqual,
)

MemoVerticalThresholdedLine.defaultProps = {
  graphWidth: undefined,
  padding: undefined,
}

MemoVerticalThresholdedLine.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color: PropTypes.string.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  curve: PropTypes.func.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  defined: PropTypes.func.isRequired,
  threshold: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoVerticalThresholdedLine
