import PropTypes from 'prop-types'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

import { ListTextTooltipWrapper } from '@common/components'
import { useSmallScreen } from '@common/utils'

import { getExistingPropertyProcess, propertyProcessConfig } from './utils'

export default function ProcessCell({ row }) {
  const isSmallScreen = useSmallScreen()

  const process = getExistingPropertyProcess(row)
  const config = propertyProcessConfig[process]
  return (
    <Box display="flex" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {!isSmallScreen && (
        <Tooltip title={config?.desc ?? 'Description missing'}>
          <InfoOutlined sx={{ fontSize: 20, color: 'primary.main', mr: 1 }} />
        </Tooltip>
      )}
      <ListTextTooltipWrapper tooltip={process}>
        <Typography noWrap variant={isSmallScreen ? 'caption' : 'body2'}>
          {process}
        </Typography>
      </ListTextTooltipWrapper>
    </Box>
  )
}

ProcessCell.propTypes = {
  row: PropTypes.shape({}).isRequired,
}
