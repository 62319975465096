import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  FileDownloadRounded,
  Refresh,
  RestartAlt,
  Settings,
  SettingsRounded,
  WarningAmberRounded,
  ZoomOut,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

import { titleize } from 'inflection'
import { DateTime, Interval } from 'luxon'

import { DateSelectionModal } from '@common/components'
import { Chart, MemoChartMinimap } from '@common/components/chart'
import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors, useLocalStorage } from '@common/utils'
import { days, hours } from '@common/utils/durations'
import processEventData, {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_PM,
} from '@common/utils/grafanaViewDataProcessor'

import { getEventTypesMeta, RESERVATION_COLOR } from './eventTypesMeta'

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const RESERVATIONS = 'reservations'

const areaSwitchers = [RESERVATIONS]
const areaTooltipDateFormat = 'yyyy-MM-dd HH:mm:ss'

const refreshTimeInMillis = 30000

const availableDateOptions = [
  { title: '1H', value: 1 * hours },
  { title: '3H', value: 3 * hours },
  { title: '6H', value: 6 * hours },
  { title: '12H', value: 12 * hours },
  { title: '1D', value: 1 * days },
  { title: '2D', value: 2 * days },
  { title: '3D', value: 3 * days },
  { title: '7D', value: 7 * days },
  { title: '30D', value: 30 * days },
  { title: 'Custom', value: 'custom' },
]

export default function DeviceDataModal({ device, onClose }) {
  const categories = [CATEGORY_PM, CATEGORY_ENVIRONMENT, CATEGORY_CO2_TVOC]

  const refreshInterval = useRef(null)

  const {
    deviceDataModalData,
    deviceDataModalChartData,
    deviceDataModalDataError,
    deviceDataModalChartDataError,
    deviceDataModalIsLoading,
    systemDataTypes,
    doFetchDeviceDataModalData,
    doFetchDeviceDataModalChartData,
    doExportDeviceDataModal,
    doResetDeviceDataModalState,
    doShowSnackbar,
  } = useConnect(
    'selectDeviceDataModalData',
    'selectDeviceDataModalChartData',
    'selectDeviceDataModalDataError',
    'selectDeviceDataModalChartDataError',
    'selectDeviceDataModalIsLoading',
    'selectSystemDataTypes',
    'doFetchDeviceDataModalData',
    'doFetchDeviceDataModalChartData',
    'doExportDeviceDataModal',
    'doResetDeviceDataModalState',
    'doShowSnackbar',
  )

  const defaultDateTimeData = { custom: null, option: 'custom', range: null }

  const [modalCoreData, setModalCoreData] = useState(null)

  const [selectedDevice, setSelectedDevice] = useState(device)

  const [refreshing, setRefreshing] = useState(false)

  const [chartData, setChartData] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [selectedDateTimeData, setSelectedDateTimeData] = useState(defaultDateTimeData)

  const [selectorId, setSelectorId] = useState(null)
  const [selectorStart, setSelectorStart] = useState(null)
  const [selectorEnd, setSelectorEnd] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const selectedAreaHistory = useRef([])

  const [cursor, setCursor] = useState(null)

  const [autoRefresh, setAutoRefresh] = useLocalStorage(
    'restDeviceDataAutoRefresh',
    true,
  )
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState(null)
  const [openDateSelectionModal, setOpenDateSelectionModal] = useState(false)
  const [typesPopoverData, setTypesPopoverData] = useState(null)
  const [typeVisibilitySettings, setTypeVisibilitySettings] = useLocalStorage(
    'restEventsTypeVisibility',
    {},
  )
  const [visibleAreas, setVisibleAreas] = useLocalStorage(
    'restEventsVisibleAreas',
    areaSwitchers.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  )

  const fetchData = useCallback(async (payload) => {
    try {
      await doFetchDeviceDataModalData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const fetchChartData = useCallback(async (payload) => {
    try {
      await doFetchDeviceDataModalChartData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const exportDeviceData = useCallback(async () => {
    try {
      let start = selectedDateTimeData.range[0].toISO()
      let end = selectedDateTimeData.range[1].toISO()
      if (selectedArea) {
        start = selectedArea.start.toISO()
        end = selectedArea.end.toISO()
      }
      await doExportDeviceDataModal({
        id: selectedDevice.id,
        start,
        end,
      })
      doShowSnackbar('Data has been exported. Please check your email')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [selectedDevice, selectedDateTimeData, selectedArea])

  useEffect(() => {
    if (autoRefresh && !deviceDataModalIsLoading) {
      refreshInterval.current = setInterval(() => {
        const now = DateTime.now().setZone(selectedDevice?.timezone)
        const endTime = selectedDateTimeData?.range?.[1]

        if (endTime && now) {
          const diff = Interval.fromDateTimes(endTime, now)
          const diffMinutes = diff.length('minutes')

          if (diffMinutes <= 5) {
            setSelectedDateTimeData((prev) => ({
              ...prev,
              range: prev.range.map((date) =>
                date.plus({ seconds: refreshTimeInMillis / 1000 }),
              ),
            }))
          }
        }
      }, refreshTimeInMillis)
    }

    return () => clearInterval(refreshInterval.current)
  }, [selectedDevice, deviceDataModalIsLoading, autoRefresh])

  const handleZoomOut = () => {
    selectedAreaHistory.current.splice(-1)
    const latestHistoryArea = selectedAreaHistory.current.at(-1)
    setSelectedArea(
      latestHistoryArea ? { ...latestHistoryArea, updateBrush: true } : null,
    )
  }

  const handleZoomReset = () => {
    selectedAreaHistory.current.length = 0
    setSelectedArea(null)
  }

  const cleanUp = useCallback(() => {
    doResetDeviceDataModalState()
    handleZoomReset()
    setChartData(null)
    setSelectedDateTimeData(defaultDateTimeData)
    setSelectedDevice(null)
    clearInterval(refreshInterval.current)
  }, [])

  useEffect(() => {
    if (device) {
      setSelectedDevice(device)
    } else {
      cleanUp()
    }
  }, [device])

  useEffect(() => {
    if (selectedDevice?.property) {
      // make sure that newly added areas will be enabled by default
      setVisibleAreas((prev) => {
        const updated = { ...prev }
        areaSwitchers.forEach((key) => {
          if (updated[key] === undefined) {
            updated[key] = true
          }
        })
        return updated
      })

      fetchData({ property: selectedDevice?.property })
    }
  }, [selectedDevice?.property])

  useEffect(() => {
    if (deviceDataModalData) {
      setModalCoreData(deviceDataModalData)
    }
  }, [deviceDataModalData])

  useEffect(() => {
    if (refreshing) {
      if (selectedDateTimeData.option !== 'custom') {
        setSelectedDateTimeData((prev) => {
          const now = DateTime.now().setZone(selectedDevice?.timezone)
          const range = [now.minus({ milliseconds: prev.option }), now]
          return { ...prev, range }
        })
      } else {
        fetchChartData({
          device: selectedDevice,
          selectedArea,
          selectedDateTimeData,
        })
      }

      setRefreshing(false)
      return
    }

    const timeNow = DateTime.now()

    const start = timeNow.minus({ days: 1 })
    const end = timeNow

    handleZoomReset()
    setSelectedDateTimeData(
      selectedDevice
        ? {
            custom: false,
            option: availableDateOptions.find((o) => o.title === '1D').value,
            range: [start, end],
          }
        : defaultDateTimeData,
    )
  }, [selectedDevice])

  useEffect(() => {
    if (selectedDevice && selectedDateTimeData.range) {
      fetchChartData({
        device: selectedDevice,
        selectedArea,
        selectedDateTimeData,
      })
    }
  }, [selectedDevice, selectedDateTimeData, selectedArea])

  useEffect(() => {
    if (deviceDataModalChartData) {
      if (!selectedArea) {
        setChartData(deviceDataModalChartData)
      }
      setCurrentData(deviceDataModalChartData)
    }
  }, [deviceDataModalChartData])

  const eventTypesMeta = getEventTypesMeta(systemDataTypes)

  const deviceDataByCategory = useMemo(() => {
    const processData = (name, value) => ({
      ...value,
      name,
      visible: typeVisibilitySettings[value.key] ?? value.visible,
    })

    return Object.entries(eventTypesMeta).reduce(
      (acc, [name, value]) => ({
        ...acc,
        [value.category]:
          value.category in acc
            ? [...acc[value.category], processData(name, value)]
            : [processData(name, value)],
      }),
      {},
    )
  }, [eventTypesMeta])

  const handleDateTimeChange = (dateRange, option) => {
    const zonedDateRange = dateRange.map((date) =>
      date.setZone(selectedDevice?.timezone, { keepLocalTime: true }),
    )
    setSelectedDateTimeData({ custom: true, option, range: zonedDateRange })
    setOpenDateSelectionModal(false)
    handleZoomReset()
  }

  const handleTimeframeChange = (_, value) => {
    if (value === 'custom') {
      setOpenDateSelectionModal(true)
      return
    }

    const now = DateTime.now().setZone(selectedDevice?.timezone)
    const range = [now.minus({ milliseconds: value }), now]
    handleDateTimeChange(range, value)
  }

  const processedData = useMemo(
    () =>
      categories.reduce(
        (acc, category) => ({
          ...acc,
          [category]: {
            current: processEventData(
              chartData?.data,
              category,
              selectedDevice?.timezone,
            ),
            zoomed: processEventData(
              currentData?.data,
              category,
              selectedDevice?.timezone,
            ),
          },
        }),
        {},
      ),
    [currentData],
  )

  const minimapData = useMemo(() => {
    const data = Object.values(processedData).find(
      (dt) => dt.current?.length > 0 && dt.current[0].time,
    )
    if (data?.current?.length > 0) {
      return data.current
    }
    return null
  }, [processedData])

  const getCardTitle = (category) => {
    switch (category) {
      case CATEGORY_PM:
        return 'PM (subtracted)'
      case CATEGORY_ENVIRONMENT:
        return 'Environment'
      case CATEGORY_CO2_TVOC:
        return 'CO2/TVOC'
      default:
        return titleize(category)
    }
  }

  const formatDateTooltip = () => {
    if (selectedDateTimeData.range) {
      const format = 'LLL dd, yyyy hh:mma'

      const [start, end] = selectedDateTimeData.range
      return `${start.toFormat(format)} - ${end.toFormat(format)}`
    }
    return ''
  }

  const getAreaStyle = useCallback(
    ({ color }) => ({
      dashedBackground: false,
      filledBackground: true,
      area: { color, opacity: 0.1 },
      handle: { color, dashArray: [4, 4], opacity: 0.6 },
    }),
    [],
  )

  const reservationsAreas = useMemo(
    () =>
      visibleAreas[RESERVATIONS]
        ? currentData?.reservations.map((reservation) => ({
            id: reservation.id,
            start: reservation.checkIn,
            end: reservation.checkOut,
            payload: {
              label: 'Reservation',
              checkIn: DateTime.fromISO(reservation.checkIn)
                .setZone(selectedDevice?.timezone)
                .toFormat(areaTooltipDateFormat),
              checkOut: DateTime.fromISO(reservation.checkOut)
                .setZone(selectedDevice?.timezone)
                .toFormat(areaTooltipDateFormat),
            },
            style: getAreaStyle({ color: RESERVATION_COLOR }),
            priority: 0,
          }))
        : [],
    [currentData, visibleAreas[RESERVATIONS], selectedDevice],
  )

  const buildGraphCard = ([key, dataTypes]) => {
    const categoryHasData = processedData[key].zoomed.find(
      (d) => d.time !== null && d.time !== undefined,
    )

    return (
      <Card
        key={`${key}_data`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          m: 1,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02))',
        }}
      >
        <Box width="100%" display="flex" position="relative" justifyContent="center">
          <Typography m={2}>{getCardTitle(key)}</Typography>
          {categoryHasData && (
            <IconButton
              sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
              onClick={(event) =>
                setTypesPopoverData({ category: key, anchor: event.currentTarget })
              }
            >
              <Settings />
            </IconButton>
          )}
        </Box>
        {categoryHasData ? (
          <Chart
            id={key}
            data={processedData[key].current}
            zoomedData={processedData[key].zoomed}
            highlightedAreas={[...reservationsAreas]}
            dataTypes={dataTypes}
            cursorState={[cursor, setCursor]}
            zoomState={{
              selectorIdState: [selectorId, setSelectorId],
              selectorStartState: [selectorStart, setSelectorStart],
              selectorEndState: [selectorEnd, setSelectorEnd],
              selectedAreaState: [selectedArea, setSelectedArea],
              historyRef: selectedAreaHistory,
            }}
            settings={{
              showNotAvailableInTooltip: true,
            }}
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            m={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" color="text.secondary" textAlign="center">
              No {titleize(key)} data to display.
            </Typography>
          </Box>
        )}
      </Card>
    )
  }

  const buildGraphByCategory = useCallback(
    (category) => {
      const data = Object.entries(deviceDataByCategory).find(
        (entry) => category === entry[0],
      )
      return buildGraphCard(data)
    },
    [deviceDataByCategory],
  )

  const deviceOption = useCallback(
    (option) => {
      const showZone = modalCoreData?.devicesByUnit?.[option.unit]?.length > 1
      return (
        <Box>
          <Typography variant="h6">
            {showZone ? `${option.unitName} - ${option.zoneName}` : option.unitName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            textTransform="uppercase"
            fontSize="0.7rem"
          >
            {option.mainMac}
          </Typography>
        </Box>
      )
    },
    [modalCoreData],
  )

  return (
    <>
      <DateSelectionModal
        open={openDateSelectionModal}
        onCancel={() => setOpenDateSelectionModal(false)}
        onSave={(range) => handleDateTimeChange(range, 'custom')}
      />
      <Popover
        open={!!filterPopoverAnchor}
        onClose={() => setFilterPopoverAnchor(null)}
        anchorEl={filterPopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack>
          <Typography variant="h6" px={2} pt={1} sx={{ fontWeight: 'bold' }}>
            Filters
          </Typography>
          <Stack direction="row" gap={1} pl={2} pb={1}>
            {areaSwitchers.map((key) => (
              <FormControlLabel
                key={key}
                label={titleize(key)}
                labelPlacement="end"
                slotProps={{ typography: { fontSize: 12 } }}
                control={
                  <Checkbox
                    checked={visibleAreas[key]}
                    size="small"
                    onChange={() =>
                      setVisibleAreas((areas) => ({
                        ...areas,
                        [key]: !areas[key],
                      }))
                    }
                  />
                }
              />
            ))}
          </Stack>
          <Typography variant="h6" px={2} sx={{ fontWeight: 'bold' }}>
            Settings
          </Typography>
          <FormControlLabel
            label="Auto-refresh"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                checked={autoRefresh}
                size="small"
                onChange={() => setAutoRefresh((value) => !value)}
              />
            }
            sx={{ pl: 2, pb: 0.5 }}
          />
        </Stack>
      </Popover>
      <Popover
        open={!!typesPopoverData}
        onClose={() => setTypesPopoverData(null)}
        anchorEl={typesPopoverData?.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {typesPopoverData?.category &&
          deviceDataByCategory[typesPopoverData.category]?.map((dataType) => (
            <Stack direction="column" gap={2} px={1.5}>
              <FormControlLabel
                key={`${typesPopoverData.category}_${dataType.tooltipLabel}`}
                label={dataType.tooltipLabel}
                labelPlacement="end"
                slotProps={{ typography: { fontSize: 14 } }}
                control={
                  <Checkbox
                    checked={typeVisibilitySettings[dataType.key] ?? dataType.visible}
                    size="small"
                    onChange={() =>
                      setTypeVisibilitySettings((settings) => ({
                        ...settings,
                        [dataType.key]:
                          settings[dataType.key] !== undefined
                            ? !settings[dataType.key]
                            : !dataType.visible,
                      }))
                    }
                  />
                }
              />
            </Stack>
          ))}
      </Popover>
      <Dialog
        fullScreen
        open={!!device}
        onClose={onClose}
        TransitionComponent={Transition}
        sx={{ m: 1.5 }}
        PaperProps={{
          style: {
            backgroundImage: 'unset',
            boxShadow: 'none',
            borderRadius: '10px',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box>
            {selectedDevice?.id && (
              <Typography
                variant="body2"
                fontSize={10}
              >{`Device ID: ${selectedDevice.id}`}</Typography>
            )}
            {modalCoreData && selectedDevice?.id && (
              <Stack direction="row" gap={2} mt={2} alignItems="center">
                <DynamicSelect
                  getFullEntity
                  disableClearable
                  useValueInRequest={false}
                  label="Unit"
                  endpoint="devices"
                  filters={{ property: selectedDevice?.property }}
                  value={selectedDevice}
                  onChange={(deviceObj) => {
                    setSelectedDevice(deviceObj)
                  }}
                  disabled={deviceDataModalIsLoading}
                  primaryTextAttr="zoneName"
                  optionRender={deviceOption}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 206 }}
                />
                <Stack direction="row" px={1}>
                  <ToggleButtonGroup
                    exclusive
                    onChange={handleTimeframeChange}
                    size="small"
                    variant="outlined"
                    disabled={deviceDataModalIsLoading}
                  >
                    {availableDateOptions.map((option) => {
                      const selected = selectedDateTimeData.option === option.value
                      const tooltipText = selected ? formatDateTooltip() : null
                      return (
                        <Tooltip key={option.value} title={tooltipText}>
                          <ToggleButton value={option.value} selected={selected}>
                            {option.title}
                          </ToggleButton>
                        </Tooltip>
                      )
                    })}
                  </ToggleButtonGroup>
                </Stack>
                {chartData && (
                  <Tooltip title="Refresh">
                    <IconButton
                      color="inherit"
                      disabled={deviceDataModalIsLoading}
                      onClick={() => {
                        setRefreshing(true)
                        fetchData({ property: selectedDevice.property })
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                )}
                {chartData && (
                  <Tooltip title="Settings">
                    <IconButton
                      color="inherit"
                      disabled={deviceDataModalIsLoading}
                      onClick={(event) => setFilterPopoverAnchor(event.currentTarget)}
                    >
                      <SettingsRounded />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Tooltip title="Export">
              <IconButton
                color="inherit"
                disabled={deviceDataModalIsLoading}
                onClick={exportDeviceData}
                aria-label="export"
              >
                <FileDownloadRounded />
              </IconButton>
            </Tooltip>
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        {!chartData && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            {deviceDataModalIsLoading && <CircularProgress />}
            {!deviceDataModalIsLoading &&
              (deviceDataModalDataError || deviceDataModalChartDataError) && (
                <Stack alignItems="center">
                  <WarningAmberRounded sx={{ fill: 'grey', fontSize: 48 }} />
                  <Typography variant="h4" color="grey">
                    Oops. Something went wrong
                  </Typography>
                </Stack>
              )}
          </Box>
        )}
        <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {deviceDataModalIsLoading && chartData && (
            <CircularProgress sx={{ position: 'absolute' }} />
          )}
          <Box
            sx={{
              width: '100%',
              transitionProperty: 'opacity',
              transitionDuration: '300ms',
              opacity: deviceDataModalIsLoading ? 0.35 : 1,
              pointerEvents: deviceDataModalIsLoading ? 'none' : 'initial',
            }}
          >
            <Box m={2}>
              <Box
                display="flex"
                gap={1}
                sx={{
                  transitionProperty: 'height, margin',
                  transitionDuration: '300ms',
                  overflow: 'hidden',
                  height: selectedArea ? 36.5 : 0,
                  mb: selectedArea ? 2 : 0,
                }}
              >
                <Button
                  startIcon={<ZoomOut />}
                  variant="outlined"
                  onClick={handleZoomOut}
                >
                  Zoom Out
                </Button>
                <Button
                  startIcon={<RestartAlt />}
                  variant="outlined"
                  onClick={handleZoomReset}
                >
                  Reset Zoom
                </Button>
              </Box>
              {chartData && minimapData && (
                <Box sx={{ mb: 1 }}>
                  <MemoChartMinimap
                    id="chart_minimap"
                    data={minimapData}
                    selectedArea={selectedArea}
                    onChange={(area) => {
                      setSelectedArea(area)
                      selectedAreaHistory.current.push(area)
                      setSelectorStart(null)
                      setSelectorEnd(null)
                    }}
                    onMove={(area) => {
                      setSelectorStart(area.start)
                      setSelectorEnd(area.end)
                    }}
                  />
                </Box>
              )}
              {chartData &&
                categories.map((category) => (
                  <Box display="flex">{buildGraphByCategory(category)}</Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

DeviceDataModal.defaultProps = {
  device: undefined,
  onClose: () => {},
}

DeviceDataModal.propTypes = {
  device: PropTypes.shape({}),
  onClose: PropTypes.func,
}
