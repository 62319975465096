import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { omit } from 'ramda'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import { DateTime } from 'luxon'

export default function Picker({
  type,
  range,
  value,
  onChange,
  label,
  filterName,
  upperCondition,
  lowerCondition,
  conditionSeparator,
  ...rest
}) {
  const processedValue = useMemo(
    () =>
      Object.keys(value).reduce((acc, key) => {
        const paramValue =
          typeof value[key] === 'string' ? DateTime.fromISO(value[key]) : value[key]
        return { ...acc, [`${key}`]: paramValue }
      }, {}),
    [value],
  )

  const handleChange = (key, rawTime) => {
    if (!DateTime.isDateTime(rawTime)) {
      onChange(omit([key], processedValue))
      return
    }
    const formatted = rawTime ? DateTime.fromISO(rawTime) : rawTime
    onChange({ ...processedValue, [key]: formatted })
  }

  const componentTypes = {
    dateTime: DateTimePicker,
    date: DatePicker,
    time: TimePicker,
  }

  const Component = componentTypes[type]

  const fromPickerKey = range
    ? `${filterName}${conditionSeparator}${lowerCondition}`
    : filterName

  const getFromPickerValue = () => processedValue?.[fromPickerKey] || null
  const getToPickerValue = () =>
    processedValue?.[`${filterName}${conditionSeparator}${upperCondition}`] || null

  const getMax = (compType) =>
    type === compType && getToPickerValue()
      ? DateTime.fromISO(getToPickerValue())
      : null
  const getMin = (compType) =>
    type === compType && getFromPickerValue()
      ? DateTime.fromISO(getFromPickerValue())
      : null

  const globalMinDT = DateTime.fromISO('2015-01-01T00:00:00-08:00')
  const globalMaxDT = DateTime.now().plus({ years: 1 })

  const getMaxProps = () => {
    const time = { maxTime: getMax('time') }
    const date = { maxDate: getMax('date') }
    const dateTime = {
      ...time,
      ...date,
      minDateTime: globalMinDT,
      maxDateTime: getMax('dateTime') || globalMaxDT,
    }
    return { time, date, dateTime }[type]
  }
  const getMinProps = () => {
    const time = { minTime: getMin('time') }
    const date = { minDate: getMin('date') }
    const dateTime = {
      ...time,
      ...date,
      minDateTime: getMin('dateTime') || globalMinDT,
      maxDateTime: globalMaxDT,
    }
    return { time, date, dateTime }[type]
  }

  return (
    <>
      <Component
        {...getMaxProps()}
        value={getFromPickerValue()}
        onChange={(t) => handleChange(fromPickerKey, t)}
        slotProps={{
          textField: {
            size: 'small',
            label: range ? `${label} range start` : label,
          },
        }}
        {...rest}
      />
      {range && (
        <Component
          {...getMinProps()}
          value={getToPickerValue()}
          onChange={(rawTime) =>
            handleChange(`${filterName}${conditionSeparator}${upperCondition}`, rawTime)
          }
          slotProps={{
            textField: {
              size: 'small',
              label: `${label} range end`,
            },
          }}
          {...rest}
        />
      )}
    </>
  )
}

Picker.defaultProps = {
  value: {},
  range: false,
  onChange: () => {},
  filterName: '',
  lowerCondition: 'gte',
  upperCondition: 'lte',
  conditionSeparator: '__',
}

Picker.propTypes = {
  range: PropTypes.bool,
  filterName: PropTypes.string,
  value: PropTypes.shape(),
  type: PropTypes.oneOf(['date', 'time', 'dateTime']).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  lowerCondition: PropTypes.oneOf(['gte', 'gt', 'After']),
  upperCondition: PropTypes.oneOf(['lte', 'lt', 'Before']),
  conditionSeparator: PropTypes.string,
}
