import cronstrue from 'cronstrue'
import { DateTime, Interval } from 'luxon'

export function formatDuration(start, end) {
  const startDateTime = typeof start === 'string' ? DateTime.fromISO(start) : start
  const endDateTime = typeof start === 'string' ? DateTime.fromISO(end) : end

  if (!startDateTime.isValid || !endDateTime.isValid) return ''
  if (startDateTime > endDateTime) return ''

  return Interval.fromDateTimes(startDateTime, endDateTime)
    .toDuration(['weeks', 'days', 'hours', 'minutes'])
    .toHuman({ unitDisplay: 'short' })
    .split(',')
    .filter((time) => parseInt(time.trim().charAt(0), 10) > 0)
    .map((selectedTime) => {
      const arr = selectedTime.trim().split(' ')
      arr[0] = Math.round(arr[0])
      return `${arr[0]} ${arr[1]}`
    })
    .join(' ')
}

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value, defaultValue = 'N/A') {
  return Number.isNaN(Number(value)) || value === null
    ? defaultValue
    : usdFormatter.format(value)
}

export function formatChargeAmount(netChargeAmount, chargeAmount) {
  const [netCharge, charge] = [Number(netChargeAmount), Number(chargeAmount)]
  const value = formatCurrency(netCharge, '-')
  const asterisk = !Number.isNaN(netCharge) && netCharge !== charge ? '*' : ''
  return `${value}${asterisk}`
}

export function formatCron(cron) {
  try {
    return cronstrue.toString(cron)
  } catch (e) {
    return ''
  }
}

export const formatScenarioName = (propertyName, unitName, createdOn) => {
  const dateTime = DateTime.isDateTime(createdOn)
    ? createdOn
    : DateTime.fromISO(createdOn)
  const part1 = `${propertyName}] ${unitName}`
  const part2 = dateTime.isValid
    ? dateTime.toLocaleString(DateTime.DATETIME_SHORT)
    : null
  return `[${part2 ? [part1, part2].join(' - ') : part1}]`
}

export function formatAsMacAddress(input) {
  const cleaned = input.replace(/[^a-zA-Z0-9]/g, '')
  if (cleaned.length !== 12) return null
  return cleaned.match(/.{1,2}/g).join(':')
}
