import PropTypes from 'prop-types'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'

export default function AlertDialog({
  'data-testid': dataTestId,
  children,
  label,
  onClose,
  open,
  buttonText,
  showButtons,
}) {
  return (
    <Dialog
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      disableScrollLock
      maxWidth="xs"
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        {label}
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ py: 0, px: 4, maxWidth: '400px' }}>{children}</DialogContent>
      {showButtons && (
        <DialogActions
          disableSpacing
          sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
          >
            {buttonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

AlertDialog.defaultProps = {
  'data-testid': undefined,
  buttonText: 'OK',
  onClose: () => {},
  showButtons: true,
}

AlertDialog.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  showButtons: PropTypes.bool,
}
