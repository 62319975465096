import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Button, Stack } from '@mui/material'

import { DateTime } from 'luxon'

import { useSmallScreen } from '@common/utils'
import {
  digestDeliveryOptions,
  getExistingPropertyProcess,
} from '@portal/pages/Properties/utils'
import { smokeProfilesUrls } from '@portal/pages/SmokeProfiles'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

export default function SmokeComponent({
  smokeProfileData,
  secondOpinionProfileData,
  property,
  margin,
  gap,
  onEdit,
}) {
  const isSmallScreen = useSmallScreen()

  const getDeliveryLabel = useCallback(
    (hours) =>
      digestDeliveryOptions
        .filter((opt) => hours?.includes(Number(opt.id)))
        .map((opt) => opt.label)
        .join(' & '),
    [digestDeliveryOptions],
  )
  return (
    <Stack direction="row" alignItems="start">
      <DetailContainer margin={margin} gap={gap}>
        <DetailItem
          label="Alerting Smoke Profile"
          value={
            smokeProfileData
              ? `${smokeProfileData.modelKey} - ${smokeProfileData.name}`
              : '--'
          }
          linkTo={
            smokeProfileData
              ? smokeProfilesUrls.entity.replace(':id', smokeProfileData.id)
              : null
          }
        />
        <DetailItem
          label="Second Opinion Profile"
          value={
            secondOpinionProfileData
              ? `${secondOpinionProfileData.modelKey} - ${secondOpinionProfileData.name}`
              : '--'
          }
          linkTo={
            secondOpinionProfileData
              ? smokeProfilesUrls.entity.replace(':id', secondOpinionProfileData.id)
              : null
          }
        />
        <DetailItem
          label="Go Live Date"
          value={
            property?.goLiveDate
              ? DateTime.fromISO(property.goLiveDate).toLocaleString(
                  DateTime.DATE_SHORT,
                )
              : 'N/A'
          }
        />
        <DetailItem label="Smoking Fee" value={`$${property?.smokingFee}`} />
        <DetailItem
          label="Process"
          value={getExistingPropertyProcess(property) ?? 'Undefined'}
        />
        <DetailItem
          label="Smoke Digest Hour(s)"
          value={getDeliveryLabel(property.smokeDigestHours)}
        />
        <DetailItem
          label="Autopopulate Smoke Fee"
          value={property?.autopopulateSmokeFee ? 'Yes' : 'No'}
        />
        <DetailItem
          label="Installation Approved"
          value={property?.installationApproved ? 'Yes' : 'No'}
        />
      </DetailContainer>
      {onEdit && (
        <Button onClick={onEdit} sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}>
          Edit
        </Button>
      )}
    </Stack>
  )
}

SmokeComponent.defaultProps = {
  smokeProfileData: undefined,
  secondOpinionProfileData: undefined,
  property: undefined,
  margin: 0,
  gap: 0,
  onEdit: undefined,
}

SmokeComponent.propTypes = {
  smokeProfileData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modelKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  secondOpinionProfileData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modelKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  property: PropTypes.shape({
    goLiveDate: PropTypes.string,
    smokingFee: PropTypes.number,
    autopopulateSmokeFee: PropTypes.bool,
    installationApproved: PropTypes.bool,
    smokeDigestHours: PropTypes.arrayOf(PropTypes.number),
  }),
  onEdit: PropTypes.func,
  margin: PropTypes.number,
  gap: PropTypes.number,
}
