import { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { DeviceHub } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import { ErrorMessage } from 'formik'
import { DateTime } from 'luxon'

import { Loading, MobileList, MobileListCardRow } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { humanizedAgo, useSmallScreen } from '@common/utils'
import DeviceStatusChip from '@rest/pages/Properties/PropertyDevices/DeviceStatusChip'
import { List } from '@rest/UI/components'

function UnitDevicesEmptyState({ isHotel }) {
  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <DeviceHub sx={{ fontSize: 60, mb: 2 }} />
      There are no sensors in this {`${isHotel ? 'room' : 'unit'}`} yet.
    </Stack>
  )
}

export default function DevicesTab({ property }) {
  const {
    deviceModels,
    unitDeviceList,
    unitDeviceListRaw: { ordering = [] },
    unitDeviceListIsLoading,
    doUnitDeviceListSetPage,
    doUnitDeviceListSetOrdering,
    doMarkUnitDeviceListAsOutdated,
  } = useConnect(
    'selectDeviceModels',
    'selectUnitDeviceList',
    'selectUnitDeviceListRaw',
    'selectUnitDeviceListIsLoading',
    'doUnitDeviceListSetPage',
    'doUnitDeviceListSetOrdering',
    'doMarkUnitDeviceListAsOutdated',
  )

  const isSmallScreen = useSmallScreen()
  const isHotel = property?.propertyType === 'HOTEL'

  useEffect(() => {
    doMarkUnitDeviceListAsOutdated()
  }, [])

  const columns = [
    {
      field: 'zoneName',
      headerName: 'Location',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'model',
      headerName: 'Type',
      flex: 0.2,
      sortable: false,
      valueGetter: (_, row) => {
        const modelData = deviceModels.find((data) => data.id === row.model)
        return modelData?.name
      },
      valueFormatter: (value) => value || 'Unknown',
      renderMobile: (params) => params.formattedValue,
    },
    {
      field: 'mainMac',
      headerName: 'MAC',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'seenOn',
      headerName: 'Last Seen',
      flex: 0.1,
      sortable: false,
      valueGetter: (_, row) =>
        row.seenOn ? humanizedAgo(DateTime.fromISO(row.seenOn)) : null,
      valueFormatter: (value) => value || '--',
      renderMobile: (params) => params.formattedValue,
    },
    {
      field: 'status',
      headerName: 'Connectivity',
      flex: 0.15,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => <DeviceStatusChip status={row.status} />,
      renderMobile: ({ row }) => <DeviceStatusChip status={row.status} />,
    },
  ]

  const MobileListItem = useCallback(
    (row) => {
      const filteredColumns = columns.filter(
        (column) => !['zoneName'].includes(column.field),
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box display="flex" alignItems="center">
              <Typography fontWeight="bold" fontSize={14}>
                {row.zoneName}
              </Typography>
            </Box>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
        </Stack>
      )
    },
    [columns],
  )

  if (unitDeviceListIsLoading && !unitDeviceList) {
    return <Loading />
  }

  if (!unitDeviceListIsLoading && !unitDeviceList) {
    return <ErrorMessage>Oops. Something went wrong</ErrorMessage>
  }

  return isSmallScreen ? (
    <MobileList
      data-testid="events_list"
      loading={unitDeviceListIsLoading}
      rows={unitDeviceList.results}
      itemBuilder={MobileListItem}
      page={unitDeviceList?.current ?? 0}
      rowCount={unitDeviceList?.count ?? 0}
      pageChange={doUnitDeviceListSetPage}
      pageSizeOptions={[25]}
      slots={{ noRowsOverlay: () => UnitDevicesEmptyState({ isHotel }) }}
      hideFooter={(unitDeviceList?.numPages ?? 0) <= 1}
    />
  ) : (
    <List
      autoHeight
      disableColumnMenu
      data-testid="events_list"
      loading={unitDeviceListIsLoading}
      columns={columns}
      rows={unitDeviceList.results}
      page={unitDeviceList?.current ?? 0}
      rowCount={unitDeviceList?.count ?? 0}
      pageChange={doUnitDeviceListSetPage}
      pageSizeOptions={[25]}
      sortChange={doUnitDeviceListSetOrdering}
      currentOrdering={ordering ?? []}
      slots={{ noRowsOverlay: () => UnitDevicesEmptyState({ isHotel }) }}
      hideFooter={(unitDeviceList?.numPages ?? 0) <= 1}
    />
  )
}

DevicesTab.propTypes = {
  property: PropTypes.shape({
    propertyType: PropTypes.string.isRequired,
  }).isRequired,
}

UnitDevicesEmptyState.propTypes = {
  isHotel: PropTypes.bool.isRequired,
}
