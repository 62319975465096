import PropTypes from 'prop-types'

import { Grid2, Stack } from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function DetailContainer({ children, margin, gap }) {
  const isSmallScreen = useSmallScreen()

  return isSmallScreen ? (
    <Grid2 container my={margin} gap={gap} width={1}>
      {children}
    </Grid2>
  ) : (
    <Stack spacing={margin} py={2} width={1}>
      {children}
    </Stack>
  )
}

DetailContainer.defaultProps = {
  margin: 0,
  gap: 0,
}

DetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
  margin: PropTypes.number,
  gap: PropTypes.number,
}
