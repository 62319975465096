import PropTypes from 'prop-types'

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

export default function RulesRow({ rules }) {
  return (
    <Box
      sx={{
        pb: 3,
        minHeight: 175,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <h5>Rules</h5>
      <TableContainer component={Paper} sx={{ alignSelf: 'center', maxWidth: '96%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Start Time</TableCell>
              <TableCell align="right">End Time</TableCell>
              <TableCell align="right">Threshold</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules
              .sort((a, b) => {
                const startHourA = parseInt(a.startTime.split(':')[0], 10)
                const startHourB = parseInt(b.startTime.split(':')[0], 10)
                return startHourA - startHourB
              })
              .map((rule) => (
                <TableRow
                  key={`${rule.name}_${rule.deviceStyle}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {rule.name}
                  </TableCell>
                  <TableCell>{rule.deviceStyle}</TableCell>
                  <TableCell align="right">{rule.startTime}</TableCell>
                  <TableCell align="right">{rule.endTime}</TableCell>
                  <TableCell align="right">{rule.highValue} NRS</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

RulesRow.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      deviceStyle: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      highValue: PropTypes.number.isRequired,
      lowValue: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
