import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'

import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import PropertyForm from '@portal/pages/Properties/Form/PropertyForm'

export default function MatchPropertyModal({ open, onClose, orderData }) {
  const [saving, setSaving] = useState(false)
  const [openNewPropertyForm, setOpenNewPropertyForm] = useState(false)

  const [selectedProperty, setSelectedProperty] = useState(null)
  const [error, setError] = useState(null)

  const {
    shipbobOrderRecipient,
    shipbobOrderRecipientIsLoading,
    doShipbobOrderFetchRecipient,
    doShipbobOrderSave,
    doShowSnackbar,
  } = useConnect(
    'selectShipbobOrderRecipient',
    'selectShipbobOrderRecipientIsLoading',
    'doShipbobOrderFetchRecipient',
    'doShipbobOrderSave',
    'doShowSnackbar',
  )

  const fetchRecipient = useCallback(async () => {
    try {
      setError(null)
      const result = await doShipbobOrderFetchRecipient(orderData.id)
      if (result?.error) {
        throw result.error
      }
    } catch (err) {
      let errorMessage
      if (err?.status === 404 || err?.status === 503) {
        errorMessage = err?.response?.detail
      } else {
        errorMessage = parseApiErrors(err)
      }
      const finalError = errorMessage || 'Oops. An unexpected error occurred'
      setError(finalError)
    }
  }, [orderData])

  useEffect(() => {
    if (orderData) {
      fetchRecipient()
      if (orderData.property && orderData.propertyName) {
        setSelectedProperty({ id: orderData.property, name: orderData.propertyName })
      }
    } else {
      setSelectedProperty(null)
      setError(null)
    }
  }, [orderData])

  const handleMatch = useCallback(async () => {
    setSaving(true)
    try {
      await doShipbobOrderSave({ id: orderData.id, property: selectedProperty.id })
      doShowSnackbar('Property has been changed', 'success')
      onClose(true)
    } catch (err) {
      const errorMessage = parseApiErrors(err)
      doShowSnackbar(errorMessage, 'error')
    } finally {
      setSaving(false)
    }
  }, [selectedProperty])

  const buildAddressText = useCallback((addressData) => {
    const addressArr = []
    const locationArr = []
    if (addressData?.address1) addressArr.push(addressData.address1)
    if (addressData?.address2) addressArr.push(addressData.address2)
    if (addressData?.city) locationArr.push(addressData.city)
    if (addressData?.state) locationArr.push(addressData.state)
    if (addressData?.zipCode) locationArr.push(addressData.zipCode)

    if (!addressArr.length && !locationArr.length) {
      return null
    }

    return [addressArr.join(', '), locationArr.join(' ')].join(', ')
  }, [])

  const selectorOption = useCallback(
    (option) => (
      <Box>
        <Typography variant="h6">{option.name}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          fontSize="0.7rem"
        >
          {buildAddressText(option) || '--'}
        </Typography>
      </Box>
    ),
    [],
  )

  return (
    <>
      <PropertyForm
        open={openNewPropertyForm}
        onClose={(success, property) => {
          if (success === true && property) {
            setSelectedProperty(property)
          }
          setOpenNewPropertyForm(false)
        }}
      />
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <Box>
          <DialogContent>
            <Typography variant="h6" mb={2}>
              Match Property
            </Typography>
            {shipbobOrderRecipient && (
              <Alert
                severity="info"
                sx={{ mb: 3, '& .MuiAlert-message': { width: 1 } }}
              >
                <AlertTitle>Recipient Info</AlertTitle>
                <Box display="flex" justifyContent="space-between">
                  <Typography fontSize={12}>Name:</Typography>
                  <Typography fontSize={12}>{shipbobOrderRecipient.name}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography fontSize={12}>Address:</Typography>
                  <Typography fontSize={12}>
                    {buildAddressText(shipbobOrderRecipient.address)}
                  </Typography>
                </Box>
              </Alert>
            )}
            {error && (
              <Alert
                severity="error"
                action={
                  <Button onClick={fetchRecipient} color="inherit" size="small">
                    Retry
                  </Button>
                }
                sx={{ mb: 3 }}
              >
                {error}
              </Alert>
            )}
            <DynamicSelect
              getFullEntity
              disableClearable
              useValueInRequest={false}
              defaultSearch={shipbobOrderRecipient?.address?.zipCode?.substring(0, 5)}
              label="Property"
              endpoint="properties"
              value={selectedProperty}
              onChange={setSelectedProperty}
              disabled={saving || shipbobOrderRecipientIsLoading || error}
              optionRender={selectorOption}
              helperText="Showing suggested properties, enter search to find others"
              variant="outlined"
              size="small"
              sx={{ minWidth: 500 }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', mx: 1 }}>
            <Button
              disabled={saving || shipbobOrderRecipientIsLoading || error}
              onClick={() => setOpenNewPropertyForm(true)}
              color="secondary"
            >
              Add New Property
            </Button>
            <Box display="flex" gap={0.5}>
              <Button
                disabled={saving || shipbobOrderRecipientIsLoading}
                onClick={onClose}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  orderData?.property === selectedProperty?.id ||
                  saving ||
                  shipbobOrderRecipientIsLoading ||
                  error
                }
                onClick={handleMatch}
              >
                Match
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

MatchPropertyModal.defaultProps = {
  orderData: undefined,
}

MatchPropertyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    property: PropTypes.string,
    propertyName: PropTypes.string,
  }),
}
