import { useState } from 'react'

import PropTypes from 'prop-types'
import { pick } from 'ramda'

import { AddCircle as AddCircleIcon } from '@mui/icons-material'
import { Box, Chip, IconButton, TextField } from '@mui/material'

export default function RateCodeTab({ values, setValues }) {
  const [newRateCode, setNewRateCode] = useState('')
  const [newRateCodeValue, setNewRateCodeValue] = useState('')
  const [rateCodeError, setRateCodeError] = useState(false)

  const rateCodes = values.smokeAutochargeParams?.rateCodes

  const handleDelete = (key) => {
    const currentKeys = Object.keys(rateCodes).filter((code) => code !== key)
    setValues({
      ...values,
      smokeAutochargeParams: {
        ...values.smokeAutochargeParams,
        rateCodes: pick(currentKeys, rateCodes),
      },
    })
  }

  const handleAddRateCode = () => {
    if (newRateCode in rateCodes) {
      setRateCodeError(true)
      return
    }
    setRateCodeError(false)
    setValues({
      ...values,
      smokeAutochargeParams: {
        ...values.smokeAutochargeParams,
        rateCodes: {
          ...rateCodes,
          [newRateCode]: newRateCodeValue,
        },
      },
    })
    setNewRateCode('')
    setNewRateCodeValue('')
  }

  return (
    <Box
      gap={3}
      py={2}
      display="flex"
      flexDirection="column"
      minHeight={65}
      justifyContent="flex-end"
    >
      <Box display="flex" flexWrap="wrap" gap={1}>
        {Object.entries(rateCodes).map(([key, value]) => (
          <Box key={key} display="flex" alignItems="center" gap={1}>
            <Chip
              color="secondary"
              label={`${key}: $${value}`}
              onDelete={() => handleDelete(key)}
              sx={{ fontSize: 15, fontWeight: 'bold' }}
            />
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        width="100%"
        gap={2}
      >
        <TextField
          fullWidth
          variant="standard"
          onChange={(e) => setNewRateCode(e.target.value.toUpperCase().trim())}
          label="Add rate code"
          placeholder="Enter unique code"
          value={newRateCode}
          error={rateCodeError}
          helperText={rateCodeError ? 'That code already exists' : ''}
          sx={{ flex: 1 }}
        />
        <TextField
          fullWidth
          variant="standard"
          type="number"
          onChange={(e) => {
            setNewRateCodeValue(e.target.value === '' ? '' : Number(e.target.value))
          }}
          label="Add value"
          value={newRateCodeValue}
          sx={{ flex: 0.8 }}
        />
        <IconButton
          size="small"
          onClick={handleAddRateCode}
          disabled={newRateCodeValue === '' || newRateCode === ''}
        >
          <AddCircleIcon
            color={
              newRateCodeValue === '' || newRateCode === '' ? 'disabled' : 'success'
            }
          />
        </IconButton>
      </Box>
    </Box>
  )
}

RateCodeTab.propTypes = {
  values: PropTypes.shape({
    smokeAutocharge: PropTypes.string,
    smokeAutochargeParams: PropTypes.shape({
      rateCodes: PropTypes.shape({}),
    }),
  }).isRequired,
  setValues: PropTypes.func.isRequired,
}
