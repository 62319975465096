import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Apartment, FileDownload } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'

import { MobileList, MobileListCardRow, SearchBox } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { downloadFile, useSmallScreen } from '@common/utils'
import { unitUrls } from '@rest/pages/Units'
import { AqiChip, List } from '@rest/UI/components'
import { useFlag } from '@rest/Utils'
import { formatCSVData } from '@rest/Utils/csv'

import DeviceStatusChip from '../PropertyDevices/DeviceStatusChip'

function PropertyUnitsEmptyState({ isHotel }) {
  return (
    <Stack
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Apartment sx={{ fontSize: 60, mb: 2 }} />
      No {`${isHotel ? 'rooms' : 'units'}`} found.
    </Stack>
  )
}

export default function PropertyUnits({ property }) {
  const {
    queryObject: { status: queryStatusFilter = 'ALL' },
    propertyUnitList,
    propertyUnitListRaw: { ordering = [] },
    propertyUnitListIsLoading,
    unitFilterStatuses,
    doPropertyUnitListSetPage,
    doPropertyUnitListSetOrdering,
    doPropertyUnitListSetSearch,
    doPropertyUnitListSetFilter,
    doPropertyUnitListExport,
    doUpdateUrl,
  } = useConnect(
    'selectQueryObject',
    'selectPropertyUnitList',
    'selectPropertyUnitListRaw',
    'selectPropertyUnitListIsLoading',
    'selectUnitFilterStatuses',
    'doPropertyUnitListSetPage',
    'doPropertyUnitListSetOrdering',
    'doPropertyUnitListSetSearch',
    'doPropertyUnitListSetFilter',
    'doPropertyUnitListExport',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  const isHotel = property.propertyType === 'HOTEL'
  const aqiEnabled = useFlag(['AQI'], property)

  const statusOptions = useMemo(
    () => [
      { id: 'ALL', label: 'All Statuses', custom: true },
      ...unitFilterStatuses
        .map((status) => ({
          id: status.value,
          label: status.name,
          custom: false,
        }))
        .reverse(),
      { id: 'WITH_DEVICES', label: 'Only with sensors', custom: true },
    ],
    [unitFilterStatuses],
  )

  const initialStatus = statusOptions.find((option) => option.id === queryStatusFilter)
    ? queryStatusFilter
    : 'ALL'

  const [statusFilter, setStatusFilter] = useState(initialStatus)

  useEffect(() => {
    doPropertyUnitListSetSearch('')
    doPropertyUnitListSetFilter(
      initialStatus === 'ALL' ? {} : { status: initialStatus },
    )
  }, [property])

  const handleExport = useCallback(async () => {
    const result = await doPropertyUnitListExport()
    const data = formatCSVData(result, {
      name: 'Room Name',
      groupName: 'Group Name',
      status: 'Status',
      unitSize: 'Room Size',
      floor: 'Floor',
      maxOccupancy: 'Occupancy',
      effectiveNoiseThresholdProfileName: 'Threshold',
      deviceCount: 'Sensors Count',
      ...(aqiEnabled ? { dailyAqi: 'AQI' } : {}),
    })
    downloadFile({
      data: [data],
      fileName: `${property.name}_room_export`,
      fileType: 'text/csv',
    })
  }, [property])

  const clickableTextStyle = {
    variant: 'body2',
    color: 'primary',
    sx: { fontWeight: 600, cursor: 'pointer' },
  }

  const buildStatusFilterValue = (option) => {
    switch (option) {
      case 'ALL':
        return {}
      case 'WITH_DEVICES':
        return {
          status: statusOptions
            .filter((opt) => !opt.custom && opt.id !== 'NO_DEVICE')
            .map((opt) => opt.id)
            .join(','),
        }
      default:
        return { status: option }
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => (
        <Typography
          onClick={() => doUpdateUrl(unitUrls.entity.replace(':id', row.id))}
          {...clickableTextStyle}
        >
          {row.name}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      sortable: true,
      renderCell: ({ row }) => <DeviceStatusChip status={row.status} />,
    },
    {
      field: 'groupName',
      headerName: 'Group',
      flex: 0.1,
      sortable: false,
    },
    {
      field: 'deviceCount',
      headerName: 'Sensors',
      flex: 0.1,
      sortable: false,
      type: 'number',
    },
  ]

  if (aqiEnabled) {
    columns.splice(2, 0, {
      field: 'dailyAqi',
      headerName: 'AQI',
      flex: 0.1,
      sortable: true,
      renderCell: ({ row }) => <AqiChip index={row.dailyAqi} />,
    })
  }

  const MobileListItem = useCallback(
    (row) => {
      const filteredColumns = columns.filter(
        (column) => !['name', 'status', 'dailyAqi'].includes(column.field),
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box display="flex" alignItems="center">
              <Typography
                {...clickableTextStyle}
                fontSize={14}
                onClick={() => doUpdateUrl(unitUrls.entity.replace(':id', row.id))}
              >
                {row.name}
              </Typography>
            </Box>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ '&&': { mt: 2, mb: 0.5 } }}
          >
            <DeviceStatusChip status={row.status} />
            {columns.find((column) => column.field === 'dailyAqi') && (
              <AqiChip index={row.dailyAqi} />
            )}
          </Box>
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        {!isSmallScreen && (
          <SearchBox
            title="Rooms"
            onSetSearch={doPropertyUnitListSetSearch}
            minLength={2}
          />
        )}

        <Box
          display="flex"
          width={isSmallScreen ? '100%' : null}
          alignItems="center"
          justifyContent={isSmallScreen ? 'space-between' : null}
        >
          <FormControl
            data-testid="status_filter"
            variant="standard"
            sx={{ m: 1, minWidth: 200 }}
          >
            <InputLabel>Status Filter</InputLabel>
            <Select
              options={statusOptions}
              label=""
              value={statusFilter}
              onChange={(e) => {
                const { value } = e.target
                setStatusFilter(value)
                doPropertyUnitListSetFilter(buildStatusFilterValue(value))
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isSmallScreen ? (
            <IconButton
              data-testid="export_rooms"
              color="primary"
              onClick={handleExport}
            >
              <FileDownload />
            </IconButton>
          ) : (
            <Button
              data-testid="export_rooms"
              variant="contained"
              sx={{ ml: 3 }}
              onClick={handleExport}
              startIcon={<FileDownload />}
            >
              Export
            </Button>
          )}
        </Box>
      </Stack>
      {isSmallScreen ? (
        <MobileList
          data-testid="rooms_list"
          title={isHotel ? 'Rooms' : 'Units'}
          rows={propertyUnitList.results}
          itemBuilder={MobileListItem}
          page={propertyUnitList?.current ?? 0}
          rowCount={propertyUnitList?.count ?? 0}
          pageChange={doPropertyUnitListSetPage}
          setSearch={doPropertyUnitListSetSearch}
          pageSizeOptions={[25]}
          slots={{ noRowsOverlay: () => PropertyUnitsEmptyState({ isHotel }) }}
          hideFooter={(propertyUnitList?.numPages ?? 0) <= 1}
          loading={propertyUnitListIsLoading}
        />
      ) : (
        <List
          autoHeight
          disableColumnMenu
          data-testid="rooms_list"
          noValuePlaceholder="--"
          columns={columns}
          rows={propertyUnitList.results}
          page={propertyUnitList?.current ?? 0}
          rowCount={propertyUnitList?.count ?? 0}
          pageChange={doPropertyUnitListSetPage}
          pageSizeOptions={[25]}
          sortChange={doPropertyUnitListSetOrdering}
          currentOrdering={ordering ?? []}
          slots={{ noRowsOverlay: () => PropertyUnitsEmptyState({ isHotel }) }}
          hideFooter={(propertyUnitList?.numPages ?? 0) <= 1}
          loading={propertyUnitListIsLoading}
        />
      )}
    </>
  )
}

PropertyUnits.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
    expandedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

PropertyUnitsEmptyState.propTypes = {
  isHotel: PropTypes.bool.isRequired,
}
