import PropTypes from 'prop-types'

import { Field, useFormikContext } from 'formik'

import { StaticSelect } from '@common/components/Selects'

export default function CommandField({
  availableCommands,
  nonValueCommands,
  commandValue,
  setCommandValue,
  sx,
}) {
  const form = useFormikContext()

  const { value: commandFieldValue } = form.getFieldProps('command')
  if (commandFieldValue !== commandValue) {
    setCommandValue(commandFieldValue)
  }

  return (
    <Field
      required
      component={StaticSelect}
      label="Command"
      name="command"
      variant="standard"
      options={availableCommands}
      onChange={(value) => {
        setCommandValue(value)
        form.setFieldValue('command', value)
        if (nonValueCommands.includes(value)) {
          form.setFieldValue('operation', null)
        }
      }}
      sx={sx}
    />
  )
}

CommandField.defaultProps = {
  commandValue: '',
}

CommandField.propTypes = {
  availableCommands: PropTypes.arrayOf(PropTypes.string).isRequired,
  nonValueCommands: PropTypes.arrayOf(PropTypes.string).isRequired,
  commandValue: PropTypes.string,
  setCommandValue: PropTypes.func.isRequired,
  sx: PropTypes.shape({}).isRequired,
}
