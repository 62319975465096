import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

function Pagination({ doInstallationListSetPage, results }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ pt: 3, pb: 3 }}>
      <Button
        onClick={() => doInstallationListSetPage(results.previous)}
        disabled={!results?.previous}
        sx={{ mr: 2, pt: 1 }}
      >
        Previous
      </Button>

      <Typography variant="h5">
        {results?.count > 0 ? (
          <span>
            Page {results.current} of {results.numPages} ({results.count} total)
          </span>
        ) : (
          <span>No Results</span>
        )}
      </Typography>

      <Button
        onClick={() => doInstallationListSetPage(results.next)}
        disabled={!results?.next}
        sx={{ ml: 2, pt: 1 }}
      >
        Next
      </Button>
    </Stack>
  )
}

Pagination.propTypes = {
  doInstallationListSetPage: PropTypes.func.isRequired,
  results: PropTypes.shape({
    current: PropTypes.number,
    count: PropTypes.number,
    numPages: PropTypes.number,
    previous: PropTypes.number,
    next: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
}

export default Pagination
