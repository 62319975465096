import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { difference, intersection, omit, pick } from 'ramda'

import { CloseOutlined, TuneOutlined } from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material'

import { pluralize } from 'inflection'

export default function Filter({ children, filterParams, setFilterParams, sx }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const childComponents = Array.isArray(children) ? children : [children]
  const filterNames = childComponents.map((child) => child.props.filterName)
  const changeableFilters = pick(filterNames, filterParams)
  const fixedFilterNames = Object.keys(filterParams).filter(
    (filter) => !filterNames.includes(filter),
  )
  const fixedFilters = pick(fixedFilterNames, filterParams)
  const [changedFilterState, setChangedFilterState] = useState({})

  const updatedFilterParams = useMemo(() => {
    let updatedParams = changeableFilters || {}
    const filterSet = Object.keys(changeableFilters)
    const changedFilterSet = Object.keys(changedFilterState)
    const toDelete = difference(filterSet, changedFilterSet)
    const toAdd = difference(changedFilterSet, filterSet)
    const toUpdate = intersection(changedFilterSet, filterSet)
    toDelete.forEach((filter) => {
      delete updatedParams[filter]
    })
    toAdd.forEach((filter) => {
      updatedParams = { ...updatedParams, [filter]: changedFilterState[filter] }
    })
    toUpdate.forEach((filter) => {
      updatedParams[filter] = changedFilterState[filter]
    })
    return { ...fixedFilters, ...updatedParams }
  }, [changedFilterState, filterParams])

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const closePopper = () => {
    setAnchorEl(null)
  }

  const uuidFiltersByEndpoint = {
    events: [],
    notifications: [],
  }

  const getFilterName = (el) => el.props?.filterName
  const getEndpointName = (el) => pluralize(getFilterName(el))

  const processPayload = (payload) => {
    let processedPayload = payload

    const isDynamicPayload = Array.isArray(payload)
    const isCheckboxPayload = typeof payload === 'object' && 'checked' in payload.target
    const isStaticPayload = typeof payload === 'object' && 'id' in payload

    if (isDynamicPayload) processedPayload = payload.join(',')
    else if (isStaticPayload) processedPayload = payload?.id
    else if (isCheckboxPayload) processedPayload = payload.target.checked

    return processedPayload
  }

  const getProps = (el) => {
    const isDynamicSelect = !!el.props?.primaryTextAttr
    const isStaticSelect = !!el.props?.options
    const isCheckbox = el.props?.role === 'checkbox'
    const elProps = { ...el.props, limitTags: 2 }

    if (isStaticSelect) {
      elProps.options = elProps.options.map((opt) => {
        if (typeof opt === 'string') return opt
        if (opt.value) return { id: opt.value, label: opt.name }
        return opt
      })
      elProps.value = elProps.options?.find((opt) =>
        typeof opt === 'string'
          ? opt === changedFilterState?.[getFilterName(el)]
          : opt.id === changedFilterState?.[getFilterName(el)],
      )
    } else if (isDynamicSelect) {
      elProps.endpoint = getEndpointName(el)
      elProps.value = filterParams?.[getFilterName(el)]
      uuidFiltersByEndpoint[getEndpointName(el)]?.forEach((filter) => {
        elProps.filters = Object.keys(filterParams).reduce(
          (acc, curr) =>
            curr.includes(filter) ? { ...acc, [curr]: filterParams[curr] } : acc,
          {},
        )
      })
    } else if (isCheckbox) {
      elProps.checked =
        filterParams?.[getFilterName(el)] || changedFilterState?.[getFilterName(el)]
    }
    return elProps
  }

  const handleChange = (el, payload) => {
    if (payload === null || payload === '' || payload === undefined) {
      setChangedFilterState((prevState) => omit([getFilterName(el)], prevState))
      return
    }

    if (el.props.role === 'checkbox' && payload.target.checked === false) {
      setChangedFilterState((prevState) => omit([getFilterName(el)], prevState))
      return
    }

    const updatedPayload = { [getFilterName(el)]: processPayload(payload) }

    setChangedFilterState((oldState) => ({ ...oldState, ...updatedPayload }))
  }

  const clearFilters = () => {
    if (Object.keys(changedFilterState).length) {
      setChangedFilterState({})
    }

    setFilterParams(fixedFilters)
  }

  const applyFilters = () => {
    setFilterParams(updatedFilterParams)
    closePopper()
  }

  const handleClose = () => {
    setChangedFilterState(filterParams)
    closePopper()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  return (
    <>
      <Badge
        color="primary"
        variant="dot"
        badgeContent={Object.keys(changedFilterState).length}
      >
        <Button
          aria-describedby={id}
          variant="outlined"
          startIcon={<TuneOutlined />}
          onClick={handleClick}
          sx={sx}
        >
          FILTER
        </Button>
      </Badge>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disablePortal
          disableScrollLock
          style={{ zIndex: 1000 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ width: 342, backgroundColor: 'white', p: 2, my: 1 }}>
              <Stack>
                <Stack direction="row" justifyContent="space-between" mb={1}>
                  <Typography variant="h6">Filters</Typography>
                  <IconButton onClick={handleClose}>
                    <CloseOutlined fontSize="small" />
                  </IconButton>
                </Stack>
                <Divider />
                <Typography
                  onClick={clearFilters}
                  color="secondary"
                  sx={{ fontSize: 12, mt: 1, cursor: 'pointer' }}
                >
                  Clear All
                </Typography>
                <Stack spacing={1} mt={2}>
                  {childComponents.map((element) => (
                    <element.type
                      key={element.props?.id || element.props?.filterName}
                      {...getProps(element)}
                      onChange={(value) => handleChange(element, value)}
                      size="small"
                    />
                  ))}
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    color="cancel"
                    sx={{ ':hover': { bgcolor: 'grey.400' } }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" fullWidth onClick={applyFilters}>
                    Apply
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popover>
      </div>
    </>
  )
}

Filter.defaultProps = {
  sx: {},
}

Filter.propTypes = {
  sx: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  filterParams: PropTypes.shape().isRequired,
  setFilterParams: PropTypes.func.isRequired,
}
