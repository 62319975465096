import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { FileDownload } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { parseApiErrors } from '@common/utils'

export default function ExportMetrics({ requestParams, interval, ...props }) {
  const {
    isAtLeastAdmin,
    smokeMetrics,
    smokeMetricsIsLoading,
    smokeMetricsExportIsLoading,
    doExportSmokeMetrics,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectSmokeMetrics',
    'selectSmokeMetricsIsLoading',
    'selectSmokeMetricsExportIsLoading',
    'doExportSmokeMetrics',
    'doShowSnackbar',
  )

  const payload = { ...requestParams }
  delete payload.metrics // export all available metrics

  return (
    isAtLeastAdmin && (
      <Tooltip title="Export CSV">
        <div>
          <IconButton
            disabled={
              smokeMetricsExportIsLoading || smokeMetricsIsLoading || !smokeMetrics
            }
            onClick={async () => {
              try {
                await doExportSmokeMetrics(payload, interval)
              } catch (err) {
                const parsedError = parseApiErrors(err?.response)
                doShowSnackbar(parsedError, 'error')
              }
            }}
            {...props}
          >
            <FileDownload />
          </IconButton>
        </div>
      </Tooltip>
    )
  )
}

ExportMetrics.propTypes = {
  interval: PropTypes.number.isRequired,
  requestParams: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}
