import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Field } from 'formik'
import { capitalize } from 'inflection'
import * as yup from 'yup'

import { FormikDynamicSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

export default function MoveEntityModal({ open, onClose, entity, entityData }) {
  const { doAccountMove, doPropertyMove, doUnitMove } = useConnect(
    'doAccountMove',
    'doPropertyMove',
    'doUnitMove',
  )

  const validationSchema = yup.object().shape({
    parentId: yup.string().uuid().required(),
  })

  const initialValues = { id: '', parentId: '' }

  if (entityData) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = entityData[field] ?? ''
    })
  }

  const fieldData = useMemo(() => {
    switch (entity) {
      case 'account':
        return {
          label: 'Organization',
          endpoint: 'organizations',
          moveToField: 'organization',
        }
      case 'property':
        return {
          label: 'Account',
          endpoint: 'accounts',
          moveToField: 'account',
          secondaryTextAttr: 'organizationName',
        }
      case 'unit':
        return {
          label: 'Property',
          endpoint: 'properties',
          moveToField: 'property',
          secondaryTextAttr: 'accountName',
        }
      default:
        throw Error(`${entity} is not supported!`)
    }
  }, [entity])

  const saveFn = useMemo(() => {
    switch (entity) {
      case 'account':
        return doAccountMove
      case 'property':
        return doPropertyMove
      case 'unit':
        return doUnitMove
      default:
        throw Error(`${entity} is not supported!`)
    }
  }, [entity])

  return (
    <FormDialog
      label={`Move ${capitalize(entity)}`}
      open={open}
      onSave={(data) => {
        const payload = {
          id: data.id,
          payload: { [fieldData.moveToField]: data.parentId },
        }
        return saveFn(payload)
      }}
      onClose={onClose}
      successMessage={`${capitalize(entity)} has been moved successfully`}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Field
        disableClearable
        component={FormikDynamicSelect}
        name="parentId"
        label={fieldData.label}
        endpoint={fieldData.endpoint}
        secondaryTextAttr={fieldData.secondaryTextAttr}
        sx={{ flex: 1 }}
      />
    </FormDialog>
  )
}

MoveEntityModal.defaultProps = {
  entityData: undefined,
}

MoveEntityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  entity: PropTypes.oneOf(['account', 'property', 'unit']).isRequired,
  entityData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
  }),
}
