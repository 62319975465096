import { memo } from 'react'

import PropTypes from 'prop-types'

import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { ToggleButtonWithTooltip } from '@common/components'

const AggregationSelector = memo(
  ({ selectedInterval, setSelectedInterval, availableIntervals, disabled }) => (
    <Stack direction="column" spacing={1} ml={1}>
      <Typography variant="body1">Aggregation</Typography>
      <ToggleButtonGroup
        color="primary"
        value={selectedInterval}
        exclusive
        onChange={(_, selectedValue) => {
          if (selectedValue !== null) {
            setSelectedInterval(selectedValue)
          }
        }}
        disabled={disabled}
      >
        {Object.keys(availableIntervals).map((key) => {
          const interval = availableIntervals[key]
          return (
            <ToggleButtonWithTooltip
              key={key}
              value={interval}
              tooltip={interval.tooltip}
              disabled={selectedInterval === interval}
              sx={{ py: 0.8 }}
            >
              <div>{interval.label}</div>
            </ToggleButtonWithTooltip>
          )
        })}
      </ToggleButtonGroup>
    </Stack>
  ),
)

AggregationSelector.propTypes = {
  selectedInterval: PropTypes.shape({
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  setSelectedInterval: PropTypes.func.isRequired,
  availableIntervals: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default AggregationSelector
