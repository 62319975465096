import './AdjustmentsPieChart.css'

import { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Stack, Typography } from '@mui/material'

import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import { Pie } from '@visx/shape'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { humanize } from 'inflection'

import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'

const dynamicColors = [
  '#7F66B3',
  '#669CC3',
  '#CF8473',
  '#344B78',
  '#547E7C',
  '#C55A86',
  '#F5B961',
  '#B8B8B8',
]

const legendGlyphSize = 15

export default function AdjustmentsPieChart({ width, height, margin, data, sx }) {
  const { smokeAdjustmentReasons } = useConnect('selectSmokeAdjustmentReasons')

  const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

  const finalMargin = margin ?? defaultMargin

  const innerWidth = width - finalMargin.left - finalMargin.right
  const innerHeight = height - finalMargin.top - finalMargin.bottom

  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const top = centerY + finalMargin.top
  const left = centerX + finalMargin.left

  const radius = Math.min(innerWidth, innerHeight) / 2

  const getCount = useCallback((d) => d.count, [])

  const ordinalScale = scaleOrdinal({
    domain: data.map((d) => d.reason),
    range: dynamicColors,
  })

  const {
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  })

  const handleTooltipChage = useCallback(
    (event, arcData) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 }
      showTooltip({
        tooltipData: arcData,
        tooltipLeft: x,
        tooltipTop: y,
      })
    },
    [showTooltip],
  )

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  const getFormattedReason = useCallback(
    (reason) => reasonsById[reason] || humanize(reason),
    [reasonsById],
  )

  return (
    <Stack direction="row" sx={{ ...sx }}>
      <svg width={width} height={height} ref={containerRef}>
        <Group top={top} left={left}>
          <Pie data={data} pieValue={getCount} outerRadius={radius}>
            {(pie) =>
              pie.arcs.map((arc) => {
                const { reason, count } = arc.data

                const [centroidX, centroidY] = pie.path.centroid(arc)
                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.25

                const arcPath = pie.path(arc)
                const arcFill = ordinalScale(reason)

                return (
                  <g
                    key={reason}
                    onMouseEnter={(event) => handleTooltipChage(event, arc.data)}
                    onMouseMove={(event) => handleTooltipChage(event, arc.data)}
                    onMouseLeave={() => hideTooltip()}
                  >
                    <path d={arcPath} fill={arcFill} />
                    {hasSpaceForLabel && (
                      <text
                        x={centroidX}
                        y={centroidY}
                        fill="#ffffff"
                        fontSize={14}
                        textAnchor="middle"
                        pointerEvents="none"
                      >
                        {count}
                      </text>
                    )}
                  </g>
                )
              })
            }
          </Pie>
        </Group>
        {tooltipData && tooltipOpen && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop}>
            <Typography variant="caption">
              {getFormattedReason(tooltipData.reason)} - {tooltipData.count}
            </Typography>
          </TooltipInPortal>
        )}
      </svg>
      <Box ml={4}>
        <LegendOrdinal
          scale={ordinalScale}
          direction="column"
          labelFormat={getFormattedReason}
        >
          {(labels) =>
            labels.map((label) => {
              const count = data.find((d) => d.reason === label.datum)?.count
              return (
                <LegendItem key={label.datum}>
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 8px">
                    {`${label.text} (${count})`}
                  </LegendLabel>
                </LegendItem>
              )
            })
          }
        </LegendOrdinal>
      </Box>
    </Stack>
  )
}

AdjustmentsPieChart.defaultProps = {
  width: 300,
  height: 300,
  margin: undefined,
  sx: {},
}

AdjustmentsPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  sx: PropTypes.shape({}),
}
