import PropTypes from 'prop-types'

import { CalendarTodayOutlined } from '@mui/icons-material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'

import { DateTime } from 'luxon'

export default function DateTimePickerCell({
  label,
  field,
  form,
  value,
  onChange,
  error,
  placeholder,
  timezone,
  isNew,
  fullWidth,
  size,
  sx,
  preallocateHelperTextSpace,
  ...rest
}) {
  const isFormik = !!form && !!field
  const currentValue = isFormik ? field.value : value
  const errorMessage = isFormik ? form.errors[`${field.name}`] || '' : error.trim()

  const handleChange = (data) => {
    if (isFormik) {
      form.setFieldValue(field.name, data)
    } else {
      onChange(data)
    }
  }

  return (
    <DateTimePicker
      disableMaskedInput
      label={label}
      // format="DD t" // https://github.com/mui/mui-x/issues/7615
      format="DD hh:mm a"
      timezone={timezone}
      slots={{ openPickerIcon: CalendarTodayOutlined }}
      minDate={DateTime.now()}
      value={
        typeof currentValue === 'string' ? DateTime.fromISO(currentValue) : currentValue
      }
      onChange={handleChange}
      slotProps={{
        tabs: { hidden: false },
        textField: {
          placeholder,
          fullWidth,
          size,
          error: errorMessage.length > 0,
          helperText: preallocateHelperTextSpace ? errorMessage || ' ' : errorMessage,
          sx: {
            ...(isNew &&
              !errorMessage.length &&
              !rest?.isSmallScreen && {
                border: '1px solid black',
                borderRadius: '4px',
              }),
            ...sx,
          },
        },
      }}
    />
  )
}

DateTimePickerCell.defaultProps = {
  label: undefined,
  field: null,
  form: null,
  value: null,
  onChange: () => {},
  error: '',
  placeholder: '',
  timezone: undefined,
  isNew: false,
  fullWidth: false,
  size: 'small',
  sx: {},
  preallocateHelperTextSpace: false,
}

DateTimePickerCell.propTypes = {
  label: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  value: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  timezone: PropTypes.string,
  isNew: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
  preallocateHelperTextSpace: PropTypes.bool,
}
