import PropTypes from 'prop-types'

import { Grid2, List, ListItemButton, ListItemText, Typography } from '@mui/material'

import configureUrls from './urls'

export const CONFIGURE_NAV_THRESHOLDS = 'thresholds'
export const CONFIGURE_NAV_MESSAGING = 'messaging'

const THRESHOLDS_LABEL = 'Threshold Profiles'
const MESSAGING_LABEL = 'Guest Messaging'

export const navOptions = [
  {
    label: THRESHOLDS_LABEL,
    value: CONFIGURE_NAV_THRESHOLDS,
    href: configureUrls.thresholdsList,
  },
  {
    label: MESSAGING_LABEL,
    value: CONFIGURE_NAV_MESSAGING,
    href: configureUrls.messaging,
  },
]
export default function ConfigureNav({ selected }) {
  return (
    <Grid2 size={{ xs: 0, md: 2.5 }}>
      <List sx={{ '.Mui-selected': { borderRadius: '7px' } }}>
        {navOptions.map((opt) => (
          <ListItemButton
            key={opt.value}
            href={opt.href}
            disableRipple
            selected={selected === opt.value}
          >
            <ListItemText>
              <Typography variant="subtitle2">{opt.label}</Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </Grid2>
  )
}

ConfigureNav.propTypes = {
  selected: PropTypes.string.isRequired,
}
