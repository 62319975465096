import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, useTheme } from '@mui/material'

import { AxisBottom } from '@visx/axis'
import { Brush } from '@visx/brush'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { PatternLines } from '@visx/pattern'
import { ParentSize } from '@visx/responsive'
import { scaleLinear, scaleTime } from '@visx/scale'
import { DateTime } from 'luxon'

import { generateFixedBottomTicks, tzAwareTickFormat } from '../chartUtils'
import BrushHandle from './BrushHandle'

export default function ChartMinimap({
  show,
  height,
  data,
  selectedArea,
  onChange,
  onMove,
}) {
  if (!data) {
    return null
  }

  const indexedData = useMemo(
    () => data.map((item, index) => ({ ...item, index })),
    [data],
  )

  const [innerSelection, setInnerSelection] = useState({ selecting: false })

  const theme = useTheme()

  const brushRef = useRef(null)

  const componentHeight = height + 24

  // scales
  const time = (d) => d.time
  const index = (d) => d.index
  const getDomain = useCallback(
    (accessor) => {
      const filteredValues = indexedData.map(accessor).filter((value) => value !== null)
      return [Math.min(...filteredValues), Math.max(...filteredValues)]
    },
    [indexedData],
  )

  const [tMin, tMax] = getDomain(time)
  const [lMin, lMax] = getDomain(index)

  const validDateTimeDomain = !isNaN(tMin) && !isNaN(tMax)
  const linearScaleDomain =
    !isNaN(lMin) && !isNaN(lMax) ? [lMin, lMax] : [0, indexedData.length - 1]

  const brushTimeScale = useMemo(
    () =>
      validDateTimeDomain
        ? scaleTime({ domain: [tMin, tMax] })
        : scaleLinear({ domain: linearScaleDomain }),
    [tMin, tMax, lMin, lMax],
  )

  const yScale = scaleLinear({
    domain: [tMin, tMax],
    range: [height, 0],
  })

  const zoneName = useMemo(() => data.find((d) => d.time)?.time.zoneName, [data])

  const handleMouseUp = useCallback(() => {
    const { x0, x1 } = innerSelection
    if (x0 !== undefined && x1 !== undefined) {
      onChange({
        start: validDateTimeDomain ? DateTime.fromMillis(x0).setZone(zoneName) : x0,
        end: validDateTimeDomain ? DateTime.fromMillis(x1).setZone(zoneName) : x1,
        isDateTimeRange: validDateTimeDomain,
      })
    }
    setInnerSelection({ selecting: false })
  }, [innerSelection])

  const handleMouseMove = useCallback(
    (event) => {
      if (innerSelection.selecting) {
        event.preventDefault()
      }
    },
    [innerSelection],
  )

  useEffect(() => {
    globalThis.addEventListener('mousemove', handleMouseMove)
    globalThis.addEventListener('mouseup', handleMouseUp)
    return () => {
      globalThis.removeEventListener('mousemove', handleMouseMove)
      globalThis.removeEventListener('mouseup', handleMouseUp)
    }
  }, [innerSelection.x0, innerSelection.x1])

  const handleUpdateBrush = (position) => {
    if (brushRef?.current) {
      const updater = (prevBrush) => {
        const newExtent = brushRef.current.getExtent(position.start, position.end)

        const newState = {
          ...prevBrush,
          start: { y: newExtent.y0, x: newExtent.x0 },
          end: { y: newExtent.y1, x: newExtent.x1 },
          extent: newExtent,
        }

        return newState
      }
      brushRef.current.updateBrush(updater)
    }
  }

  useEffect(() => {
    if (innerSelection.selecting) {
      return
    }

    if (selectedArea && brushTimeScale.range) {
      handleUpdateBrush({
        start: { x: brushTimeScale(selectedArea.start) },
        end: { x: brushTimeScale(selectedArea.end) },
      })
    } else {
      brushRef?.current?.reset()
    }
  }, [selectedArea, brushTimeScale])

  const patternLinesId = `brush_selected_${Math.random()}`

  return (
    <Box
      style={{
        width: '100%',
        position: 'relative',
        pointerEvents: onChange ? 'auto' : 'none',
      }}
    >
      <ParentSize>
        {({ width }) => {
          const limitedWidth = Math.max(0, width)
          brushTimeScale.range([0, limitedWidth])

          const bottomTickValues = generateFixedBottomTicks({
            graphData: indexedData,
            count: width * 0.015,
          })

          return (
            <Box
              sx={{
                transitionProperty: 'height',
                transitionDuration: '300ms',
                overflow: 'hidden',
                height: limitedWidth && show ? componentHeight : 0,
              }}
            >
              <svg
                height={componentHeight}
                width={limitedWidth}
                onMouseDown={() => {
                  if (onChange) {
                    setInnerSelection({ selecting: true })
                  }
                }}
              >
                <Group>
                  <rect
                    x={0}
                    width={limitedWidth}
                    height={height}
                    fill={`url(#${patternLinesId})`}
                    opacity={0.2}
                  />
                  <AxisBottom
                    scale={brushTimeScale}
                    curve={curveLinear}
                    top={height}
                    orientation="bottom"
                    strokeWidth={0.99}
                    stroke={theme.palette.text.primary}
                    tickStroke="none"
                    tickValues={bottomTickValues}
                    tickFormat={(value, i) =>
                      validDateTimeDomain
                        ? tzAwareTickFormat(
                            { time: value, index: i, tickValues: bottomTickValues },
                            brushTimeScale,
                            zoneName,
                          )
                        : value
                    }
                    tickLabelProps={() => ({
                      fontSize: 12,
                      fontFamily: 'sans-serif',
                      textAnchor: 'middle',
                      fill: theme.palette.text.primary,
                    })}
                  />
                  <PatternLines
                    id={patternLinesId}
                    height={6}
                    width={6}
                    stroke={theme.palette.background.chartMinimap}
                    strokeWidth={1}
                    background={theme.palette.background.chartMinimap}
                    orientation={['diagonal']}
                  />
                  <Brush
                    useWindowMoveEvents
                    disableDraggingSelection={!onChange}
                    width={limitedWidth}
                    height={height}
                    yScale={yScale}
                    xScale={brushTimeScale}
                    resizeTriggerAreas={onChange ? ['left', 'right'] : []}
                    brushDirection="horizontal"
                    innerRef={brushRef}
                    onChange={(event) => {
                      if (event && innerSelection.selecting) {
                        const { x0, x1 } = event
                        setInnerSelection((prev) => ({ ...prev, x0, x1 }))
                        onMove({
                          start: DateTime.fromMillis(x0).setZone(zoneName),
                          end: DateTime.fromMillis(x1).setZone(zoneName),
                        })
                      }
                    }}
                    renderBrushHandle={({ x, height: h, isBrushActive }) => (
                      <BrushHandle x={x} height={h} isBrushActive={isBrushActive} />
                    )}
                    selectedBoxStyle={{
                      fill: `url(#${patternLinesId})`,
                      stroke: theme.palette.text.secondary,
                      strokeWidth: 1,
                    }}
                  />
                </Group>
              </svg>
            </Box>
          )
        }}
      </ParentSize>
    </Box>
  )
}

ChartMinimap.defaultProps = {
  height: 70,
  show: true,
  selectedArea: null,
  onChange: undefined,
  onMove: undefined,
}

ChartMinimap.propTypes = {
  height: PropTypes.number,
  show: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({ time: PropTypes.shape({}) })).isRequired,
  selectedArea: PropTypes.shape({
    start: PropTypes.shape({}).isRequired,
    end: PropTypes.shape({}).isRequired,
  }),
  onChange: PropTypes.func,
  onMove: PropTypes.func,
}
