import PropTypes from 'prop-types'

import { Box, Button, Popover } from '@mui/material'

export default function BulkPopover({ items, open, onClose, anchorEl, anchorOrigin }) {
  const generateKey = (label) =>
    `${label}_${Number(new Date().getTime()) / Math.random()}`

  return (
    <Popover
      id={generateKey('bulk_popover')}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {items.map((item) => (
          <Button key={generateKey(item.label)} onClick={item.handler}>
            {item.label}
          </Button>
        ))}
      </Box>
    </Popover>
  )
}

BulkPopover.defaultProps = {
  anchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
}

BulkPopover.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  ).isRequired,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
}
