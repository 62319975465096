import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { Group } from '@visx/group'

const handleSize = 2
const color = 'black'
const opacity = 1

export default function AdjustableArea({
  id,
  width,
  height,
  offsetLeft,
  offsetTop,
  scale,
  externalAreasState,
}) {
  const xMin = offsetLeft
  const xMax = width + offsetLeft
  const minGap = handleSize * 3
  const sliderActionableWidth = 12

  const [isDragging, setIsDragging] = useState(false)
  const [resizingSide, setResizingSide] = useState(null)

  const [areas, setAreas] =
    externalAreasState ??
    useState([{ id, x0i: scale.invert(xMin), x1i: scale.invert(xMax) }])

  const handleAreaUpdate = (arr, itemBuilder) => {
    const updatedArr = [...arr]
    const index = arr.findIndex((item) => item.id === id)

    if (index !== -1) {
      updatedArr.splice(index, 1, { id, ...itemBuilder(updatedArr[index]) })
    }
    return updatedArr
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    setResizingSide(null)
  }

  const handleMouseDown = () => {
    setIsDragging(true)
  }

  const handleResize = (event) => {
    if (!resizingSide) return

    event.preventDefault()
    switch (resizingSide) {
      case 'right': {
        setAreas((prev) =>
          handleAreaUpdate(prev, (prevItem) => ({
            x0i: prevItem.x0i,
            x1i: scale.invert(
              Math.max(
                scale(prevItem.x0i) + minGap,
                Math.min(xMax, scale(prevItem.x1i) + event.movementX),
              ),
            ),
          })),
        )
        break
      }
      case 'left': {
        setAreas((prev) =>
          handleAreaUpdate(prev, (prevItem) => ({
            x0i: scale.invert(
              Math.min(
                scale(prevItem.x1i) - minGap,
                Math.max(xMin, scale(prevItem.x0i) + event.movementX),
              ),
            ),
            x1i: prevItem.x1i,
          })),
        )
        break
      }
      default:
        throw Error(`Unsupported resizing side: ${resizingSide}`)
    }
  }

  const handleDrag = (event) => {
    if (!isDragging || event.movementX === 0) return

    event.preventDefault()
    const direction = event.movementX > 0 ? 'right' : 'left'
    switch (direction) {
      case 'left': {
        setAreas((prev) =>
          handleAreaUpdate(prev, (prevItem) => ({
            x0i: scale.invert(Math.max(xMin, scale(prevItem.x0i) + event.movementX)),
            x1i: scale.invert(
              scale(prevItem.x0i) > xMin + handleSize
                ? scale(prevItem.x1i) + event.movementX
                : scale(prevItem.x1i),
            ),
          })),
        )
        break
      }
      case 'right': {
        setAreas((prev) =>
          handleAreaUpdate(prev, (prevItem) => ({
            x0i: scale.invert(
              scale(prevItem.x1i) < xMax
                ? Math.max(xMin + handleSize, scale(prevItem.x0i) + event.movementX)
                : scale(prevItem.x0i),
            ),
            x1i: scale.invert(Math.min(xMax, scale(prevItem.x1i) + event.movementX)),
          })),
        )
        break
      }
      default:
        throw Error(`Unsupported direction: ${direction}`)
    }
  }

  useEffect(() => {
    const area = areas.find((item) => item.id === id)
    if (area && Object.keys(area).length < 2) {
      setAreas((prev) =>
        handleAreaUpdate(prev, () => ({
          id: area.id,
          x0i: scale.invert(xMin),
          x1i: scale.invert(xMax),
        })),
      )
    }
    if (area && area.initial) {
      setAreas((prev) =>
        handleAreaUpdate(prev, () => ({
          id: area.id,
          x0i: scale.invert(Math.min(xMax - minGap, Math.max(xMin, scale(area.start)))),
          x1i: scale.invert(Math.min(xMax, Math.max(xMin + minGap, scale(area.end)))),
        })),
      )
    }
  }, [areas])

  useEffect(() => {
    globalThis.addEventListener('mouseup', handleMouseUp)
    globalThis.addEventListener('mousemove', handleResize)
    globalThis.addEventListener('mousemove', handleDrag)

    return () => {
      globalThis.removeEventListener('mouseup', handleMouseUp)
      globalThis.removeEventListener('mousemove', handleResize)
      globalThis.removeEventListener('mousemove', handleDrag)
    }
  }, [resizingSide, isDragging])

  const area = areas?.find((item) => item.id === id)
  if (!area || area.x0i === undefined || area.x1i === undefined) {
    return null
  }

  const { x0i, x1i } = area
  const x0 = scale(x0i)
  const x1 = scale(x1i)

  return (
    <Group key={id} top={offsetTop} width={width} height={height}>
      <rect
        x={x0}
        width={x1 - x0}
        height={height}
        fill="black"
        opacity={0.1}
        cursor="move"
        onMouseDown={handleMouseDown}
      />
      <rect x={x0} width={handleSize} height={height} fill={color} opacity={opacity} />
      <rect x={x1} width={handleSize} height={height} fill={color} opacity={opacity} />
      <rect
        x={x0 - sliderActionableWidth / 2}
        width={sliderActionableWidth}
        height={height}
        fill="transparent"
        cursor="ew-resize"
        onMouseDown={() => setResizingSide('left')}
      />
      <rect
        x={x1 - sliderActionableWidth / 2}
        width={sliderActionableWidth}
        height={height}
        fill="transparent"
        cursor="ew-resize"
        onMouseDown={() => setResizingSide('right')}
      />
      {areas.length > 1 && (
        <foreignObject
          x={Math.max(0, x1 - Math.min(46, x1 - x0 + 6))}
          y="2%"
          width={`${Math.max(0, Math.min(40, x1 - x0))}px`}
          height={`${Math.max(0, Math.min(40, x1 - x0))}px`}
        >
          <IconButton
            sx={{ opacity: 0.6, fontSize: Math.min(40, x1 - x0) }}
            onClick={() => {
              setAreas((prev) => {
                const updatedAreas = [...prev]
                const indexToRemove = prev.findIndex((item) => item.id === id)
                if (indexToRemove !== -1) {
                  updatedAreas.splice(indexToRemove, 1)
                }
                return updatedAreas
              })
            }}
          >
            <Delete />
          </IconButton>
        </foreignObject>
      )}
      <Group left={x0 + handleSize / 2} top={0} height={height}>
        <polygon points="0,5 4,0 -4,0" fill={color} stroke={color} opacity={opacity} />
      </Group>
      <Group left={x1 + handleSize / 2} top={0} height={height}>
        <polygon points="0,5 4,0 -4,0" fill={color} stroke={color} opacity={opacity} />
      </Group>
    </Group>
  )
}

AdjustableArea.defaultProps = {
  offsetLeft: 0,
  offsetTop: 0,
  externalAreasState: undefined,
}

AdjustableArea.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  scale: PropTypes.func.isRequired,
  externalAreasState: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        x01: PropTypes.number.isRequired,
        x1i: PropTypes.number.isRequired,
      }),
      PropTypes.func,
    ]),
  ),
}
