import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'

import { ClickableCell, MobileList, MobileListDefaultCard } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import {
  getApiFetch,
  getEntityFromUrl,
  isAbortError,
  parseApiErrors,
  useSmallScreen,
} from '@common/utils'
import accUrls from '@portal/pages/Accounts/urls'
import UserMembershipForm from '@portal/pages/Memberships/MembershipForm'
import orgUrls from '@portal/pages/Organizations/urls'
import propUrls from '@portal/pages/Properties/urls'
import userUrls from '@portal/pages/Users/urls'
import { BooleanCell, CheckboxCell } from '@portal/UI/components/cells'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import MembershipForm from '@portal/UI/components/MembershipForm'
import boolOptions from '@portal/Utils/constants'

import { initialParams } from './membershipListBundle'

export default function MembershipList({ renderAsTab, userId }) {
  const [membershipFormOpen, setMembershipFormOpen] = useState(false)
  const [userMembershipFormOpen, setUserMembershipFormOpen] = useState(false)
  const [currentMembership, setCurrentMembership] = useState(null)
  const [currentMembershipList, setCurrentMembershipList] = useState([])
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [deleteError, setDeleteError] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    membershipList,
    membershipListApiParams,
    membershipListIsLoading,
    doMembershipDelete,
    doShowSnackbar,
    doMembershipListSetFilter,
    doMembershipListClearParams,
    doMembershipListSetPage,
    doMembershipListSetPageSize,
    doMembershipListSetSearch,
    doMembershipListSetOrdering,
    doMarkMembershipListAsOutdated,
  } = useConnect(
    'selectMembershipList',
    'selectMembershipListApiParams',
    'selectMembershipListIsLoading',
    'doMembershipDelete',
    'doShowSnackbar',
    'doMembershipListSetFilter',
    'doMembershipListClearParams',
    'doMembershipListSetPage',
    'doMembershipListSetPageSize',
    'doMembershipListSetSearch',
    'doMembershipListSetOrdering',
    'doMarkMembershipListAsOutdated',
  )

  const store = useReduxBundlerStore()
  const { entityType, entityId } = getEntityFromUrl(store)
  const apiFetch = getApiFetch(store)

  const handleCheckboxClick = useCallback(async ({ row, field, value }) => {
    try {
      await apiFetch(
        '/memberships/',
        { ...row, [field]: value },
        { method: 'PUT', cancelationPrefix: 'memberships_page' },
      )
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [])

  const membershipColumns = [
    {
      field: 'user',
      headerName: 'User',
      sortable: false,
      isMainCell: true,
      isMainCellActive: (row) => row.user,
      valueGetter: (_, row) => row.userName,
      flex: 0.3,
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      sortable: false,
      flex: 0.3,
    },
    {
      field: 'roleName',
      headerName: 'Role',
      sortable: false,
      flex: 0.3,
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 0.15,
      type: 'boolean',
      renderCell: BooleanCell,
      headerAlign: 'center',
    },
    ...(entityType === 'property'
      ? [
          {
            field: 'outageAlert',
            headerName: 'Outage Alert',
            flex: 0.15,
            type: 'boolean',
            renderCell: ({ row, field }) => (
              <CheckboxCell
                row={row}
                field={field}
                onChange={async (value) => handleCheckboxClick({ row, field, value })}
              />
            ),
            headerAlign: 'center',
          },
          {
            field: 'noiseAlert',
            headerName: 'Noise Alert',
            flex: 0.15,
            type: 'boolean',
            renderCell: ({ row, field }) => (
              <CheckboxCell
                row={row}
                field={field}
                onChange={async (value) => handleCheckboxClick({ row, field, value })}
              />
            ),
            headerAlign: 'center',
          },
          {
            field: 'smokeAlert',
            headerName: 'Smoke Alert',
            flex: 0.15,
            type: 'boolean',
            renderCell: ({ row, field }) => (
              <CheckboxCell
                row={row}
                field={field}
                onChange={async (value) => handleCheckboxClick({ row, field, value })}
              />
            ),
            headerAlign: 'center',
          },
          {
            field: 'smokeDigest',
            headerName: 'Smoke Digest',
            flex: 0.15,
            type: 'boolean',
            renderCell: ({ row, field }) => (
              <CheckboxCell
                row={row}
                field={field}
                onChange={async (value) => handleCheckboxClick({ row, field, value })}
              />
            ),
            headerAlign: 'center',
          },
        ]
      : []),
  ]

  const userMembershipColumns = [
    {
      flex: 0.25,
      sortable: false,
      field: 'roleName',
      headerName: 'Role',
    },
    {
      flex: 0.25,
      sortable: false,
      field: 'active',
      headerName: 'Active',
      headerAlign: 'center',
      renderCell: BooleanCell,
    },
    {
      flex: 0.25,
      sortable: false,
      field: 'organization',
      headerName: 'Organization',
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.organizationName}
          url={orgUrls.entity.replace(':id', row.organization)}
        />
      ),
    },
    {
      flex: 0.25,
      sortable: false,
      field: 'account',
      headerName: 'Account',
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.accountName}
          url={accUrls.entity.replace(':id', row.account)}
        />
      ),
    },
    {
      flex: 0.25,
      sortable: false,
      field: 'property',
      headerName: 'Property',
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.propertyName}
          url={propUrls.entity.replace(':id', row.property)}
        />
      ),
    },
  ]

  const MobileItemHeader = useCallback(
    ({ row }) => {
      const StatusIcon = row.active ? CheckCircleOutline : CancelOutlined
      return (
        <Box display="flex" alignItems="center" mb={1} sx={{ '&&': { mb: 1 } }}>
          {userId ? (
            <Typography variant="caption" fontWeight="bold">
              {userId ? row.roleName : row.userName}
            </Typography>
          ) : (
            <Link href={userUrls.entity.replace(':id', row.user)}>
              <Typography variant="caption" fontWeight="bold">
                {userId ? row.roleName : row.userName}
              </Typography>{' '}
            </Link>
          )}

          <StatusIcon
            color={row.active ? 'success' : 'warning'}
            sx={{ fontSize: 16, ml: 0.5 }}
          />
        </Box>
      )
    },
    [userId],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns: userId ? userMembershipColumns : membershipColumns,
        ignoredFields: ['active', ...(userId ? ['roleName'] : ['user'])],
        multiRowFields: [
          ['propertyCount', 'unitCount'],
          ['deviceCount', 'score'],
        ],
        header: <MobileItemHeader row={row} />,
      }),
    [userId, membershipColumns, userMembershipColumns],
  )

  const rows = currentMembershipList.map((membership) => ({
    id: membership.id,
    active: !membership.deletedOn,
    role: membership.role,
    roleName: membership.roleName,
    organization: membership.organization,
    organizationName: membership.organizationName,
    account: membership.account,
    accountName: membership.accountName,
    property: membership.property,
    propertyName: membership.propertyName,
    smokeAlert: membership.smokeAlert,
    smokeDigest: membership.smokeDigest,
    noiseAlert: membership.noiseAlert,
    outageAlert: membership.outageAlert,
    user: membership.user,
    userName: membership.userName,
    userEmail: membership.userEmail,
  }))

  const openMembershipForm = () =>
    userId ? setUserMembershipFormOpen(true) : setMembershipFormOpen(true)

  const fetchMemberships = (props) => {
    doMembershipListClearParams()
    doMembershipListSetFilter({ ...initialParams, ...props })
  }

  const onUpdate = (row) => {
    setCurrentMembership(row)
    openMembershipForm()
  }

  const onDelete = (row) => {
    setCurrentMembership(row)
    setDeleteConfirmOpen(true)
  }

  const handleMembershipDelete = async () => {
    try {
      await doMembershipDelete(currentMembership)
      doShowSnackbar('Successfully deleted Membership')
      setDeleteConfirmOpen(false)
      fetchMemberships(membershipListApiParams)
    } catch (error) {
      setDeleteError(error)
    }
  }

  const onMembershipFormClose = () => {
    setCurrentMembership(null)
    setUserMembershipFormOpen(false)
    setMembershipFormOpen(false)
  }

  const onDeleteFormClose = () => {
    setCurrentMembership(null)
    setDeleteConfirmOpen(false)
  }

  useEffect(() => {
    fetchMemberships()
  }, [])

  useEffect(() => {
    setCurrentMembershipList(membershipList?.results)
  }, [membershipListApiParams])

  const listActions = {
    create: openMembershipForm,
    update: onUpdate,
    delete: onDelete,
  }

  return (
    <>
      <UserMembershipForm
        open={userMembershipFormOpen}
        onClose={onMembershipFormClose}
        instance={{ ...currentMembership, user: userId }}
      />
      <MembershipForm
        open={membershipFormOpen}
        onClose={(saved) => {
          onMembershipFormClose()
          if (saved === true) {
            doMarkMembershipListAsOutdated()
          }
        }}
        entity={entityType}
        instance={{ ...currentMembership, [entityType]: entityId }}
      />
      <DeleteModal
        open={deleteConfirmOpen}
        error={deleteError}
        onConfirmDelete={handleMembershipDelete}
        onCancelDelete={onDeleteFormClose}
      />
      <Box display="flex">
        <Box display="flex" marginRight={isSmallScreen ? 0 : 2} marginTop={1.5}>
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={membershipListIsLoading}
            apiParams={membershipListApiParams}
            setApiParams={doMembershipListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
        </Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" width="100%">
            <MobileList
              title="Memberships"
              loading={membershipListIsLoading}
              actions={listActions}
              itemBuilder={MobileListItem}
              onFilterPressed={() => setFilterModalOpen(true)}
              listActions={[{ label: 'Add Membership', onClick: openMembershipForm }]}
              page={membershipList.current || 1}
              pageChange={doMembershipListSetPage}
              pageSize={membershipList.pageSize}
              pageSizeChange={doMembershipListSetPageSize}
              rows={rows}
              rowCount={membershipList.count || 0}
              setSearch={doMembershipListSetSearch}
            />
          </Box>
        ) : (
          <Box
            marginTop={1.5}
            flexDirection="column"
            display="flex"
            flex={1}
            height="100%"
            overflow="hidden"
          >
            {!renderAsTab && <Typography variant="h5">Memberships</Typography>}
            <List
              columnsAutosize
              title="Memberships"
              loading={membershipListIsLoading}
              actions={listActions}
              columns={userId ? userMembershipColumns : membershipColumns}
              page={membershipList.current || 1}
              pageChange={doMembershipListSetPage}
              pageSize={membershipList.pageSize}
              pageSizeChange={doMembershipListSetPageSize}
              rows={rows}
              rowCount={membershipList.count || 0}
              setSearch={doMembershipListSetSearch}
              sortChange={doMembershipListSetOrdering}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

MembershipList.defaultProps = {
  renderAsTab: false,
  userId: '',
}

MembershipList.propTypes = {
  userId: PropTypes.string,
  renderAsTab: PropTypes.bool,
}
