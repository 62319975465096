import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Checkbox } from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'

export default function CheckboxCell({ row, field, onChange }) {
  const isSmallScreen = useSmallScreen()
  const [value, setValue] = useState(false)

  const { doShowSnackbar, isAtLeastAdmin } = useConnect(
    'doShowSnackbar',
    'selectIsAtLeastAdmin',
  )

  useEffect(() => {
    setValue(!!row?.[field])
  }, [row?.[field]])

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Checkbox
        size={isSmallScreen ? 'small' : 'medium'}
        checked={value}
        disabled={!isAtLeastAdmin}
        onChange={async (event) => {
          const newValue = event.target.checked
          try {
            setValue(newValue)
            await onChange(newValue)
          } catch (err) {
            setValue(!newValue)
            const parsedError = parseApiErrors(err)
            doShowSnackbar(parsedError, 'error')
          }
        }}
      />
    </Box>
  )
}

CheckboxCell.propTypes = {
  row: PropTypes.shape({}).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
