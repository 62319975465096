import PropTypes from 'prop-types'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'

export default function SettingsExplanation({ open, close }) {
  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 3, pb: 0, px: 4 }}
      >
        Threshold Settings Guide
        <IconButton onClick={close}>
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5, px: 4 }}>
        <Chip
          label="Very Low Sensitvity"
          color="veryLowSensitivity"
          size="small"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">
          You will receive alerts when it’s extremely loud.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Chip
          label="Low Sensitvity"
          color="lowSensitivity"
          size="small"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">
          You will receive alerts when it’s moderately loud.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Chip
          label="Medium Sensitivity"
          color="mediumSensitivity"
          size="small"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">
          You will receive alerts when the noise levels are slightly above normal.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Chip
          label="High Sensitivity"
          color="highSensitivity"
          size="small"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">
          You will receive alerts when the noise levels are normal.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Chip
          label="Very High Sensitivity"
          color="veryHighSensitivity"
          size="small"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">
          Alerts will be sent when the noise level are below normal.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

SettingsExplanation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}
