import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { ArrowBack, Warning } from '@mui/icons-material'
import { Box, Button, Link, Paper, Stack, Tooltip, Typography } from '@mui/material'

import { inflect } from 'inflection'
import { useMediaQuery } from 'react-responsive'

import {
  Breadcrumbs,
  ErrorMessage,
  Loading,
  MobileDetailRow,
  TabComponent,
} from '@common/components'
import {
  humanizeTimezone,
  parseApiErrors,
  useHashFilter,
  useSmallScreen,
} from '@common/utils'
import { AqiDetailItem, DetailStack } from '@rest/UI/components'
import { useFlag } from '@rest/Utils'

import { Reservations } from '../Reservations'
import MetricsDetailStack from './MetricsDetailStack'
import DeviceStatusChip from './PropertyDevices/DeviceStatusChip'
import PropertyDevices from './PropertyDevices/PropertyDevices'
import PropertyEvents from './PropertyEvents/PropertyEvents'
import PropertyThresholds from './PropertyThresholds/PropertyThresholds'
import PropertyUnits from './PropertyUnits/PropertyUnits'
import PropertyUsers from './PropertyUsers/PropertyUsers'
import propertyUrls from './urls'

export default function PropertyDetail() {
  const {
    routeParams: { id: propertyId },
    currentOrganizationDetails: organization,
    currentAccountDetails: account,
    propertyDetails,
    propertyDetailsMetrics,
    propertyDetailsIsLoading,
    propertyDetailsError,
    hashObject,
    flags,
    doFetchPropertyDetails,
    doResetPropertyDetailsState,
    doPropertyEventListClear,
    doPropertyUnitListClear,
    doPropertyDeviceListClear,
    doPropertyUserListClear,
    doReservationListClear,
    doSetCurrentProperty,
    doSetHeaderLevel,
    doUpdateQuery,
    doShowSnackbar,
  } = useConnect(
    'selectRouteParams',
    'selectCurrentOrganizationDetails',
    'selectCurrentAccountDetails',
    'selectPropertyDetails',
    'selectPropertyDetailsMetrics',
    'selectPropertyDetailsIsLoading',
    'selectPropertyDetailsError',
    'selectHashObject',
    'selectFlags',
    'doFetchPropertyDetails',
    'doResetPropertyDetailsState',
    'doPropertyEventListClear',
    'doPropertyUnitListClear',
    'doPropertyDeviceListClear',
    'doPropertyUserListClear',
    'doReservationListClear',
    'doSetCurrentProperty',
    'doSetHeaderLevel',
    'doUpdateQuery',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()
  const wrapHeaderData = useMediaQuery({ query: '(max-width: 1400px)' })

  const [initialLoading, setInitialLoading] = useState(true)
  const [tabValue, setTabValue] = useState(0)

  const { property, summary } = propertyDetails ?? {}

  const isHotel = property?.propertyType === 'HOTEL'
  const aqiEnabled = useFlag(['AQI'], property)

  const fetchData = useCallback(async (id) => {
    try {
      await doFetchPropertyDetails(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // clear tabs bundles data to prevent showing data that
      // is not related to the current property
      doPropertyEventListClear()
      doPropertyUnitListClear()
      doPropertyDeviceListClear()
      doPropertyUserListClear()
      doReservationListClear()
    }
  }, [])

  useEffect(() => {
    if (property) {
      setInitialLoading(false)
    }
  }, [property])

  useEffect(() => {
    fetchData(propertyId)

    doSetCurrentProperty(propertyId)
    doSetHeaderLevel('property')

    return () => {
      doResetPropertyDetailsState()
    }
  }, [propertyId])

  const propertyDetailsItems = useMemo(() => {
    if (property) {
      return {
        organization: { label: 'Organization', value: organization?.name },
        account: { label: 'Account', value: property.accountName },
        ...(property.groupName
          ? { group: { label: 'Group', value: property.groupName } }
          : {}),
        timezone: { label: 'Timezone', value: humanizeTimezone(property.timezone) },
        units: { label: isHotel ? 'Rooms' : 'Units', value: property.unitCount },
        sensors: { label: 'Sensors', value: property.deviceCount },
        ...(property?.aqi && aqiEnabled
          ? { aqi: { label: 'AQI', value: property.aqi } }
          : {}),
        ...(summary && property.deviceCount !== 1
          ? { connectivity: { label: 'Connectivity', value: summary.connectivityPct } }
          : {}),
        ...(summary && property.deviceCount === 1
          ? {
              status: {
                label: 'Status',
                value: summary.totalOnline === 1 ? 'ONLINE' : 'OFFLINE',
              },
            }
          : {}),
        metrics: { label: 'Metrics', value: propertyDetailsMetrics ?? {} },
      }
    }
    return {}
  }, [property, summary, propertyDetailsMetrics, organization])

  const fullAddress = useMemo(() => {
    if (property) {
      const { address1, address2, city, state, zipCode } = property
      let result = [address1, address2, city, state].filter((value) => value).join(', ')
      if (zipCode) {
        result += ` ${zipCode}`
      }
      return result
    }
    return ''
  }, [property])

  const isPrunit = useMemo(() => property?.unitCount === 1, [property])

  const tabs = useMemo(() => {
    if (property) {
      return [
        {
          label: 'Events',
          component: <PropertyEvents property={property} />,
          hash: 'events',
        },
        {
          label: isHotel ? 'Rooms' : 'Units',
          component: <PropertyUnits property={property} />,
          hash: 'units',
          visible: !isPrunit,
        },
        {
          label: 'Sensors',
          component: <PropertyDevices property={property} />,
          hash: 'sensors',
        },
        {
          label: 'Users',
          component: <PropertyUsers property={property} />,
          hash: 'users',
        },
        ...(flags.includes('RESERVATION_INPUT')
          ? [
              {
                label: 'Reservations',
                component: <Reservations level="property" />,
                hash: 'reservations',
              },
            ]
          : []),
        ...(['NOISE', 'THRESHOLDS'].every((flag) =>
          property?.expandedFlags.includes(flag),
        )
          ? [
              {
                label: 'Thresholds',
                component: <PropertyThresholds property={property} />,
                hash: 'thresholds',
              },
            ]
          : []),
        // ...(isNoise
        //   ? [
        //       {
        //         label: 'Configure',
        //         component: <PropertyConfigure property={property ?? {}} />,
        //         hash: 'configure',
        //       },
        //     ]
        //   : []),
      ]
    }
    return []
  }, [property])

  useHashFilter(isEmpty(hashObject) ? tabs[0]?.hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  // clear query params for tabs that don't use query filter
  useEffect(() => {
    if (!isEmpty(tabs)) {
      const isSensorsTab = tabs[tabValue].hash === 'sensors'
      if (!isSensorsTab && !initialLoading) {
        doUpdateQuery({})
      }
    }
  }, [tabValue])

  const numericFormatter = useCallback(
    (value) => (value === undefined ? '--' : value),
    [],
  )

  const ConnectivityDetail = useCallback(
    ({ connectivity }) => {
      const tooltipText = `${numericFormatter(summary?.totalOnline)} ${inflect(
        'sensor',
        summary?.totalOnline,
      )} online, ${numericFormatter(summary?.totalOffline)} offline`
      const formattedText = numericFormatter(connectivity)
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <Tooltip title={tooltipText}>
            <Typography
              variant="body1"
              color="black"
              fontSize={isSmallScreen ? 14 : 16}
              whiteSpace="pre-line"
            >
              {formattedText}%
            </Typography>
          </Tooltip>
          {connectivity < 95 && summary?.totalDevices > 0 && (
            <Tooltip title="Connectivity below 95%, click to view offline sensors">
              <Link
                display="flex"
                href={`${propertyUrls.entity.replace(
                  ':id',
                  propertyId,
                )}?status=OFFLINE#sensors`}
              >
                <Warning color="warning" sx={{ fontSize: 18 }} />
              </Link>
            </Tooltip>
          )}
        </Stack>
      )
    },
    [summary, isSmallScreen],
  )

  if (
    (propertyDetailsIsLoading || property?.id !== propertyId) &&
    !propertyDetailsError
  ) {
    return <Loading />
  }

  if (propertyDetailsError) {
    return (
      <ErrorMessage>
        {parseApiErrors(propertyDetailsError) || 'Oops. Something went wrong'}
      </ErrorMessage>
    )
  }

  if (!property) {
    return <ErrorMessage>Property Not Found</ErrorMessage>
  }

  return (
    <>
      <Breadcrumbs
        links={[
          { label: organization?.name ?? '--' },
          { href: propertyUrls.list, label: account?.name ?? 'Properties' },
          { label: property.name },
        ]}
      />
      <Box mt={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button
            startIcon={<ArrowBack />}
            href={propertyUrls.list}
            size={isSmallScreen ? 'small' : 'medium'}
          >
            BACK TO PROPERTIES
          </Button>
          {!isSmallScreen && (
            <Typography variant="caption" color="text.secondary">
              id: {propertyId}
            </Typography>
          )}
        </Stack>

        <Stack sx={{ ml: isSmallScreen ? 0 : 3 }} direction="column">
          <Typography variant={isSmallScreen ? 'h5' : 'h4'}>{property.name}</Typography>
          <Typography
            variant={isSmallScreen ? 'caption' : 'body2'}
            color="text.secondary"
          >
            {fullAddress}
          </Typography>
          {!isSmallScreen ? (
            <Box
              display="flex"
              flexDirection={wrapHeaderData ? 'column' : 'row'}
              mt={1.5}
              gap={4}
            >
              <Box display="flex" gap={4}>
                {Object.keys(propertyDetailsItems)
                  .filter((key) => key !== 'metrics')
                  .map((key) => {
                    const detail = propertyDetailsItems[key]

                    let renderValue
                    if (detail.label === 'Connectivity') {
                      renderValue = (value) => (
                        <ConnectivityDetail connectivity={value} />
                      )
                    } else if (detail.label === 'AQI') {
                      renderValue = (value) => <AqiDetailItem index={value} />
                    } else if (detail.label === 'Status') {
                      renderValue = (value) => <DeviceStatusChip status={value} />
                    }

                    return (
                      <DetailStack
                        key={detail.label}
                        label={detail.label}
                        value={detail.value}
                        renderValue={renderValue}
                      />
                    )
                  })}
              </Box>
              <Box>
                {propertyDetailsItems.metrics ? (
                  <MetricsDetailStack
                    key={propertyDetailsItems.metrics.label}
                    property={propertyId}
                    mt={-3}
                  />
                ) : null}
              </Box>
            </Box>
          ) : (
            <Box mt={1.5} display="flex" flexDirection="column">
              {Object.values(propertyDetailsItems).map((detail) => {
                let renderValue
                if (detail.label === 'Connectivity') {
                  renderValue = (value) => <ConnectivityDetail connectivity={value} />
                } else if (detail.label === 'AQI') {
                  renderValue = (value) => <AqiDetailItem index={value} />
                } else if (detail.label === 'Status') {
                  renderValue = (value) => <DeviceStatusChip status={value} />
                }

                if (detail.label === 'Metrics') {
                  return (
                    <MetricsDetailStack
                      mobileLayout
                      key={detail.label}
                      property={propertyId}
                    />
                  )
                }
                return (
                  <MobileDetailRow
                    key={detail.label}
                    label={detail.label}
                    value={detail.value}
                    renderValue={renderValue}
                  />
                )
              })}
            </Box>
          )}
        </Stack>
      </Box>
      <Box sx={{ pb: 2 }}>
        <Paper elevation={2} sx={{ mt: 4, bgcolor: 'grey.50' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabComponent
              tabs={tabs}
              externalState={{ value: tabValue, setValue: setTabValue }}
              color="secondary"
              contentSx={{ p: isSmallScreen ? 0 : 2 }}
              tabsSx={{ mx: 2 }}
              sx={isSmallScreen ? { m: 2 } : null}
            />
          </Box>
        </Paper>
      </Box>
    </>
  )
}
