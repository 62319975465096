import PropTypes from 'prop-types'

import { Dialog, DialogContent } from '@mui/material'

import PropertyEventListRowDetail from './PropertyEventListRowDetail'

export default function AdditionalOccurenciesModal({ open, onClose, data }) {
  if (!data) return null

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <PropertyEventListRowDetail
          {...data.row}
          mobileView
          zone={data.property.timezone}
          isPrunit={data.property.unitCount === 1}
          onReportClick={(childRow) => {
            const isSmoke = childRow?.eventClass === 'SMOKE'
            if (!isSmoke || childRow?.metadata?.smoke?.alarmTag) {
              data.reportActions.setEventReportOpen(true)
            } else {
              data.reportActions.setOldEventReportOpen(true)
            }
            data.reportActions.setActiveInstance(childRow)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

AdditionalOccurenciesModal.defaultProps = {
  data: undefined,
}

AdditionalOccurenciesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    row: PropTypes.shape({}).isRequired,
    property: PropTypes.shape({
      timezone: PropTypes.string,
      unitCount: PropTypes.number,
    }).isRequired,
    reportActions: PropTypes.shape({
      setEventReportOpen: PropTypes.func.isRequired,
      setOldEventReportOpen: PropTypes.func.isRequired,
      setActiveInstance: PropTypes.func.isRequired,
    }).isRequired,
  }),
}
