import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'

import { Form, Formik } from 'formik'

export default function FormDialog({
  children,
  label,
  initialValues,
  maxWidth,
  onClose,
  onSave,
  open,
  hidden,
  validationSchema,
  isLoading,
  successMessage,
  extraActions,
  extraActionsPosition,
  submitOptions,
}) {
  const [disabled, setDisabled] = useState(submitOptions?.disabled ?? false)

  const { doShowSnackbar } = useConnect('doShowSnackbar')
  const handleSubmit = async (values, { setErrors }) => {
    try {
      setDisabled(true)
      const response = await onSave({ id: initialValues?.id, ...values })
      if (response?.error) {
        const hasErrorResponse = !isEmpty(response.error.response)
        setErrors(
          hasErrorResponse
            ? response.error.response
            : {
                nonFieldErrors: [
                  `API request failed, HTTP status code: ${response.error.status}`,
                ],
              },
        )
      } else if (response) {
        const action = initialValues?.id ? 'updated' : 'created'
        doShowSnackbar(successMessage ?? `Successfully ${action} ${label}`, 'success')
        onClose(true, response)
      }
      setDisabled(false)
    } catch (err) {
      if (err.error?.response) {
        setErrors(err.error.response)
      } else {
        doShowSnackbar('Ooops. Something went wrong', 'error')
      }
      setDisabled(false)
    }
  }
  const displayNonFieldErrors = (nonFieldErrors) => {
    if (nonFieldErrors) {
      return (
        <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
          {nonFieldErrors.map((error) => (
            <Alert key={error} severity="error">
              {error}
            </Alert>
          ))}
        </Box>
      )
    }

    return null
  }

  const ExtraActions = useCallback(
    ({ values }) => (
      <>
        {extraActions.map((action) => (
          <Button
            key={action.label}
            color={action.color ?? 'inherit'}
            onClick={() => action.onClick(values)}
          >
            {action.label}
          </Button>
        ))}
      </>
    ),
    [extraActions],
  )

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={maxWidth ?? 'sm'}
        open={open}
        onClose={onClose}
        sx={{ visibility: hidden ? 'hidden' : 'visible' }}
      >
        <DialogTitle sx={{ fontSize: '1.7rem', textAlign: 'center' }}>
          {label}
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ errors, values }) => (
            <Form>
              <DialogContent>
                {displayNonFieldErrors(errors?.nonFieldErrors)}
                {children}
              </DialogContent>
              {isLoading ? (
                <Stack direction="row" justifyContent="end" sx={{ p: 2 }}>
                  <CircularProgress size="1.2rem" color="secondary" />
                </Stack>
              ) : (
                <DialogActions>
                  {extraActionsPosition === 'left' && <ExtraActions values={values} />}
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    type="submit"
                    color={submitOptions?.color ?? 'secondary'}
                    disabled={submitOptions?.disabled ?? disabled}
                  >
                    {submitOptions?.label ?? 'Submit'}
                  </Button>
                  {extraActionsPosition === 'right' && <ExtraActions values={values} />}
                </DialogActions>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

FormDialog.defaultProps = {
  maxWidth: 'sm',
  hidden: false,
  isLoading: false,
  successMessage: null,
  extraActions: [],
  extraActionsPosition: 'left',
  submitOptions: undefined,
}

FormDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  label: PropTypes.string.isRequired,
  initialValues: PropTypes.PropTypes.shape().isRequired,
  maxWidth: PropTypes.string,
  hidden: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  validationSchema: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
  submitOptions: PropTypes.shape({
    label: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
  }),
  successMessage: PropTypes.string,
  extraActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ),
  extraActionsPosition: PropTypes.oneOf(['left', 'right']),
}
