import PropTypes from 'prop-types'

import { Grid2, List, ListItemButton, ListItemText, Typography } from '@mui/material'

export const RESERVATIONS_NAV_RESERVATIONS = 'reservations'
export const RESERVATIONS_NAV_MESSAGING = 'messaging'
export default function ReservationsNav({ selected, setSelected }) {
  return (
    <Grid2 size={{ xs: 0, md: 2.5 }}>
      <List sx={{ '.Mui-selected': { borderRadius: '7px' } }}>
        <ListItemButton
          onClick={() => setSelected(RESERVATIONS_NAV_RESERVATIONS)}
          disableRipple
          selected={selected === RESERVATIONS_NAV_RESERVATIONS}
        >
          <ListItemText>
            <Typography variant="subtitle2">Reservations</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => setSelected(RESERVATIONS_NAV_MESSAGING)}
          disableRipple
          selected={selected === RESERVATIONS_NAV_MESSAGING}
        >
          <ListItemText>
            <Typography variant="subtitle2">Guest Messaging</Typography>
          </ListItemText>
        </ListItemButton>
      </List>
    </Grid2>
  )
}

ReservationsNav.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}
