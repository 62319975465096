import PropTypes from 'prop-types'

export default function BoldText({ children }) {
  return <span style={{ fontWeight: 'bold' }}>{children}</span>
}

BoldText.defaultProps = {
  children: '',
}

BoldText.propTypes = {
  children: PropTypes.string,
}
