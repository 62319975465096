import { PropTypes } from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, Button, Paper, Typography } from '@mui/material'

export default function FinanceActionCard({ title, description, actions }) {
  const paperStyles = {
    p: 2,
    maxWidth: 400,
    maxHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  }

  const { isAtLeastFinance } = useConnect('selectIsAtLeastFinance')

  return (
    <Paper elevation={2} sx={paperStyles}>
      <Box>
        <Typography variant="h5" alignSelf="flex-start" justifySelf="flex-start" mb={1}>
          {title}
        </Typography>
        <Typography variant="body2" mb={2} color="text.secondary">
          {description}
        </Typography>
      </Box>
      {actions.map((action) => (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          flex={1}
          key={action.title}
          onClick={action.onClick}
          sx={{ alignSelf: 'flex-end', ...action.styles }}
          disabled={!isAtLeastFinance}
        >
          {action.title}
        </Button>
      ))}
    </Paper>
  )
}

FinanceActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
