import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Box,
  Card,
  List as MuiList,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { DateTime, Interval } from 'luxon'

import { Loading, MobileListCardRow } from '@common/components'
import { getApiFetch, isAbortError } from '@common/utils'
import { EventTypeCell } from '@portal/UI/components/cells'

import ScenarioOptions from './ScenarioOptions'

const formatDate = (date) => date.toLocaleString(DateTime.DATETIME_MED)

const endDate = (endTime, zone) =>
  endTime ? DateTime.fromISO(endTime, { zone }) : DateTime.now().setZone(zone)

const getDistance = (start, end, zone) =>
  Interval.fromDateTimes(
    DateTime.fromISO(start, { zone }),
    DateTime.fromISO(endDate(end), { zone }),
  )
    .toDuration(['weeks', 'days', 'hours', 'minutes'])
    .toHuman({ unitDisplay: 'short' })
    .split(',')
    .filter((time) => parseInt(time.trim().charAt(0), 10) > 0)
    .map((selectedTime) => {
      const arr = selectedTime.trim().split(' ')
      arr[0] = Math.round(arr[0])
      return `${arr[0]} ${arr[1]}`
    })
    .join(' ')

export default function EventListRowDetail({
  row,
  setScenarioFormOpen,
  setScenarioData,
  modalView,
}) {
  const { id, eventClass, timeZone } = row ?? {}
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState([])

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  useEffect(() => {
    async function fetchChildrenEvents() {
      try {
        const response = await apiFetch(
          '/events/',
          {
            parentEvent: id,
            eventClass,
            includeChildEvents: 1,
          },
          { cancelationPrefix: 'row_detail' },
        )

        setEvents(response?.results ?? [])
        setIsLoading(false)
      } catch (err) {
        if (!isAbortError(err)) {
          setEvents(false)
          setIsLoading(false)
        }
      }
    }
    fetchChildrenEvents()
  }, [row])

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Loading containerStyles={{ height: '150px' }} />
      </Box>
    )
  }

  if (events === false || events.length === 0 || !events) {
    return <Box sx={{ p: 3 }}>Something went wrong. Please contact support.</Box>
  }

  if (modalView) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="body2" fontWeight="bold" mb={1}>
          Additional Occurrences ({events.length})
        </Typography>
        <MuiList>
          {events.map((event, index) => (
            <Card sx={{ p: 1, mt: index === 0 ? 0 : 1.5 }}>
              <MobileListCardRow label="Type" value={event.eventType} />
              <MobileListCardRow label="Unit" value={event.unitName} />
              <MobileListCardRow
                label="Start Time"
                value={formatDate(
                  DateTime.fromISO(event.createdOn, { zone: timeZone }),
                )}
              />
              <MobileListCardRow
                label="End Time"
                value={
                  event.endTime
                    ? formatDate(endDate(event.endTime, timeZone))
                    : 'Ongoing'
                }
              />
              <MobileListCardRow
                label="Duration"
                value={getDistance(event.createdOn, event.endTime, timeZone)}
              />
              <Box sx={{ mt: 1, mb: 0.5 }}>
                <ScenarioOptions
                  fullWidth
                  row={event}
                  setScenarioData={setScenarioData}
                  setScenarioFormOpen={setScenarioFormOpen}
                />
              </Box>
            </Card>
          ))}
        </MuiList>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        pb: 3,
        minHeight: 175,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <h5>Additional Occurrences</h5>
      <TableContainer component={Paper} sx={{ alignSelf: 'center', maxWidth: '96%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell align="right">Start Time</TableCell>
              <TableCell align="right">End Time</TableCell>

              <TableCell align="right">Duration</TableCell>
              <TableCell align="right" sx={{ pr: '100px' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((ev) => (
              <TableRow
                key={ev.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <EventTypeCell type={ev.eventType} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {ev.unitName}
                </TableCell>
                <TableCell align="right">
                  {formatDate(DateTime.fromISO(ev.createdOn, { zone: timeZone }))}
                </TableCell>
                <TableCell align="right">
                  {ev.endTime ? formatDate(endDate(ev.endTime, timeZone)) : 'ongoing'}
                </TableCell>
                <TableCell align="right">
                  {getDistance(ev.createdOn, ev.endTime, timeZone)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Box>
                    <ScenarioOptions
                      row={ev}
                      setScenarioData={setScenarioData}
                      setScenarioFormOpen={setScenarioFormOpen}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

EventListRowDetail.defaultProps = {
  modalView: false,
}

EventListRowDetail.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    eventClass: PropTypes.string,
    timeZone: PropTypes.string,
  }).isRequired,
  setScenarioFormOpen: PropTypes.func.isRequired,
  setScenarioData: PropTypes.func.isRequired,
  modalView: PropTypes.bool,
}
