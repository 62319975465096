import PropTypes from 'prop-types'

import { SvgIcon } from '@mui/material'

const path1 = `M 83.226562 15.273438 C 89.679688 8.554688 98.660156 4.382812 108.589844 4.382812 C 121.792969
  4.382812 133.308594 11.746094 139.445312 22.675781 C 144.773438 20.292969 150.675781 18.96875 156.882812 18.96875
  C 180.695312 18.96875 200 38.441406 200 62.460938 C 200 86.484375 180.695312 105.957031 156.882812 105.957031 C
  154.03125 105.957031 151.1875 105.675781 148.390625 105.109375 C 142.988281 114.746094 132.695312 121.257812 120.878906
  121.257812 C 115.933594 121.257812 111.257812 120.113281 107.089844 118.082031 C 101.617188 130.960938 88.859375
  139.992188 73.988281 139.992188 C 58.503906 139.992188 45.308594 130.195312 40.242188 116.457031 C 38.027344 116.925781
  35.734375 117.171875 33.382812 117.171875 C 14.945312 117.171875 0 102.070312 0 83.441406 C 0 70.957031 6.714844 60.058594
  16.691406 54.226562 C 14.636719 49.5 13.496094 44.28125 13.496094 38.796875 C 13.496094 17.375 30.890625 0.00390625
  52.34375 0.00390625 C 64.9375 0.00390625 76.132812 5.996094 83.226562 15.273438
`
const path2 = `M 28.96875 72.601562 C 28.84375 72.929688 29.015625 72.996094 29.054688 73.054688 C 29.429688 73.328125
29.8125 73.523438 30.195312 73.742188 C 32.234375 74.824219 34.164062 75.140625 36.175781 75.140625 C 40.277344 75.140625
42.824219 72.960938 42.824219 69.445312 L 42.824219 69.378906 C 42.824219 66.128906 39.953125 64.949219 37.253906 64.097656
L 36.902344 63.984375 C 34.867188 63.324219 33.113281 62.753906 33.113281 61.414062 L 33.113281 61.34375 C 33.113281 60.199219
34.140625 59.355469 35.730469 59.355469 C 37.496094 59.355469 39.59375 59.941406 40.941406 60.6875 C 40.941406 60.6875
41.339844 60.945312 41.484375 60.5625 C 41.5625 60.355469 42.246094 58.515625 42.320312 58.316406 C 42.394531 58.097656
42.257812 57.9375 42.121094 57.855469 C 40.578125 56.917969 38.445312 56.277344 36.242188 56.277344 L 35.832031 56.277344 C
32.078125 56.277344 29.457031 58.546875 29.457031 61.796875 L 29.457031 61.867188 C 29.457031 65.292969 32.347656 66.402344
35.058594 67.179688 L 35.492188 67.3125 C 37.46875 67.917969 39.167969 68.441406 39.167969 69.832031 L 39.167969 69.898438 C
39.167969 71.171875 38.0625 72.117188 36.277344 72.117188 C 35.585938 72.117188 33.378906 72.101562 30.996094 70.59375 C
30.707031 70.425781 30.539062 70.304688 30.316406 70.171875 C 30.199219 70.097656 29.90625 69.96875 29.777344 70.355469 Z
M 89.027344 72.601562 C 88.902344 72.929688 89.074219 72.996094 89.113281 73.054688 C 89.488281 73.328125 89.871094 73.523438
90.253906 73.742188 C 92.296875 74.824219 94.222656 75.140625 96.234375 75.140625 C 100.339844 75.140625 102.886719 72.960938
102.886719 69.445312 L 102.886719 69.378906 C 102.886719 66.128906 100.011719 64.949219 97.3125 64.097656 L 96.960938 63.984375
C 94.929688 63.324219 93.171875 62.753906 93.171875 61.414062 L 93.171875 61.34375 C 93.171875 60.199219 94.199219 59.355469
95.789062 59.355469 C 97.554688 59.355469 99.652344 59.941406 101.003906 60.6875 C 101.003906 60.6875 101.398438 60.945312
101.542969 60.5625 C 101.621094 60.355469 102.308594 58.515625 102.378906 58.316406 C 102.457031 58.097656 102.316406 57.9375
102.179688 57.855469 C 100.636719 56.917969 98.507812 56.277344 96.300781 56.277344 L 95.890625 56.277344 C 92.136719 56.277344
89.515625 58.546875 89.515625 61.796875 L 89.515625 61.867188 C 89.515625 65.292969 92.40625 66.402344 95.117188 67.179688 L
95.550781 67.3125 C 97.527344 67.917969 99.230469 68.441406 99.230469 69.832031 L 99.230469 69.898438 C 99.230469 71.171875
98.121094 72.117188 96.339844 72.117188 C 95.644531 72.117188 93.4375 72.101562 91.054688 70.59375 C 90.765625 70.425781 90.59375
70.308594 90.378906 70.171875 C 90.304688 70.121094 89.957031 69.988281 89.835938 70.355469 Z M 130.027344 65.71875 C 130.027344
67.707031 129.660156 69.269531 128.929688 70.375 C 128.207031 71.46875 127.117188 72 125.597656 72 C 124.070312 72 122.988281
71.472656 122.277344 70.375 C 121.558594 69.273438 121.195312 67.707031 121.195312 65.71875 C 121.195312 63.738281 121.558594
62.175781 122.277344 61.082031 C 122.988281 60 124.070312 59.472656 125.597656 59.472656 C 127.117188 59.472656 128.207031 60
128.933594 61.082031 C 129.660156 62.175781 130.027344 63.738281 130.027344 65.71875 M 133.453125 62.039062 C 133.117188 60.902344
132.59375 59.898438 131.894531 59.0625 C 131.195312 58.226562 130.3125 57.554688 129.265625 57.0625 C 128.21875 56.578125 126.984375
56.328125 125.597656 56.328125 C 124.207031 56.328125 122.96875 56.578125 121.921875 57.0625 C 120.875 57.554688 119.992188 58.226562
119.292969 59.0625 C 118.597656 59.902344 118.070312 60.90625 117.734375 62.039062 C 117.398438 63.167969 117.230469 64.40625 117.230469
65.71875 C 117.230469 67.03125 117.398438 68.273438 117.734375 69.402344 C 118.070312 70.535156 118.59375 71.539062 119.292969 72.375 C
119.992188 73.210938 120.878906 73.882812 121.925781 74.359375 C 122.972656 74.832031 124.207031 75.074219 125.597656 75.074219 C
126.984375 75.074219 128.21875 74.832031 129.265625 74.359375 C 130.3125 73.882812 131.195312 73.210938 131.894531 72.375 C 132.59375
71.539062 133.117188 70.539062 133.453125 69.402344 C 133.789062 68.269531 133.957031 67.03125 133.957031 65.71875 C 133.957031 64.410156
133.789062 63.167969 133.453125 62.039062 M 161.574219 71.472656 C 161.460938 71.136719 161.140625 71.261719 161.140625 71.261719 C 160.640625
71.453125 160.109375 71.628906 159.546875 71.71875 C 158.972656 71.808594 158.34375 71.851562 157.667969 71.851562 C 156.011719 71.851562
154.695312 71.359375 153.75 70.386719 C 152.804688 69.410156 152.277344 67.835938 152.28125 65.703125 C 152.289062 63.765625 152.753906
62.304688 153.59375 61.195312 C 154.429688 60.089844 155.699219 59.523438 157.394531 59.523438 C 158.808594 59.523438 159.886719 59.683594
161.015625 60.042969 C 161.015625 60.042969 161.285156 60.15625 161.414062 59.804688 C 161.710938 58.972656 161.933594 58.378906 162.253906
57.460938 C 162.34375 57.203125 162.121094 57.09375 162.042969 57.0625 C 161.597656 56.886719 160.550781 56.605469 159.757812 56.484375 C
159.015625 56.371094 158.152344 56.3125 157.1875 56.3125 C 155.75 56.3125 154.464844 56.558594 153.371094 57.046875 C 152.277344 57.535156
151.347656 58.207031 150.613281 59.042969 C 149.878906 59.882812 149.320312 60.882812 148.945312 62.019531 C 148.574219 63.148438 148.386719
64.390625 148.386719 65.703125 C 148.386719 68.546875 149.152344 70.839844 150.667969 72.523438 C 152.183594 74.210938 154.457031 75.066406
157.425781 75.066406 C 159.183594 75.066406 160.984375 74.710938 162.277344 74.199219 C 162.277344 74.199219 162.523438 74.082031 162.414062
73.792969 Z M 167.566406 63.816406 C 167.730469 62.714844 168.035156 61.796875 168.503906 61.082031 C 169.214844 59.996094 170.296875 59.402344
171.816406 59.402344 C 173.339844 59.402344 174.34375 60 175.066406 61.082031 C 175.542969 61.796875 175.753906 62.753906 175.835938 63.816406
Z M 179.097656 61.394531 C 178.804688 60.296875 178.085938 59.1875 177.613281 58.679688 C 176.867188 57.875 176.136719 57.316406 175.414062
57.003906 C 174.46875 56.597656 173.332031 56.328125 172.089844 56.328125 C 170.644531 56.328125 169.332031 56.570312 168.265625 57.074219 C
167.195312 57.574219 166.300781 58.257812 165.59375 59.109375 C 164.890625 59.960938 164.363281 60.972656 164.027344 62.117188 C 163.6875
63.261719 163.515625 64.507812 163.515625 65.820312 C 163.515625 67.15625 163.691406 68.402344 164.042969 69.519531 C 164.394531 70.648438
164.960938 71.644531 165.722656 72.464844 C 166.484375 73.296875 167.464844 73.945312 168.636719 74.398438 C 169.804688 74.847656 171.21875
75.082031 172.847656 75.078125 C 176.195312 75.066406 177.957031 74.320312 178.683594 73.917969 C 178.8125 73.847656 178.933594 73.722656
178.78125 73.363281 L 178.023438 71.242188 C 177.910156 70.925781 177.585938 71.042969 177.585938 71.042969 C 176.757812 71.347656 175.578125
71.902344 172.828125 71.898438 C 171.03125 71.894531 169.699219 71.363281 168.863281 70.535156 C 168.003906 69.6875 167.585938 68.4375 167.511719
66.675781 L 179.105469 66.6875 C 179.105469 66.6875 179.410156 66.683594 179.441406 66.386719 C 179.453125 66.261719 179.839844 64.003906
179.097656 61.394531 M 74.726562 63.816406 C 74.890625 62.714844 75.195312 61.796875 75.664062 61.082031 C 76.375 59.996094 77.457031 59.402344
78.976562 59.402344 C 80.5 59.402344 81.503906 60 82.226562 61.082031 C 82.703125 61.796875 82.914062 62.753906 82.996094 63.816406 Z M
86.253906 61.394531 C 85.964844 60.296875 85.246094 59.1875 84.773438 58.679688 C 84.027344 57.875 83.296875 57.316406 82.574219 57.003906 C
81.628906 56.597656 80.492188 56.328125 79.25 56.328125 C 77.808594 56.328125 76.492188 56.570312 75.425781 57.074219 C 74.355469 57.574219
73.460938 58.257812 72.753906 59.109375 C 72.050781 59.960938 71.523438 60.972656 71.1875 62.117188 C 70.851562 63.261719 70.675781 64.507812
70.675781 65.820312 C 70.675781 67.15625 70.851562 68.402344 71.203125 69.519531 C 71.554688 70.648438 72.121094 71.644531 72.882812 72.464844
C 73.644531 73.296875 74.625 73.945312 75.796875 74.398438 C 76.964844 74.847656 78.378906 75.082031 80.007812 75.078125 C 83.355469 75.066406
85.117188 74.320312 85.84375 73.917969 C 85.972656 73.847656 86.09375 73.722656 85.941406 73.363281 L 85.1875 71.242188 C 85.070312 70.925781
84.746094 71.042969 84.746094 71.042969 C 83.917969 71.347656 82.742188 71.902344 79.988281 71.898438 C 78.191406 71.894531 76.859375 71.363281
76.023438 70.535156 C 75.167969 69.6875 74.746094 68.4375 74.671875 66.675781 L 86.265625 66.6875 C 86.265625 66.6875 86.570312 66.683594
86.601562 66.386719 C 86.613281 66.261719 87 64.003906 86.253906 61.394531 M 49.667969 71.40625 C 49.21875 71.046875 49.152344 70.953125 49
70.722656 C 48.773438 70.363281 48.65625 69.859375 48.65625 69.214844 C 48.65625 68.195312 48.992188 67.460938 49.691406 66.96875 C 49.679688
66.972656 50.6875 66.101562 53.050781 66.132812 C 54.710938 66.152344 56.195312 66.398438 56.195312 66.398438 L 56.195312 71.667969 L 56.199219
71.667969 C 56.199219 71.667969 54.726562 71.984375 53.070312 72.085938 C 50.710938 72.226562 49.660156 71.40625 49.667969 71.40625 M 54.2812
63.261719 C 53.8125 63.226562 53.203125 63.207031 52.472656 63.207031 C 51.480469 63.207031 50.519531 63.332031 49.617188 63.574219 C 48.710938
63.816406 47.898438 64.195312 47.195312 64.699219 C 46.496094 65.199219 45.921875 65.851562 45.515625 66.609375 C 45.105469 67.375 44.898438
68.273438 44.898438 69.28125 C 44.898438 70.308594 45.074219 71.199219 45.425781 71.929688 C 45.78125 72.660156 46.289062 73.269531 46.941406
73.742188 C 47.585938 74.210938 48.378906 74.554688 49.300781 74.765625 C 50.210938 74.972656 51.242188 75.078125 52.371094 75.078125 C 53.558594
75.078125 54.742188 74.980469 55.890625 74.785156 C 57.027344 74.589844 58.425781 74.308594 58.8125 74.21875 C 59.082031 74.152344 59.355469
74.085938 59.625 74.011719 C 59.910156 73.941406 59.890625 73.632812 59.890625 73.632812 L 59.882812 63.03125 C 59.882812 60.707031 59.261719
58.984375 58.039062 57.914062 C 56.824219 56.847656 55.03125 56.308594 52.714844 56.308594 C 51.847656 56.308594 50.449219 56.429688 49.609375
56.597656 C 49.609375 56.597656 47.078125 57.089844 46.035156 57.902344 C 46.035156 57.902344 45.804688 58.046875 45.933594 58.363281 L 46.753906
60.570312 C 46.855469 60.855469 47.132812 60.757812 47.132812 60.757812 C 47.132812 60.757812 47.21875 60.722656 47.324219 60.664062 C 49.554688
59.449219 52.375 59.488281 52.375 59.488281 C 53.628906 59.488281 54.589844 59.738281 55.238281 60.238281 C 55.871094 60.722656 56.195312 61.453125
56.195312 62.996094 L 56.195312 63.488281 C 55.195312 63.34375 54.28125 63.261719 54.28125 63.261719 M 147.78125 57.289062 C 147.871094 57.027344
147.683594 56.902344 147.609375 56.875 C 147.410156 56.796875 146.425781 56.589844 145.664062 56.539062 C 144.210938 56.453125 143.398438 56.695312
142.675781 57.019531 C 141.957031 57.347656 141.160156 57.871094 140.714844 58.464844 L 140.714844 57.054688 C 140.714844 56.859375 140.578125
56.703125 140.382812 56.703125 L 137.410156 56.703125 C 137.21875 56.703125 137.078125 56.859375 137.078125 57.054688 L 137.078125 74.347656 C
137.078125 74.539062 137.238281 74.699219 137.429688 74.699219 L 140.476562 74.699219 C 140.671875 74.699219 140.828125 74.539062 140.828125
74.347656 L 140.828125 65.707031 C 140.828125 64.546875 140.957031 63.390625 141.210938 62.664062 C 141.460938 61.949219 141.804688 61.371094
142.226562 60.957031 C 142.652344 60.546875 143.132812 60.261719 143.660156 60.097656 C 144.199219 59.933594 144.792969 59.878906 145.214844
59.878906 C 145.820312 59.878906 146.488281 60.035156 146.488281 60.035156 C 146.710938 60.0625 146.835938 59.925781 146.910156 59.722656 C
147.109375 59.191406 147.671875 57.605469 147.78125 57.289062 `

const path3 = `M 119.195312 49.277344 C 118.824219 49.160156 118.488281 49.085938 118.046875 49.003906 C 117.605469 48.921875 117.074219 48.882812
116.472656 48.882812 C 114.375 48.882812 112.722656 49.476562 111.5625 50.644531 C 110.410156 51.804688 109.625 53.574219 109.234375 55.902344 L
109.089844 56.6875 L 106.457031 56.6875 C 106.457031 56.6875 106.140625 56.675781 106.070312 57.023438 L 105.640625 59.433594 C 105.609375 59.664062
105.710938 59.808594 106.015625 59.808594 L 108.578125 59.808594 L 105.980469 74.316406 C 105.777344 75.484375 105.542969 76.445312 105.285156 77.171875
C 105.03125 77.890625 104.785156 78.429688 104.476562 78.824219 C 104.179688 79.199219 103.898438 79.476562 103.414062 79.640625 C 103.015625
79.773438 102.554688 79.835938 102.050781 79.835938 C 101.773438 79.835938 101.398438 79.789062 101.121094 79.734375 C 100.847656 79.679688 100.703125
79.621094 100.496094 79.53125 C 100.496094 79.53125 100.195312 79.417969 100.078125 79.71875 C 99.984375 79.964844 99.300781 81.84375 99.214844
82.074219 C 99.136719 82.304688 99.25 82.484375 99.394531 82.539062 C 99.738281 82.65625 99.992188 82.738281 100.457031 82.847656 C 101.101562 83
101.644531 83.007812 102.152344 83.007812 C 103.21875 83.007812 104.195312 82.855469 105 82.566406 C 105.808594 82.273438 106.515625 81.761719
107.140625 81.074219 C 107.816406 80.328125 108.242188 79.546875 108.648438 78.476562 C 109.046875 77.421875 109.394531 76.113281 109.667969 74.585938
L 112.277344 59.808594 L 116.097656 59.808594 C 116.097656 59.808594 116.417969 59.820312 116.484375 59.46875 L 116.917969 57.058594 C 116.945312
56.828125 116.847656 56.6875 116.539062 56.6875 L 112.832031 56.6875 C 112.851562 56.605469 113.019531 55.296875 113.445312 54.070312 C 113.625
53.550781 113.96875 53.125 114.257812 52.835938 C 114.542969 52.550781 114.867188 52.347656 115.226562 52.230469 C 115.59375 52.109375 116.015625
52.054688 116.472656 52.054688 C 116.820312 52.054688 117.164062 52.09375 117.425781 52.148438 C 117.785156 52.226562 117.921875 52.265625 118.015625
52.292969 C 118.394531 52.40625 118.445312 52.296875 118.523438 52.113281 L 119.40625 49.679688 C 119.5 49.417969 119.273438 49.308594 119.195312
49.277344 M 67.40625 74.347656 C 67.40625 74.539062 67.265625 74.699219 67.074219 74.699219 L 64 74.699219 C 63.804688 74.699219 63.667969 74.539062
63.667969 74.347656 L 63.667969 49.605469 C 63.667969 49.414062 63.804688 49.253906 64 49.253906 L 67.074219 49.253906 C 67.265625 49.253906 67.40625
49.414062 67.40625 49.605469 Z M 67.40625 74.347656 `

export default function SalesForce(props) {
  const { width, height, fill, viewBox } = props
  return (
    <SvgIcon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path fill="#00A1E0" d={path1} />
      <path fill="#FFF" d={path2} />
      <path fill="#FFF" d={path3} />
    </SvgIcon>
  )
}

SalesForce.defaultProps = {
  width: '200',
  height: '100',
  fill: 'none',
  viewBox: '0 0 200 100',
}

SalesForce.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
}
