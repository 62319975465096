import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { SmokingRooms as SmokingRoomsIcon } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import Typography from '@mui/material/Typography'

import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect } from '@common/components/Selects'
import { useQueryFilter, useSmallScreen } from '@common/utils'
import eventUrls from '@portal/pages/EventDashboard/urls'
import { homeUrls } from '@portal/pages/Home'
import propertyUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import Picker from '@portal/UI/components/Picker'

export default function Reservations(props) {
  const [pageSize, setPageSize] = useState('')
  const { renderAsTab, property } = props

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    doReservationListSetPageSize,
    doReservationListSetPage,
    doReservationListSetOrdering,
    doReservationListSetFilter,
    doReservationListClearParams,
    doEventsListSetFilter,
    doUpdateUrl,
    reservationList,
    reservationListRaw: { ordering = [] },
    reservationListIsLoading,
    reservationListApiParams,
    queryObject,
  } = useConnect(
    'doReservationListSetPageSize',
    'doReservationListSetPage',
    'doReservationListSetOrdering',
    'doReservationListSetFilter',
    'doReservationListClearParams',
    'doEventsListSetFilter',
    'doUpdateUrl',
    'selectReservationList',
    'selectReservationListRaw',
    'selectReservationListIsLoading',
    'selectReservationListApiParams',
    'selectQueryObject',
  )

  useQueryFilter({
    query: queryObject,
    apiParams: reservationListApiParams,
    setFilter: doReservationListSetFilter,
    setPageSize: doReservationListSetPageSize,
  })

  const handleClear = () => {
    doReservationListClearParams()
    doReservationListSetFilter()
  }

  useEffect(() => {
    handleClear()
    return () => doReservationListClearParams()
  }, [])

  const handlePageChange = async (pageNumber) => {
    doReservationListSetPage(pageNumber)
  }

  const handleSortChange = async (sort) => {
    doReservationListSetOrdering(sort)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doReservationListSetPageSize(size)
  }

  const handleViewEvents = (row) => {
    doEventsListSetFilter({
      organization: '',
      account: '',
      property: row.property,
      search: row.unitName,
      createdOnAfter: row.checkIn,
      createdOnBefore: row.checkOut,
    })
    doUpdateUrl(eventUrls.list)
  }

  const exportListConfig = {
    apiParams: reservationListApiParams,
    entity: 'reservations',
  }

  const ViewEventsButton = useCallback(
    ({ row }) => (
      <Button
        size="small"
        variant="outlined"
        sx={{ mx: 1, whiteSpace: 'nowrap', minWidth: 'max-content' }}
        color="primary"
        onClick={() => handleViewEvents(row)}
        startIcon={<SmokingRoomsIcon />}
        disabled={row?.events?.length < 1}
      >
        View events
      </Button>
    ),
    [],
  )

  const columns = [
    {
      field: 'unitName',
      sortField: 'unit__name',
      headerName: 'Unit',
      flex: 0.6,
      sortable: true,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.unitName}
          url={unitUrls.entity.replace(':id', row.unit)}
        />
      ),
    },
    ...(!renderAsTab
      ? [
          {
            field: 'propertyName',
            sortField: 'unit__property__name',
            headerName: 'Property',
            flex: 0.7,
            sortable: true,
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.propertyName}
                url={propertyUrls.entity.replace(':id', row.property)}
              />
            ),
          },
        ]
      : []),
    {
      field: 'confirmationNumber',
      headerName: 'Confirmation #',
      flex: 0.6,
      valueGetter: (_, row) => row.confirmationNumber ?? '',
    },
    {
      field: 'phoneNumber',
      headerName: 'Guest Phone #',
      flex: 0.7,
      valueGetter: (_, row) => row.phoneNumber ?? '',
    },
    {
      field: 'guestName',
      headerName: 'Guest Name',
      flex: 0.7,
      valueGetter: (_, row) => row.guestName ?? '',
    },
    {
      field: 'checkIn',
      sortField: 'checkIn',
      headerName: 'Check In',
      flex: 0.7,
      sortable: true,
      valueGetter: (_, row) =>
        DateTime.fromISO(row.checkIn, { zone: row.timezone }).toLocaleString(
          DateTime.DATETIME_MED,
        ),
    },
    {
      field: 'checkOut',
      sortField: 'checkOut',
      headerName: 'Check Out',
      flex: 0.7,
      sortable: true,
      valueGetter: (_, row) =>
        DateTime.fromISO(row.checkOut, { zone: row.timezone }).toLocaleString(
          DateTime.DATETIME_MED,
        ),
    },
    {
      field: 'events',
      headerName: 'Events',
      flex: 0.7,
      renderCell: ({ row }) => <ViewEventsButton row={row} />,
    },
  ]

  const MobileItemFooter = useCallback(({ row }) => <ViewEventsButton row={row} />, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['events'],
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  const containerProps = {
    display: 'flex',
    flex: 1,
    ...(!renderAsTab && { margin: 3, flexDirection: 'column' }),
  }

  if (!reservationList.results)
    return <ErrorComponent title="Reservations" callback={handleClear} />

  return (
    <Box {...containerProps}>
      {!renderAsTab && (
        <>
          <Breadcrumbs
            links={[{ label: 'Home', href: homeUrls.home }, { label: 'Reservations' }]}
          />
          <Typography variant="h3" mb={2}>
            Reservations
          </Typography>
        </>
      )}
      <Box display="flex" flex={1}>
        <Filter
          mode={isSmallScreen ? 'modal' : 'drawer'}
          disabled={reservationListIsLoading}
          apiParams={reservationListApiParams}
          setApiParams={(params) => {
            const updatedParams = {}
            Object.entries(params).forEach(([key, value]) => {
              if (key.startsWith('checkIn') || key.startsWith('checkOut')) {
                updatedParams[key] =
                  typeof value !== 'string' ? value.toFormat('yyyy-MM-dd') : value
              } else {
                updatedParams[key] = value
              }
            })
            doReservationListSetFilter(updatedParams)
          }}
          dialogOpen={filterModalOpen}
          dialogOnClose={() => setFilterModalOpen(false)}
        >
          <Picker
            range
            type="date"
            label="Check in"
            disableMaskedInput
            filterName="checkIn"
            conditionSeparator=""
            lowerCondition="After"
            upperCondition="Before"
            value={reservationListApiParams?.checkIn}
          />
          <Picker
            range
            type="date"
            label="Check out"
            disableMaskedInput
            filterName="checkOut"
            conditionSeparator=""
            lowerCondition="After"
            upperCondition="Before"
            value={reservationListApiParams?.checkOut}
          />
          {!renderAsTab && <DynamicSelect label="Properties" filterName="property" />}
          <DynamicSelect
            label="Units"
            filterName="unit"
            filters={{
              ...(property?.id ? { property: property.id } : {}),
              pageSize: 9999,
            }}
          />
        </Filter>
        {isSmallScreen ? (
          <Box width="100%">
            <MobileList
              dynamicRowHeight
              queryDrivenSearch
              title="Reservations"
              loading={reservationListIsLoading}
              itemBuilder={MobileListItem}
              onFilterPressed={() => setFilterModalOpen(true)}
              page={reservationList.current || 1}
              pageChange={handlePageChange}
              pageSize={reservationList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={reservationList?.results || []}
              rowCount={reservationList.count || 0}
              sortChange={handleSortChange}
              currentOrdering={ordering}
            />
          </Box>
        ) : (
          <Box ml={2} flex={1} overflow="hidden" minHeight="900px">
            <List
              columnsAutosize
              dynamicRowHeight
              queryDrivenSearch
              title="Reservations"
              showAddButton={false}
              showActions={false}
              loading={reservationListIsLoading}
              exportListConfig={renderAsTab ? null : exportListConfig}
              columns={columns}
              page={reservationList.current || 1}
              pageChange={handlePageChange}
              pageSize={reservationList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={reservationList?.results || []}
              rowCount={reservationList.count || 0}
              sortChange={handleSortChange}
              currentOrdering={ordering}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

Reservations.defaultProps = {
  renderAsTab: false,
  property: undefined,
}

Reservations.propTypes = {
  renderAsTab: PropTypes.bool,
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}
