import { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { CopyAll } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import { humanize, underscore } from 'inflection'

import { formatCurrency } from '@common/utils'

import InvoiceStatusChip from './InvoiceStatusChip'

export default function InvoiceDetailModal({ open, onClose, invoice }) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const DetailLink = useCallback(
    ({ label, href }) => (
      <Link target="_blank" rel="noopener" href={href} variant="body2">
        {label}
      </Link>
    ),
    [],
  )

  const Row = useCallback(
    ({ label, value }) => (
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">{label}:</Typography>
        {['string', 'number'].includes(typeof value) ? (
          <Typography variant="body2">{value}</Typography>
        ) : (
          value
        )}
      </Box>
    ),
    [],
  )

  const ignoreRows = [
    'id',
    'property',
    'stripeInvoiceId',
    'stripeCustomerId',
    'hostedInvoiceUrl',
  ]

  const formatValue = useCallback(({ field, value }) => {
    switch (field) {
      case 'amountDue':
        return value ? formatCurrency(value) : 'N/A'
      case 'invoicePaymentStatus':
        return <InvoiceStatusChip status={value} />
      default:
        return value
    }
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 3, pb: 0, px: 4 }}
      >
        <Box>
          Invoice Detail
          <Box display="flex" alignItems="center">
            <Typography variant="caption" color="text.secondary">
              id: {invoice?.id}
            </Typography>
            <CopyAll
              sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
              onClick={() => {
                navigator.clipboard.writeText(invoice?.id)
                doShowSnackbar('ID copied to clipboard')
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5, px: 4 }}>
        <Stack direction="column" spacing={0.5}>
          {Object.keys(invoice ?? {})
            .filter((key) => !ignoreRows.includes(key))
            .map((key) => (
              <Row
                label={humanize(underscore(key))}
                value={formatValue({ field: key, value: invoice?.[key] })}
              />
            ))}
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" mb={1}>
          Links:
        </Typography>
        <Stack direction="column" spacing={0.5}>
          <DetailLink
            label="Customer URL"
            href={`https://dashboard.stripe.com/customers/${invoice?.stripeCustomerId}`}
          />
          <DetailLink
            label="Invoice URL"
            href={`https://dashboard.stripe.com/invoices/${invoice?.stripeInvoiceId}`}
          />
          <DetailLink label="Hosted Invoice URL" href={invoice?.hostedInvoiceUrl} />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

InvoiceDetailModal.defaultProps = {
  invoice: undefined,
}

InvoiceDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    stripeCustomerId: PropTypes.string,
    stripeInvoiceId: PropTypes.string,
    hostedInvoiceUrl: PropTypes.string,
  }),
}
