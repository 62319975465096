import PropTypes from 'prop-types'

import { Grid2, List, ListItemButton, ListItemText, Typography } from '@mui/material'

export default function SubNav({ selected, setSelected }) {
  return (
    <Grid2 size={{ md: 2.5 }}>
      <List sx={{ pt: 0, '& .Mui-selected': { borderRadius: '7px' } }}>
        <ListItemButton
          selected={selected === 'threshold'}
          onClick={() => setSelected('threshold')}
        >
          <ListItemText>
            <Typography variant="subtitle2">Threshold Profile</Typography>
          </ListItemText>
        </ListItemButton>
      </List>
    </Grid2>
  )
}

SubNav.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}
