import { createEntityBundle, isAbortError } from '@common/utils'

const entityName = 'smoke_profiles'

const smokeProfileBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'schema_fetch',
      handler: async ({ apiFetch }) => {
        try {
          return await apiFetch('/smoke_profiles/schema/', null, {
            method: 'GET',
            cancelationPrefix: entityName,
          })
        } catch (err) {
          if (!isAbortError(err)) throw err
          return null
        }
      },
    },
    {
      actionName: 'make_default',
      handler: ({ apiFetch, payload }) =>
        apiFetch(`/smoke_profiles/${payload}/make_default/`, null, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default smokeProfileBundle
