import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { AutoStoriesOutlined, KeyboardArrowDown } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Grid2,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { inflect } from 'inflection'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import { Breadcrumbs, Loading, MobileList, SearchBox } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import { ConfirmationDialog, Filter, List, PageTitle } from '@rest/UI/components'
import {
  DateTimePickerCell,
  PhoneInputCell,
  StaticSelectCell,
} from '@rest/UI/components/cells'
import NavigationTabs from '@rest/UI/Navigation/NavigationTabs'

import { Messaging } from '../Configure'
import CSVPicker from './CsvPicker'
import GettingStarted from './GettingStarted'
import ReservationsNav, {
  RESERVATIONS_NAV_MESSAGING,
  RESERVATIONS_NAV_RESERVATIONS,
} from './ReservationsNav'

let newRowCounter = 0

const navOptions = [
  { label: 'Reservations', value: RESERVATIONS_NAV_RESERVATIONS },
  { label: 'Guest Messaging', value: RESERVATIONS_NAV_MESSAGING },
]
export default function Reservations({ level }) {
  const {
    routeParams: { id: entityId },
    currentOrganizationDetails: organization,
    reservationList,
    reservationListIsLoading,
    reservationListParams,
    doReservationListSetFilter,
    doReservationListSetPage,
    doReservationListSetPageSize,
    doReservationListSetSearch,
    doReservationUpload,
    doReservationSave,
    doReservationDelete,
    doFetchReservationOptions,
    doFetchReservationUnitData,
    doFetchReservationPropertyData,
    doResetReservationHelperState,
    doShowSnackbar,
  } = useConnect(
    'selectRouteParams',
    'selectCurrentOrganizationDetails',
    'selectReservationList',
    'selectReservationListIsLoading',
    'selectReservationListParams',
    'doReservationListSetFilter',
    'doReservationListSetPage',
    'doReservationListSetPageSize',
    'doReservationListSetSearch',
    'doReservationUpload',
    'doReservationSave',
    'doReservationDelete',
    'doFetchReservationOptions',
    'doFetchReservationUnitData',
    'doFetchReservationPropertyData',
    'doResetReservationHelperState',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const validationSchema = Yup.object().shape({
    unit: Yup.string().required('Unit is required'),
    checkIn: Yup.date()
      .typeError('Must be a valid date')
      .nullable()
      .required('Check In is required'),
    checkOut: Yup.date()
      .typeError('Must be a valid date')
      .nullable()
      .required('Check Out is required')
      .min(Yup.ref('checkIn'), 'Cannot be before check in'),
    phoneNumber: Yup.string().matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      {
        message: 'Enter a valid Mobile Number',
      },
    ),
    guestName: Yup.string().max(255),
  })

  const [isLoading, setIsLoading] = useState(true)

  const [navItem, setNavItem] = useState(RESERVATIONS_NAV_RESERVATIONS)

  const [propertyOptions, setPropertyOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])

  const [showGettingStarted, setShowGettingStarted] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [headerError, setHeaderError] = useState('')
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [unitEntityData, setUnitEntityData] = useState(null)
  const [propertyEntityData, setPropertyEntityData] = useState(null)

  const isMain = level === 'org'
  const isPropertyLevel = level === 'property'
  const isUnitLevel = level === 'unit'

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)

      if (isUnitLevel) {
        const unitData = await doFetchReservationUnitData(entityId)
        setUnitEntityData(unitData)
      }

      if (isPropertyLevel) {
        const propertyData = await doFetchReservationPropertyData(entityId)
        setPropertyEntityData(propertyData)
      }

      const options = await doFetchReservationOptions({
        property: isPropertyLevel ? entityId : null,
      })
      setPropertyOptions(
        options?.properties?.map((prop) => ({
          id: prop.id,
          label: prop.name,
          timezone: prop.timezone,
        })) ?? [],
      )
      setUnitOptions(
        options?.units?.map((unit) => ({
          id: unit.id,
          label: unit.name,
          propertyId: unit.property,
        })) ?? [],
      )

      await Promise.all([
        doReservationListSetFilter(
          entityId
            ? {
                ...(isPropertyLevel ? { property: entityId } : {}),
                ...(isUnitLevel ? { unit: entityId } : {}),
              }
            : {},
        ),
        doReservationListSetSearch(''),
      ])
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')

      setIsLoading(false)
    }
  }, [entityId, level])

  useEffect(() => {
    fetchData()
    return () => {
      doResetReservationHelperState()
    }
  }, [entityId, level])

  useEffect(() => {
    if (reservationListIsLoading) {
      setIsLoading(false)
    }
  }, [reservationListIsLoading])

  const selectedReservationText = inflect('Reservation', selectedRows.length)

  let columnVisibilityModel = {}

  if (!isMain) {
    columnVisibilityModel = {
      property: false,
      unit: !isUnitLevel,
    }
  }

  const hasChanges = rows?.some((r) => r.hasChanges)

  const getRowErrorCount = (row) => Object.keys(row.errors).length

  const getErrorCount = () => {
    let errorCount = 0

    rows.forEach((row) => {
      errorCount += getRowErrorCount(row)
    })

    return errorCount
  }

  const getNonFieldErrors = () => {
    const errors = []

    rows.forEach((row) => {
      if (row.errors.nonFieldErrors) {
        errors.push(row.errors.nonFieldErrors)
      }
    })

    return errors
  }

  const rowHasErrors = (row, field) => row.errors[field]?.length > 0
  const getErrorString = (row, field) => row.errors[field]?.join(' ') || ''

  const getPropertyUnitOptions = (propId) =>
    unitOptions.filter((u) => u.propertyId === propId)

  const handleUpdate = (row, field, newValue) => {
    if (row[field] === newValue) {
      return
    }

    const newRows = [...rows]
    const rowToUpdate = newRows.find((r) => r.id === row.id)

    if (!rowToUpdate) {
      return
    }

    rowToUpdate[field] = newValue
    rowToUpdate.hasChanges = true
    delete rowToUpdate.errors.nonFieldErrors

    if (field === 'property') {
      const newUnitOptions = getPropertyUnitOptions(newValue)

      if (newUnitOptions.length === 1) {
        rowToUpdate.unit = newUnitOptions[0].id
        delete rowToUpdate.errors.unit
      } else {
        rowToUpdate.unit = ''
      }
    } else {
      try {
        validationSchema.validateSyncAt(field, rowToUpdate)
        delete rowToUpdate.errors[field]
      } catch (error) {
        rowToUpdate.errors[error.path] = error.errors
      }
    }

    if (field === 'phoneNumber') {
      rowToUpdate.isPhoneValidated = false
    }

    setRows(newRows)
  }

  const PropertySelector = useCallback(
    ({ row, field }) => (
      <StaticSelectCell
        label="Property"
        hideLabel={!isSmallScreen}
        isSmallScreen={isSmallScreen}
        value={row[field]}
        options={propertyOptions}
        onChange={(newValue) => handleUpdate(row, field, newValue)}
        isNew={row.isNew}
        placeholder="Select Property"
        preallocateHelperTextSpace={isSmallScreen}
      />
    ),
    [isSmallScreen, propertyOptions, handleUpdate],
  )

  const UnitSelector = useCallback(
    ({ row, field }) => {
      const propertyUnitOptions = getPropertyUnitOptions(row.property)

      return (
        <StaticSelectCell
          label="Unit"
          hideLabel={!isSmallScreen}
          isSmallScreen={isSmallScreen}
          value={row[field]}
          options={propertyUnitOptions}
          disabled={propertyUnitOptions.length < 2}
          onChange={(newValue) => handleUpdate(row, field, newValue)}
          error={getErrorString(row, field)}
          isNew={row.isNew}
          preallocateHelperTextSpace={isSmallScreen}
          placeholder="Select Unit"
        />
      )
    },
    [isSmallScreen, handleUpdate],
  )

  const DateTimeSelector = useCallback(
    ({ row, field, headerName }) => (
      <DateTimePickerCell
        fullWidth
        label={isSmallScreen ? headerName : null}
        isSmallScreen={isSmallScreen}
        preallocateHelperTextSpace={isSmallScreen}
        value={row[field]}
        timezone={
          propertyOptions.find((property) => property.id === row.property)?.timezone
        }
        onChange={(newValue) => handleUpdate(row, field, newValue)}
        error={getErrorString(row, field)}
        isNew={row.isNew}
      />
    ),
    [isSmallScreen, propertyOptions, handleUpdate],
  )

  const PhoneInput = useCallback(
    ({ row, field, headerName }) => (
      <PhoneInputCell
        label={isSmallScreen ? headerName : null}
        fieldName={field}
        value={row[field]}
        isPhoneValidated={row.isPhoneValidated}
        onUpdate={(val) => handleUpdate(row, field, val)}
        errors={row.errors[field]}
        onKeyDown={(event) => event.stopPropagation()}
        size="small"
        InputProps={{
          sx:
            row.isNew && !rowHasErrors(row, field) && !isSmallScreen
              ? { border: '1px solid black' }
              : {},
        }}
      />
    ),
    [isSmallScreen, handleUpdate],
  )

  const GuestNameInput = useCallback(
    ({ row, field, headerName }) => {
      const error = rowHasErrors(row, field)
      const errorString = getErrorString(row, field)

      return (
        <TextField
          fullWidth
          label={isSmallScreen ? headerName : null}
          onChange={(e) => handleUpdate(row, field, e.currentTarget.value)}
          defaultValue={row[field]}
          error={error}
          helperText={isSmallScreen ? errorString || ' ' : errorString}
          size="small"
          onKeyDown={(event) => event.stopPropagation()}
          InputProps={{
            sx:
              row.isNew && !error && !isSmallScreen
                ? { border: '1px solid black' }
                : {},
          }}
        />
      )
    },
    [isSmallScreen, handleUpdate],
  )

  const columns = [
    {
      field: 'property',
      headerName: 'Property',
      flex: 1.1,
      sortable: false,
      renderCell: PropertySelector,
      renderMobile: PropertySelector,
    },
    ...(!propertyEntityData || propertyEntityData.unitCount !== 1
      ? [
          {
            field: 'unit',
            headerName: 'Unit',
            flex: 1,
            sortable: false,
            renderCell: UnitSelector,
            renderMobile: UnitSelector,
          },
        ]
      : []),
    {
      field: 'checkIn',
      headerName: 'Check In',
      flex: 1.5,
      renderCell: DateTimeSelector,
      renderMobile: DateTimeSelector,
    },
    {
      field: 'checkOut',
      headerName: 'Check Out',
      flex: 1.5,
      sortable: false,
      renderCell: DateTimeSelector,
      renderMobile: DateTimeSelector,
    },
    {
      field: 'phoneNumber',
      sortable: false,
      headerName: 'Mobile Number',
      flex: 1.6,
      renderCell: PhoneInput,
      renderMobile: PhoneInput,
    },
    {
      field: 'guestName',
      headerName: 'Guest Name',
      flex: 1,
      editable: false,
      renderCell: GuestNameInput,
      renderMobile: GuestNameInput,
    },
  ]

  const getRow = (reservation) => {
    const getPropertyId = () =>
      reservation?.property ||
      (isPropertyLevel ? entityId : (isUnitLevel && unitEntityData?.property) || null)

    const getUnitId = () =>
      reservation?.unit ||
      (isPropertyLevel && unitOptions.length === 1 ? unitOptions[0].id : '') ||
      (isUnitLevel ? entityId : null)

    return {
      id: reservation?.id || `new_${newRowCounter}`,
      property: getPropertyId(),
      unit: getUnitId(),
      checkIn: reservation?.checkIn ? DateTime.fromISO(reservation.checkIn) : null,
      checkOut: reservation?.checkOut ? DateTime.fromISO(reservation.checkOut) : null,
      phoneNumber: reservation?.phoneNumber || '',
      isPhoneValidated: reservation?.phoneNumber.length > 0,
      guestName: reservation?.guestName || '',
      hasChanges: reservation?.hasChanges || false,
      isNew: !reservation?.id,
      errors: reservation?.errors || {},
    }
  }

  const getPayload = (reservation) => ({
    id: reservation.isNew ? '' : reservation.id,
    unit: reservation.unit,
    checkIn: reservation.checkIn.toString(),
    checkOut: reservation.checkOut.toString(),
    phoneNumber: reservation.phoneNumber,
    guestName: reservation.guestName,
  })

  const addNewRow = () => {
    const newRows = [...rows]
    newRows.unshift(getRow())

    newRowCounter += 1

    setRows(newRows)
  }

  const handleSave = async () => {
    const updatedRows = []
    const rowsToUpdate = []

    const createPayload = []
    const updatePayload = []

    let allGood = true

    rows.forEach((row) => {
      const updatedRow = { ...row }
      updatedRows.push(updatedRow)

      if (updatedRow.hasChanges) {
        try {
          validationSchema.validateSync(updatedRow, { abortEarly: false })
          rowsToUpdate.push(updatedRow)

          const payload = getPayload(updatedRow)
          if (payload.id) {
            updatePayload.push(payload)
          } else {
            createPayload.push(payload)
          }
        } catch (error) {
          allGood = false

          error.inner?.forEach((err) => {
            updatedRow.errors[err.path] = err.errors
          })
        }
      }
    })

    if (!allGood) {
      setRows(updatedRows)
      return
    }

    const reservationText = inflect(
      'Reservation',
      createPayload.length + updatePayload.length,
    )

    try {
      const resultsCreate = isEmpty(createPayload)
        ? []
        : await doReservationSave(createPayload)
      const resultsUpdate = isEmpty(updatePayload)
        ? []
        : await doReservationSave(updatePayload)
      const results = [...resultsCreate, ...resultsUpdate]

      for (let i = 0, len = results.length; i < len; i += 1) {
        const result = results[i]
        const row = rowsToUpdate[i]

        row.id = result.id
        row.hasChanges = false
        row.isNew = false
        row.isPhoneValidated = true
        row.errors = {}
      }

      doShowSnackbar(`${reservationText} Saved`)
    } catch (err) {
      doShowSnackbar(`${reservationText} could not be saved`, 'error')
      const { response } = err?.error ?? {}

      if (response) {
        for (let i = 0, len = response.length; i < len; i += 1) {
          const row = rowsToUpdate[i]
          row.errors = response[i]
        }
      }
    }

    setRows(updatedRows)
  }

  const setErrorStatusMessage = () => {
    const errorCount = getErrorCount()

    if (errorCount === 0) {
      setHeaderError('')
      return
    }

    const issueText = inflect('issue', errorCount)
    const nonFieldErrors = getNonFieldErrors()
    const instructionalText =
      nonFieldErrors.length === 0
        ? `Resolve ${issueText} before saving.`
        : nonFieldErrors.join(' ')
    setHeaderError(`${errorCount} ${issueText} found. ${instructionalText}`)
  }

  useEffect(() => {
    setErrorStatusMessage()
  }, [rows])

  useEffect(() => {
    setRows(reservationList?.results?.map((reservation) => getRow(reservation)) || [])
  }, [reservationList])

  const updateSelectedRows = (selectionModel) => {
    setSelectedRows(selectionModel)
  }

  const handleDeleteConfirmationClose = (result) => {
    setShowDeleteConfirmation(false)

    if (result === true) {
      const updatedRows = rows.filter((row) => !selectedRows.includes(row.id))
      setRows(updatedRows)
      setSelectedRows([])
    }
  }

  const handleBulkDelete = async () => {
    const rowsToDelete = selectedRows.filter((row) => !row.includes('new_'))

    if (rowsToDelete.length === 0) {
      return true
    }

    const result = await doReservationDelete(rowsToDelete.map((id) => ({ id })))

    if (result.error) {
      throw parseApiErrors(result)
    }

    return result
  }

  const MobileListItem = useCallback(
    (row) => {
      const filteredColumns = columns.filter((column) =>
        column.field in columnVisibilityModel
          ? columnVisibilityModel[column.field]
          : true,
      )
      return (
        <Stack spacing={0.5} mt={1}>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return value
          })}
        </Stack>
      )
    },
    [columns],
  )

  const getListContent = () => {
    if (isLoading) {
      return <Loading />
    }

    if (rows.length > 0) {
      return (
        <Stack>
          <Stack spacing={1} sx={{ height: isSmallScreen ? null : 60 }}>
            {selectedRows.length > 0 && (
              <Button
                variant="outlined"
                size="small"
                sx={{ maxWidth: 180 }}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Delete {selectedReservationText}
              </Button>
            )}
            <Typography
              color="error"
              whitespace="pre"
              sx={{
                height: 0,
                pb: isSmallScreen && !headerError ? 0 : 3,
                fontSize: isSmallScreen ? 14 : 16,
              }}
            >
              {headerError}
            </Typography>
          </Stack>
          {isSmallScreen ? (
            <MobileList
              checkbox
              loading={reservationListIsLoading || isLoading}
              rows={rows}
              itemBuilder={MobileListItem}
              page={reservationList.current}
              pageSize={reservationList.pageSize}
              pageChange={doReservationListSetPage}
              pageSizeChange={doReservationListSetPageSize}
              rowCount={reservationList.count}
              hideFooter={reservationList.count <= reservationList.pageSize}
              onCellKeyDown={(_, events) => events.stopPropagation()}
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={updateSelectedRows}
            />
          ) : (
            <Box
              sx={{
                '& .MuiFormHelperText-root': { marginTop: 0, height: 0 },
              }}
            >
              <List
                checkbox
                autoHeight
                columns={columns}
                loading={reservationListIsLoading || isLoading}
                initialState={{ columns: { columnVisibilityModel } }}
                getRowClassName={(params) =>
                  getRowErrorCount(params.row) > 0 ? 'hasErrors' : ''
                }
                rows={rows}
                columnHeaderHeight={32}
                rowHeight={66}
                page={reservationList.current}
                pageSize={reservationList.pageSize}
                pageChange={doReservationListSetPage}
                pageSizeChange={doReservationListSetPageSize}
                rowCount={reservationList.count}
                hideFooter={reservationList.count <= reservationList.pageSize}
                onCellKeyDown={(_, events) => events.stopPropagation()}
                onRowSelectionModelChange={updateSelectedRows}
                sx={{
                  '& .MuiDataGrid-row.hasErrors': {
                    border: 1,
                    borderColor: (theme) => theme.palette.error.main,
                    borderRadius: 1,
                  },
                  '& .MuiDataGrid-cell': {
                    padding: '5px',
                  },
                  '& .MuiDataGrid-columnHeader:focus-within': {
                    border: 0,
                    outline: 'none',
                  },
                }}
              />
            </Box>
          )}
        </Stack>
      )
    }

    return (
      <Stack direction="row" variant="h5">
        <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
          No reservations found. Need help
          <Typography
            display="inline"
            variant={isSmallScreen ? 'h6' : 'h5'}
            component={Link}
            onClick={() => setShowGettingStarted(true)}
            sx={{ cursor: 'pointer', ml: 0.7 }}
          >
            getting started
          </Typography>
          ?
        </Typography>
      </Stack>
    )
  }

  return (
    <>
      <GettingStarted
        open={showGettingStarted}
        level={level}
        onClose={() => setShowGettingStarted(false)}
      />
      <ConfirmationDialog
        label={`Delete ${selectedReservationText}`}
        onClose={handleDeleteConfirmationClose}
        open={showDeleteConfirmation}
        onSave={handleBulkDelete}
        buttonText={`Yes, Delete ${selectedReservationText}`}
        successMessage={`${selectedReservationText} Deleted`}
        isFormik={false}
      >
        <Typography variant="body1" color="text.primary" sx={{ my: 2.5 }}>
          Are you sure you want to delete the selected{' '}
          {`${selectedReservationText.toLowerCase()}?`}
        </Typography>
      </ConfirmationDialog>

      {isMain && (
        <Breadcrumbs
          links={[{ label: organization?.name || '--' }, { label: 'Reservations' }]}
        />
      )}
      {isMain && <NavigationTabs />}
      {isSmallScreen && (
        <Select
          fullWidth
          variant="outlined"
          size="small"
          value={navItem}
          onChange={(e) => setNavItem(e.target.value)}
          MenuProps={{ disableScrollLock: true }}
          IconComponent={KeyboardArrowDown}
          sx={{ mb: 2, mt: 1 }}
        >
          {navOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <Grid2 container spacing={3.5} sx={{ p: isMain ? 0 : 1, pb: isMain ? 2 : 0 }}>
        {!isSmallScreen && (
          <ReservationsNav selected={navItem} setSelected={setNavItem} />
        )}
        {navItem === RESERVATIONS_NAV_RESERVATIONS && (
          <Grid2 size={{ xs: 12, md: 9.5 }} height="100%">
            <>
              <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={isSmallScreen ? null : 'center'}
                spacing={1}
              >
                <PageTitle>Reservations</PageTitle>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <SearchBox
                    title="Reservations"
                    minLength={2}
                    onSetSearch={doReservationListSetSearch}
                    sx={{ marginRight: 0 }}
                  />
                  {!isUnitLevel && (
                    <Filter
                      filterParams={reservationListParams.filter}
                      setFilterParams={doReservationListSetFilter}
                      sx={{ ml: 2 }}
                    >
                      <StaticSelect
                        key="unit"
                        label="Unit"
                        filterName="unit"
                        options={unitOptions}
                      />
                    </Filter>
                  )}
                </Stack>
              </Stack>
              <Stack spacing={3} mt={1}>
                <Stack>
                  <Typography variant="body2">
                    Guests in this list will automatically receive SMS notifications
                    when a noise alert is triggered.
                  </Typography>
                  <Typography variant="body2">
                    To review and customize the escalation message sent to guests, go to{' '}
                    <Typography
                      variant="body2"
                      component={Link}
                      onClick={() => setNavItem(RESERVATIONS_NAV_MESSAGING)}
                    >
                      Guest Messaging
                    </Typography>
                  </Typography>
                </Stack>
                <Stack
                  direction={isSmallScreen ? 'column' : 'row'}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    width={isSmallScreen ? '100%' : null}
                    justifyContent="space-evenly"
                    pb={isSmallScreen ? 0.5 : 0}
                  >
                    <Button
                      fullWidth={isSmallScreen}
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={addNewRow}
                    >
                      Add
                    </Button>
                    <Button
                      fullWidth={isSmallScreen}
                      variant="contained"
                      disabled={!hasChanges || getErrorCount() > 0}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    {hasChanges && !isSmallScreen && (
                      <Typography alignSelf="center" variant="caption" pl={1}>
                        You have unsaved changes
                      </Typography>
                    )}
                  </Stack>
                  {hasChanges && isSmallScreen && (
                    <Typography alignSelf="center" variant="caption" pl={1}>
                      You have unsaved changes
                    </Typography>
                  )}
                  <Box display="flex" gap={1} width={isSmallScreen ? '100%' : null}>
                    <CSVPicker
                      fullWidth={isSmallScreen}
                      level={level}
                      startIcon={<AutoStoriesOutlined />}
                      onImport={(data) =>
                        doReservationUpload({
                          property:
                            data.property ?? unitEntityData?.property ?? entityId,
                          file: data.file,
                        })
                      }
                      onClose={(success) => {
                        if (success === true) {
                          fetchData()
                        }
                      }}
                    />
                  </Box>
                </Stack>
                {getListContent()}
              </Stack>
            </>
          </Grid2>
        )}
        {navItem === RESERVATIONS_NAV_MESSAGING && (
          <Grid2 size={{ xs: 12, md: 9.5 }} height="100%">
            <Messaging level="tab" />
          </Grid2>
        )}
      </Grid2>
    </>
  )
}

Reservations.defaultProps = {
  level: 'org',
}

Reservations.propTypes = {
  level: PropTypes.oneOf(['org', 'property', 'unit']),
}
