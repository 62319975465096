import PropTypes from 'prop-types'

import { Chip } from '@mui/material'

import { titleize } from 'inflection'

export default function DeviceStatusChip({ status, size }) {
  const getConnectivityLabel = () => {
    switch (status) {
      case 'RMA':
        return status
      default:
        return titleize(status.toLowerCase())
    }
  }

  const getConnectivityColor = () => {
    switch (status) {
      case 'ONLINE':
      case 'PROVISIONED':
        return 'success.main'
      case 'OFFLINE':
      case 'RMA':
      case 'FAILURE':
        return 'error.main'
      default:
        return 'grey.500'
    }
  }

  return (
    <Chip
      size={size ?? 'small'}
      label={getConnectivityLabel()}
      sx={{
        backgroundColor: getConnectivityColor(),
        '& .MuiChip-label': { color: 'white' },
      }}
    />
  )
}

DeviceStatusChip.defaultProps = {
  size: undefined,
}

DeviceStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  size: PropTypes.string,
}
