import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { is, isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { Field, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Switch, TextField } from '@common/components/Form'
import { StaticMultiSelect, StaticSelect } from '@common/components/Selects'
import {
  DEFAULT_PROCESS,
  digestDeliveryOptions,
  getExistingPropertyProcess,
  GROUPING_PROCESS,
  propertyProcessConfig,
} from '@portal/pages/Properties/utils'
import FormDialog from '@portal/UI/components/FormDialog'

function GoLiveDateField(props) {
  const formik = useFormikContext()
  return (
    <DesktopDatePicker
      onChange={(value) => {
        formik.setFieldValue('goLiveDate', value)
      }}
      value={formik.values.goLiveDate}
      {...props}
    />
  )
}

function ProcessConfigSelector({
  ingestEnabled,
  instance,
  selectedProcessConfig,
  setSelectedProcessConfig,
}) {
  const formik = useFormikContext()

  const handleProcessConfigChange = (process) => {
    if (!process) {
      setSelectedProcessConfig(null)
      formik.setValues({
        ...formik.values,
        ...propertyProcessConfig[DEFAULT_PROCESS].fields,
        smokeDigestHours: [],
      })
      return
    }

    setSelectedProcessConfig(process)
    formik.setValues({
      ...formik.values,
      ...propertyProcessConfig[process].fields,
      ...(isEmpty(formik.values.smokeDigestHours)
        ? {
            smokeDigestHours: propertyProcessConfig[process].fields.reservationGrouping
              ? [9, 23]
              : [],
          }
        : formik.values.smokeDigestHours),
    })
  }

  const disabledOptions = (opt) => {
    const disabledFields = [GROUPING_PROCESS]
    return disabledFields.includes(opt) && !ingestEnabled
  }

  const getDescription = (configName) =>
    configName ? propertyProcessConfig[configName]?.desc : ''

  useEffect(() => {
    const process = getExistingPropertyProcess(instance)
    if (process) setSelectedProcessConfig(process)
    else setSelectedProcessConfig(null)
  }, [instance])

  return (
    <StaticSelect
      sx={{ marginBottom: 2 }}
      label="Property Process Type"
      value={selectedProcessConfig}
      variant="standard"
      options={Object.keys(propertyProcessConfig)}
      onChange={handleProcessConfigChange}
      getOptionDisabled={disabledOptions}
      renderOption={(optProps, option) => (
        <Box {...optProps} py={0.4} key={option.id}>
          <Stack>
            <Typography>{optProps.key}</Typography>
            <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
              {getDescription(optProps.key)}
            </Typography>
          </Stack>
        </Box>
      )}
    />
  )
}

function SmokeDigestSelector({ disableSubmit }) {
  const formik = useFormikContext()
  const selectedDigestHours = formik?.values?.smokeDigestHours

  const handleDigestHoursChange = (hourObjs) => {
    if (hourObjs.length < 1) disableSubmit(true)
    else disableSubmit(false)

    let hours = hourObjs.map((hour) => (is(Number, hour) ? hour : Number(hour.id)))

    if (formik.values?.reservationGrouping) {
      const elevenPm = 23
      const firstHour = hours.find((hour) => hour !== elevenPm)
      hours = [...(firstHour !== undefined ? [firstHour] : []), elevenPm]
    }

    const sortedHours = hours.sort((a, b) => a - b)
    formik.setFieldValue('smokeDigestHours', sortedHours)
  }

  const isOptionEqualToValue = (opt, val) => Number(opt.id) === val

  const getOptionLabel = (hour) =>
    is(Number, hour)
      ? digestDeliveryOptions.find((opt) => opt.id === hour.toString())?.label
      : hour?.label

  const isOptionDisabled = (option) =>
    !selectedDigestHours.includes(Number(option.id)) &&
    selectedDigestHours?.length === 2

  return (
    <StaticMultiSelect
      disableSort
      dynamicWidth
      sx={{ marginBottom: 2 }}
      label="Smoke Digest Hour(s)"
      value={formik?.values?.smokeDigestHours}
      variant="standard"
      options={digestDeliveryOptions}
      onChange={handleDigestHoursChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={isOptionDisabled}
      error={selectedDigestHours?.length === 0 && 'At least one hour must be selected'}
    />
  )
}

function ToggleField({ name, label, helperText }) {
  const formik = useFormikContext()
  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Field
            type="checkbox"
            component={Switch}
            name={name}
            onChange={(e) => formik.handleChange(e)}
          />
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </>
  )
}

export default function PropertySmokeConfiguration({ open, onClose, instance }) {
  const [selectedProcessConfig, setSelectedProcessConfig] = useState(null)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const { doPropertySave, propertySaveStatus } = useConnect(
    'doShowSnackbar',
    'doPropertySave',
    'selectPropertySaveStatus',
    'selectSystemTimeZones',
    'selectSystemPropertyTypes',
  )

  const validationSchema = yup.object().shape({
    goLiveDate: yup.date().nullable(),
    smokingFee: yup
      .number()
      .typeError('Please enter a valid amount')
      .test(
        'is-decimal',
        'Please enter a valid amount',
        (value) =>
          value === undefined ||
          value === 0 ||
          `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
      ),
    reservationGrouping: yup.bool(),
    suppressVacantUnitEvents: yup.bool(),
    smokeDigestHours: yup.array().of(yup.number()),
  })

  const ingestEnabled = instance?.lastReservationEmailTs

  const initialValues = {
    id: '',
    goLiveDate: null,
    smokingFee: '250.00',
    liveSmokeAlerts: false,
    autopopulateSmokeFee: false,
    installationApproved: false,
    reservationGrouping: false,
    suppressVacantUnitEvents: false,
    smokeDigestHours: [],
  }

  if (instance?.id) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
    initialValues.goLiveDate = instance.goLiveDate
      ? DateTime.fromISO(instance.goLiveDate)
      : null
  }

  const sharedStyles = { flex: 1 }

  const saveForm = async (params) =>
    doPropertySave({
      ...instance,
      ...params,
      goLiveDate: params.goLiveDate ? params.goLiveDate.toISODate() : null,
      liveSmokeAlerts: params.reservationGrouping ? false : params.liveSmokeAlerts,
    })

  return (
    <FormDialog
      label="Property Smoke Configuration"
      open={open}
      onSave={saveForm}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={propertySaveStatus === 'loading'}
      submitOptions={{ disabled: submitDisabled }}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box display="flex" gap="2rem">
          <GoLiveDateField
            label="Go-Live Date"
            name="goLiveDate"
            slotProps={{ textField: { variant: 'standard' } }}
            sx={{ width: '47%' }}
          />
          <Field
            required
            component={TextField}
            label="Smoking Fee"
            name="smokingFee"
            sx={sharedStyles}
          />
        </Box>
        <Stack>
          <ProcessConfigSelector
            instance={instance}
            ingestEnabled={ingestEnabled}
            selectedProcessConfig={selectedProcessConfig}
            setSelectedProcessConfig={setSelectedProcessConfig}
          />
          <SmokeDigestSelector disableSubmit={setSubmitDisabled} />
          <ToggleField
            name="autopopulateSmokeFee"
            label="Autopopulate smoke fee in dashboard"
            helperText="New smoke events are auto-populated with the configured smoking fee"
          />
          <ToggleField
            name="installationApproved"
            label="Installation approved"
            helperText="Burns the fuses of installed devices at the property"
          />
        </Stack>
      </Box>
    </FormDialog>
  )
}

SmokeDigestSelector.propTypes = {
  disableSubmit: PropTypes.func.isRequired,
}
ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
}

ProcessConfigSelector.propTypes = {
  ingestEnabled: PropTypes.bool.isRequired,
  instance: PropTypes.shape({}).isRequired,
  selectedProcessConfig: PropTypes.string.isRequired,
  setSelectedProcessConfig: PropTypes.func.isRequired,
}

PropertySmokeConfiguration.defaultProps = {
  instance: {},
}

PropertySmokeConfiguration.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    goLiveDate: PropTypes.string,
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
    lastReservationEmailTs: PropTypes.string,
    smokeDigestHours: PropTypes.arrayOf(PropTypes.number),
    suppressVacantUnitEvents: PropTypes.bool,
  }),
}
