import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

export default function OutageForm({ open, onClose, instance }) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [formikProps, setFormikProps] = useState({
    values: {},
    setFieldValue: () => {},
  })

  const validationSchema = yup.object().shape({
    snoozedUntil: yup.string().nullable(),
    comments: yup.string(),
  })

  const initialValues = {
    id: '',
    snoozedUntil: '',
    comments: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field]
    })
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const updateOutage = async (payload) => {
    try {
      const result = await apiFetch(
        '/property_outages/',
        { ...payload, snoozedUntil: payload.snoozedUntil || null },
        {
          method: 'PUT',
          cancelationPrefix: 'outage_form',
        },
      )
      return result
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }

    return null
  }

  const sx = { flex: 1 }

  return (
    <FormDialog
      label="Update Outage"
      open={open}
      onSave={updateOutage}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      successMessage="Outage has been updated!"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Field
          component={DateTimePicker}
          variant="standard"
          label="Snoozed Until"
          name="snoozedUntil"
          slotProps={{
            textField: { variant: 'standard' },
            field: { clearable: true },
          }}
          value={
            formikProps?.values?.snoozedUntil
              ? DateTime.fromISO(formikProps?.values?.snoozedUntil)
              : null
          }
          onChange={(value) => {
            formikProps?.setFieldValue('snoozedUntil', value?.toISO())
          }}
          sx={sx}
        />
        <Field component={TextField} label="Comments" name="comments" sx={sx} />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}

OutageForm.defaultProps = {
  instance: undefined,
}

OutageForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    snoozedUntil: PropTypes.string,
    comments: PropTypes.string,
  }),
}
