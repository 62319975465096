import { useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Box, Button, MenuItem, TextField } from '@mui/material'

import { parseApiErrors } from '@common/utils'
import IntegrationCard from '@portal/pages/Properties/Tabs/Integrations/Card'

import AutochargeParamsForm from './AutoChargeParamsForm'
import AutoChargeTestErrorModal from './AutoChargeTestErrorModal'

export default function AutoChargeCard({
  property,
  chargeTypes,
  saveProperty,
  showSnackBar,
  fetchProperty,
  testAutoCharge,
  isAtLeastAdmin,
}) {
  const [formikProps, setFormikProps] = useState({
    validateForm: () => {},
    resetForm: () => {},
  })
  const [testAutochargeError, setTestAutochargeError] = useState('')

  const [selectedIntegration, setSelectedIntegration] = useState(
    property?.smokeAutocharge,
  )
  const [apiValues, setApiValues] = useState({})
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [apiErrors, setApiErrors] = useState({})
  const [submittingDisabled, setSubmittingDisabled] = useState(false)

  const handleSubmit = async (shouldShowSnackBar = true) => {
    const params = apiValues?.smokeAutochargeParams
    const cleanedAutochargeParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== '') acc[key] = params[key]
      return acc
    }, {})

    const validationErrors = await formikProps.validateForm()
    if (!isEmpty(validationErrors)) return

    try {
      const res = await saveProperty({
        ...property,
        smokeAutocharge: selectedIntegration,
        smokeAutochargeParams: cleanedAutochargeParams,
      })

      if (res?.id) {
        fetchProperty(property?.id)
        setApiErrors({})
        if (shouldShowSnackBar)
          showSnackBar('Auto charge integration updated successfully.')
      }
    } catch (err) {
      if (err?.error && err?.response) {
        setApiErrors(err.response)
      } else {
        const parsedError = parseApiErrors(err)
        if (shouldShowSnackBar)
          showSnackBar(parsedError || 'Oops. An unexpected error occurred', 'error')
      }
    }
  }

  const handleTest = async () => {
    if (formIsDirty) await handleSubmit(false)

    const response = await testAutoCharge(property?.id)
    if (response?.ok) {
      showSnackBar('Auto charge integration test successful')
    } else {
      setTestAutochargeError(
        response?.detail ||
          'An error occurred while testing the auto charge integration.',
      )
    }
  }

  const handleDisable = async () => {
    try {
      await saveProperty({ ...property, smokeAutocharge: '' })
      await fetchProperty(property?.id)

      formikProps.resetForm()

      showSnackBar('Auto charge integration disabled.')
    } catch (err) {
      const parsedError = parseApiErrors(err)
      showSnackBar(parsedError, 'error')
    }
  }

  const enabled = Boolean(property?.smokeAutocharge)

  return (
    <>
      <AutoChargeTestErrorModal
        open={testAutochargeError !== ''}
        onClose={() => setTestAutochargeError('')}
        error={testAutochargeError}
      />
      <IntegrationCard
        title="Smoke Auto Charge"
        subheader={`Manage the auto charge integration for ${property?.name}.`}
        enabled={enabled}
        content={
          <Box display="flex" flexDirection="column" gap={1}>
            <TextField
              select
              value={selectedIntegration}
              label="Smoke Auto Charge Integration *"
              variant="standard"
              onChange={(e) => setSelectedIntegration(e.target.value)}
            >
              {chargeTypes.map(({ value, name }) => (
                <MenuItem key={name} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <AutochargeParamsForm
              property={property}
              apiErrors={apiErrors}
              setApiValues={setApiValues}
              setFormIsDirty={setFormIsDirty}
              setFormikProps={setFormikProps}
              integrationType={selectedIntegration}
              setSubmittingDisabled={setSubmittingDisabled}
            />
          </Box>
        }
        actions={
          <Box width="100%" display="flex" justifyContent="space-between">
            <Button
              color="error"
              disabled={!isAtLeastAdmin || !enabled}
              onClick={handleDisable}
              sx={{
                visibility:
                  property?.smokeAutocharge || selectedIntegration
                    ? 'visible'
                    : 'hidden',
              }}
            >
              Disable
            </Button>

            <Box width="100%" display="flex" justifyContent="flex-end" gap={2}>
              <Button color="secondary" onClick={handleTest}>
                Test
              </Button>
              <Button
                color="secondary"
                disabled={submittingDisabled || !selectedIntegration || !isAtLeastAdmin}
                onClick={handleSubmit}
              >
                {property?.smokeAutocharge ? 'Update' : 'Enable'}
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  )
}

AutoChargeCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    emailIngestionIdentifier: PropTypes.string,
    smokeAutocharge: PropTypes.string,
    smokeAutochargeParams: PropTypes.shape({
      chain: PropTypes.string,
      apiId: PropTypes.string,
      resort: PropTypes.string,
      apiKey: PropTypes.string,
      transactionCode: PropTypes.string,
      rateCodes: PropTypes.shape({}),
    }),
  }).isRequired,
  saveProperty: PropTypes.func.isRequired,
  testAutoCharge: PropTypes.func.isRequired,
  showSnackBar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  chargeTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAtLeastAdmin: PropTypes.bool.isRequired,
}
