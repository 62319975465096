import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { FileDownload, PieChartRounded } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

import { DateTime } from 'luxon'

import { SearchBox } from '@common/components'
import { downloadFile, useSmallScreen } from '@common/utils'
import { AdjustmentSummaryModal } from '@rest/UI/components'
import { formatCSVData } from '@rest/Utils/csv'

import ChargeSummary from './ChargeSummary'
import PropertyEventList from './PropertyEventList'

export default function PropertyEvents({ property }) {
  const {
    propertyEventListRaw: { filter = {} },
    doPropertyEventListSetFilter,
    doPropertyEventListSetOrdering,
    doPropertyEventListSetSearch,
    doPropertyEventListExport,
  } = useConnect(
    'selectPropertyEventListRaw',
    'doPropertyEventListSetFilter',
    'doPropertyEventListSetOrdering',
    'doPropertyEventListSetSearch',
    'doPropertyEventListExport',
  )

  const isSmallScreen = useSmallScreen()

  const defaultEnd = DateTime.now()
  const defaultStart = DateTime.now().minus({ days: 7 })

  const [dateRangeValue, setDateRangeValue] = useState([defaultStart, defaultEnd])
  const [adjustmentSummaryOpen, setAdjustmentSummaryOpen] = useState(false)

  useEffect(() => {
    doPropertyEventListSetSearch()
    doPropertyEventListSetOrdering(['createdOn', 'desc'])
  }, [property])

  useEffect(() => {
    const startDate = dateRangeValue[0] ? dateRangeValue[0] : null
    const endDate = dateRangeValue[1] ? dateRangeValue[1] : null

    if (startDate && endDate && startDate < endDate) {
      let eventClasses = 'SMOKE'
      if (property.expandedFlags?.includes('NOISE')) {
        eventClasses += ',NOISE'
      }

      doPropertyEventListSetFilter({
        ...filter,
        startDate,
        endDate,
        eventClass: eventClasses,
      })
    }
  }, [dateRangeValue, property])

  return (
    <>
      <AdjustmentSummaryModal
        open={adjustmentSummaryOpen}
        dateRange={dateRangeValue}
        onClose={() => setAdjustmentSummaryOpen(false)}
      />
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmallScreen ? 'start' : 'center'}
        spacing={2}
        mb={3}
        mt={1.5}
      >
        {!isSmallScreen && (
          <SearchBox
            title="Events"
            onSetSearch={doPropertyEventListSetSearch}
            minLength={0}
          />
        )}
        <Box display="flex" flexDirection="row" width={isSmallScreen ? '100%' : null}>
          <Button
            fullWidth={isSmallScreen}
            data-testid="adjustment_summary"
            variant="contained"
            startIcon={!isSmallScreen ? <PieChartRounded /> : null}
            onClick={() => setAdjustmentSummaryOpen(true)}
          >
            Adjustment Summary
          </Button>
          <Button
            fullWidth={isSmallScreen}
            data-testid="export_events"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={async () => {
              const result = await doPropertyEventListExport()
              const data = formatCSVData(result, (event) => ({
                unitName: 'Unit Name',
                zoneName: 'Zone Name',
                eventClass: 'Type',
                createdOn: 'Created On',
                endTime: 'End Time',
                lastOccurrence: 'Last Occurrence',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.comments`]: 'Comments',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.modifiedByName`]: 'Modified By',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.modifiedOn`]: 'Modified On',
                'smokeFeedback.adjustmentReason': 'Adjustment Reason',
                'smokeFeedback.adjustedOn': 'Adjusted On',
                'smokeFeedback.netChargeAmount': 'Net Charge Amount',
              }))
              downloadFile({
                data: [data],
                fileName: `${property.name}_events_export.csv`,
                fileType: 'text/csv',
              })
            }}
            startIcon={!isSmallScreen ? <FileDownload /> : null}
          >
            Export
          </Button>
        </Box>
      </Stack>

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        mb={3}
      >
        <ChargeSummary />

        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <DateRangePicker
            disableMaskedInput
            value={dateRangeValue}
            maxDate={defaultEnd}
            onChange={setDateRangeValue}
            slotProps={{
              fieldSeparator: { children: 'and' },
              textField: {
                style: { width: !isSmallScreen ? 150 : null },
                size: isSmallScreen ? 'small' : 'medium',
                required: true,
              },
            }}
          />
        </Stack>
      </Stack>
      <PropertyEventList id={property.id} property={property} />
    </>
  )
}

PropertyEvents.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}
