import PropTypes from 'prop-types'

import { ToggleButton, Tooltip } from '@mui/material'

// workaround to be able to show a tooltip on the disabled button
export default function ToggleButtonWithTooltip({
  children,
  value,
  disabled,
  tooltip,
  sx,
  ...rest
}) {
  const styles = { ...sx }

  if (disabled) {
    Object.assign(styles, {
      color: 'grey.400',
      cursor: 'default',
      '&.MuiButtonBase-root:hover': {
        bgcolor: rest.selected ? 'rgba(94, 71, 93, 0.08)' : 'transparent',
      },
    })
  }

  return (
    <ToggleButton
      value={value}
      sx={styles}
      {...rest}
      onChange={disabled ? null : rest.onChange}
      disableRipple={disabled ? true : rest.disableRipple}
    >
      <Tooltip title={tooltip}>
        <span style={{ display: 'flex', alignItems: 'center' }}>{children}</span>
      </Tooltip>
    </ToggleButton>
  )
}

ToggleButtonWithTooltip.defaultProps = {
  disabled: false,
  tooltip: '',
  sx: {},
}

ToggleButtonWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
    .isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.shape({}),
}
