import PropTypes from 'prop-types'

import { Link, Typography } from '@mui/material'

import ListTextTooltipWrapper from '@common/components/ListTextTooltipWrapper'
import { useSmallScreen } from '@common/utils'

export default function ClickableCell({ label, url, renderNoValue, ...params }) {
  const isSmallScreen = useSmallScreen()
  const fontSize = isSmallScreen ? 12 : 14

  if (label) {
    return (
      <ListTextTooltipWrapper tooltip={label}>
        <Link noWrap href={url} onClick={(e) => e.stopPropagation()}>
          <Typography noWrap fontSize={fontSize} {...params}>
            {label}
          </Typography>
        </Link>
      </ListTextTooltipWrapper>
    )
  }
  return renderNoValue ? (
    renderNoValue()
  ) : (
    <Typography variant={isSmallScreen ? 'caption' : 'body1'} fontSize={fontSize}>
      --
    </Typography>
  )
}

ClickableCell.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired,
  renderNoValue: PropTypes.func,
}

ClickableCell.defaultProps = {
  label: '',
  renderNoValue: undefined,
}
