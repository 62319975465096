/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { sum, uniq } from 'ramda'

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { formatMetricValue, getMetricsDateRange } from '@common/utils'

function MetricTable({ data, groupBy, selectedMetrics }) {
  const stickyStyle = {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 800,
  }

  const filteredData = useMemo(() => {
    if (selectedMetrics && groupBy === 'entity') {
      return data.filter((item) => selectedMetrics[item.metric])
    }
    return data
  }, [data, selectedMetrics])

  const { headerDates, propertyDateMap, dateValues } = useMemo(() => {
    const dateMap = {}
    const entityValues = {}

    const dates = uniq(
      filteredData.reduce((acc, entity) => {
        acc.push(
          ...entity.data.map((d) => {
            dateMap[`${entity.key}|${d.date}`] = d.value
            if (!(d.date in entityValues)) {
              entityValues[d.date] = []
            }
            entityValues[d.date].push(d.value)
            return {
              start: d.date,
              end: d.bucketEndDate,
              displayData: d.displayData,
            }
          }),
        )
        return acc
      }, []),
    ).sort()

    return {
      headerDates: dates,
      propertyDateMap: dateMap,
      dateValues: entityValues,
    }
  }, [filteredData])

  const calculatePercentageDifference = (oldValue, newValue) =>
    ((newValue - oldValue) / ((Math.abs(oldValue) + Math.abs(newValue)) / 2)) * 100

  const getDiffData = (prevValue, currentValue) => {
    const diff =
      typeof prevValue === 'number' && typeof currentValue === 'number'
        ? calculatePercentageDifference(prevValue, currentValue)
        : null
    if (diff === null) {
      return null
    }

    let color = 'teal'
    let icon = ''

    if (diff > 0) {
      icon = '↑'
      color = 'green'
    } else if (diff < 0) {
      icon = '↓'
      color = 'red'
    }

    return {
      formattedValue: Number.isNaN(diff) ? 0 : Math.abs(diff.toFixed(1)),
      icon,
      color,
    }
  }

  const getPrevDiffItem = (i) => {
    let prevItem = i !== 0 ? headerDates[i - 1] : null
    if (prevItem !== null && !!prevItem.displayData?.showSuffix) {
      prevItem = i !== 0 ? headerDates.at(i - 2) : null
    }
    return prevItem
  }

  const hasData = useMemo(() => Object.keys(dateValues).length > 0, [dateValues])

  const { symbol, symbolPosition } = data[0] ?? {}

  if (!hasData) {
    return (
      <Typography m={4} textAlign="center">
        No Data
      </Typography>
    )
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', zIndex: 900 }}>
              {groupBy === 'metric' ? 'Entity' : 'Metric'}
            </TableCell>
            {headerDates.map((pointDate, i) => {
              const displayPointDate = (() => {
                const { displayData } = pointDate
                if (displayData) {
                  return displayData.end
                }
                return pointDate.end ?? pointDate.start
              })()
              const displayBucketStart = (() => {
                const { displayData } = pointDate
                if (displayData) {
                  return displayData.start
                }
                return pointDate.end ? pointDate.start : null
              })()

              const suffix = (() => {
                const { displayData } = pointDate
                if (!displayData || !displayData.showSuffix) {
                  return null
                }
                return displayData.isProjected ? 'projected' : 'actual'
              })()

              return (
                <TableCell
                  key={`${pointDate.start}_${i}`}
                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                >
                  {getMetricsDateRange(displayPointDate, displayBucketStart, suffix)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((d) => (
            <TableRow key={d.name}>
              <TableCell style={stickyStyle}>{d.name}</TableCell>
              {headerDates.map((hd, i) => {
                const prevItem = getPrevDiffItem(i)
                const prevValue = prevItem
                  ? propertyDateMap?.[`${d.key}|${prevItem.start}`]
                  : null
                const currentValue = propertyDateMap?.[`${d.key}|${hd.start}`]
                const diffData = getDiffData(prevValue, currentValue)

                return (
                  <TableCell key={`${hd.start}_${i}`} style={{ textAlign: 'right' }}>
                    <Box display="flex" justifyContent="flex-end">
                      {formatMetricValue({
                        value: currentValue,
                        symbol: d.symbol,
                        position: d.symbolPosition,
                      }) ?? '--'}
                      {diffData && (
                        <Typography
                          display="flex"
                          color={diffData.color}
                          sx={{ fontSize: 13, ml: 1 }}
                        >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {groupBy !== 'entity' ? (
            <TableRow>
              <TableCell style={stickyStyle}>Average</TableCell>

              {headerDates.map((hd, i) => {
                const prevItem = getPrevDiffItem(i)
                const prevValue = prevItem
                  ? sum(dateValues[prevItem.start]) / dateValues[prevItem.start].length
                  : null
                const currentValue =
                  sum(dateValues[hd.start]) / dateValues[hd.start].length
                const diffData = getDiffData(prevValue, currentValue)

                return (
                  <TableCell key={hd.start} style={{ textAlign: 'right' }}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      {formatMetricValue({
                        value: currentValue,
                        symbol,
                        position: symbolPosition,
                      })}
                      {diffData && (
                        <Typography
                          display="flex"
                          color={diffData.color}
                          sx={{ fontSize: 12, ml: 1, opacity: 0.65 }}
                        >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ) : null}
          {symbol !== '%' && groupBy !== 'entity' ? (
            <TableRow>
              <TableCell style={stickyStyle}>Total</TableCell>
              {headerDates.map((hd, i) => {
                const prevItem = getPrevDiffItem(i)
                const prevValue = prevItem ? sum(dateValues[prevItem.start]) : null
                const currentValue = sum(dateValues[hd.start])
                const diffData = getDiffData(prevValue, currentValue)
                return (
                  <TableCell key={hd.start} style={{ textAlign: 'right' }}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      {formatMetricValue({
                        value: currentValue,
                        symbol,
                        position: symbolPosition,
                      })}
                      {diffData && (
                        <Typography
                          display="flex"
                          color={diffData.color}
                          sx={{ fontSize: 12, ml: 1, opacity: 0.65 }}
                        >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ) : null}
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

MetricTable.defaultProps = {
  groupBy: 'metric',
  selectedMetrics: {},
}

MetricTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      symbol: PropTypes.string.isRequired,
      symbolPosition: PropTypes.string.isRequired,
    }),
  ).isRequired,
  groupBy: PropTypes.oneOf(['metric', 'entity']),
  selectedMetrics: PropTypes.shape({}),
}

export default MetricTable
