import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { alpha, Collapse, styled, Tooltip, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'

const StyledListItem = styled(ListItem)({
  '& .Mui-selected': { backgroundColor: '#4840ba !important' },
  '&:hover': {
    backgroundColor: `${alpha('#4840ba', 0.5)} !important`,
  },
})

const urlMatches = (current, other) => {
  if (current === homeUrls.home || other === homeUrls.home) {
    return current === other
  }
  return current.indexOf(other?.replace(/\?.*$/, '')) === 0
}

const urlIsCurrent = (url, currentURL, childrenURLs) =>
  urlMatches(currentURL, url) ||
  childrenURLs.some((listUrl) => urlMatches(currentURL, listUrl))

function SidebarMenuItem({ open, icon, label, href, subitems, currentURL }) {
  const { doCloseDrawer } = useConnect('doCloseDrawer')

  const isSmallScreen = useSmallScreen()

  const [openNestedList, setOpenNestedList] = useState(false)

  const IconComponent = icon
  const hasChildren = subitems.length

  let ExpandIcon = null
  if (hasChildren) {
    ExpandIcon = openNestedList ? ExpandLess : ExpandMore
  }

  useEffect(() => {
    if (
      open &&
      urlIsCurrent(
        href,
        currentURL,
        subitems.map((subitem) => subitem.href),
      )
    )
      setOpenNestedList(true)
  }, [])

  const itemOnClick = () => {
    if (hasChildren) {
      setOpenNestedList(!openNestedList)
    } else if (isSmallScreen) {
      doCloseDrawer()
    }
  }

  const isActive = currentURL === href

  return (
    <>
      <StyledListItem disablePadding>
        <ListItemButton
          href={isActive ? null : href}
          selected={urlIsCurrent(
            href,
            currentURL,
            subitems.map((subitem) => subitem.href),
          )}
          onClick={itemOnClick}
          disableRipple={isActive}
          sx={{ cursor: isActive ? 'default' : 'pointer' }}
        >
          {open ? (
            <ListItemIcon>
              <IconComponent sx={{ color: 'white' }} />
            </ListItemIcon>
          ) : (
            <Tooltip placement="right" title={label}>
              <ListItemIcon>
                <IconComponent sx={{ color: 'white' }} />
              </ListItemIcon>
            </Tooltip>
          )}
          <ListItemText disableTypography>
            <Typography variant="body2">{label}</Typography>
          </ListItemText>
          {ExpandIcon && <ExpandIcon sx={{ color: 'grey.400' }} />}
        </ListItemButton>
      </StyledListItem>
      {open && hasChildren ? (
        <Collapse in={openNestedList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subitems.map((subitem) => {
              const isSubitemActive = subitem.href === currentURL
              return (
                <StyledListItem key={subitem.label} disablePadding>
                  <ListItemButton
                    key={subitem.label}
                    href={isSubitemActive ? null : subitem.href}
                    disableRipple={isSubitemActive}
                    selected={urlIsCurrent(subitem.href, currentURL, [])}
                    sx={{
                      ml: 4,
                      borderLeft: '2px solid',
                      borderLeftColor: 'grey.300',
                      cursor: isSubitemActive ? 'default' : 'pointer',
                    }}
                    onClick={isSmallScreen ? () => doCloseDrawer() : null}
                  >
                    <ListItemText disableTypography>
                      <Typography variant="body2">{subitem.label}</Typography>
                    </ListItemText>
                  </ListItemButton>
                </StyledListItem>
              )
            })}
          </List>
        </Collapse>
      ) : null}
    </>
  )
}

SidebarMenuItem.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  subitems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
  currentURL: PropTypes.string.isRequired,
}

SidebarMenuItem.defaultProps = {
  subitems: [],
  href: undefined,
}

export default SidebarMenuItem
