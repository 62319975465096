/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { AxisBottom } from '@visx/axis'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.scale === next.scale &&
    prev.hideAxisLine === next.hideAxisLine &&
    prev.hideTicks === next.hideTicks &&
    prev.curve === next.curve &&
    prev.top === next.top &&
    prev.numTicks === next.numTicks &&
    prev.orientation === next.orientation &&
    prev.stroke === next.stroke &&
    prev.tickStroke === next.tickStroke &&
    prev.tickFormat?.toString() === next.tickFormat?.toString() &&
    prev.tickLabelProps?.toString() === next.tickLabelProps?.toString() &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.tickValues, next.tickValues) &&
    equals(prev.graphData, next.graphData) &&
    equals(prev.padding, next.padding)
  )
}

// 'graphData', 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoAxisBottom = memo(
  // eslint-disable-next-line no-unused-vars
  ({
    id,
    scale,
    hideAxisLine,
    hideTicks,
    curve,
    top,
    numTicks,
    orientation,
    stroke,
    tickStroke,
    tickFormat,
    tickValues,
    tickLabelProps,
    graphData,
    graphWidth,
    padding,
  }) => (
    <AxisBottom
      key={id}
      scale={scale}
      hideAxisLine={hideAxisLine}
      hideTicks={hideTicks}
      curve={curve}
      top={top}
      numTicks={numTicks}
      orientation={orientation}
      stroke={stroke}
      tickStroke={tickStroke}
      tickFormat={tickFormat}
      tickValues={tickValues}
      tickLabelProps={tickLabelProps}
      pointerEvents="none"
    />
  ),
  propsAreEqual,
)

MemoAxisBottom.defaultProps = {
  hideAxisLine: false,
  hideTicks: true,
  numTicks: undefined,
  stroke: undefined,
  tickStroke: undefined,
  tickFormat: undefined,
  tickValues: undefined,
  tickLabelProps: undefined,
  graphData: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoAxisBottom.propTypes = {
  id: PropTypes.string.isRequired,
  scale: PropTypes.func.isRequired,
  hideAxisLine: PropTypes.bool,
  hideTicks: PropTypes.bool,
  curve: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  orientation: PropTypes.string.isRequired,
  numTicks: PropTypes.number,
  stroke: PropTypes.string,
  tickStroke: PropTypes.string,
  tickFormat: PropTypes.func,
  tickValues: PropTypes.arrayOf(PropTypes.shape({})),
  tickLabelProps: PropTypes.func,
  graphWidth: PropTypes.number,
  graphData: PropTypes.arrayOf(PropTypes.shape({})),
  padding: PropTypes.shape({}),
}

export default MemoAxisBottom
