import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { FormikDynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

export default function AlertingProfileForm({ open, onClose, properties }) {
  const [loading, setLoading] = useState(false)
  const [confirmationData, setConfirmationData] = useState(null)
  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setFieldValue: () => {},
  })

  const { doPropertySetAlertingProfile, doShowSnackbar } = useConnect(
    'doPropertySetAlertingProfile',
    'doShowSnackbar',
  )

  const initialValues = {
    profile: '',
  }

  const validationSchema = yup.object().shape({
    profile: yup.string().uuid().nullable(),
  })

  const handleSave = useCallback(
    async (profileId) => {
      setLoading(true)
      try {
        await doPropertySetAlertingProfile({
          ids: properties.map((property) => property.id),
          profileId: profileId || null,
        })
        doShowSnackbar('Alerting profile has been changed', 'success')
        onClose(true)
      } catch (err) {
        const errorMessage = parseApiErrors(err)
        doShowSnackbar(errorMessage, 'error')
      } finally {
        setLoading(false)
      }
    },
    [properties],
  )

  return (
    <>
      <ConfirmationDialog
        open={!!confirmationData}
        title="Confirm Alerting Profile Change"
        message={`Affected properties:\n\n ${properties
          ?.map((property) => property.name)
          .join('\n')}`}
        fullWidth={false}
        onConfirm={() => {
          setConfirmationData(null)
          handleSave(confirmationData.profile)
        }}
        onCancel={() => setConfirmationData(null)}
      />
      <FormDialog
        label="Modify Alerting Profile"
        open={open}
        onSave={(data) => setConfirmationData(data)}
        onClose={onClose}
        isLoading={loading}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
          <Field
            fullWidth
            alwaysShowPlaceholder
            component={FormikDynamicSelect}
            useValueInRequest={false}
            label="Smoke Profile"
            name="profile"
            formatInputValue={(option) =>
              option ? `${option.modelKey} - ${option.name}` : option
            }
            value={formikProps.values.profile}
            showError={!!formikProps.errors.profile}
            error={formikProps.errors.profile}
            placeholder="Default"
            endpoint="smoke_profiles"
            secondaryTextAttr="modelKey"
            filters={{ status: ['AVAILABLE', 'DEFAULT_ALERTING'].join(',') }}
            inputStyle={{ '& ::placeholder': { fontStyle: 'italic' } }}
          />
        </Box>
        <FormikStatePropagator propSetter={setFormikProps} />
      </FormDialog>
    </>
  )
}

AlertingProfileForm.defaultProps = {
  properties: null,
}

AlertingProfileForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})),
}
