import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  Assessment,
  FileDownload,
  HistoryToggleOff,
  NotificationsActive as UnMutedIcon,
  NotificationsOff as MutedIcon,
  PieChartRounded,
  RateReview,
  SmokingRooms,
} from '@mui/icons-material'
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid'
import { DateRangePicker } from '@mui/x-date-pickers-pro'

import { ErrorMessage } from 'formik'
import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { Loading, MobileList, MobileListCardRow, SearchBox } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import {
  downloadFile,
  formatCurrency,
  isRequestError,
  useSmallScreen,
} from '@common/utils'
import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'
import AdditionalOccurenciesModal from '@rest/pages/Properties/PropertyEvents/AdditionalOccurenciesModal'
import EditFeedback from '@rest/pages/Properties/PropertyEvents/EditFeedback'
import EventReport from '@rest/pages/Properties/PropertyEvents/EventReport'
import PostCharge from '@rest/pages/Properties/PropertyEvents/PostCharge'
import {
  renderCreatedOnCell,
  renderEventTypeColumn,
  renderLastModifiedCell,
  renderNetChargeCell,
} from '@rest/pages/Properties/PropertyEvents/propertyEventListCellRenderers'
import PropertyEventListRowDetail from '@rest/pages/Properties/PropertyEvents/PropertyEventListRowDetail'
import NoiseReport from '@rest/pages/Properties/PropertyEvents/Report/NoiseReport/NoiseReport'
import SmokeReport from '@rest/pages/Properties/PropertyEvents/Report/SmokeReport'
import { AdjustmentSummaryModal, ConfirmationDialog, List } from '@rest/UI/components'
import { GridCellExpand } from '@rest/UI/components/cells'
import { checkPermissions, PrivilegeCheck } from '@rest/Utils'
import { formatCSVData } from '@rest/Utils/csv'

import EventComment from '../EventComment'

function UnitEventsEmptyState({ isHotel }) {
  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <SmokingRooms sx={{ fontSize: 60, mb: 2 }} />
      No events have been detected for this {`${isHotel ? 'room' : 'unit'}`} yet.
    </Stack>
  )
}

export default function EventsTab({ property, unit }) {
  const {
    currentRole: role,
    unitEventList,
    unitEventListRaw: { ordering = [] },
    unitEventListIsLoading,
    smokeAdjustmentReasons,
    doUnitEventListSetPage,
    doUnitEventListSetOrdering,
    doUnitEventListSetFilter,
    doMarkUnitEventListAsOutdated,
    doUnitEventListSetSearch,
    doUnitEventListExport,
    doUnitSave,
  } = useConnect(
    'selectCurrentRole',
    'selectUnitEventList',
    'selectUnitEventListRaw',
    'selectUnitEventListIsLoading',
    'selectSmokeAdjustmentReasons',
    'doUnitEventListSetPage',
    'doUnitEventListSetOrdering',
    'doUnitEventListSetFilter',
    'doMarkUnitEventListAsOutdated',
    'doUnitEventListSetSearch',
    'doUnitEventListExport',
    'doUnitSave',
  )

  const isSmallScreen = useSmallScreen()

  const defaultEnd = DateTime.now()
  const defaultStart = DateTime.now().minus({ days: 30 })

  const activePermissions = role?.permissions ?? []
  const isHotel = property?.propertyType === 'HOTEL'

  const [isNoise, setIsNoise] = useState(false)
  const [additionalOccurenciesData, setAdditionalOccurenciesData] = useState(null)

  const [dateRangeValue, setDateRangeValue] = useState([defaultStart, defaultEnd])
  const [commentsFormOpen, setCommentsFormOpen] = useState(false)
  const [postFormOpen, setPostFormOpen] = useState(false)
  const [adjustFormOpen, setAdjustFormOpen] = useState(false)
  const [eventReportOpen, setEventReportOpen] = useState(false)
  const [oldEventReportOpen, setOldEventReportOpen] = useState(false)
  const [muteContext, setMuteContext] = useState({ isMute: false })
  const [activeInstance, setActiveInstance] = useState(null)
  const [adjustmentSummaryOpen, setAdjustmentSummaryOpen] = useState(false)

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  useEffect(() => {
    setIsNoise(property?.expandedFlags?.includes('NOISE') || false)
    return () => {
      doUnitEventListSetSearch('')
    }
  }, [property])

  useEffect(() => {
    if (!property) return

    if (property) {
      const startDate = dateRangeValue[0] ? dateRangeValue[0] : null
      const endDate = dateRangeValue[1] ? dateRangeValue[1] : null

      if (startDate && endDate && startDate < endDate) {
        let eventClasses = 'SMOKE'
        if (property.expandedFlags?.includes('NOISE')) {
          eventClasses += ',NOISE'
        }
        doUnitEventListSetFilter({
          startDate,
          endDate,
          eventClass: eventClasses,
        })
        doMarkUnitEventListAsOutdated()
      }
    }
  }, [dateRangeValue, property, unit])

  const toggleMute = useCallback(
    async (mute) => {
      if (unit) {
        const updatedUnit = { ...unit }
        updatedUnit.muteNotificationsStart = mute ? DateTime.now().toISO() : null
        updatedUnit.muteNotificationsEnd = mute
          ? DateTime.now().plus({ days: 1 }).toISO()
          : null

        const saveResult = await doUnitSave(updatedUnit)
        if (isRequestError(saveResult)) {
          throw saveResult
        }
        doMarkUnitEventListAsOutdated()
      }
    },
    [unit],
  )

  const isUnitMuted = (event) => {
    const now = DateTime.now()
    if (event.unitMuteNotificationsStart) {
      const muteStart = DateTime.fromISO(event.unitMuteNotificationsStart)
      if (event.unitMuteNotificationsEnd) {
        const muteEnd = DateTime.fromISO(event.unitMuteNotificationsEnd)
        return muteStart < now < muteEnd
      }
      return now > muteStart
    }
    return false
  }

  const getMuteButton = (row, mobileView) => {
    if (row.unit && isUnitMuted(row)) {
      return (
        <Tooltip title="Unmute this room">
          <Button
            fullWidth={mobileView}
            variant="outlined"
            startIcon={<MutedIcon />}
            size="small"
            color="error"
            onClick={() =>
              setMuteContext({ unitName: row.unitName, unit: row.unit, isMute: false })
            }
          >
            Unmute
          </Button>
        </Tooltip>
      )
    }
    return (
      <Tooltip title="Mute all notifications for this room for 24 hours">
        <Button
          fullWidth={mobileView}
          variant="outlined"
          startIcon={<UnMutedIcon />}
          size="small"
          color="secondary"
          onClick={() =>
            setMuteContext({ unitName: row.unitName, unit: row.unit, isMute: true })
          }
        >
          Mute
        </Button>
      </Tooltip>
    )
  }

  const getReport = () => {
    switch (activeInstance.eventClass) {
      case 'NOISE':
        return (
          <NoiseReport
            instance={activeInstance}
            property={property}
            onClose={() => {
              setEventReportOpen(false)
            }}
          />
        )
      case 'SMOKE':
        return (
          <SmokeReport
            instance={activeInstance}
            property={property}
            onClose={() => {
              setEventReportOpen(false)
            }}
          />
        )
      default:
        return null
    }
  }

  const detailRowContent = useCallback(
    ({ row }) =>
      row.childCount === 0 ? null : (
        <PropertyEventListRowDetail
          {...row}
          zone={property.timezone}
          isPrunit={property.unitCount === 1}
          onReportClick={(childRow) => {
            const isSmoke = childRow?.eventClass === 'SMOKE'
            if (!isSmoke || childRow?.metadata?.smoke?.alarmTag) {
              setEventReportOpen(true)
            } else {
              setOldEventReportOpen(true)
            }
            setActiveInstance(childRow)
          }}
        />
      ),
    [property],
  )
  const detailRowHeight = useCallback(() => 'auto', [])

  const renderActions = useCallback(
    (row) => {
      const feedbackPermissions = ['change_event_feedback']
      const isSmokeEvent = row.eventClass === 'SMOKE'
      const isExternalEvent = row.eventType === 'EXTERNAL'
      const hasFeedback = row.noiseFeedback || row.smokeFeedback
      const hasPermissions = checkPermissions(feedbackPermissions, activePermissions)

      const label = (() => {
        if (isExternalEvent) {
          return hasFeedback ? 'Adjust' : 'Post'
        }
        return hasFeedback ? 'Adjust' : 'Comment'
      })()

      return (
        <Stack direction="row" spacing={2} justifyContent="flex-end" width="100%">
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            startIcon={<RateReview />}
            size="small"
            sx={{ width: isSmallScreen ? '100%' : 120 }}
            disabled={!hasPermissions}
            onClick={() => {
              if (isExternalEvent && isSmokeEvent) {
                if (hasFeedback) {
                  setAdjustFormOpen(true)
                } else {
                  setPostFormOpen(true)
                }
              } else {
                setCommentsFormOpen(true)
              }
              setActiveInstance(row)
            }}
          >
            {label}
          </Button>
          <Tooltip title={!row.endTime ? 'Report not yet available' : undefined}>
            <span style={{ width: isSmallScreen ? '100%' : null }}>
              <Button
                fullWidth={isSmallScreen}
                variant="outlined"
                startIcon={<Assessment />}
                size="small"
                color="secondary"
                onClick={() => {
                  if (!isSmokeEvent || row?.metadata?.smoke?.alarmTag) {
                    setEventReportOpen(true)
                  } else {
                    setOldEventReportOpen(true)
                  }
                  setActiveInstance(row)
                }}
                disabled={!row.endTime}
              >
                Report
              </Button>
            </span>
          </Tooltip>
          <PrivilegeCheck flags={['MUTE_BUTTON']}>
            {isNoise && getMuteButton(row, isSmallScreen)}
          </PrivilegeCheck>
        </Stack>
      )
    },
    [isSmallScreen, isNoise],
  )

  if (unitEventListIsLoading && !unitEventList) {
    return <Loading />
  }

  if (!unitEventListIsLoading && !unitEventList) {
    return <ErrorMessage>Oops. Something went wrong</ErrorMessage>
  }

  const CREATED_ON_COLUMN = {
    field: 'createdOn',
    sortable: true,
    headerName: 'Detected On',
    flex: 0.4,
    minWidth: 175,
    renderCell: ({ row: { createdOn, endTime } }) =>
      renderCreatedOnCell(createdOn, endTime, property),
    renderMobile: ({ row: { createdOn, endTime } }) =>
      renderCreatedOnCell(createdOn, endTime, property, true),
  }

  const COMMENTS_COLUMN = {
    field: 'comments',
    sortable: false,
    headerName: 'Comments',
    flex: 0.9,
    minWidth: 250,
    valueGetter: (_, row) => {
      if (row.eventClass === 'NOISE') {
        return row.noiseFeedback?.comments
      }
      return row.smokeFeedback?.comments
    },
    valueFormatter: (value) => value || '--',
    renderCell: GridCellExpand,
    renderMobile: (params) => (
      <Typography variant="caption" textAlign="end">
        {params.formattedValue}
      </Typography>
    ),
  }

  const LAST_MODIFIED_COLUMN = {
    field: 'feedback.modifiedByName',
    sortable: false,
    headerName: 'Last Modified',
    flex: 0.4,
    minWidth: 175,
    valueGetter: (_, row) =>
      row.eventClass === 'NOISE'
        ? row.noiseFeedback?.modifiedByName
        : row.smokeFeedback?.modifiedByName,
    valueFormatter: (value) => value ?? 'N/A',
    renderCell: (params) => renderLastModifiedCell(params, property),
    renderMobile: (params) => renderLastModifiedCell(params, property, true),
  }

  const NET_CHARGE_COLUMN = {
    field: 'feedback.netChargeAmount',
    headerName: 'Net Charge',
    sortable: false,
    type: 'number',
    flex: 0.4,
    minWidth: 125,
    valueGetter: (_, row) => ({
      netCharge: Number(row.smokeFeedback?.netChargeAmount),
      adjustedOn: row.smokeFeedback?.adjustedOn,
    }),
    valueFormatter: (value) => {
      const formattedValue = formatCurrency(value.netCharge, '--')
      const asterisk = value.adjustedOn ? '*' : ''
      return `${formattedValue}${asterisk}`
    },
    renderCell: (params) => renderNetChargeCell(params, reasonsById),
    renderMobile: (params) => renderNetChargeCell(params, reasonsById, true),
  }

  const ACTION_COLUMN = {
    field: 'actions',
    sortable: false,
    headerName: 'Actions',
    headerAlign: 'center',
    width: isNoise ? 400 : 300,
    renderCell: ({ row }) => renderActions(row),
  }

  const EVENT_CLASS_COLUMN = {
    field: 'eventClass',
    sortable: true,
    headerName: 'Type',
    flex: 0.15,
    renderCell: ({ row }) => renderEventTypeColumn(row),
  }

  const CONFIRMATION_NUMBER_COLUMN = {
    field: 'reservation.confirmationNumber',
    sortField: 'reservation__confirmation_number',
    sortable: true,
    headerName: 'Confirmation #',
    flex: 0.35,
    minWidth: 125,
    valueGetter: (_, row) => row.reservation?.confirmationNumber,
    valueFormatter: (value) => value || 'N/A',
    renderMobile: (params) => params.formattedValue,
  }

  const EVENT_CATEGORY_COLUMN = {
    field: 'eventType',
    sortable: false,
    headerName: '',
    flex: 0.01,
    renderCell: ({ row }) => {
      if (row.eventType !== 'EXTERNAL') {
        return (
          <Tooltip title="Guest has not checked out yet">
            <HistoryToggleOff />
          </Tooltip>
        )
      }
      return null
    },
  }

  const columns = [
    EVENT_CATEGORY_COLUMN,
    CREATED_ON_COLUMN,
    COMMENTS_COLUMN,
    LAST_MODIFIED_COLUMN,
    NET_CHARGE_COLUMN,
    ACTION_COLUMN,
  ]

  if (property?.reservationGrouping) {
    columns.splice(1, 0, CONFIRMATION_NUMBER_COLUMN)

    const CHECK_IN_COLUMN = {
      field: 'checkIn',
      sortable: true,
      headerName: 'Check In',
      flex: 0.3,
      minWidth: 125,
      valueGetter: (_, row) =>
        row.checkIn
          ? DateTime.fromISO(row.checkIn, {
              zone: property.timezone,
            }).toLocaleString(DateTime.DATE_MED)
          : null,
      valueFormatter: (value) => value ?? 'N/A',
      renderMobile: (params) => params.formattedValue,
    }
    const CHECK_OUT_COLUMN = {
      field: 'checkOut',
      sortable: true,
      headerName: 'Check Out',
      flex: 0.3,
      minWidth: 125,
      valueGetter: (_, row) =>
        row?.checkOut
          ? DateTime.fromISO(row.checkOut, {
              zone: property.timezone,
            }).toLocaleString(DateTime.DATE_MED)
          : null,
      valueFormatter: (value) => value ?? 'N/A',
      renderMobile: (params) => params.formattedValue,
    }

    const checkInIndex = columns.indexOf(CREATED_ON_COLUMN)
    columns.splice(checkInIndex + 1, 0, CHECK_IN_COLUMN, CHECK_OUT_COLUMN)
  }

  if (isNoise) {
    columns.splice(1, 0, EVENT_CLASS_COLUMN)
  }

  const MobileListItem = useCallback(
    (row) => {
      const { eventClass } = row

      const rowsToRender = columns.filter(
        (col) => !['eventClass', 'eventType', 'actions'].includes(col.field),
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box display="flex" alignItems="center">
              {row.eventType !== 'EXTERNAL' && (
                <HistoryToggleOff sx={{ mr: 0.5, fontSize: 18 }} />
              )}
              {renderEventTypeColumn(row, true)}
              <Typography variant="caption" fontWeight="bold" ml={0.5}>
                {titleize(eventClass)} Event
              </Typography>
            </Box>
          </Box>
          {rowsToRender.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
          <MobileListCardRow
            label="Additional Occurencies"
            value={
              row.childCount ? (
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    setAdditionalOccurenciesData({
                      row,
                      property,
                      reportActions: {
                        setEventReportOpen,
                        setOldEventReportOpen,
                        setActiveInstance,
                      },
                    })
                  }
                >
                  View
                </Typography>
              ) : null
            }
          />
          <Box sx={{ '&&': { mt: 2, mb: 0.5 } }}>{renderActions(row)}</Box>
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      {/* Dialogs */}
      {commentsFormOpen && (
        <EventComment
          propertyId={activeInstance.property}
          eventId={activeInstance.id}
          isSmokeEvent={activeInstance.eventClass === 'SMOKE'}
          currentFeedback={activeInstance.smokeFeedback ?? activeInstance.noiseFeedback}
          onClose={(success) => {
            setCommentsFormOpen(false)
            if (success === true) {
              doMarkUnitEventListAsOutdated()
            }
          }}
        />
      )}
      {adjustFormOpen && (
        <EditFeedback
          instance={activeInstance}
          onClose={(success) => {
            setAdjustFormOpen(false)
            if (success === true) {
              doMarkUnitEventListAsOutdated()
            }
          }}
        />
      )}
      {postFormOpen && (
        <PostCharge
          instance={activeInstance}
          property={property}
          onClose={(success) => {
            setPostFormOpen(false)
            if (success === true) {
              doMarkUnitEventListAsOutdated()
            }
          }}
        />
      )}

      {eventReportOpen ? getReport() : null}
      {oldEventReportOpen ? (
        <EventReport
          instance={activeInstance}
          property={property}
          onClose={() => {
            setOldEventReportOpen(false)
          }}
        />
      ) : null}

      <AdditionalOccurenciesModal
        open={!!additionalOccurenciesData}
        onClose={() => setAdditionalOccurenciesData(null)}
        data={additionalOccurenciesData}
      />

      <ConfirmationDialog
        isFormik={false}
        open={!!muteContext.unit}
        onClose={() => setMuteContext({ isMute: false })}
        label={`${muteContext.isMute ? 'Mute' : 'Unmute'} ${muteContext.unitName}`}
        onSave={() => toggleMute(muteContext.isMute)}
        buttonText={`Yes, ${muteContext.isMute ? 'Mute' : 'Unmute'}`}
        successMessage={
          muteContext.isMute
            ? `${muteContext.unitName} will not generate notifications for 24 hours.`
            : `${muteContext.unitName} will now generate notifications.`
        }
      >
        <Box sx={{ my: 3 }}>
          <Typography>
            {`Are you sure you want to ${muteContext.isMute ? 'mute' : 'unmute'} `}
            <b>{muteContext.unitName}</b>
            {muteContext.isMute ? ' for 24 hours?' : '?'}
          </Typography>
        </Box>
      </ConfirmationDialog>

      <AdjustmentSummaryModal
        open={adjustmentSummaryOpen}
        dateRange={dateRangeValue}
        unitId={unit.id}
        onClose={() => setAdjustmentSummaryOpen(false)}
      />

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        mt={2}
        mb={1}
      >
        {!isSmallScreen && (
          <SearchBox
            title="Units"
            onSetSearch={doUnitEventListSetSearch}
            minLength={2}
          />
        )}
        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box display="flex">
            <DateRangePicker
              disableMaskedInput
              value={dateRangeValue}
              maxDate={defaultEnd}
              onChange={setDateRangeValue}
              slotProps={{
                fieldSeparator: { children: 'and' },
                textField: {
                  size: 'small',
                  style: { width: !isSmallScreen ? 150 : null },
                  required: true,
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            width={isSmallScreen ? '100%' : null}
            sx={{ pb: isSmallScreen ? 1 : 0 }}
          >
            <Button
              fullWidth={isSmallScreen}
              data-testid="adjustment_summary"
              variant="contained"
              startIcon={!isSmallScreen ? <PieChartRounded /> : null}
              onClick={() => setAdjustmentSummaryOpen(true)}
              sx={{ ml: isSmallScreen ? 0 : 2 }}
            >
              Adjustment Summary
            </Button>
            <Button
              fullWidth={isSmallScreen}
              data-testid="export_events"
              variant="contained"
              sx={{ ml: 1 }}
              onClick={async () => {
                const result = await doUnitEventListExport()
                const data = formatCSVData(result, (event) => ({
                  unitName: 'Unit Name',
                  zoneName: 'Zone Name',
                  eventClass: 'Type',
                  createdOn: 'Created On',
                  endTime: 'End Time',
                  lastOccurrence: 'Last Occurrence',
                  [`${
                    event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                  }.comments`]: 'Comments',
                  [`${
                    event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                  }.modifiedByName`]: 'Modified By',
                  [`${
                    event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                  }.modifiedOn`]: 'Modified On',
                  'smokeFeedback.adjustmentReason': 'Adjustment Reason',
                  'smokeFeedback.adjustedOn': 'Adjusted On',
                  'smokeFeedback.netChargeAmount': 'Net Charge Amount',
                }))
                downloadFile({
                  data: [data],
                  fileName: `${unit.name}_events_export.csv`,
                  fileType: 'text/csv',
                })
              }}
              startIcon={!isSmallScreen ? <FileDownload /> : null}
            >
              Export
            </Button>
          </Box>
        </Stack>
      </Stack>
      {isSmallScreen ? (
        <MobileList
          data-testid="events_list"
          title="Events"
          loading={unitEventListIsLoading}
          rows={unitEventList.results}
          itemBuilder={MobileListItem}
          page={unitEventList?.current ?? 0}
          rowCount={unitEventList?.count ?? 0}
          pageChange={doUnitEventListSetPage}
          pageSizeOptions={[25]}
          setSearch={doUnitEventListSetSearch}
          slots={{ noRowsOverlay: () => UnitEventsEmptyState({ isHotel }) }}
          hideFooter={(unitEventList?.numPages ?? 0) <= 1}
        />
      ) : (
        <List
          autoHeight
          disableColumnMenu
          data-testid="events_list"
          loading={unitEventListIsLoading}
          columns={columns}
          rows={unitEventList.results}
          page={unitEventList?.current ?? 0}
          rowCount={unitEventList?.count ?? 0}
          pageChange={doUnitEventListSetPage}
          pageSizeOptions={[25]}
          sortChange={doUnitEventListSetOrdering}
          currentOrdering={ordering ?? []}
          slots={{ noRowsOverlay: () => UnitEventsEmptyState({ isHotel }) }}
          hideFooter={(unitEventList?.numPages ?? 0) <= 1}
          getDetailPanelContent={detailRowContent}
          getDetailPanelHeight={detailRowHeight}
          sx={{
            [`& .${gridClasses.detailPanel}`]: {
              backgroundColor: 'transparent',
            },
          }}
        />
      )}
    </>
  )
}

EventsTab.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    reservationGrouping: PropTypes.bool.isRequired,
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
    propertyType: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    unitCount: PropTypes.number.isRequired,
  }).isRequired,
  unit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

UnitEventsEmptyState.propTypes = {
  isHotel: PropTypes.bool.isRequired,
}
