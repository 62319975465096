import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Alert, Box } from '@mui/material'

import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

export function FormObserver({ onUpdate }) {
  const { values, dirty, isSubmitting, isValid } = useFormikContext()

  useEffect(() => {
    onUpdate(dirty && !isSubmitting && isValid)
  }, [values, isSubmitting, isValid])
}
export default function FormBase({
  children,
  initialValues,
  successMessage,
  onSave,
  onUpdate,
  validationSchema,
  formRef,
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const handleSubmit = async (values, { setErrors }) => {
    try {
      const response = await onSave({ ...values })

      if (response?.error) {
        setErrors(response.error.response)
      } else if (response) {
        doShowSnackbar(successMessage, 'success')
      }
    } catch (err) {
      if (err?.response) {
        setErrors(err.response)
      }
    }
  }

  const displayNonFieldErrors = (nonFieldErrors) => {
    if (nonFieldErrors) {
      return (
        <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
          {nonFieldErrors.map((error) => (
            <Alert key={error} severity="error">
              {error}
            </Alert>
          ))}
        </Box>
      )
    }

    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ errors }) => (
        <Form>
          <FormObserver onUpdate={onUpdate} />
          {displayNonFieldErrors(errors?.nonFieldErrors)}
          {children}
        </Form>
      )}
    </Formik>
  )
}

FormBase.defaultProps = {
  onUpdate: () => {},
  successMessage: 'Updates successfully saved.',
  validationSchema: Yup.object().shape({}),
}

FormBase.propTypes = {
  children: PropTypes.shape().isRequired,
  initialValues: PropTypes.shape().isRequired,
  successMessage: PropTypes.string,
  validationSchema: PropTypes.shape(),
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  formRef: PropTypes.shape().isRequired,
}
