import PropTypes from 'prop-types'

import { Group } from '@visx/group'
import { Line } from '@visx/shape'

export default function LineMarker({ x, top, height, color, showCaret, dashed }) {
  return (
    <Group left={x} top={showCaret ? top - 4.5 : top} height={height}>
      {showCaret && (
        <g transform="translate(0,-4)">
          <polygon points="0,5 4,0 -4,0" fill={color} stroke={color} />
        </g>
      )}
      <Line
        from={{ y: 0 }}
        to={{ y: showCaret ? height + 4.5 : height }}
        stroke={color}
        strokeWidth={dashed ? 1 : 1.5}
        strokeDasharray={[dashed ? 4 : 0]}
      />
    </Group>
  )
}

LineMarker.defaultProps = {
  showCaret: true,
  dashed: false,
}

LineMarker.propTypes = {
  x: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  showCaret: PropTypes.bool,
  dashed: PropTypes.bool,
}
