import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro'

import { DateTime } from 'luxon'

import { parseApiErrors } from '@common/utils'

import AdjustmentsPieChart from './AdjustmentsPieChart'

export default function AdjustmentSummaryModal({ open, dateRange, unitId, onClose }) {
  const {
    adjustmentSummary,
    adjustmentSummaryError,
    adjustmentSummaryIsLoading,
    doFetchAdjustmentSummary,
    doResetAdjustmentSummaryState,
    doShowSnackbar,
  } = useConnect(
    'selectAdjustmentSummary',
    'selectAdjustmentSummaryError',
    'selectAdjustmentSummaryIsLoading',
    'doFetchAdjustmentSummary',
    'doResetAdjustmentSummaryState',
    'doShowSnackbar',
  )

  const [dateRangeValue, setDateRangeValue] = useState(dateRange)
  const [dateRangeToFetch, setDateRangeToFetch] = useState(dateRange)

  useEffect(() => {
    if (open) {
      setDateRangeValue(dateRange)
      setDateRangeToFetch(dateRange)
    } else {
      setDateRangeValue(null)
      setDateRangeToFetch(null)
      doResetAdjustmentSummaryState()
    }
  }, [open])

  const fetchAdjustmentSummary = useCallback(async () => {
    try {
      await doFetchAdjustmentSummary({ range: dateRangeToFetch, unitId })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [dateRangeToFetch, unitId])

  useEffect(() => {
    if (dateRangeToFetch) {
      fetchAdjustmentSummary()
    }
  }, [dateRangeToFetch])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableScrollLock
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: 650,
          },
        },
      }}
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        Adjustment Summary
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ px: 4 }}>
        {dateRangeValue && (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <DateRangePicker
              disableMaskedInput
              disabled={adjustmentSummaryIsLoading}
              value={dateRangeValue}
              maxDate={DateTime.now()}
              onChange={setDateRangeValue}
              slotProps={{
                fieldSeparator: { children: 'and' },
                textField: {
                  style: { width: 150 },
                  size: 'small',
                  required: true,
                },
              }}
            />
            <Button
              variant="contained"
              disabled={equals(dateRangeValue, dateRangeToFetch)}
              onClick={() => setDateRangeToFetch(dateRangeValue)}
            >
              Change date
            </Button>
          </Box>
        )}
        {adjustmentSummaryIsLoading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummaryError && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              Oops! Something went wrong!
            </Typography>
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummary?.eventsTotal === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="grey">
              No data for selected time period
            </Typography>
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummary?.eventsTotal > 0 && (
          <Box>
            <Typography component="span">
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventsTotal}
              </Box>{' '}
              events,{' '}
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventWithAdjustments}
              </Box>{' '}
              with adjustments (
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventWithAdjustmentsPercentage}%
              </Box>
              )
            </Typography>
            <AdjustmentsPieChart
              data={adjustmentSummary.adjustmentsCount}
              sx={{ mt: 4, mb: 1.5 }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

AdjustmentSummaryModal.defaultProps = {
  unitId: undefined,
  onClose: () => {},
}

AdjustmentSummaryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unitId: PropTypes.string,
  onClose: PropTypes.func,
}
