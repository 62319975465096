import { useState } from 'react'

import PropTypes from 'prop-types'

import ChevronDown from '@mui/icons-material/ExpandMore'
import { MenuItem, Select } from '@mui/material'

const rangeOptions = [
  { display: 'Last 3 days', value: 3 },
  { display: 'Last 7 days', value: 7 },
  { display: 'Last month', value: 30 },
  { display: 'Last 3 months', value: 90 },
  { display: 'Last 6 months', value: 180 },
]

export default function DateRangeSelect({ defaultValue, onChange }) {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const handleChange = (e) => {
    const newValue = e.target.value
    setSelectedValue(newValue)
    onChange(newValue)
  }

  return (
    <Select
      value={selectedValue}
      onChange={handleChange}
      variant="standard"
      IconComponent={ChevronDown}
      sx={{ fontSize: '13px', '& .MuiSelect-select': { pl: '4px' } }}
      disableUnderline
      MenuProps={{
        disableScrollLock: true,
      }}
    >
      {rangeOptions.map((option) => (
        <MenuItem key={option.value} value={option.value} style={{ fontSize: '13px' }}>
          {option.display.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  )
}

DateRangeSelect.defaultProps = {
  defaultValue: 7,
}

DateRangeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
}
