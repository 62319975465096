import PropTypes from 'prop-types'

import Avatar from '@mui/material/Avatar'

export function getAvatarInitials(name) {
  if (name.split(' ').length > 1) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
  return name.charAt(0)
}
export default function UserAvatar({ name, image, index, fontSize, size, sx }) {
  const avatarColors = ['#00796B', '#D97E2A', '#29B6F6', '#536DFE']

  if (image) {
    return (
      <Avatar
        data-testid="user_avatar"
        alt={`Avatar for ${name}`}
        src={image}
        sx={{ height: size, width: size, ...sx }}
      />
    )
  }

  return (
    <Avatar
      data-testid="user_avatar"
      alt={`Avatar for ${name}`}
      sx={[
        {
          bgcolor: avatarColors[index % avatarColors.length],
          height: size,
          width: size,
          fontSize,
          ...sx,
        },
      ]}
    >
      {getAvatarInitials(name)}
    </Avatar>
  )
}

UserAvatar.defaultProps = {
  name: '',
  image: '',
  index: 0,
  size: undefined,
  fontSize: 16,
  sx: {},
}

UserAvatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  sx: PropTypes.shape({}),
}
