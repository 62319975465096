import {
  createListBundle,
  defaultInitialState,
  getEntityFromUrl,
  isAbortError,
} from '@common/utils'
import { filterValidEntities } from '@portal/pages/EventDashboard/utils'
import { viewableEventClasses } from '@portal/Utils/constants'

import eventUrls from '../../urls'

const entityName = 'events'

const eventListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/events/',
      {
        ...params,
        ...(entityType !== 'event'
          ? { [entityType]: entityId }
          : { eventClass: params.eventClass ?? viewableEventClasses.join(',') }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['createdOn', 'desc'],
  },
  urlTest: (url, _, hash) => url === eventUrls.list || ['eventLog'].includes(hash),
})

export default {
  ...eventListBundle,
  doFetchChargeSummary:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectEventsListApiParams()

      const { localCreatedOnDateBefore, localCreatedOnDateAfter } = apiParams
      const incompleteDate = !localCreatedOnDateAfter || !localCreatedOnDateBefore

      const validEntities = filterValidEntities(apiParams)
      const getEntityFilter = () => {
        if (validEntities.property) return { property: validEntities.property }
        if (validEntities.account) return { account: validEntities.account }
        if (validEntities.organization)
          return { organization: validEntities.organization }
        if (validEntities.organizationGroup)
          return { organizationGroup: validEntities.organizationGroup }
        return {}
      }

      const entityFilter = getEntityFilter()

      if (incompleteDate) {
        return null
      }

      try {
        const response = await apiFetch(
          `/events/smoke_charge_summary/`,
          {
            localCreatedOnDateAfter,
            localCreatedOnDateBefore,
            eventClass: 'SMOKE',
            ...entityFilter,
          },
          { cancelationPrefix: entityName },
        )
        return response
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
  doDeleteEvent:
    (payload) =>
    async ({ apiFetch }) =>
      apiFetch('/events/', payload, {
        method: 'DELETE',
        cancelationPrefix: entityName,
      }),
}
