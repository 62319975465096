import { useState } from 'react'

import PropTypes from 'prop-types'

import { Group, Layer, Stage } from 'react-konva'

import AddThresholdRect from './AddThresholdRect'
import { CANVAS_HEIGHT, CANVAS_WIDTH, colors } from './constants'
import ThresholdRect from './ThresholdRect'
import Timeline from './Timeline'
import {
  getOpenHours,
  hourToCanvasGridLine,
  setCursorStyle,
  toCircularBuffer,
  wrapsMidnight,
} from './utils'
import WrappedThresholdRect from './WrappedThresholdRect'

export default function ThresholdEditor({
  deviceStyle,
  onAddSetting,
  onDeleteSetting,
  onResizeSetting,
  onDragSetting,
  onEditSetting,
  isEditing,
  editingId,
  thresholdSettings,
  setFieldValue,
  editorWidth,
}) {
  const openHours = getOpenHours(thresholdSettings)
  const [hoveredHour, setHoveredHour] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const thresholdSettingsCircularBuffer = toCircularBuffer(thresholdSettings)

  return (
    <Stage width={editorWidth ?? CANVAS_WIDTH} height={CANVAS_HEIGHT}>
      <Layer>
        <Timeline width={editorWidth} />
      </Layer>
      <Layer>
        <Group>
          {thresholdSettingsCircularBuffer.map((setting) => {
            const Component = wrapsMidnight(setting.startTime, setting.endTime)
              ? WrappedThresholdRect
              : ThresholdRect
            return (
              <Component
                id={setting.id}
                editorWidth={editorWidth}
                NRS={setting.highValue}
                key={setting.startTime}
                start={setting.startTime}
                end={setting.endTime}
                color={colors[setting.highValue]}
                next={setting.next}
                label={setting.name}
                previous={setting.previous}
                onDelete={
                  onDeleteSetting
                    ? () => {
                        onDeleteSetting(setting)
                        setFieldValue('setting', null)
                      }
                    : null
                }
                onEdit={() => onEditSetting(setting)}
                onResizeStart={() => setIsDragging(true)}
                onResizeEnd={(startTime, endTime) => {
                  setIsDragging(false)
                  onResizeSetting({
                    id: setting.id,
                    startTime,
                    endTime,
                    deviceStyle: setting.deviceStyle,
                  })
                }}
                onDragStart={() => {
                  setIsDragging(true)
                }}
                onDragEnd={(startTime, endTime) => {
                  onDragSetting({
                    id: setting.id,
                    startTime,
                    endTime,
                    deviceStyle: setting.deviceStyle,
                  })
                  setIsDragging(false)
                }}
                isDragging={isDragging}
                isEditing={isEditing}
                editingId={editingId}
              />
            )
          })}
          {onAddSetting &&
            !(isDragging || isEditing) &&
            openHours.map((startTime) => (
              <AddThresholdRect
                editorWidth={editorWidth}
                y={hourToCanvasGridLine(startTime)}
                key={startTime}
                onClick={() => onAddSetting(startTime, deviceStyle)}
                visible={startTime === hoveredHour}
                onMouseEnter={(e) => {
                  setHoveredHour(startTime)
                  setCursorStyle(e, 'pointer')
                }}
                onMouseLeave={(e) => {
                  setHoveredHour(null)
                  setCursorStyle(e, 'default')
                }}
              />
            ))}
        </Group>
      </Layer>
    </Stage>
  )
}

ThresholdEditor.defaultProps = {
  editingId: null,
  onDeleteSetting: undefined,
  editorWidth: undefined,
}

ThresholdEditor.propTypes = {
  deviceStyle: PropTypes.oneOf(['INDOOR', 'OUTDOOR']).isRequired,
  onAddSetting: PropTypes.func.isRequired,
  onEditSetting: PropTypes.func.isRequired,
  onDeleteSetting: PropTypes.func,
  onResizeSetting: PropTypes.func.isRequired,
  onDragSetting: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thresholdSettings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      highValue: PropTypes.number.isRequired,
      startTime: PropTypes.number.isRequired,
      endTime: PropTypes.number.isRequired,
      deviceStyle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  editorWidth: PropTypes.number,
}
