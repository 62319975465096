/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { pathOr } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Link, Typography } from '@mui/material'
import Container from '@mui/material/Container'

import { getNavHelper } from 'internal-nav-helper'

import { Loading, NewVersionBanner } from '@common/components'
import Snackbar from '@common/components/Snackbar'
import { useSmallScreen } from '@common/utils'
import { privacyStatementUrls } from '@rest/pages/PrivacyStatement'
import TopNav from '@rest/UI/Navigation/HeaderNav'
import { AutoLogoutHandler } from '@rest/Utils'

function RestApp() {
  const {
    route,
    doUpdateUrl,
    currentOrganization,
    currentAccount,
    currentAccountDetails,
    currentOrganizationDetails,
  } = useConnect(
    'selectRoute',
    'doUpdateUrl',
    'selectCurrentOrganization',
    'selectCurrentOrganizationDetails',
    'selectCurrentAccount',
    'selectCurrentAccountDetails',
  )

  const isSmallScreen = useSmallScreen()

  const RoutedComponent = route.component || route
  const showHeader = pathOr(true, ['showHeader'], route)
  const publicAccess = pathOr(true, ['publicAccess'], route)

  const verticalPaddingPx = isSmallScreen ? 15 : 25

  const getWrapperClassName = () => {
    const isMacOs = navigator?.userAgent?.toLowerCase()?.indexOf('mac') >= 0
    return isMacOs ? 'mac-os' : null
  }

  return (
    <>
      <div
        id="wrapper"
        className={getWrapperClassName()}
        onClick={getNavHelper(doUpdateUrl)}
      >
        <NewVersionBanner />
        {showHeader && <TopNav />}
        <main
          style={{
            padding: isSmallScreen ? '20px' : '50px',
            paddingTop: `${verticalPaddingPx}px`,
            paddingBottom: `${verticalPaddingPx}px`,
          }}
        >
          <Container
            disableGutters
            className="mainContainer"
            maxWidth="xl"
            sx={{
              minHeight: `calc(100vh - ${verticalPaddingPx * 2}px - ${
                showHeader ? '64px' : '0px'
              } - 29px)`,
            }}
          >
            {showHeader &&
            !publicAccess &&
            (!currentOrganizationDetails || !currentAccountDetails) ? (
              <Loading />
            ) : (
              <AutoLogoutHandler>
                <RoutedComponent
                  key={`${currentOrganization}-${currentAccount}`}
                  currentOrganization={currentOrganization}
                  currentAccount={currentAccount}
                />
              </AutoLogoutHandler>
            )}
            <Snackbar />
          </Container>
        </main>
      </div>
      <div className="footer">
        <Link href={privacyStatementUrls.entity}>
          <Typography variant="caption" disableGutters color="black">
            Privacy Statement
          </Typography>
        </Link>
      </div>
    </>
  )
}

export default RestApp
