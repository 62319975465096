import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Button, Snackbar } from '@mui/material'

const SNACKBAR_TIME = 15 * 60 // Seconds
const AUTO_LOGOUT_TIME = 1 * 60000 // Millis

export default function AutoLogoutHandler({ children }) {
  let logoutTimer

  const [showSnackbar, setShowSnackbar] = useState(false)

  const { appTime, appIdle, isAuthenticated, flags, doAuthLogout, doResetAppIdle } =
    useConnect(
      'selectAppTime',
      'selectAppIdle',
      'selectIsAuthenticated',
      'selectFlags',
      'doAuthLogout',
      'doResetAppIdle',
    )

  useEffect(() => {
    if (isAuthenticated && flags.includes('IDLE_SESSION_TIMEOUT')) {
      const diffInSeconds = (appTime - appIdle) / 1000
      if (!showSnackbar && diffInSeconds > SNACKBAR_TIME) {
        setShowSnackbar(true)
      }
    }
  }, [appIdle, appTime, flags])

  const resetLogoutTimer = () => {
    if (logoutTimer) clearTimeout(logoutTimer)
  }

  useEffect(() => {
    if (showSnackbar) {
      resetLogoutTimer()
      logoutTimer = setTimeout(() => {
        doAuthLogout()
        setShowSnackbar(false)
      }, AUTO_LOGOUT_TIME)
    }
  }, [showSnackbar])

  return (
    <>
      {children}
      <Snackbar
        open={showSnackbar}
        message="You've been inactive for 15 minutes. Click 'Stay Logged In' to continue your session."
        action={
          <Button
            sx={{ color: 'red' }}
            onClick={() => {
              doResetAppIdle()
              resetLogoutTimer()
              setShowSnackbar(false)
            }}
          >
            Stay Logged In
          </Button>
        }
      />
    </>
  )
}

AutoLogoutHandler.propTypes = {
  children: PropTypes.node.isRequired,
}
