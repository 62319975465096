import { useCallback } from 'react'

import PropTypes from 'prop-types'

import {
  AirRounded,
  LocalAtm as ChargeIcon,
  SpeakerPhone as DeviceIcon,
  VolumeUp as NoiseIcon,
  Warning as WarningIcon,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'

import { inflect } from 'inflection'

import { SmokeIcon } from '@common/icons'
import { formatCurrency, useSmallScreen } from '@common/utils'
import { aqiToDetailData } from '@rest/UI/components'
import { PrivilegeCheck } from '@rest/Utils'

import DeviceStatusChip from './PropertyDevices/DeviceStatusChip'
import urls from './urls'

const numericFormatter = (value) => (value === undefined ? '--' : value)

function PropertyCard(props) {
  const { property } = props

  const isSmallScreen = useSmallScreen()

  const {
    totalOnline,
    totalOffline,
    totalDevices,
    connectivityPct,
    eventsTotal,
    eventsCharged,
    noiseEventsTotal,
    noiseResponsePct,
    noiseEventsFeedback,
    chargePct,
    chargesNet,
    chargeOpportunity,
    chargeOpportunityPct,
    aqi,
  } = property?.summary ?? {}

  const AqiChip = useCallback(({ index }) => {
    const { color, description } = aqiToDetailData(index)
    return (
      <Tooltip title={description}>
        <Chip
          icon={<AirRounded />}
          label={`AQI: ${index}`}
          variant="outlined"
          sx={{ '> .MuiChip-icon': { color } }}
          data-testid="aqi_container"
        />
      </Tooltip>
    )
  }, [])

  const isPrunit = totalDevices === 1

  const listItemTextStyle = {
    primaryTypographyProps: {
      fontSize: isSmallScreen ? 14 : 16,
      textOverflow: 'ellipsis',
    },
    secondaryTypographyProps: {
      fontSize: isSmallScreen ? 12 : 14,
      textOverflow: 'ellipsis',
    },
  }

  const listItemIconStyle = { fontSize: isSmallScreen ? 20 : 24 }

  return (
    <Card data-testid={`card_${property.id}`}>
      <CardContent sx={{ pb: 0, position: 'relative', minHeight: 288 }}>
        <Box display="flex" flexDirection="column" style={{ minHeight: '60px' }}>
          <Link
            variant={isSmallScreen ? 'h6' : 'h5'}
            color="primary"
            underline="none"
            href={urls.entity.replace(':id', property.id)}
          >
            {property.name}
          </Link>
          {property.city && property.state ? (
            <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
              {property.city}, {property.state}
            </Typography>
          ) : null}
        </Box>

        <List
          disablePadding
          sx={{
            '& > li': { my: 0.5 },
            '& > li:last-child': { mb: 1 },
            mt: isPrunit ? 2.5 : 0,
          }}
        >
          {!isPrunit && (
            <ListItem data-testid="connectivity_container" disablePadding>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
                <DeviceIcon color="secondary" sx={listItemIconStyle} />
              </ListItemIcon>
              <ListItemText
                primary={`${numericFormatter(connectivityPct)}% connectivity`}
                secondary={`${numericFormatter(totalOnline)} ${inflect(
                  'sensor',
                  totalOnline,
                )} online, ${numericFormatter(totalOffline)} offline`}
                {...listItemTextStyle}
              />
              {connectivityPct < 95 && totalDevices > 0 && (
                <Tooltip title="Connectivity below 95%, click to view offline sensors">
                  <Link
                    href={`${urls.entity.replace(
                      ':id',
                      property.id,
                    )}?status=OFFLINE#sensors`}
                  >
                    <WarningIcon color="warning" />
                  </Link>
                </Tooltip>
              )}
            </ListItem>
          )}
          <ListItem data-testid="smoke_container" disablePadding>
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
              <SmokeIcon sx={{ ...listItemIconStyle, transform: 'rotate(-90deg)' }} />
            </ListItemIcon>
            <ListItemText
              primary={`${numericFormatter(eventsCharged)} ${inflect(
                'charge',
                eventsCharged,
              )} last 7 days (${numericFormatter(chargePct)}%)`}
              secondary={`${numericFormatter(eventsTotal)} chargeable ${inflect(
                'events',
                eventsTotal,
              )}`}
              {...listItemTextStyle}
            />
          </ListItem>
          <PrivilegeCheck
            flags={['NOISE']}
            property={property}
            alternate={
              <ListItem data-testid="charges_container" disablePadding>
                <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
                  <ChargeIcon color="success" sx={listItemIconStyle} />
                </ListItemIcon>
                <ListItemText
                  primary={`${formatCurrency(
                    chargesNet,
                    '--',
                  )} net charges last 7 days (${numericFormatter(
                    chargeOpportunityPct,
                  )}%)`}
                  secondary={`${formatCurrency(
                    chargeOpportunity,
                    '--',
                  )} total opportunity`}
                  {...listItemTextStyle}
                />
              </ListItem>
            }
          >
            <ListItem data-testid="charges_container" disablePadding>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
                <NoiseIcon color="action" sx={listItemIconStyle} />
              </ListItemIcon>
              <ListItemText
                primary={`${numericFormatter(noiseEventsTotal)} noise ${inflect(
                  'event',
                  noiseEventsTotal,
                )} last 7 days`}
                secondary={`${numericFormatter(noiseEventsFeedback)} ${inflect(
                  'response',
                  noiseEventsFeedback,
                )} (${numericFormatter(noiseResponsePct)}%)`}
                {...listItemTextStyle}
              />
            </ListItem>
          </PrivilegeCheck>
        </List>
        {isPrunit && (
          <Box sx={{ position: 'absolute', bottom: 10 }}>
            <DeviceStatusChip
              size="medium"
              status={totalOnline === 1 ? 'ONLINE' : 'OFFLINE'}
            />
          </Box>
        )}
        {aqi ? (
          <PrivilegeCheck flags={['AQI']} property={property}>
            <Box sx={{ position: 'absolute', right: 10, bottom: 10 }}>
              <AqiChip index={aqi} />
            </Box>
          </PrivilegeCheck>
        ) : null}
      </CardContent>
    </Card>
  )
}

PropertyCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
    propertyType: PropTypes.string,
    summary: PropTypes.shape({}),
  }).isRequired,
}

export default PropertyCard
