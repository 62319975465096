import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'

import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'

export default function ChangeProfile({ open, onClose, currentProfile, hierarchy }) {
  const [isLoading, setIsLoading] = useState(false)

  const [selectedProfile, setSelectedProfile] = useState(null)

  const { doThresholdProfileAssign, doShowSnackbar } = useConnect(
    'doThresholdProfileAssign',
    'doShowSnackbar',
  )

  useEffect(() => {
    if (open) {
      setSelectedProfile(currentProfile?.id)
    } else {
      setSelectedProfile(null)
    }
  }, [open])

  const handleSave = useCallback(async () => {
    setIsLoading(true)
    try {
      const promises = []
      hierarchy.unitGroups.forEach((group) => {
        promises.push(
          doThresholdProfileAssign({
            thresholdProfile: selectedProfile,
            unitGroup: group.id,
          }),
        )
      })

      const results = await Promise.all(promises)
      const err = results.find((result) => result.error)
      if (err) {
        throw err
      }

      doShowSnackbar(`Selected entities reassigned successfully`, 'success')
      onClose(true)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setIsLoading(false)
    }
  }, [selectedProfile, hierarchy])

  return (
    <Dialog fullWidth open={open} onClose={onClose} disableScrollLock maxWidth="sm">
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 0, px: 4 }}
      >
        Change Threshold Profile
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ py: 4, px: 4, maxWidth: '1000px' }}>
        <Stack direction="column" spacing={2} mt={4}>
          <DynamicSelect
            fullWidth
            useValueInRequest={false}
            label="Threshold Profiles"
            endpoint="threshold_profiles"
            forceActiveFilter={false}
            filters={{
              property: currentProfile?.property,
            }}
            value={selectedProfile}
            onChange={setSelectedProfile}
            variant="outlined"
            size="small"
          />
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={isLoading || selectedProfile === currentProfile?.id}
              onClick={handleSave}
            >
              Assign
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

ChangeProfile.defaultProps = {
  hierarchy: undefined,
  currentProfile: undefined,
}

ChangeProfile.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hierarchy: PropTypes.shape({
    unitGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  currentProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    property: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}
