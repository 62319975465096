import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box, FormGroup, FormLabel } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

import ScenarioTagCheckBox from '../Scenarios/ScenarioTagCheckBox'

export default function BeginTestForm({ open, onClose, onBegin, onAbort, instance }) {
  const [disabled, setDisabled] = useState([])
  const { systemScenarioTags } = useConnect('selectSystemScenarioTags')

  const validationSchema = yup.object().shape({
    scenarioTags: yup.array().required().min(1, 'At least one tag should be selected'),
  })

  const initialValues = {
    scenarioTags: ['confirmed_true'],
    notes: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] =
        instance[field] ?? initialValues[field] ?? (field === 'scenarioTags' ? [] : '')
    })
  }

  const toggleTags = ['confirmed_false', 'confirmed_true', 'suspicious']

  const isEditMode = instance && Object.keys(instance).length > 0

  return (
    <FormDialog
      label={isEditMode ? 'Edit Test' : 'Begin Test'}
      open={open}
      onSave={onBegin}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      extraActions={
        isEditMode ? [{ label: 'Abort Test', onClick: onAbort, color: 'error' }] : []
      }
    >
      <Box gap="2rem" display="flex" flexDirection="column">
        <Field
          multiple
          variant="standard"
          component={StaticSelect}
          label="Tags"
          name="scenarioTags"
          options={systemScenarioTags}
          optionLabelFormatter={(opt) => opt.replace('_', ' ')}
          getOptionDisabled={(option) => disabled.includes(option)}
          sx={{ flex: 1 }}
        />
        <FormGroup>
          <FormLabel>Toggle Tags</FormLabel>
          <Box display="flex" justifyContent="space-between">
            {toggleTags.map((tag) => (
              <ScenarioTagCheckBox tag={tag} key={tag} setDisabled={setDisabled} />
            ))}
          </Box>
        </FormGroup>
        <Field
          multiline
          name="notes"
          label="Notes"
          component={TextField}
          variant="outlined"
          sx={{ flex: 1 }}
        />
      </Box>
    </FormDialog>
  )
}

BeginTestForm.defaultProps = {
  instance: undefined,
}

BeginTestForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBegin: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    scenarioTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    notes: PropTypes.string,
  }),
}
