import PropTypes from 'prop-types'

import { CircularProgress } from '@mui/material'

import { Project } from '@common/config'

const loadingContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: Project.isRest ? 'calc(100vh - 143px)' : '100vh',
}

function Loading({ containerStyles, size, color, height }) {
  return (
    <div
      data-testid="loading"
      style={{
        ...loadingContainerStyles,
        ...containerStyles,
        ...(height ? { height } : {}),
      }}
    >
      <CircularProgress size={size} style={{ color }} />
    </div>
  )
}

export default Loading

Loading.defaultProps = {
  containerStyles: {},
  size: 100,
  color: undefined,
  height: undefined,
}

Loading.propTypes = {
  containerStyles: PropTypes.shape({}),
  size: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
