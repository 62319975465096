import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'

export default function MessageInput({
  'data-testid': dataTestId,
  initialValue,
  placeholder,
  limit,
  onChange,
  sx,
}) {
  const isSmallScreen = useSmallScreen()

  const [text, setText] = useState(initialValue)

  useEffect(() => {
    setText(initialValue)
  }, [initialValue])

  const isError = text.length > limit

  return (
    <FormControl
      data-testid={`${dataTestId}_container`}
      fullWidth
      variant="outlined"
      sx={sx}
    >
      <OutlinedInput
        multiline
        data-testid={`${dataTestId}`}
        id={`${dataTestId}`}
        placeholder={placeholder}
        variant="outlined"
        minRows={2}
        value={text}
        onChange={(event) => {
          const { value } = event.target
          setText(value)
          onChange(value)
        }}
        sx={{ fontSize: isSmallScreen ? 14 : 16 }}
      />
      <FormHelperText id="helper" error={isError}>
        <Box component="span" display="flex" justifyContent="space-between">
          <Typography variant="caption">
            {isError
              ? 'To avoid sending multiple SMS messages, we recommend keeping your message below 160 characters. Note: Character count is an approximation when using variables.'
              : ''}
          </Typography>
          <Typography variant="caption">{`${text.length}/${limit}`}</Typography>
        </Box>
      </FormHelperText>
    </FormControl>
  )
}

MessageInput.defaultProps = {
  'data-testid': undefined,
  initialValue: '',
  limit: 160,
  onChange: () => {},
  sx: {},
}

MessageInput.propTypes = {
  'data-testid': PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.shape({}),
}
