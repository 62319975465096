import { memo } from 'react'

import PropTypes from 'prop-types'

import { Grid2 } from '@mui/material'

import EntityCard from '../EntityCard'

const GroupedByEntityView = memo(
  ({ groupedByEntityData, selectedMetrics, hideNonLiveProperties }) =>
    Object.entries(groupedByEntityData).map(([key, data]) => {
      const shouldFilterByLiveParam =
        hideNonLiveProperties && data.length && 'live' in data[0]
      if (shouldFilterByLiveParam) {
        return data[0].live ? (
          <Grid2 key={key} size={{ xll: 6, sm: 12 }}>
            <EntityCard dataKey={key} selectedMetrics={selectedMetrics} data={data} />
          </Grid2>
        ) : null
      }

      return (
        <Grid2 key={key} size={{ xll: 6, sm: 12 }}>
          <EntityCard dataKey={key} selectedMetrics={selectedMetrics} data={data} />
        </Grid2>
      )
    }),
)

GroupedByEntityView.propTypes = {
  groupedByEntityData: PropTypes.shape({}).isRequired,
  selectedMetrics: PropTypes.shape({}).isRequired,
  hideNonLiveProperties: PropTypes.bool.isRequired,
}

export default GroupedByEntityView
