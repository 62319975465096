import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box, Typography } from '@mui/material'

import { BoldText, Loading } from '@common/components'
import { getApiFetch, isAbortError, parseApiErrors } from '@common/utils'
import { ConfirmationDialog } from '@rest/UI/components'

import GroupChangeDialog from './GroupChangeDialog'
import ThresholdAccordion from './ThresholdAccordion'

export default function ThresholdAccordionContainer({
  type,
  propertyId,
  setProfileToAlter,
  setProfileToAssign,
  setEditId,
  setEditOpen,
  setDeleteOpen,
  setMenuOverflow,
  menuOverflow,
  thresholdsToDisplay,
  checkedUnitGroups,
  setCheckedUnitGroups,
  groupChange,
  setGroupChange,
  openGroupsList,
  setOpenGroupsList,
}) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const {
    doThresholdProfileAssign,
    doThresholdsListSetFilter,
    thresholdsList,
    thresholdsListIsLoading,
    doMarkThresholdsListAsOutdated,
    currentAccount,
    doShowSnackbar,
  } = useConnect(
    'doThresholdProfileAssign',
    'doThresholdsListSetFilter',
    'selectThresholdsList',
    'selectThresholdsListIsLoading',
    'doMarkThresholdsListAsOutdated',
    'selectCurrentAccount',
    'doShowSnackbar',
  )

  const [initialLoading, setInitialLoading] = useState(true)
  const [hierarchyLoading, setHierarchyLoading] = useState(true)
  const [profilesWithHierarchy, setProfilesWithHierarchy] = useState(null)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [confirmGroupChange, setConfirmGroupChange] = useState(false)

  useEffect(() => {
    if (type === 'dashboard') {
      doThresholdsListSetFilter(propertyId ? { property: propertyId } : null)
    } else if (type === 'configure') {
      doThresholdsListSetFilter({ account: currentAccount })
    }
    return () => setProfilesWithHierarchy(null)
  }, [type, propertyId])

  const fetchHierarchy = useCallback(async () => {
    setInitialLoading(true)
    setHierarchyLoading(true)
    try {
      const promises = []
      thresholdsList?.results.forEach((profile) => {
        promises.push(
          apiFetch(`/threshold_profiles/${profile.id}/assignment_hierarchy/`),
        )
      })

      const results = await Promise.all(promises)
      const err = results.find((result) => result.error)
      if (err) {
        throw err
      }

      const updatedProfiles = []
      for (let i = 0; i < results.length; i += 1) {
        updatedProfiles.push({ ...thresholdsList.results[i], hierarchy: results[i] })
      }
      setProfilesWithHierarchy(updatedProfiles)
    } catch (err) {
      if (!isAbortError) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    } finally {
      setInitialLoading(false)
      setHierarchyLoading(false)
    }
  }, [thresholdsList])

  useEffect(() => {
    setHierarchyLoading(true)
    setInitialLoading(false)
  }, [thresholdsListIsLoading])

  useEffect(() => {
    if (thresholdsList?.results && !initialLoading) {
      fetchHierarchy()
    }
  }, [thresholdsList])

  const thresholds = useMemo(() => {
    if (thresholdsToDisplay === 'unassigned') {
      return profilesWithHierarchy?.filter((threshold) => threshold.assigned)
    }
    return profilesWithHierarchy
  }, [profilesWithHierarchy, thresholdsToDisplay])

  const thresholdOptions = useMemo(() => {
    if (type === 'dashboard') {
      return profilesWithHierarchy?.reduce(
        (acc, item) => (acc.includes([item.name]) ? acc : [...acc, item.name]),
        [],
      )
    }
    if (type === 'configure') {
      const selectedProfiles = Object.keys(checkedUnitGroups)
      if (profilesWithHierarchy && !isEmpty(selectedProfiles)) {
        const { property } = profilesWithHierarchy.find(
          (profile) => profile.id === selectedProfiles[0],
        )
        return profilesWithHierarchy
          .filter((threshold) => threshold.property === property)
          .reduce(
            (acc, item) => (acc.includes([item.name]) ? acc : [...acc, item.name]),
            [],
          )
      }
    }
    return []
  }, [profilesWithHierarchy, checkedUnitGroups])

  const setEditing = (id) => {
    const profileToEdit = profilesWithHierarchy.find((profile) => profile.id === id)
    setProfileToAlter(profileToEdit)
    setEditId(id)
    setEditOpen(true)
  }

  const handleGroupChange = useCallback(async () => {
    try {
      const unitGroups = Object.values(checkedUnitGroups).reduce(
        (acc, arr) => [...acc, ...arr],
        [],
      )
      const newProfile = profilesWithHierarchy.find(
        (item) => item.name === selectedProfile,
      )
      const promises = []
      unitGroups.forEach((group) => {
        promises.push(
          doThresholdProfileAssign({
            thresholdProfile: newProfile.id,
            unitGroup: group,
          }),
        )
      })

      const results = await Promise.all(promises)
      const err = results.find((result) => result.error)
      if (err) {
        throw err
      }

      doShowSnackbar(`Selected groups reassigned successfully`, 'success')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [selectedProfile, checkedUnitGroups])

  const openDeleteProfile = (profile) => {
    setProfileToAlter(profile)
    setDeleteOpen(true)
  }

  if (thresholdsListIsLoading || hierarchyLoading || !profilesWithHierarchy) {
    return <Loading />
  }

  return profilesWithHierarchy.length ? (
    <>
      {groupChange && (
        <GroupChangeDialog
          thresholdOptions={thresholdOptions}
          setSelectedProfile={setSelectedProfile}
          selectedProfile={selectedProfile}
          setConfirmGroupChange={setConfirmGroupChange}
          open={groupChange}
          onClose={() => {
            setGroupChange(false)
          }}
        />
      )}
      {confirmGroupChange && (
        <ConfirmationDialog
          label="Change Profile"
          onClose={(success) => {
            setConfirmGroupChange(false)
            setSelectedProfile(null)
            setGroupChange(false)
            if (success === true) {
              doMarkThresholdsListAsOutdated()
            }
          }}
          onSave={handleGroupChange}
          open={confirmGroupChange}
          buttonText="Yes, Change Profile"
          successMessage="Threshold Profile Changed"
          isFormik={false}
        >
          <Typography variant="body1" sx={{ my: 2.5 }}>
            Are you sure you want to change the selected unit profiles to{' '}
            <BoldText>{selectedProfile}</BoldText>?
          </Typography>
        </ConfirmationDialog>
      )}
      <Box mb={2}>
        {thresholds.map((profile) => (
          <ThresholdAccordion
            key={profile.id}
            thresholdProfile={profile}
            setCheckedUnitGroups={setCheckedUnitGroups}
            setEditing={setEditing}
            setProfileToAssign={setProfileToAssign}
            setProfileToDelete={openDeleteProfile}
            setMenuOverflow={setMenuOverflow}
            menuOverflow={menuOverflow}
            openGroupsList={openGroupsList}
            setOpenGroupsList={setOpenGroupsList}
          />
        ))}
      </Box>
    </>
  ) : (
    <Typography variant="h5" color="text.secondary" mt={2}>
      No threshold profiles have been created.
    </Typography>
  )
}

ThresholdAccordionContainer.defaultProps = {
  propertyId: undefined,
}

ThresholdAccordionContainer.propTypes = {
  type: PropTypes.oneOf(['dashboard', 'configure']).isRequired,
  propertyId: PropTypes.string,
  thresholdsToDisplay: PropTypes.string.isRequired,
  setProfileToAssign: PropTypes.func.isRequired,
  setProfileToAlter: PropTypes.func.isRequired,
  setEditId: PropTypes.func.isRequired,
  setEditOpen: PropTypes.func.isRequired,
  setDeleteOpen: PropTypes.func.isRequired,
  setMenuOverflow: PropTypes.func.isRequired,
  menuOverflow: PropTypes.bool.isRequired,
  checkedUnitGroups: PropTypes.shape({}).isRequired,
  setCheckedUnitGroups: PropTypes.func.isRequired,
  groupChange: PropTypes.bool.isRequired,
  setGroupChange: PropTypes.func.isRequired,
  openGroupsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpenGroupsList: PropTypes.func.isRequired,
}
