import PropTypes from 'prop-types'

import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'

import { getAvatarInitials } from '@common/components/Avatar'

export default function OrgAvatar({ details }) {
  if (details.image)
    return <Avatar alt={`Avatar for ${details.name}`} src={details.image} />

  return (
    <Tooltip placement="right" title={details.name}>
      <Avatar
        alt={`Avatar for ${details.name}`}
        sx={{ bgcolor: 'secondary.main', fontSize: 16, mr: 1 }}
      >
        {getAvatarInitials(details.name)}
      </Avatar>
    </Tooltip>
  )
}

OrgAvatar.propTypes = {
  details: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
}
