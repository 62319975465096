import PropTypes from 'prop-types'

import { Box, Link, Typography } from '@mui/material'

export default function HeaderItem({ label, value, linkTo }) {
  return (
    <Typography variant="body2">
      <Box component="span" fontWeight="fontWeightMedium" display="block">
        {label}
      </Box>
      {linkTo ? <Link href={linkTo}>{value}</Link> : value}
    </Typography>
  )
}

HeaderItem.defaultProps = {
  linkTo: undefined,
}

HeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
}
