import PropTypes from 'prop-types'

import { Box, Checkbox, Typography } from '@mui/material'

import { formatSubitemCount } from '@rest/Utils/formatMenuStrings'

export default function UnitGroupItem({
  id,
  name,
  childrenCount,
  checkedGroups,
  onClick,
}) {
  return (
    <Box display="flex" key={id ?? Math.random()} alignItems="center">
      <Checkbox
        disableRipple
        edge="start"
        size="small"
        onClick={() => onClick(id)}
        indeterminate={!id}
        disabled={!id}
        checked={checkedGroups.includes(id)}
      />
      <Typography>
        Unit Group - {name} ({formatSubitemCount('Unit', childrenCount)})
      </Typography>
    </Box>
  )
}

UnitGroupItem.defaultProps = {
  id: undefined,
}

UnitGroupItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  childrenCount: PropTypes.number.isRequired,
  checkedGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
}
