import { useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import { titleize } from 'inflection'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

export default function RoleForm({ open, onClose, instance, isOrgTab }) {
  const [roleLevel, setRoleLevel] = useState('')

  const { systemRoleLevels, doRoleSave, doMarkRolesListAsOutdated } = useConnect(
    'selectSystemRoleLevels',
    'doRoleSave',
    'doMarkRolesListAsOutdated',
  )

  const validationSchema = yup.object().shape({
    name: yup.string().max(30),
  })

  const initialValues = {
    id: '',
    name: '',
    account: '',
    organization: '',
    permissions: [],
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? initialValues[field]
    })
  }

  const handleSave = async (values) => {
    const res = await doRoleSave(values)
    if (res?.id) doMarkRolesListAsOutdated()
    return res
  }

  const handleClose = () => {
    setRoleLevel('')
    onClose()
  }

  const sharedStyles = { width: '90%' }
  const mutable = instance?.mutable ?? true

  const roleLevelOptions = systemRoleLevels.map((lvl) => lvl.name)
  const accRoleLevelOptions = roleLevelOptions.filter((lvl) =>
    lvl.toLowerCase().includes('account'),
  )

  const orgLevelRoleSelected = roleLevel?.toLowerCase().includes('organization')
  const selectedEntityType = orgLevelRoleSelected ? 'organization' : 'account'

  const entityFilters = {
    active: true,
    ...{ [orgLevelRoleSelected ? 'id' : 'organization']: instance?.organization }, // organization tab
    ...(!orgLevelRoleSelected && !isOrgTab && { id: instance?.account }), // account tab
  }

  const getEndpoint = () =>
    orgLevelRoleSelected ? `organizations/${instance?.organization}` : 'accounts'

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Role`}
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitOptions={{ disabled: !mutable }}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <Field
          required
          sx={sharedStyles}
          component={TextField}
          label="Name"
          name="name"
          disabled={!mutable}
          error={!mutable}
          helperText={!mutable && 'Cannot edit immutable Roles'}
        />
        {!instance?.id && (
          <StaticSelect
            required
            sx={sharedStyles}
            label="At which level do you wish to create this role?"
            value={roleLevel?.value}
            variant="standard"
            options={isOrgTab ? roleLevelOptions : accRoleLevelOptions}
            onChange={setRoleLevel}
          />
        )}
        {roleLevel && (
          <Field
            required
            sx={sharedStyles}
            name={orgLevelRoleSelected ? 'organization' : 'account'}
            endpoint={getEndpoint()}
            label={titleize(selectedEntityType)}
            isOptionEqualToValue={(opt, val) => opt.id === val}
            component={FormikDynamicSelect}
            filters={entityFilters}
            secondaryTextAttr={orgLevelRoleSelected ? '' : 'organizationName'}
            disabled={orgLevelRoleSelected || !isOrgTab}
          />
        )}
      </Box>
    </FormDialog>
  )
}

RoleForm.defaultProps = {
  instance: {},
}

RoleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    mutable: PropTypes.bool,
    organization: PropTypes.string,
    account: PropTypes.string,
  }),
  isOrgTab: PropTypes.bool.isRequired,
}
