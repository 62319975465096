/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { AreaClosed } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.data === next.data &&
    prev.curve === next.curve &&
    prev.strokeWidth === next.strokeWidth &&
    prev.stroke === next.stroke &&
    prev.fill === next.fill &&
    prev.fillOpacity === next.fillOpacity &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoAreaClosed = memo(
  ({
    id,
    defined,
    data,
    x,
    y,
    y0,
    y1,
    yScale,
    curve,
    strokeWidth,
    stroke,
    fill,
    fillOpacity,
    graphWidth,
    padding,
  }) => (
    <AreaClosed
      key={id}
      defined={defined}
      data={data}
      x={x}
      y={y}
      y0={y0}
      y1={y1}
      yScale={yScale}
      curve={curve}
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill={fill}
      fillOpacity={fillOpacity}
    />
  ),
  propsAreEqual,
)

MemoAreaClosed.defaultProps = {
  y: undefined,
  y0: undefined,
  y1: undefined,
  strokeWidth: undefined,
  stroke: undefined,
  fill: undefined,
  fillOpacity: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoAreaClosed.propTypes = {
  id: PropTypes.string.isRequired,
  defined: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func,
  y0: PropTypes.func,
  y1: PropTypes.func,
  yScale: PropTypes.func.isRequired,
  curve: PropTypes.func.isRequired,
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  fillOpacity: PropTypes.number,
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoAreaClosed
