import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { CloseFullscreenRounded } from '@mui/icons-material'
import { Box, Dialog, Grow, Typography } from '@mui/material'

import MetricChart from '../MetricChart'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

export default function FullscreenMetricCard({ open, onClose, cardData }) {
  const {
    title,
    metric,
    data,
    dataTypes,
    dataInterval,
    requestedDateRange,
    showTotal,
  } = cardData ?? {}

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ margin: 4 }}
      maxWidth={false}
      PaperProps={{
        style: {
          backgroundImage: 'unset',
          boxShadow: 'none',
          width: '100%',
          padding: 30,
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Typography variant="h4">{title}</Typography>
        <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
          <CloseFullscreenRounded sx={{ fontSize: 28 }} />
        </Box>
      </Box>
      {cardData && (
        <MetricChart
          fullscreen
          id={`${metric}_fullscreen`}
          data={data}
          dataTypes={dataTypes}
          dataInterval={dataInterval}
          requestedDateRange={requestedDateRange}
          showTotal={showTotal}
        />
      )}
    </Dialog>
  )
}

FullscreenMetricCard.defaultProps = {
  cardData: undefined,
}

FullscreenMetricCard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cardData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    metric: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        symbol: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }),
    ).isRequired,
    dataTypes: PropTypes.shape({}).isRequired,
    dataInterval: PropTypes.shape({
      value: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    requestedDateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showTotal: PropTypes.bool.isRequired,
  }),
}
