import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

function PageTitle({ children, ...props }) {
  const isSmallScreen = useSmallScreen()
  return (
    <Typography variant={isSmallScreen ? 'h5' : 'h4'} gutterBottom {...props}>
      {children}
    </Typography>
  )
}

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
}

export default PageTitle
