import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material'

import html2pdf from 'html2pdf.js'
import { DateTime } from 'luxon'

import logo from '@assets/logo_dark.svg'
import { Loading } from '@common/components'
import { getApiFetch, isAbortError, sanitizeFileName } from '@common/utils'

import BarChart from './BarChart'
import {
  basicPaperStyles,
  chartHeaderStyles,
  chartPaperStyles,
  closeIconStyles,
  definitionPaperStyles,
  exportButtonStyles,
  graphStackStyles,
  logoStyles,
  summaryPaperStyles,
} from './EventReport.styles'
import hairsprayData from './hairsprayData.json'
import LineCheck from './LineCheck'
import lysolData from './lysolData.json'

function round(value) {
  return +parseFloat(value).toFixed(2)
}

function EventReport(props) {
  const { onClose, instance, property } = props

  const { doShowSnackbar } = useConnect('doShowSnackbar')
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [elevation, setElevation] = useState(1)

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  useEffect(() => {
    async function fetchDeviceData() {
      try {
        const response = await apiFetch(`/events/${instance.id}/smoke_report/`, null, {
          cancelationPrefix: 'event_report',
        })
        setData(response)
        setLoading(false)
      } catch (e) {
        if (!isAbortError(e)) {
          onClose(false)
          doShowSnackbar('Unable to load report. Something went wrong.', 'error')
        }
      }
    }
    fetchDeviceData()
  }, [])

  const generateReport = async () => {
    const input = document.getElementById('printable_canvas')

    const { propertyName, unitName, createdOn } = instance

    const fileName = `smoke_report_${propertyName}_${unitName}_${createdOn}.pdf`

    setElevation(0)

    await html2pdf()
      .from(input)
      .set({
        filename: sanitizeFileName(fileName),
        jsPDF: {
          orientation: 'landscape',
          format: [225, 415],
        },
      })
      .save()

    setElevation(1)
  }

  const eventTime = DateTime.fromISO(instance.createdOn, {
    zone: property.timezone,
  })

  return (
    <Dialog
      data-testid="event_report_dialog"
      fullScreen
      open
      onClose={onClose}
      disableScrollLock
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <IconButton onClick={onClose} sx={closeIconStyles}>
          <CloseIcon />
        </IconButton>

        <Button variant="contained" sx={exportButtonStyles} onClick={generateReport}>
          Generate PDF
        </Button>

        <div
          id="printable_canvas"
          style={{
            position: 'relative',
            padding: 10,
            paddingLeft: 30,
            paddingTop: 75,
            width: '400mm',
            height: '220mm',
          }}
        >
          <img src={logo} alt="Rest Logo" style={logoStyles} />

          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12 }} sx={{ mb: 1 }}>
              <Typography variant="h3" align="center">
                {instance.propertyName} Smoking Report
              </Typography>
              <Typography variant="h5" align="center">
                Room {instance.unitName},{' '}
                {eventTime.toLocaleString(DateTime.DATETIME_SHORT)}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 5 }}>
              {loading ? null : (
                <Paper elevation={elevation} sx={basicPaperStyles}>
                  <LineCheck
                    Icon={CheckBoxIcon}
                    text="All criteria met. Very high probability of smoking."
                    justifyContent="center"
                  />
                </Paper>
              )}
              <Paper elevation={elevation} sx={summaryPaperStyles}>
                {loading ? (
                  <Loading />
                ) : (
                  data.criteria?.map((criterion) => (
                    <LineCheck
                      key={criterion.name}
                      Icon={CheckBoxIcon}
                      text={`${criterion.name}: ${criterion.value}`}
                    />
                  ))
                )}
              </Paper>
              <Paper elevation={elevation} sx={definitionPaperStyles}>
                <List>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      primary="Max Particulate"
                      secondary="The total number of particulates found in the air of the peak measurement of a particulate event."
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      primary="Percent of Fine Particulate"
                      secondary="Smoking and vaping events create significantly higher concentrations of
                    fine particulates when compared to non-smoking
                    particulate events which have a more even distribution of the
                    different particulate sizes."
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      primary="Total Volatile Organic Compounds"
                      secondary="Smoking and common products create gasses but aerosol products like hairspray and cleaners create them in significantly higher concentrations."
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      primary="Duration"
                      secondary="This is the time between the first detection of a particulate event and the return to normal air quality."
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid2>
            <Grid2 size={{ xs: 7 }}>
              <Stack direction="row" sx={{ gap: 2, height: '100%' }}>
                <Paper elevation={elevation} sx={{ ...chartPaperStyles, width: 282 }}>
                  <Stack direction="column" sx={graphStackStyles}>
                    <Typography variant="h5" sx={chartHeaderStyles}>
                      THIS EVENT
                    </Typography>
                    <Typography variant="h6" align="center">
                      Ratio of particulates
                    </Typography>
                    {loading ? (
                      <Loading />
                    ) : (
                      <BarChart
                        data={[
                          [
                            {
                              x: 'Particulate',
                              y: round(data.mc1p0Mean) + round(data.mc2p5Mean),
                            },
                            { x: 'TVOC', y: 0 },
                          ],
                          [
                            {
                              x: 'Particulate',
                              y: round(data.mc4p0Mean) + round(data.mc10p0Mean),
                            },
                            { x: 'TVOC', y: data.tvocPercent },
                          ],
                        ]}
                      />
                    )}
                  </Stack>
                </Paper>
                <Paper elevation={elevation} sx={{ ...chartPaperStyles, width: 282 }}>
                  <Stack direction="column" sx={graphStackStyles}>
                    <Typography variant="h5" sx={chartHeaderStyles}>
                      REFERENCE
                    </Typography>
                    <Typography variant="h6" align="center">
                      Sample hairspray
                    </Typography>
                    <BarChart data={hairsprayData} />
                  </Stack>
                </Paper>
                <Paper elevation={elevation} sx={{ ...chartPaperStyles, width: 282 }}>
                  <Stack direction="column" sx={graphStackStyles}>
                    <Typography variant="h5" sx={chartHeaderStyles}>
                      REFERENCE
                    </Typography>
                    <Typography variant="h6" align="center">
                      Sample Lysol
                    </Typography>
                    <BarChart data={lysolData} />
                  </Stack>
                </Paper>
              </Stack>
            </Grid2>
          </Grid2>
        </div>
      </DialogContent>
    </Dialog>
  )
}

EventReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
  }).isRequired,
  property: PropTypes.shape({
    timezone: PropTypes.string,
  }).isRequired,
}

export default EventReport
