import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { DomainDisabledRounded, KeyboardArrowRightOutlined } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import { captureException } from '@sentry/react'

import { parseApiErrors, useSmallScreen } from '@common/utils'
import { formatSubitemCount } from '@rest/Utils/formatMenuStrings'

import UnitGroupItem from './UnitGroupItem'

export default function ThresholdAccordion({
  thresholdProfile,
  setCheckedUnitGroups,
  setEditing,
  setProfileToAssign,
  setProfileToDelete,
  setMenuOverflow,
  menuOverflow,
  openGroupsList,
  setOpenGroupsList,
}) {
  const [overflowAnchor, setOverflowAnchor] = useState(null)
  const [checkedGroups, setCheckedGroups] = useState([])

  const { doNewThresholdProfileSave, doMarkThresholdsListAsOutdated, doShowSnackbar } =
    useConnect(
      'doNewThresholdProfileSave',
      'doMarkThresholdsListAsOutdated',
      'doShowSnackbar',
    )

  const isSmallScreen = useSmallScreen()

  const isOpen = useMemo(
    () => thresholdProfile && openGroupsList.includes(thresholdProfile.id),
    [thresholdProfile, openGroupsList],
  )

  useEffect(() => {
    if (!menuOverflow) {
      setOverflowAnchor(null)
    }
  }, [menuOverflow])

  useEffect(() => {
    setCheckedUnitGroups((prev) => {
      const updatedObj = { ...prev }
      updatedObj[thresholdProfile.id] = checkedGroups
      if (updatedObj[thresholdProfile.id].length === 0) {
        delete updatedObj[thresholdProfile.id]
      }
      return updatedObj
    })
  }, [thresholdProfile, checkedGroups])

  const handleDuplication = async () => {
    const payload = {
      name: `${thresholdProfile.name} (copy)`,
      description: thresholdProfile.description,
      property: thresholdProfile.property,
      dataType: thresholdProfile.dataType,
      rules: thresholdProfile.rules,
    }

    try {
      await doNewThresholdProfileSave(payload)
      doShowSnackbar(`${thresholdProfile.name} (copy) has been created.`, 'success')
      setOverflowAnchor(null)
      doMarkThresholdsListAsOutdated()
    } catch (error) {
      captureException(error)
      const parsedError = parseApiErrors(error?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }

  const handleSingleCheck = (id) => {
    setCheckedGroups((prev) => {
      const currIdx = checkedGroups.indexOf(id)
      const updatedList = [...prev]
      if (currIdx === -1) {
        updatedList.push(id)
      } else {
        updatedList.splice(currIdx, 1)
      }
      return updatedList
    })
  }

  const unitGroups =
    thresholdProfile?.hierarchy?.propertyGroups?.[0]?.properties?.[0]?.unitGroups

  const isProperyAssigned =
    thresholdProfile.hierarchy?.propertyGroups?.[0]?.properties?.[0]?.explicit

  const assignedUnitGroups = unitGroups?.filter((group) => group.explicit)

  const handleGroupCheckbox = (checked) => {
    if (checked) {
      const checkedIds =
        unitGroups?.filter((group) => group.id)?.map((group) => group.id) ?? []
      setCheckedGroups(checkedIds)
    } else {
      setCheckedGroups([])
    }
  }

  const groupSelectAllowed = useMemo(() => {
    if (unitGroups) {
      return unitGroups.filter((group) => group.id).length > 0
    }
    return false
  }, [thresholdProfile, isOpen])

  const AssignedGroupsList = useCallback(() => {
    if (!unitGroups) {
      return (
        <Box display="flex" alignItems="center">
          <DomainDisabledRounded sx={{ color: 'grey.600' }} />
          <Typography variant="body2" ml={1.5} color="grey.600">
            This threshold profile is not assigned.
          </Typography>
        </Box>
      )
    }
    return (
      <Box display="grid" gap={1}>
        {unitGroups.map((group) => (
          <UnitGroupItem
            key={`${group.id}_${group.name}`}
            id={group.id}
            name={group.name}
            childrenCount={group.units.length}
            onClick={handleSingleCheck}
            checkedGroups={checkedGroups}
          />
        ))}
      </Box>
    )
  }, [thresholdProfile, checkedGroups, handleSingleCheck])

  return (
    <List sx={{ '& .MuiListItem-root': { minWidth: '39px' } }}>
      <ListItem
        sx={{
          bgcolor: 'primary.contrastText',
          borderRadius: '7px',
          boxShadow: 3,
          minHeight: '68px',
        }}
        secondaryAction={
          <IconButton
            edge="end"
            onClick={(e) => {
              setOverflowAnchor(e.currentTarget)
              setMenuOverflow(true)
            }}
          >
            <MoreVertIcon />
          </IconButton>
        }
      >
        <Menu
          open={Boolean(overflowAnchor)}
          anchorEl={overflowAnchor}
          onClose={() => setOverflowAnchor(null)}
          disableScrollLock
        >
          <MenuItem onClick={() => setProfileToAssign(thresholdProfile)}>
            <Typography>Assign To</Typography>
          </MenuItem>
          <MenuItem onClick={() => setEditing(thresholdProfile.id)}>
            <Typography>Edit Threshold Profile</Typography>
          </MenuItem>
          <MenuItem onClick={handleDuplication}>
            <Typography>Duplicate</Typography>
          </MenuItem>
          <MenuItem onClick={() => setProfileToDelete(thresholdProfile)}>
            <Typography>Delete</Typography>
          </MenuItem>
        </Menu>
        <IconButton
          onClick={() => {
            setOpenGroupsList((prev) => {
              const updatedList = [...prev]
              const index = updatedList.indexOf(thresholdProfile.id)
              if (index !== -1) {
                updatedList.splice(index, 1)
              } else {
                updatedList.push(thresholdProfile.id)
              }
              return updatedList
            })
          }}
        >
          {isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightOutlined />}
        </IconButton>
        {groupSelectAllowed && (
          <Checkbox
            edge="start"
            size="small"
            checked={
              unitGroups.filter((group) => group.id).length === checkedGroups.length
            }
            onChange={(event) => handleGroupCheckbox(event.target.checked)}
          />
        )}
        <ListItemText
          disableTypography
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: isSmallScreen ? 'start' : 'center',
          }}
        >
          <Typography variant="h6" color="primary">
            {thresholdProfile.name}
          </Typography>
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{ ml: isSmallScreen ? null : 'auto' }}
          >
            <Typography variant="caption" color="grey.500" sx={{ mr: 14 }}>
              {thresholdProfile.description}
            </Typography>
            <Typography variant="caption">
              {isProperyAssigned && formatSubitemCount('Property', 1)}
              {isProperyAssigned && ' • '}
              {formatSubitemCount('Unit Group', assignedUnitGroups?.length || 0)}
            </Typography>
          </Box>
        </ListItemText>
      </ListItem>
      <Collapse in={isOpen}>
        <Box ml={7} mt={2}>
          <AssignedGroupsList />
        </Box>
      </Collapse>
    </List>
  )
}

ThresholdAccordion.defaultProps = {
  menuOverflow: false,
  setMenuOverflow: () => {},
}

ThresholdAccordion.propTypes = {
  thresholdProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    dataType: PropTypes.string,
    description: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({})),
    property: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    hierarchy: PropTypes.shape({
      propertyGroups: PropTypes.arrayOf(
        PropTypes.shape({
          properties: PropTypes.arrayOf(
            PropTypes.shape({
              explicit: PropTypes.bool,
              unitGroups: PropTypes.arrayOf(
                PropTypes.shape({
                  units: PropTypes.arrayOf(PropTypes.shape({})),
                }),
              ),
              units: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          ),
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  setCheckedUnitGroups: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  setProfileToAssign: PropTypes.func.isRequired,
  setProfileToDelete: PropTypes.func.isRequired,
  menuOverflow: PropTypes.bool,
  setMenuOverflow: PropTypes.func,
  openGroupsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpenGroupsList: PropTypes.func.isRequired,
}
