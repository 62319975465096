import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Alert, Box, Button, Link } from '@mui/material'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import logo from '@assets/logo_dark.svg'
import { authPayloadIsValid } from '@common/bundles/utils'
import { Loading } from '@common/components'
import TextField from '@common/components/Form/TextField'
import { useSmallScreen } from '@common/utils'
import loginUrls from '@rest/pages/Login/urls'

import { loginBoxStyles, loginFormStyles, loginLogoStyles } from './login.styles'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required(''),
  password: Yup.string().required(''),
})

export function LoginComponent({ doAuthLogin, meIsLoading }) {
  const [error, setError] = useState()

  const doLogin = useCallback(async (values) => {
    setError(null)
    const result = await doAuthLogin(values)
    if (result?.error?.response?.detail) {
      setError(result.error.response.detail)
      return false
    }
    return true
  }, [])

  const {
    queryObject,
    doForceAuthenticate,
    doMarkHeaderAsOutdated,
    doMarkSystemAsOutdated,
  } = useConnect(
    'selectQueryObject',
    'doForceAuthenticate',
    'doMarkHeaderAsOutdated',
    'doMarkSystemAsOutdated',
  )

  const isSmallScreen = useSmallScreen()

  const pingSsoUrl = globalThis?.config?.PING_SSO_URL
  const validPingSSOUrl = Boolean(pingSsoUrl) && typeof pingSsoUrl === 'string'

  useEffect(() => {
    if (queryObject?.error) {
      setError(queryObject.error)
    }

    const queryObjectKeys = Object.keys(queryObject)

    if (authPayloadIsValid(queryObjectKeys)) {
      doForceAuthenticate(queryObject)
      doMarkHeaderAsOutdated()
      doMarkSystemAsOutdated()
    }
  }, [queryObject])

  if (queryObject?.access && !error) {
    return <Loading />
  }

  return (
    <Box style={loginBoxStyles}>
      <img alt="Rest" src={logo} style={loginLogoStyles} />

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={doLogin}
      >
        {({ isSubmitting, isValid }) => (
          <Form
            style={{
              ...loginFormStyles,
              width: isSmallScreen ? '100%' : loginFormStyles.width,
            }}
          >
            {error && <Alert severity="error">{error}</Alert>}
            <Field
              data-testid="login-email"
              component={TextField}
              name="email"
              label="Email"
              placeholder="Email"
            />
            <Field
              data-testid="login-password"
              component={TextField}
              name="password"
              label="Password"
              type="password"
            />
            <Button
              data-testid="login-submit"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isSubmitting || meIsLoading}
            >
              Login
            </Button>
            {validPingSSOUrl && (
              <Button
                variant="contained"
                type="submit"
                href={pingSsoUrl}
                sx={{
                  maxWidth: '50%',
                  alignSelf: 'center',
                  background: '#b82330',
                  '&:hover': { background: '#821720' },
                }}
              >
                Login via Ping
              </Button>
            )}
            <Link href={loginUrls.forgotPassword} align="center">
              Forgot Password?
            </Link>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

LoginComponent.propTypes = {
  doAuthLogin: PropTypes.func.isRequired,
  meIsLoading: PropTypes.bool.isRequired,
}

export default function Login() {
  const { doAuthLogin, meIsLoading } = useConnect('doAuthLogin', 'selectMeIsLoading')
  return <LoginComponent doAuthLogin={doAuthLogin} meIsLoading={meIsLoading} />
}
