import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import {
  NotificationsActive as UnMutedIcon,
  NotificationsOff as MutedIcon,
} from '@mui/icons-material'
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { Loading, MobileList, MobileListCardRow } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { formatCurrency, isRequestError, useSmallScreen } from '@common/utils'
import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'
import { ConfirmationDialog, List } from '@rest/UI/components'
import { GridCellExpand } from '@rest/UI/components/cells'

import AdditionalOccurenciesModal from './AdditionalOccurenciesModal'
import EditFeedback from './EditFeedback'
import EventReport from './EventReport'
import NoiseFeedback from './NoiseFeedback'
import PostCharge from './PostCharge'
import {
  renderActionCell,
  renderCreatedOnCell,
  renderEventTypeColumn,
  renderLastModifiedCell,
  renderNetChargeCell,
} from './propertyEventListCellRenderers'
import PropertyEventListRowDetail from './PropertyEventListRowDetail'
import NoiseReport from './Report/NoiseReport/NoiseReport'
import SmokeReport from './Report/SmokeReport'

function PropertyEventList({ property }) {
  const {
    doMarkPropertyEventListAsOutdated,
    doPropertyEventListSetPage,
    doPropertyEventListSetPageSize,
    doPropertyEventListSetOrdering,
    doPropertyEventListSetSearch,
    doUnitFetch,
    doUnitSave,
    propertyEventList,
    propertyEventListRaw: { ordering = [] },
    propertyEventListIsLoading,
    currentRole: role,
    smokeAdjustmentReasons,
  } = useConnect(
    'doMarkPropertyEventListAsOutdated',
    'doPropertyEventListSetPage',
    'doPropertyEventListSetPageSize',
    'doPropertyEventListSetOrdering',
    'doPropertyEventListSetSearch',
    'doUnitFetch',
    'doUnitSave',
    'selectPropertyEventList',
    'selectPropertyEventListRaw',
    'selectPropertyEventListIsLoading',
    'selectCurrentRole',
    'selectSmokeAdjustmentReasons',
  )
  const isSmallScreen = useSmallScreen()

  const isHotel = property?.propertyType === 'HOTEL'
  const activePermissions = role?.permissions ?? []

  const [isNoise, setIsNoise] = useState(false)
  const [additionalOccurenciesData, setAdditionalOccurenciesData] = useState(null)

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  useEffect(() => {
    setIsNoise(property.expandedFlags?.includes('NOISE') || false)
  }, [property])

  const [adjustFormOpen, setAdjustFormOpen] = useState(false)
  const [postFormOpen, setPostFormOpen] = useState(false)
  const [noiseFeedbackOpen, setNoiseFeedbackOpen] = useState(false)
  const [eventReportOpen, setEventReportOpen] = useState(false)
  const [oldEventReportOpen, setOldEventReportOpen] = useState(false)
  const [muteContext, setMuteContext] = useState({ isMute: false })
  const [activeInstance, setActiveInstance] = useState(null)

  const detailRowContent = useCallback(
    ({ row }) =>
      row.childCount === 0 ? null : (
        <PropertyEventListRowDetail
          {...row}
          zone={property.timezone}
          isPrunit={property.unitCount === 1}
          onReportClick={(childRow) => {
            const isSmoke = childRow?.eventClass === 'SMOKE'
            if (!isSmoke || childRow?.metadata?.smoke?.alarmTag) {
              setEventReportOpen(true)
            } else {
              setOldEventReportOpen(true)
            }
            setActiveInstance(childRow)
          }}
        />
      ),
    [property],
  )
  const detailRowHeight = useCallback(() => 'auto', [])

  const toggleMute = async (mute) => {
    const unit = await doUnitFetch(muteContext.unit)
    unit.muteNotificationsStart = mute ? DateTime.now().toISO() : null
    unit.muteNotificationsEnd = mute ? DateTime.now().plus({ days: 1 }).toISO() : null

    const saveResult = await doUnitSave(unit)
    if (isRequestError(saveResult)) {
      throw saveResult
    }
    doMarkPropertyEventListAsOutdated()
  }

  const isUnitMuted = (event) => {
    const now = DateTime.now()
    if (event.unitMuteNotificationsStart) {
      const muteStart = DateTime.fromISO(event.unitMuteNotificationsStart)
      if (event.unitMuteNotificationsEnd) {
        const muteEnd = DateTime.fromISO(event.unitMuteNotificationsEnd)
        return muteStart < now < muteEnd
      }
      return now > muteStart
    }
    return false
  }

  if (propertyEventListIsLoading && !propertyEventList) {
    return <Loading />
  }

  const getMuteButton = (row, mobileView) => {
    if (row.unit && isUnitMuted(row)) {
      return (
        <Tooltip title="Unmute this unit">
          <Button
            fullWidth={mobileView}
            variant="outlined"
            startIcon={<MutedIcon />}
            size="small"
            color="error"
            onClick={() =>
              setMuteContext({ unitName: row.unitName, unit: row.unit, isMute: false })
            }
          >
            Unmute
          </Button>
        </Tooltip>
      )
    }
    return (
      <Tooltip title="Mute all notifications for this unit for 24 hours">
        <Button
          fullWidth={mobileView}
          variant="outlined"
          startIcon={<UnMutedIcon />}
          size="small"
          color="secondary"
          onClick={() =>
            setMuteContext({ unitName: row.unitName, unit: row.unit, isMute: true })
          }
        >
          Mute
        </Button>
      </Tooltip>
    )
  }

  const UNIT_NAME_COLUMN = {
    field: 'unitName',
    sortField: 'unit__name',
    headerName: isHotel ? 'Room' : 'Unit',
    flex: 0.15,
    sortable: true,
    renderMobile: ({ row }) => row.unitName,
    minWidth: 100,
  }

  const CREATED_ON_COLUMN = {
    field: 'createdOn',
    sortable: true,
    headerName: property?.liveSmokeAlerts ? 'Alert Sent' : 'Detected On',
    flex: 0.4,
    minWidth: 175,
    renderCell: ({ row: { createdOn, endTime } }) =>
      renderCreatedOnCell(createdOn, endTime, property),
    renderMobile: ({ row: { createdOn, endTime } }) =>
      renderCreatedOnCell(createdOn, endTime, property, true),
  }

  const COMMENTS_COLUMN = {
    field: 'comments',
    sortable: false,
    headerName: 'Comments',
    flex: 0.9,
    minWidth: 250,
    valueGetter: (_, row) => {
      if (row.eventClass === 'NOISE') {
        return row.noiseFeedback?.comments
      }
      return row.smokeFeedback?.comments
    },
    valueFormatter: (value) => value || '--',
    renderCell: GridCellExpand,
    renderMobile: (params) => (
      <Typography variant="caption" textAlign="end">
        {params.formattedValue}
      </Typography>
    ),
  }

  const LAST_MODIFIED_COLUMN = {
    field: 'feedback.modifiedByName',
    sortable: false,
    headerName: 'Last Modified',
    flex: 0.4,
    minWidth: 175,
    valueGetter: (_, row) =>
      row.eventClass === 'NOISE'
        ? row.noiseFeedback?.modifiedByName
        : row.smokeFeedback?.modifiedByName,
    valueFormatter: (value) => value ?? 'N/A',
    renderCell: (params) => renderLastModifiedCell(params, property),
    renderMobile: (params) => renderLastModifiedCell(params, property, true),
  }

  const NET_CHARGE_COLUMN = {
    field: 'feedback.netChargeAmount',
    headerName: 'Net Charge',
    sortable: false,
    type: 'number',
    flex: 0.4,
    minWidth: 125,
    valueGetter: (_, row) => ({
      netCharge:
        row.smokeFeedback?.netChargeAmount !== null
          ? Number(row.smokeFeedback?.netChargeAmount)
          : null,
      adjustedOn: row.smokeFeedback?.adjustedOn,
    }),
    valueFormatter: (value) => {
      const foremmatedValue = formatCurrency(value.netCharge, '--')
      const asterisk = value.adjustedOn ? '*' : ''
      return `${foremmatedValue}${asterisk}`
    },
    renderCell: (params) => renderNetChargeCell(params, reasonsById),
    renderMobile: (params) => renderNetChargeCell(params, reasonsById, true),
  }

  const ACTION_COLUMN = {
    field: 'actions',
    sortable: false,
    headerName: 'Actions',
    headerAlign: 'center',
    width: isNoise ? 400 : 300,
    renderCell: (params) =>
      renderActionCell(
        params,
        setActiveInstance,
        setNoiseFeedbackOpen,
        setAdjustFormOpen,
        setPostFormOpen,
        setEventReportOpen,
        setOldEventReportOpen,
        isNoise,
        activePermissions,
        getMuteButton,
      ),
  }

  const EVENT_TYPE_COLUMN = {
    field: 'eventClass',
    sortable: true,
    headerName: 'Type',
    flex: 0.15,
    renderCell: ({ row }) => renderEventTypeColumn(row),
  }

  const CONFIRMATION_NUMBER_COLUMN = {
    field: 'reservation.confirmationNumber',
    sortField: 'reservation__confirmation_number',
    sortable: true,
    headerName: 'Confirmation #',
    flex: 0.35,
    minWidth: 200,
    valueGetter: (_, row) => row.reservation?.confirmationNumber,
    valueFormatter: (value) => value || 'N/A',
    renderMobile: (params) => params.formattedValue,
  }

  const columns = [
    CREATED_ON_COLUMN,
    COMMENTS_COLUMN,
    LAST_MODIFIED_COLUMN,
    NET_CHARGE_COLUMN,
    ACTION_COLUMN,
  ]

  if (property?.unitCount !== 1) {
    columns.unshift(UNIT_NAME_COLUMN)
  }

  if (property?.reservationGrouping) {
    columns.unshift(CONFIRMATION_NUMBER_COLUMN)

    const CHECK_IN_COLUMN = {
      field: 'checkIn',
      sortable: true,
      headerName: 'Check In',
      flex: 0.3,
      minWidth: 125,
      valueGetter: (_, row) =>
        row.checkIn
          ? DateTime.fromISO(row.checkIn, {
              zone: property.timezone,
            }).toLocaleString(DateTime.DATE_MED)
          : null,
      valueFormatter: (value) => value ?? 'N/A',
      renderMobile: (params) => params.formattedValue,
    }
    const CHECK_OUT_COLUMN = {
      field: 'checkOut',
      sortable: true,
      headerName: 'Check Out',
      flex: 0.3,
      minWidth: 125,
      valueGetter: (_, row) =>
        row.checkOut
          ? DateTime.fromISO(row.checkOut, {
              zone: property.timezone,
            }).toLocaleString(DateTime.DATE_MED)
          : null,
      valueFormatter: (value) => value ?? 'N/A',
      renderMobile: (params) => params.formattedValue,
    }

    const checkInIndex = columns.indexOf(CREATED_ON_COLUMN)
    columns.splice(checkInIndex + 1, 0, CHECK_IN_COLUMN, CHECK_OUT_COLUMN)
  }

  if (isNoise) {
    columns.unshift(EVENT_TYPE_COLUMN)
  }

  const getReport = () => {
    switch (activeInstance.eventClass) {
      case 'NOISE':
        return (
          <NoiseReport
            instance={activeInstance}
            property={property}
            onClose={() => {
              setEventReportOpen(false)
            }}
          />
        )
      case 'SMOKE':
        return (
          <SmokeReport
            instance={activeInstance}
            property={property}
            onClose={() => {
              setEventReportOpen(false)
            }}
          />
        )
      default:
        return null
    }
  }

  const MobileListItem = useCallback(
    (row) => {
      const { eventClass } = row

      const filteredColumns = columns.filter(
        (column) => column.field !== 'eventClass' && column.field !== 'actions',
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box display="flex" alignItems="center">
              {renderEventTypeColumn(row, true)}
              <Typography variant="caption" fontWeight="bold" ml={0.5}>
                {titleize(eventClass)} Event
              </Typography>
            </Box>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
          <MobileListCardRow
            label="Additional Occurencies"
            value={
              row.childCount ? (
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    setAdditionalOccurenciesData({
                      row,
                      property,
                      reportActions: {
                        setEventReportOpen,
                        setOldEventReportOpen,
                        setActiveInstance,
                      },
                    })
                  }
                >
                  View
                </Typography>
              ) : null
            }
          />
          <Box sx={{ '&&': { mt: 2, mb: 0.5 } }}>
            {renderActionCell(
              { row },
              setActiveInstance,
              setNoiseFeedbackOpen,
              setAdjustFormOpen,
              setPostFormOpen,
              setEventReportOpen,
              setOldEventReportOpen,
              isNoise,
              activePermissions,
              getMuteButton,
              true,
            )}
          </Box>
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      {adjustFormOpen ? (
        <EditFeedback
          instance={activeInstance}
          onClose={(success) => {
            setAdjustFormOpen(false)
            if (success === true) {
              doMarkPropertyEventListAsOutdated()
            }
          }}
        />
      ) : null}

      {postFormOpen ? (
        <PostCharge
          instance={activeInstance}
          property={property}
          onClose={(success) => {
            setPostFormOpen(false)
            if (success === true) {
              doMarkPropertyEventListAsOutdated()
            }
          }}
        />
      ) : null}

      {noiseFeedbackOpen ? (
        <NoiseFeedback
          propertyId={activeInstance.property}
          eventId={activeInstance.id}
          existingFeedback={activeInstance.noiseFeedback}
          onClose={(success) => {
            setNoiseFeedbackOpen(false)
            if (success === true) {
              doMarkPropertyEventListAsOutdated()
            }
          }}
        />
      ) : null}

      {eventReportOpen ? getReport() : null}

      {oldEventReportOpen ? (
        <EventReport
          instance={activeInstance}
          property={property}
          onClose={() => {
            setOldEventReportOpen(false)
          }}
        />
      ) : null}

      <AdditionalOccurenciesModal
        open={!!additionalOccurenciesData}
        onClose={() => setAdditionalOccurenciesData(null)}
        data={additionalOccurenciesData}
      />

      <ConfirmationDialog
        isFormik={false}
        open={!!muteContext.unit}
        onClose={() => setMuteContext({ isMute: false })}
        label={`${muteContext.isMute ? 'Mute' : 'Unmute'} ${muteContext.unitName}`}
        onSave={() => toggleMute(muteContext.isMute)}
        buttonText={`Yes, ${muteContext.isMute ? 'Mute' : 'Unmute'}`}
        successMessage={
          muteContext.isMute
            ? `${muteContext.unitName} will not generate notifications for 24 hours.`
            : `${muteContext.unitName} will now generate notifications.`
        }
      >
        <Box sx={{ my: 3 }}>
          <Typography>
            {`Are you sure you want to ${muteContext.isMute ? 'mute' : 'unmute'} `}
            <b>{muteContext.unitName}</b>
            {muteContext.isMute ? ' for 24 hours?' : '?'}
          </Typography>
        </Box>
      </ConfirmationDialog>

      {isSmallScreen ? (
        <MobileList
          data-testid="events_list"
          title="Events"
          rows={propertyEventList?.results ?? []}
          itemBuilder={MobileListItem}
          page={propertyEventList?.current ?? 0}
          pageChange={doPropertyEventListSetPage}
          pageSize={propertyEventList?.pageSize}
          pageSizeChange={doPropertyEventListSetPageSize}
          rowCount={propertyEventList?.count ?? 0}
          setSearch={doPropertyEventListSetSearch}
          pageSizeOptions={[25]}
          hideFooter={(propertyEventList?.numPages ?? 0) <= 1}
          loading={propertyEventListIsLoading}
        />
      ) : (
        <List
          autoHeight
          disableColumnMenu
          data-testid="events_list"
          columns={columns}
          rows={propertyEventList?.results ?? []}
          page={propertyEventList?.current ?? 0}
          pageChange={doPropertyEventListSetPage}
          pageSize={propertyEventList?.pageSize}
          pageSizeChange={doPropertyEventListSetPageSize}
          rowCount={propertyEventList?.count ?? 0}
          sortChange={doPropertyEventListSetOrdering}
          setSearch={doPropertyEventListSetSearch}
          pageSizeOptions={[25]}
          hideFooter={(propertyEventList?.numPages ?? 0) <= 1}
          loading={propertyEventListIsLoading}
          getDetailPanelContent={detailRowContent}
          getDetailPanelHeight={detailRowHeight}
          currentOrdering={ordering ?? []}
          sx={{
            [`& .${gridClasses.detailPanel}`]: {
              backgroundColor: 'transparent',
            },
          }}
        />
      )}
    </>
  )
}

PropertyEventList.propTypes = {
  property: PropTypes.shape({
    timezone: PropTypes.string,
    liveSmokeAlerts: PropTypes.bool,
    propertyType: PropTypes.string.isRequired,
    reservationGrouping: PropTypes.bool,
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
    unitCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default PropertyEventList
