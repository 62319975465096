import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { LinePath } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.data === next.data &&
    prev.graphWidth === next.graphWidth &&
    prev.curve === next.curve &&
    prev.stroke === next.stroke &&
    prev.yScale === next.yScale &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth', 'padding' and 'yScale' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoLine = memo(
  // eslint-disable-next-line no-unused-vars
  ({ id, defined, data, curve, x, y, stroke, graphWidth, padding, yScale }) => (
    <LinePath
      key={id}
      defined={defined}
      data={data}
      curve={curve}
      x={x}
      y={y}
      stroke={stroke}
    />
  ),
  propsAreEqual,
)

MemoLine.defaultProps = {
  stroke: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoLine.propTypes = {
  id: PropTypes.string.isRequired,
  defined: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  curve: PropTypes.func.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  stroke: PropTypes.string,
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoLine
