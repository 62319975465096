import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

import { ListTextTooltipWrapper } from '@common/components'
import { useSmallScreen } from '@common/utils'

export function getPropertyAddress(property) {
  let sub = []
  if (property.address2) sub.push(property.address2)
  if (property.city) sub.push(property.city)
  if (property.state) sub.push(property.state)
  if (property.country) sub.push(property.country)
  sub = sub.join(' ')

  return { address1: property.address1, sub }
}

export default function ListAddressItem({ item }) {
  const isSmallScreen = useSmallScreen()
  const titleFontSize = isSmallScreen ? 12 : 14
  const subtitleFontSize = isSmallScreen ? 10 : 12

  const { address1, sub } = getPropertyAddress(item)

  if (!sub.length) {
    return <Typography fontSize={titleFontSize}>N/A</Typography>
  }

  return (
    <Box
      sx={{
        display: isSmallScreen ? 'flex' : 'block',
        flexDirection: 'column',
        minWidth: 0,
        alignItems: isSmallScreen ? 'end' : 'start',
      }}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <ListTextTooltipWrapper tooltip={address1}>
          <Typography component="span" fontSize={titleFontSize}>
            {address1}
          </Typography>
        </ListTextTooltipWrapper>
      </Box>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <ListTextTooltipWrapper tooltip={sub}>
          <Typography variant="caption" fontSize={subtitleFontSize}>
            {sub}
          </Typography>
        </ListTextTooltipWrapper>
      </Box>
    </Box>
  )
}

ListAddressItem.propTypes = {
  item: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
}
