import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useReduxBundlerStore } from 'redux-bundler-hook'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

import { ParentSize } from '@visx/responsive'
import html2pdf from 'html2pdf.js'
import { DateTime } from 'luxon'

import { Loading } from '@common/components'
import { getApiFetch, isAbortError, sanitizeFileName } from '@common/utils'

import LineChart from '../LineChart'
import aerosolData from './aerosolData.json'
import cookingData from './cookingData.json'
import showerData from './showerData.json'
import {
  canvasStyles,
  closeIconStyles,
  exportButtonStyles,
  headerStyles,
  propertyHeaderStyles,
  titleStyles,
} from './SmokeReport.styles'

function ParentChart({ loading, error, probabilityData, timezone }) {
  if (error) {
    return (
      <Typography variant="h5">
        Something went wrong. Please contact support.
      </Typography>
    )
  }

  if (loading) {
    return <Loading />
  }

  if (!probabilityData) {
    return (
      <Typography variant="h5">
        Something went wrong. Please contact support.
      </Typography>
    )
  }

  return (
    <ParentSize>
      {({ width }) => (
        <LineChart
          lines={[
            {
              key: 'smoke',
              color: '#000',
              data: probabilityData,
            },
          ]}
          height={300}
          width={width}
          timezone={timezone}
        />
      )}
    </ParentSize>
  )
}

ParentChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  probabilityData: PropTypes.arrayOf(PropTypes.shape({})),
}

ParentChart.defaultProps = {
  probabilityData: null,
}

function SmokeReport({ onClose, instance, property }) {
  const [probabilityData, setProbabilityData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  useEffect(() => {
    async function fetchDeviceData() {
      try {
        const response = await apiFetch(
          `/events/${instance.id}/smoke_probability_chart/`,
          null,
          { cancelationPrefix: 'smoke_report' },
        )
        setProbabilityData(response)
        setLoading(false)
      } catch (e) {
        if (!isAbortError(e)) {
          setError(true)
        }
      }
    }
    fetchDeviceData()
  }, [])

  const massageData = useCallback(
    (data) =>
      data.map((item, idx) => ({
        value: item,
        ts: DateTime.fromISO(instance.createdOn, { zone: property.timezone })
          .plus({ minutes: idx + 1 })
          .toMillis(),
      })),
    [instance],
  )

  const aerosolChart = useMemo(
    () => [
      {
        key: 'aerosol',
        color: '#c1c1c1',
        data: massageData(aerosolData),
      },
    ],
    [instance],
  )

  const cookingChart = useMemo(
    () => [
      {
        key: 'cooking',
        color: '#c1c1c1',
        data: massageData(cookingData),
      },
    ],
    [instance],
  )

  const showerChart = useMemo(
    () => [
      {
        key: 'shower',
        color: '#c1c1c1',
        data: massageData(showerData),
      },
    ],
    [instance],
  )

  const generateReport = useCallback(async () => {
    const input = document.getElementById('printable_canvas')

    const { propertyName, unitName, createdOn } = instance

    const fileName = `smoke_report_${propertyName}_${unitName}_${createdOn}`

    await html2pdf()
      .from(input)
      .set({
        filename: sanitizeFileName(fileName),
        jsPDF: {
          orientation: 'landscape',
          format: 'letter',
        },
      })
      .save()
  }, [instance])

  const eventTime = useMemo(
    () =>
      DateTime.fromISO(instance.createdOn, {
        zone: property.timezone,
      }),
    [property, instance],
  )

  const isPrunit = useMemo(() => property?.unitCount === 1, [property])

  const isHotel = useMemo(() => property?.propertyType === 'HOTEL', [property])

  const exampleChartHeight = 84

  return (
    <Dialog
      data-testid="smoke_report_dialog"
      fullScreen
      open
      onClose={onClose}
      disableScrollLock
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <IconButton onClick={onClose} sx={closeIconStyles}>
          <CloseIcon />
        </IconButton>

        <Button variant="contained" sx={exportButtonStyles} onClick={generateReport}>
          Generate PDF
        </Button>

        <div id="printable_canvas" style={canvasStyles}>
          <Typography variant="h4" sx={propertyHeaderStyles}>
            {property.name}
          </Typography>

          <Typography variant="h4" sx={titleStyles}>
            Smoke Report
          </Typography>

          <Typography variant="h6" sx={headerStyles}>
            {!isPrunit ? (
              <>
                {`${isHotel ? 'Room' : 'Unit'} ${instance.unitName}`}
                <br />
              </>
            ) : null}
            event confirmed at {eventTime.toLocaleString(DateTime.DATETIME_SHORT)}{' '}
            <br />
          </Typography>

          <Stack spacing={2}>
            <Typography variant="caption" style={{ lineHeight: 1.5 }} component="div">
              The data in this graph represents the period of time that the air around
              the sensor had a distinctive signature indicative of smoking in sufficient
              quantity to trigger an alert. Note that the actual start/stop time of
              smoking may have been earlier/later depending on the distance between the
              smoker and the sensor and patterns of airflow around the sensor and within
              the room.
            </Typography>
            <Box sx={{ border: '1px solid #ccc', p: 2, height: 370 }}>
              <ParentChart
                loading={loading}
                error={error}
                probabilityData={probabilityData}
                timezone={property.timezone}
              />
            </Box>
            <Box>
              <Typography variant="body1" sx={{ pl: 1, pb: 2 }}>
                Representative smoke detection score graphs from other common activities
              </Typography>
              <Grid2 container spacing={1} sx={{ pb: 1 }}>
                <Grid2 size={{ xs: 4 }}>
                  <Typography variant="body2" align="center">
                    Aerosols
                  </Typography>
                  <ParentSize>
                    {({ width }) => (
                      <LineChart
                        lines={aerosolChart}
                        height={exampleChartHeight}
                        width={width}
                        timezone={property.timezone}
                        hideAxis
                      />
                    )}
                  </ParentSize>
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Typography variant="body2" align="center">
                    Shower
                  </Typography>
                  <ParentSize>
                    {({ width }) => (
                      <LineChart
                        lines={showerChart}
                        height={exampleChartHeight}
                        width={width}
                        timezone={property.timezone}
                        hideAxis
                      />
                    )}
                  </ParentSize>
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Typography variant="body2" align="center">
                    Cooking
                  </Typography>
                  <ParentSize>
                    {({ width }) => (
                      <LineChart
                        lines={cookingChart}
                        height={exampleChartHeight}
                        width={width}
                        timezone={property.timezone}
                        hideAxis
                      />
                    )}
                  </ParentSize>
                </Grid2>
              </Grid2>
              <Typography variant="caption" style={{ lineHeight: 1.5 }} component="div">
                Rest sensor technology measures several aspects of air quality in guest
                rooms. Measurements from sensors are passed to a proprietary algorithm
                that has been verified by rigorous statistical measures. The algorithm
                is specifically designed to detect combusted or vaporized tobacco,
                marijuana, and nicotine products. The algorithm generates a smoke score,
                where a score of 100 indicates that smoking has occurred in the room
                where the sensor is installed.
              </Typography>
            </Box>
          </Stack>
        </div>
      </DialogContent>
    </Dialog>
  )
}

SmokeReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
  }).isRequired,
  property: PropTypes.shape({
    name: PropTypes.string,
    timezone: PropTypes.string,
    unitCount: PropTypes.number.isRequired,
    propertyType: PropTypes.string.isRequired,
  }).isRequired,
}

export default SmokeReport
