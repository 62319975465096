import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { FlagOutlined } from '@mui/icons-material'
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'
import FeatureFlagsConfiguration from '@portal/UI/components/FeatureFlagConfiguration'

export default function FeatureFlagsComponent({
  property,
  availableFlags,
  onEditSuccess,
}) {
  const isSmallScreen = useSmallScreen()

  const [featureFlagsFormOpen, setFeatureFlagsFormOpen] = useState(false)

  const enabledFlags = useMemo(() => property?.flags ?? [], [property])
  const effectiveFlags = useMemo(
    () =>
      (property?.expandedFlags ?? []).filter((flag) => !property.flags.includes(flag)),
    [property],
  )

  const sectionStyle = {
    variant: 'body2',
    color: 'text.secondary',
    textTransform: 'uppercase',
    fontSize: isSmallScreen ? 12 : 14,
    mt: 2,
  }

  const noDataStyle = {
    variant: 'body2',
    color: 'text.secondary',
    mt: 1,
  }

  return (
    <>
      {property && (
        <FeatureFlagsConfiguration
          entityType="property"
          entity={property}
          open={featureFlagsFormOpen}
          onClose={(success) => {
            setFeatureFlagsFormOpen(false)
            if (success === true && onEditSuccess) {
              onEditSuccess()
            }
          }}
        />
      )}

      <Stack direction="row" alignItems="start">
        <Box width={1}>
          <Typography {...sectionStyle}>Direct Feature Flags</Typography>
          {isEmpty(enabledFlags) && (
            <Typography {...noDataStyle}>
              This property does not have directly enabled feature flags
            </Typography>
          )}
          {availableFlags && !isEmpty(enabledFlags) && (
            <Box display="flex" flexWrap="wrap" gap={1} mb={2} mt={1.5}>
              {availableFlags
                .filter((flag) => enabledFlags.includes(flag.key))
                .map((flag) => (
                  <Tooltip arrow key={flag.key} title={flag.description}>
                    <Chip
                      icon={<FlagOutlined />}
                      label={flag.key}
                      variant="outlined"
                      sx={{ cursor: 'default' }}
                    />
                  </Tooltip>
                ))}
            </Box>
          )}

          <Typography {...sectionStyle}>Inherited Feature Flags</Typography>
          {isEmpty(effectiveFlags) && (
            <Typography {...noDataStyle}>
              This property does not have inherited feature flags
            </Typography>
          )}
          {availableFlags && !isEmpty(effectiveFlags) && (
            <Box display="flex" flexWrap="wrap" gap={1} mb={2} mt={1.5}>
              {availableFlags
                .filter((flag) => effectiveFlags.includes(flag.key))
                .map((flag) => (
                  <Tooltip arrow title={flag.description}>
                    <Chip
                      icon={<FlagOutlined />}
                      label={flag.key}
                      variant="outlined"
                      sx={{ cursor: 'default' }}
                    />
                  </Tooltip>
                ))}
            </Box>
          )}
        </Box>
        <Button
          onClick={() => setFeatureFlagsFormOpen(true)}
          sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}
        >
          Edit
        </Button>
      </Stack>
    </>
  )
}

FeatureFlagsComponent.defaultProps = {
  property: null,
  onEditSuccess: null,
}

FeatureFlagsComponent.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    flags: PropTypes.arrayOf(PropTypes.string),
    expandedFlags: PropTypes.arrayOf(PropTypes.string),
  }),
  availableFlags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEditSuccess: PropTypes.func,
}
