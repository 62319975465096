import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel } from '@mui/material'

import { useFormikContext } from 'formik'
import { capitalize } from 'inflection'

export default function ScenarioTagCheckBox({ tag, setDisabled }) {
  const { values, setFieldValue } = useFormikContext()
  const { scenarioTags } = values
  const soloTags = ['confirmed_true', 'confirmed_false', 'suspicious']

  const handleCheck = () =>
    scenarioTags.includes(tag)
      ? setFieldValue(
          'scenarioTags',
          scenarioTags.filter((t) => t !== tag),
        )
      : setFieldValue('scenarioTags', [
          ...scenarioTags.filter((t) => !soloTags.includes(t)),
          tag,
        ])

  const formatTagLabel = () => {
    const [pre, suf] = tag.split('_')
    return suf ? `${capitalize(pre)} (${capitalize(suf)})` : `${capitalize(pre)}`
  }

  useEffect(() => {
    // disabled is used to mimic toggle functionality in the select dropdown
    const disabled = soloTags.filter((st) => !scenarioTags.includes(st))
    setDisabled(disabled?.length === 2 ? disabled : [])
  }, [scenarioTags])

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleCheck} checked={scenarioTags.includes(tag)} />}
      label={formatTagLabel(tag)}
    />
  )
}
ScenarioTagCheckBox.propTypes = {
  tag: PropTypes.string.isRequired,
  setDisabled: PropTypes.func.isRequired,
}
