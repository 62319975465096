import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { FormikDynamicSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

export default function ModifyProfileForm({ open, onClose, data }) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setFieldValue: () => {},
  })

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const initialValues = {
    id: data?.id,
    alertingSmokeProfile: data?.alertingSmokeProfile,
    secondOpinionSmokeProfile: data?.secondOpinionSmokeProfile,
    shadowSmokeProfiles: data?.shadowSmokeProfiles ?? [],
  }

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    alertingSmokeProfile: yup.string().uuid().nullable(),
    secondOpinionSmokeProfile: yup.string().uuid().nullable(),
    shadowSmokeProfiles: yup.array().of(yup.string().uuid()),
  })

  const handleSave = useCallback(
    async ({
      id,
      alertingSmokeProfile,
      shadowSmokeProfiles,
      secondOpinionSmokeProfile,
    }) => {
      try {
        await apiFetch(
          '/properties/smoke_profiles/',
          { id, alertingSmokeProfile, shadowSmokeProfiles, secondOpinionSmokeProfile },
          { method: 'PUT', cancelationPrefix: 'modify_profile_form' },
        )
        doShowSnackbar(`Property profiles have been updated`, 'success')
        onClose(true)
      } catch (err) {
        const errorMessage = parseApiErrors(err)
        doShowSnackbar(errorMessage, 'error')
      }
    },
    [data],
  )

  const filters = { status: ['AVAILABLE', 'DEFAULT_ALERTING'].join(',') }

  const formatFieldVlaue = useCallback(
    (option) => (option ? `${option.modelKey} - ${option.name}` : option),
    [],
  )

  return (
    <FormDialog
      label="Edit Property Profiles"
      open={open}
      onSave={handleSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box>
        <Field
          fullWidth
          alwaysShowPlaceholder
          component={FormikDynamicSelect}
          formatInputValue={formatFieldVlaue}
          useValueInRequest={false}
          label="Alerting Smoke Profile"
          name="alertingSmokeProfile"
          value={formikProps.values.alertingSmokeProfile}
          showError={!!formikProps.errors.alertingSmokeProfile}
          error={formikProps.errors.alertingSmokeProfile}
          placeholder="Default"
          endpoint="smoke_profiles"
          secondaryTextAttr="modelKey"
          filters={filters}
          inputStyle={{ '& ::placeholder': { fontStyle: 'italic' } }}
          cancelationPrefix="alerting"
        />
        <Field
          fullWidth
          component={FormikDynamicSelect}
          formatInputValue={formatFieldVlaue}
          useValueInRequest={false}
          label="Second Opinion Profile"
          name="secondOpinionSmokeProfile"
          value={formikProps.values.secondOpinionSmokeProfile}
          showError={!!formikProps.errors.secondOpinionSmokeProfile}
          error={formikProps.errors.secondOpinionSmokeProfile}
          endpoint="smoke_profiles"
          secondaryTextAttr="modelKey"
          filters={filters}
          cancelationPrefix="second_opinion"
          sx={{ mt: 2 }}
        />
        <Field
          fullWidth
          multiple
          disableCloseOnSelect
          component={FormikDynamicSelect}
          useValueInRequest={false}
          label="Shadow Smoke Profiles"
          name="shadowSmokeProfiles"
          formatTagValue={formatFieldVlaue}
          value={formikProps.values.shadowSmokeProfiles}
          showError={!!formikProps.errors.shadowSmokeProfiles}
          error={formikProps.errors.shadowSmokeProfiles}
          endpoint="smoke_profiles"
          secondaryTextAttr="modelKey"
          filters={filters}
          cancelationPrefix="shadow"
          sx={{ mt: 2 }}
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}

ModifyProfileForm.defaultProps = {
  data: null,
}

ModifyProfileForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    alertingSmokeProfile: PropTypes.string,
    secondOpinionSmokeProfile: PropTypes.string,
    shadowSmokeProfiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
}
