import { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Field } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { merge, parseApiErrors } from '@common/utils'
import { FormDialog } from '@rest/UI/components'

const validationSchema = Yup.object().shape({
  comments: Yup.string(),
})

function NoiseFeedback({ eventId, propertyId, existingFeedback, onClose }) {
  const { doNoiseEventFeedbackSave } = useConnect('doNoiseEventFeedbackSave')

  const onFeedbackSave = useCallback(
    async (data) => {
      const final = {
        ...data,
        event: eventId,
        property: propertyId,
      }

      if (existingFeedback) {
        final.id = existingFeedback.id
      }
      const result = await doNoiseEventFeedbackSave(final)
      if (result.error) throw parseApiErrors(result)
      return result
    },
    [existingFeedback, doNoiseEventFeedbackSave],
  )
  const successMessage = existingFeedback
    ? 'Successfully adjusted feedback information'
    : 'Successfully created feedback information'
  const initialValues = merge({ comments: '' }, existingFeedback)
  return (
    <FormDialog
      open
      onClose={onClose}
      initialValues={initialValues}
      label="Noise Event Feedback"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      successMessage={successMessage}
    >
      <Field
        component={TextField}
        name="comments"
        label="Comments"
        InputProps={{ multiline: true }}
        sx={{ width: '100%' }}
      />
    </FormDialog>
  )
}

NoiseFeedback.propTypes = {
  eventId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  existingFeedback: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comments: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
}

NoiseFeedback.defaultProps = {
  existingFeedback: {},
}

export default NoiseFeedback
