import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Card, CardContent, Stack, Typography } from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'

function ChargeSummary({ eventList, fetch, formatCurrency }) {
  const [summary, setSummary] = useState(null)

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const isSmallScreen = useSmallScreen()

  const fetchSummary = useCallback(async () => {
    try {
      const result = await fetch()
      if (result?.error) {
        throw result.error
      }

      setSummary(result)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(
        parsedError || 'Cannot get charge summary. An unexpected error occurred',
        'error',
      )
    }
  }, [fetch])

  useEffect(() => {
    fetchSummary()
    return () => setSummary(null)
  }, [eventList])

  const renderChargePct = () =>
    isNaN(summary?.chargePct) ? '0' : parseFloat(summary?.chargePct).toFixed(1)

  return (
    <Card>
      <CardContent
        sx={{
          '&.MuiCardContent-root': { p: isSmallScreen ? 1 : 2 },
        }}
      >
        <Stack direction="column">
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography
              variant={isSmallScreen ? 'body2' : 'h6'}
              sx={{ width: isSmallScreen ? 50 : 110 }}
            >
              Net Charges
            </Typography>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                Selected Dates
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.netCharges, '--')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                All Time
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.allTimeCharges, '--')}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" sx={{ mt: 2 }}>
            <Typography
              variant={isSmallScreen ? 'body2' : 'h6'}
              sx={{ width: isSmallScreen ? 50 : 110 }}
            >
              Events
            </Typography>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                Parent/Charged (%)
              </Typography>
              <Typography variant="body2">
                {summary?.eventsTotal ?? 0}/{summary?.eventsCharged ?? 0} (
                {renderChargePct()}%)
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                $/Charged
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.perChargedEvent, '--')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ChargeSummary

ChargeSummary.propTypes = {
  eventList: PropTypes.shape({}).isRequired,
  fetch: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
}
