import PropTypes from 'prop-types'

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

function ConfirmationDialog({
  open,
  title,
  content,
  message,
  onConfirm,
  onCancel,
  onClose,
  error,
  maxWidth,
  fullWidth,
  contentFontSize,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle color="primary" fontSize="1.5rem">
        {title}
      </DialogTitle>
      <DialogContent>
        {error && (
          <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
            <Alert key={error} severity="error">
              <Typography fontSize={contentFontSize}>{error}</Typography>
            </Alert>
          </Box>
        )}
        {content}
        <DialogContentText whiteSpace="pre-line" fontSize={contentFontSize}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} color="secondary" disabled={!!error} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.defaultProps = {
  onCancel: () => {},
  onClose: () => {},
  error: '',
  title: '',
  maxWidth: 'sm',
  message: '',
  content: null,
  fullWidth: true,
  contentFontSize: 16,
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  contentFontSize: PropTypes.number,
}

export default ConfirmationDialog
