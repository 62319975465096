import { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FreeSoloDynamicSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

export default function OrganizationForm({ open, onClose, instance }) {
  const { doOrganizationSave, doShowSnackbar } = useConnect(
    'doOrganizationSave',
    'doShowSnackbar',
  )

  const validationSchema = yup.object().shape({
    name: yup.string().max(30),
  })

  const initialValues = {
    id: '',
    name: '',
    group: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const createGroup = useCallback(async ({ groupName }) => {
    const result = await apiFetch(
      '/organization_groups/',
      { name: groupName },
      { method: 'POST', cancelationPrefix: 'organization_form' },
    )
    return result?.id
  }, [])

  const saveOrganization = async (params) => {
    try {
      const newGroupId = params?.groupName ? await createGroup(params) : null
      const finalGroup = newGroupId ?? params.group
      const { groupName, ...newParams } = params

      const saveResult = await doOrganizationSave({
        ...newParams,
        group: finalGroup,
      })

      return saveResult
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
      throw err
    }
  }

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Organization`}
      open={open}
      onSave={saveOrganization}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
        <Field sx={{ width: '45%' }} component={TextField} label="Name" name="name" />
        <Field
          autoSelect
          name="group"
          altField="groupName"
          label="Organization Group"
          endpoint="organization_groups"
          initialValue={initialValues.group}
          filters={{ organization: initialValues.id || instance?.id }}
          component={FreeSoloDynamicSelect}
          sx={{ width: '45%' }}
        />
      </Box>
    </FormDialog>
  )
}

OrganizationForm.defaultProps = {
  instance: {},
}

OrganizationForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
}
