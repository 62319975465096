import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Alert, Box, Button, Link, Typography } from '@mui/material'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import logo from '@assets/logo_dark.svg'
import TextField from '@common/components/Form/TextField'
import { config, Project } from '@common/config'
import {
  cardContent,
  contentContainer,
  formStyles,
  loginButton,
  loginStyles,
  logoStyles,
  splashContainer,
} from '@portal/pages/Login/Login.styles'

import GoogleSignInButton from './GoogleSignInButton'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required(''),
  password: Yup.string().required(''),
})

export function LoginComponent({
  auth,
  queryObject,
  doAuthLogin,
  doAuthLoginWithTokens,
}) {
  const [paramsError, setParamsError] = useState(null)

  useEffect(() => {
    if (queryObject?.access && queryObject?.refresh) {
      doAuthLoginWithTokens(queryObject)
    }

    if (queryObject?.error) {
      setParamsError(queryObject?.error)
    }
  }, [queryObject])

  const { GOOGLE_OAUTH_CLIENT_ID: googleClientId } = config

  return (
    <div style={loginStyles}>
      <div style={contentContainer}>
        <div style={splashContainer}>
          <div style={cardContent}>
            <img alt="rest logo" src={logo} style={logoStyles} />
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={validationSchema}
              validateOnMount
              onSubmit={doAuthLogin}
            >
              {({ isSubmitting, isValid }) => (
                <Form style={formStyles}>
                  {auth.login.status === 'failed' && (
                    <Alert severity="error" sx={{ mb: '35px' }}>
                      {auth.login.message || 'Unable to login'}
                    </Alert>
                  )}
                  {paramsError && (
                    <Alert severity="error" sx={{ mb: '35px' }}>
                      {paramsError}
                    </Alert>
                  )}
                  <Field
                    data-testid="login-email"
                    component={TextField}
                    name="email"
                    label="Email"
                    placeholder="Email"
                  />
                  <Field
                    data-testid="login-password"
                    component={TextField}
                    name="password"
                    label="Password"
                    type="password"
                  />
                  <Button
                    data-testid="login-submit"
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    sx={{ ...loginButton }}
                  >
                    Login
                  </Button>
                  {googleClientId && <GoogleSignInButton />}
                  <Box display="flex" justifyContent="center">
                    <Typography
                      component={Link}
                      onClick={() => Project.switch()}
                      sx={{ cursor: 'pointer', fontSize: 12, userSelect: 'none' }}
                    >
                      Switch To Rest
                    </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginComponent.propTypes = {
  doAuthLogin: PropTypes.func.isRequired,
  doAuthLoginWithTokens: PropTypes.func.isRequired,
  queryObject: PropTypes.shape({
    access: PropTypes.string,
    refresh: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  auth: PropTypes.shape({
    login: PropTypes.shape({
      status: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default function Login() {
  const { doAuthLogin, doAuthLoginWithTokens, auth, queryObject } = useConnect(
    'doAuthLogin',
    'doAuthLoginWithTokens',
    'selectAuth',
    'selectQueryObject',
  )
  return (
    <LoginComponent
      auth={auth}
      queryObject={queryObject}
      doAuthLogin={doAuthLogin}
      doAuthLoginWithTokens={doAuthLoginWithTokens}
    />
  )
}
