/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import HorizontalThresholdedLine from '../components/HorizontalThresholdedLine'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    equals(prev.data, next.data) &&
    prev.color === next.color &&
    prev.width === next.width &&
    prev.height === next.height &&
    prev.curve === next.curve &&
    prev.yScale === next.yScale &&
    prev.upperBound === next.upperBound &&
    equals(prev.padding, next.padding)
  )
}

// 'padding' and 'yScale' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoHorizontalThresholdedLine = memo(
  ({
    id,
    data,
    color,
    x,
    y,
    upperBound,
    height,
    width,
    curve,
    defined,
    padding,
    yScale,
  }) => (
    <HorizontalThresholdedLine
      id={id}
      data={data}
      color={color}
      x={x}
      y={y}
      upperBound={upperBound}
      height={height}
      width={width}
      curve={curve}
      defined={defined}
    />
  ),
  propsAreEqual,
)

MemoHorizontalThresholdedLine.defaultProps = {
  padding: undefined,
}

MemoHorizontalThresholdedLine.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color: PropTypes.string.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  upperBound: PropTypes.number.isRequired,
  curve: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  defined: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  padding: PropTypes.shape({}),
}

export default MemoHorizontalThresholdedLine
