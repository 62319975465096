import {
  AccessTimeRounded,
  Assessment as AssessmentIcon,
  AutoFixHigh as AutoFixHighIcon,
  AutoFixOff as AutoFixOffIcon,
  RateReview as RateReviewIcon,
  Try as TryIcon,
  VolumeUp as NoiseIcon,
} from '@mui/icons-material'
import { Button, Stack, Tooltip, Typography } from '@mui/material'

import { DateTime } from 'luxon'

import { SmokeIcon } from '@common/icons'
import { checkPermissions, PrivilegeCheck } from '@rest/Utils'

function isBoolean(value) {
  return value === true || value === false
}

export function renderCreatedOnCell(createdOn, endTime, property, mobileView) {
  const createdLuxon = DateTime.fromISO(createdOn, { zone: property.timezone })
  const endLuxon = endTime
    ? DateTime.fromISO(endTime, { zone: property.timezone })
    : DateTime.now()

  const formattedCreatedOn = createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)
  const endTimeFormat = createdLuxon.hasSame(endLuxon, 'day')
    ? DateTime.TIME_SIMPLE
    : DateTime.DATETIME_MED
  const formattedEndTime = endLuxon.toLocaleString(endTimeFormat)

  return (
    <Tooltip title={`${formattedCreatedOn} - ${formattedEndTime}`} placement="top">
      <Typography variant={mobileView ? 'caption' : 'body2'}>
        {formattedCreatedOn}
      </Typography>
    </Tooltip>
  )
}

export function renderLastModifiedCell(params, property, mobileView) {
  let modifiedOn = null
  if (params.row.eventClass === 'NOISE') {
    modifiedOn = params.row.noiseFeedback?.modifiedOn
  } else if (params.row.eventClass === 'SMOKE' && params.row.feedbackOn) {
    modifiedOn = params.row.smokeFeedback?.modifiedOn || params.row.feedbackOn
  }

  let createdLuxon = null
  if (modifiedOn) {
    createdLuxon = DateTime.fromISO(modifiedOn, {
      zone: property.timezone,
    })
  }

  return (
    <Stack alignItems={mobileView ? 'end' : null}>
      <Typography
        variant={mobileView ? 'caption' : 'body1'}
        fontSize={mobileView ? 12 : 14}
        whiteSpace="pre-line"
      >
        {params.row.smokeFeedback?.autocharge && params.formattedValue === 'N/A'
          ? 'Autocharge'
          : params.formattedValue}
      </Typography>
      {createdLuxon && (
        <Typography
          variant="caption"
          fontSize={mobileView ? 10 : 12}
          color="grey.500"
          fontWeight={600}
        >
          {createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)}
        </Typography>
      )}
    </Stack>
  )
}

export function renderNetChargeCell(params, reasonsById, mobileView) {
  const isAutochargePending =
    params.row.smokeFeedback?.autochargeMetadata?.chargeStatus === 'pending'

  return (
    <Stack>
      <Stack direction="row" justifyContent="right">
        {isAutochargePending && (
          <Tooltip title="Auto-charge pending">
            <AccessTimeRounded
              fontSize="small"
              sx={{ mr: 1, fontSize: mobileView ? 16 : 20 }}
              color="warning"
            />
          </Tooltip>
        )}
        {isBoolean(params.row.smokeFeedback?.autocharge) &&
          (params.row.smokeFeedback.autocharge ? (
            <Tooltip title="Auto-charge successful">
              <AutoFixHighIcon fontSize="small" sx={{ mr: 1 }} color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Auto-charge failed">
              <AutoFixOffIcon fontSize="small" sx={{ mr: 1 }} color="error" />
            </Tooltip>
          ))}
        <Typography
          variant={mobileView ? 'caption' : 'body1'}
          fontSize={mobileView ? 12 : 14}
        >
          {params.formattedValue}
        </Typography>
      </Stack>
      <Typography
        variant="caption"
        fontSize={mobileView ? 10 : 12}
        color="grey.500"
        align="right"
      >
        {reasonsById?.[params.row.smokeFeedback?.adjustmentReason] ?? ''}
      </Typography>
    </Stack>
  )
}

export function renderActionCell(
  params,
  setActiveInstance,
  setNoiseFeedbackOpen,
  setAdjustFormOpen,
  setPostFormOpen,
  setEventReportOpen,
  setOldEventReportOpen,
  isNoise,
  activePermissions,
  getMuteButton,
  mobileView,
) {
  const feedbackPermissions = ['change_event_feedback']
  let buttonProps = {
    fullWidth: mobileView,
    variant: 'contained',
    startIcon: <TryIcon />,
    size: 'small',
    sx: { width: mobileView ? '100%' : 120 },
  }
  let label = ''

  if (params.row.eventClass === 'NOISE') {
    buttonProps = {
      variant: 'contained',
      startIcon: <RateReviewIcon />,
      size: 'small',
      sx: { width: mobileView ? '100%' : 120, justifyContent: 'center' },
      onClick: () => {
        setActiveInstance(params.row)
        setNoiseFeedbackOpen(true)
      },
    }
    label = 'Feedback'
  } else if (params.row.feedbackOn) {
    buttonProps = {
      variant: 'contained',
      startIcon: <RateReviewIcon />,
      size: 'small',
      sx: { width: mobileView ? '100%' : 120, justifyContent: 'center' },
      onClick: () => {
        setActiveInstance(params.row)
        setAdjustFormOpen(true)
      },
    }
    label = 'Adjust'
  } else {
    buttonProps.onClick = () => {
      setActiveInstance(params.row)
      setPostFormOpen(true)
    }
    label = 'Post'
  }

  const hasPermissions = checkPermissions(feedbackPermissions, activePermissions)

  const isSmokeEvent = params.row.eventClass === 'SMOKE'

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end" width="100%">
      <Button {...buttonProps} disabled={!hasPermissions}>
        {label}
      </Button>
      <Tooltip title={!params.row.endTime ? 'Report not yet available' : undefined}>
        <span style={{ width: mobileView ? '100%' : null }}>
          <Button
            fullWidth={mobileView}
            variant="outlined"
            startIcon={<AssessmentIcon />}
            size="small"
            color="secondary"
            onClick={() => {
              if (!isSmokeEvent || params.row?.metadata?.smoke?.alarmTag) {
                setEventReportOpen(true)
              } else {
                setOldEventReportOpen(true)
              }
              setActiveInstance(params.row)
            }}
            disabled={!params.row.endTime}
          >
            Report
          </Button>
        </span>
      </Tooltip>
      <PrivilegeCheck flags={['MUTE_BUTTON']}>
        {isNoise && getMuteButton(params.row, mobileView)}
      </PrivilegeCheck>
    </Stack>
  )
}

export function renderEventTypeColumn(row, small) {
  switch (row.eventClass) {
    case 'NOISE':
      return (
        <Tooltip title="Noise">
          <NoiseIcon color="action" sx={{ fontSize: small ? 18 : 24 }} />
        </Tooltip>
      )
    case 'SMOKE':
      return (
        <Tooltip title="Smoke">
          <SmokeIcon
            color="action"
            style={{ transform: 'rotate(-90deg)', fontSize: small ? 18 : 24 }}
          />
        </Tooltip>
      )
    default:
      return null
  }
}
