import { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Box, Chip, Link, Tooltip, Typography } from '@mui/material'

import { humanize } from 'inflection'

import accountsUrls from '@portal/pages/Accounts/urls'
import orgsUrls from '@portal/pages/Organizations/urls'
import propertiesUrls from '@portal/pages/Properties/urls'

export default function FiltersInfoHeader({
  range,
  entities,
  eventTypes,
  eventClasses,
}) {
  const [start, end] = range

  const showEntitiesBlock = useMemo(
    () => Object.values(entities).some((obj) => !isEmpty(obj)),
    [entities],
  )

  const headerStyle = { variant: 'caption', fontWeight: 'bold' }
  const contentStyle = { variant: 'caption' }

  const entityLinks = {
    organization: orgsUrls.entity,
    account: accountsUrls.entity,
    property: propertiesUrls.entity,
  }

  const MultiOptionsRow = useCallback(
    ({ options, label }) => (
      <Box display="flex" flexDirection={isEmpty(options) ? 'row' : 'column'} gap={0.5}>
        <Typography {...headerStyle}>{label}:</Typography>
        {isEmpty(options) ? (
          <Typography {...contentStyle}>All</Typography>
        ) : (
          <Box display="flex" gap={1}>
            {options.map((cls) => (
              <Tooltip key={cls.id} title={cls.label}>
                <Chip key={cls.id} label={humanize(cls.id)} size="small" />
              </Tooltip>
            ))}
          </Box>
        )}
      </Box>
    ),
    [],
  )

  return (
    <>
      <Box display="flex" gap={0.5}>
        <Typography {...headerStyle}>Range:</Typography>
        <Typography {...contentStyle}>
          {start}
          {' — '}
          {end}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection={showEntitiesBlock ? 'column' : 'row'}
        gap={showEntitiesBlock ? 0 : 0.5}
      >
        <Typography {...headerStyle}>Entities:</Typography>
        {showEntitiesBlock ? (
          Object.entries(entities)
            .filter((arr) => !isEmpty(arr[1]))
            .map(([key, value]) => {
              const link = entityLinks[key]
              return (
                <Box display="flex" gap={1} ml={1}>
                  <Typography {...headerStyle}>{humanize(key)}:</Typography>
                  {link ? (
                    <Typography {...contentStyle}>
                      <Link href={link.replace(':id', value.id)}>{value.name}</Link>
                    </Typography>
                  ) : (
                    <Typography {...contentStyle}>{value.name}</Typography>
                  )}
                </Box>
              )
            })
        ) : (
          <Typography {...contentStyle}>All</Typography>
        )}
      </Box>
      <MultiOptionsRow label="Event types" options={eventTypes} />
      <MultiOptionsRow label="Event classes" options={eventClasses} />
    </>
  )
}

FiltersInfoHeader.propTypes = {
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  entities: PropTypes.shape({
    organizationGroup: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    account: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    property: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  eventClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
