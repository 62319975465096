import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'
import homeUrls from '@portal/pages/Home/urls'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'propertyOutages'

const propertyOutagesListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)
    return apiFetch(
      '/property_outages/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: defaultInitialState,
  urlTest: (url, pattern, hash) =>
    url === homeUrls.home || (propertyUrls.entity === pattern && hash === 'property'),
})

export default propertyOutagesListBundle
