import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Chip } from '@mui/material'

import { humanize } from 'inflection'
import { DateTime } from 'luxon'

import {
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useSmallScreen } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'
import { BooleanCell, GridCellExpand } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import Picker from '@portal/UI/components/Picker'
import boolOptions from '@portal/Utils/constants'

import OutageActions from './OutageActions'
import OutageForm from './OutageForm'
import OutagesAuditLog from './OutagesAuditLog'

export default function Outages({ renderAsTab }) {
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const [outageToUpdate, setOutageToUpdate] = useState(null)

  const isSmallScreen = useSmallScreen()

  const defaultFilter = { resolved: renderAsTab, isSnoozed: false }

  const {
    doPropertyOutagesListClearParams,
    doPropertyOutagesListSetFilter,
    doPropertyOutagesListSetPage,
    doPropertyOutagesListSetPageSize,
    doPropertyOutagesListSetOrdering,
    doPropertyOutagesListSetSearch,
    doMarkPropertyOutagesListAsOutdated,
    propertyOutagesList,
    propertyOutagesListRaw: { ordering = [] },
    propertyOutagesListIsLoading,
    propertyOutagesListApiParams,
    queryObject,
    doUpdateQuery,
  } = useConnect(
    'doPropertyOutagesListClearParams',
    'doPropertyOutagesListSetFilter',
    'doPropertyOutagesListSetPage',
    'doPropertyOutagesListSetPageSize',
    'doPropertyOutagesListSetOrdering',
    'doPropertyOutagesListSetSearch',
    'doMarkPropertyOutagesListAsOutdated',
    'selectPropertyOutagesList',
    'selectPropertyOutagesListRaw',
    'selectPropertyOutagesListIsLoading',
    'selectPropertyOutagesListApiParams',
    'selectQueryObject',
    'doUpdateQuery',
  )

  useEffect(() => {
    doPropertyOutagesListSetFilter(defaultFilter)
  }, [])

  const handleClear = () => {
    doPropertyOutagesListClearParams()
  }

  const columns = [
    ...(!renderAsTab
      ? [
          {
            field: 'property',
            headerName: 'Property',
            renderCell: ({ row }) => {
              if (row.property) {
                return (
                  <ClickableCell
                    label={row.propertyName}
                    url={propertyUrls?.entity.replace(':id', row.property)}
                  />
                )
              }
              return row.propertyName
            },
          },
        ]
      : []),
    {
      field: 'resolved',
      headerName: 'Resolved',
      type: 'boolean',
      sortable: false,
      renderCell: BooleanCell,
      renderMobile: ({ row }) => (row.resolved ? 'Yes' : 'No'),
    },
    {
      field: 'category',
      headerName: 'Category',
      valueFormatter: (value) => humanize(value),
    },
    {
      field: 'start',
      headerName: 'Start Time',
      valueFormatter: (value) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
    },
    {
      field: 'end',
      headerName: 'End Time',
      valueFormatter: (value) =>
        value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'N/A',
    },
    {
      field: 'snoozedUntil',
      headerName: 'Snoozed Until',
      sortable: false,
      valueFormatter: (value) =>
        value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'N/A',
    },
    {
      field: 'comments',
      headerName: 'Comments',
      sortable: false,
      renderCell: GridCellExpand,
      renderMobile: ({ row }) => row.comments,
      maxWidth: 250,
    },
    {
      field: 'extraActions',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Actions',
      sortable: false,
      renderCell: ({ row }) => (
        <OutageActions
          row={row}
          onEditPressed={setOutageToUpdate}
          onAuditLogPressed={(outage) => doUpdateQuery({ auditLog: outage.id })}
        />
      ),
    },
  ]

  const categoriesOptions = useMemo(
    () => ['AUTOCHARGE', 'CONNECTIVITY', 'RESERVATION_REPORT_SYNC'],
    [],
  )

  const MobileItemFooter = useCallback(
    ({ row }) => (
      <OutageActions
        row={row}
        onEditPressed={setOutageToUpdate}
        onAuditLogPressed={(outage) => doUpdateQuery({ auditLog: outage.id })}
      />
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['extraActions'],
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  if (!propertyOutagesList.results) {
    return <ErrorComponent title="Outages" callback={handleClear} />
  }

  const rows = propertyOutagesList?.results?.map((outage) => ({
    id: outage.id,
    property: outage.property,
    propertyName: outage.propertyName,
    category: outage.category,
    start: outage.start,
    end: outage.end,
    comments: outage.comments,
    snoozedUntil: outage.snoozedUntil,
    resolved: !!outage.end,
  }))

  const getFilterChipsData = () => {
    const now = DateTime.now()

    const isCurrentlyOpenSelected = () => {
      if (
        'resolved' in propertyOutagesListApiParams &&
        'isSnoozed' in propertyOutagesListApiParams
      ) {
        const unexpectedFilters = Object.keys(propertyOutagesListApiParams).some(
          (key) =>
            [
              'startAfter',
              'startBefore',
              'endAfter',
              'endBefore',
              'snoozedAfter',
              'snoozedBefore',
            ].includes(key),
        )
        return (
          !propertyOutagesListApiParams.resolved &&
          !propertyOutagesListApiParams.isSnoozed &&
          !unexpectedFilters
        )
      }
      return false
    }

    const isRecentlyResolvedSelected = () => {
      if (
        'resolved' in propertyOutagesListApiParams &&
        propertyOutagesListApiParams?.endAfter &&
        propertyOutagesListApiParams?.endBefore
      ) {
        const unexpectedFilters = Object.keys(propertyOutagesListApiParams).some(
          (key) =>
            ['startAfter', 'startBefore', 'snoozedAfter', 'snoozedBefore'].includes(
              key,
            ),
        )
        const end =
          typeof propertyOutagesListApiParams.endBefore === 'string'
            ? DateTime.fromISO(propertyOutagesListApiParams.endBefore)
            : propertyOutagesListApiParams.endBefore
        const start =
          typeof propertyOutagesListApiParams.endAfter === 'string'
            ? DateTime.fromISO(propertyOutagesListApiParams.endAfter)
            : propertyOutagesListApiParams.endAfter
        const diff = end.diff(start, 'days')
        const isDesiredDiff = diff.days === 7
        return (
          propertyOutagesListApiParams.resolved && isDesiredDiff && !unexpectedFilters
        )
      }
      return false
    }

    const isSnoozedSelected = () => {
      if (
        propertyOutagesListApiParams?.snoozedAfter &&
        propertyOutagesListApiParams?.snoozedBefore
      ) {
        const unexpectedFilters = Object.keys(propertyOutagesListApiParams).some(
          (key) =>
            ['resolved', 'startAfter', 'startBefore', 'endAfter', 'endBefore'].includes(
              key,
            ),
        )
        const end =
          typeof propertyOutagesListApiParams.snoozedBefore === 'string'
            ? DateTime.fromISO(propertyOutagesListApiParams.snoozedBefore)
            : propertyOutagesListApiParams.snoozedBefore
        const start =
          typeof propertyOutagesListApiParams.snoozedAfter === 'string'
            ? DateTime.fromISO(propertyOutagesListApiParams.snoozedAfter)
            : propertyOutagesListApiParams.snoozedAfter
        const diff = end.diff(start, 'years')
        return diff.years === 2 && !unexpectedFilters
      }
      return false
    }

    return [
      {
        label: 'Currently Open',
        params: { resolved: false, isSnoozed: false },
        selected: isCurrentlyOpenSelected(),
      },
      {
        label: 'Recently Resolved',
        params: { resolved: true, endAfter: now.minus({ days: 7 }), endBefore: now },
        selected: isRecentlyResolvedSelected(),
      },
      {
        label: 'Snoozed',
        params: {
          snoozedAfter: now.minus({ years: 1 }),
          snoozedBefore: now.plus({ years: 1 }),
        },
        selected: isSnoozedSelected(),
      },
    ]
  }

  return (
    <>
      <OutageForm
        open={!!outageToUpdate}
        onClose={(success) => {
          setOutageToUpdate(null)
          if (success === true) {
            doMarkPropertyOutagesListAsOutdated()
          }
        }}
        instance={outageToUpdate}
      />
      <OutagesAuditLog
        open={!!queryObject?.auditLog}
        onClose={() => doUpdateQuery({})}
        outageId={queryObject?.auditLog}
      />
      <Box m={isSmallScreen ? 0 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box display="flex">
          <Filter
            open={false}
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={propertyOutagesListIsLoading}
            apiParams={propertyOutagesListApiParams}
            setApiParams={doPropertyOutagesListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            {!renderAsTab && (
              <DynamicSelect label="Properties" filterName="property" size="small" />
            )}
            <StaticSelect
              label="Resolved"
              filterName="resolved"
              options={boolOptions}
            />
            <StaticSelect
              label="Snoozed"
              filterName="isSnoozed"
              options={boolOptions}
            />
            <StaticSelect
              multiple
              label="Categories"
              filterName="category"
              options={categoriesOptions}
            />
            <Picker
              range
              type="dateTime"
              label="Start at"
              disableMaskedInput
              conditionSeparator=""
              filterName="start"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(['startBefore', 'startAfter'], propertyOutagesListApiParams)}
            />
            <Picker
              range
              type="dateTime"
              label="End at"
              disableMaskedInput
              conditionSeparator=""
              filterName="end"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(['endBefore', 'endAfter'], propertyOutagesListApiParams)}
            />
            <Picker
              range
              type="dateTime"
              label="Snoozed at"
              disableMaskedInput
              conditionSeparator=""
              filterName="snoozed"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(
                ['snoozedBefore', 'snoozedAfter'],
                propertyOutagesListApiParams,
              )}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            <Box display="flex" gap={1}>
              {getFilterChipsData().map((item) => (
                <Chip
                  key={item.label}
                  label={item.label}
                  size="small"
                  onClick={
                    item.selected
                      ? () => doPropertyOutagesListSetFilter(defaultFilter)
                      : () => doPropertyOutagesListSetFilter(item.params)
                  }
                  color="primary"
                  variant={item.selected ? 'standard' : 'outlined'}
                />
              ))}
            </Box>
            {isSmallScreen ? (
              <MobileList
                title="Outages"
                loading={propertyOutagesListIsLoading}
                itemBuilder={MobileListItem}
                page={propertyOutagesList.current || 1}
                pageChange={doPropertyOutagesListSetPage}
                pageSize={propertyOutagesList.pageSize}
                pageSizeChange={doPropertyOutagesListSetPageSize}
                rows={rows}
                rowCount={propertyOutagesList.count || 0}
                setSearch={doPropertyOutagesListSetSearch}
              />
            ) : (
              <List
                columnsAutosize
                title="Outages"
                showAddButton={false}
                showActions={false}
                loading={propertyOutagesListIsLoading}
                columns={columns}
                page={propertyOutagesList.current || 1}
                pageChange={doPropertyOutagesListSetPage}
                pageSize={propertyOutagesList.pageSize}
                pageSizeChange={doPropertyOutagesListSetPageSize}
                rows={rows}
                rowCount={propertyOutagesList.count || 0}
                setSearch={doPropertyOutagesListSetSearch}
                sortChange={doPropertyOutagesListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

Outages.defaultProps = {
  renderAsTab: false,
}

Outages.propTypes = {
  renderAsTab: PropTypes.bool,
}
