import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Field, useFormikContext } from 'formik'

import countryList from '@assets/country_list.json'
import { StaticSelect } from '@common/components/Selects'

export default function CountryField({ sx, countryStateValue, setCountryValue }) {
  const form = useFormikContext()
  const humanizeCountryLabel = (value) => {
    const countryObject = countryList.find(
      (country) =>
        country.iso3.toLowerCase() === value?.toLowerCase() ||
        country.iso2.toLowerCase() === value?.toLowerCase(),
    )
    if (countryObject) {
      return countryObject.name
    }
    return value
  }

  const { value: countryFieldValue } = form.getFieldProps('country')

  useEffect(() => {
    if (countryFieldValue !== countryStateValue) {
      setCountryValue(countryFieldValue)
    }
  }, [countryFieldValue, countryStateValue])

  return (
    <Field
      required
      component={StaticSelect}
      label="Country"
      name="country"
      variant="standard"
      options={countryList.map((country) => country.iso2)}
      optionLabelFormatter={humanizeCountryLabel}
      onChange={(value) => {
        setCountryValue(value)
        form.setFieldValue('state', '')
      }}
      sx={sx}
    />
  )
}

CountryField.defaultProps = {
  countryStateValue: '',
}

CountryField.propTypes = {
  sx: PropTypes.shape({}).isRequired,
  setCountryValue: PropTypes.func.isRequired,
  countryStateValue: PropTypes.string,
}
