import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { Circle } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.cx === next.cx &&
    prev.cy === next.cy &&
    prev.r === next.r &&
    prev.fill === next.fill &&
    prev.stroke === next.stroke &&
    prev.strokeWidth === next.strokeWidth &&
    prev.graphWidth &&
    next.graphWidth &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoCircle = memo(
  // eslint-disable-next-line no-unused-vars
  ({ id, cx, cy, r, fill, stroke, strokeWidth, graphWidth, padding }) => (
    <Circle
      key={id}
      cx={cx}
      cy={cy}
      r={r}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      pointerEvents="none"
    />
  ),
  propsAreEqual,
)

MemoCircle.defaultProps = {
  fill: undefined,
  stroke: undefined,
  strokeWidth: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoCircle.propTypes = {
  id: PropTypes.string.isRequired,
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  r: PropTypes.number.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoCircle
