import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Group } from 'react-konva'

import { HANDLE_HEIGHT } from './constants'
import HoverOverlayRect from './HoverOverlayRect'
import { DurationLabel, NRSLabel, SettingLabel } from './Labels'
import { Rect } from './Rects'
import ResizeHandle from './ResizeHandle'
import {
  getDurationLabel,
  rectToSetting,
  setCursorStyle,
  settingToRectBounds,
  verticalDragHandler,
  verticalResizeHandler,
} from './utils'

export default function ThresholdRect({
  id,
  NRS,
  start,
  end,
  label,
  color,
  next,
  previous,
  onDelete,
  onEdit,
  onResizeStart,
  onResizeEnd,
  onDragStart,
  onDragEnd,
  isDragging,
  editingId,
  isEditing,
  editorWidth,
}) {
  const { top, bottom } = settingToRectBounds(start, end)
  const durationLabel = getDurationLabel(start, end)
  const isHourLong = Math.abs(start - end) === 1
  const [isFocused, setIsFocused] = useState(false)
  const rectRef = useRef(null)
  const topDragHandleRef = useRef(null)
  const bottomDragHandleRef = useRef(null)
  const labelsRef = useRef(null)
  const overlayRef = useRef(null)
  const touchTimeoutRef = useRef()

  const handleMouseEnter = (e) => {
    setIsFocused(true)
    setCursorStyle(e, 'move')
  }
  const handleMouseLeave = (e) => {
    setIsFocused(false)
    setCursorStyle(e, 'default')
  }

  const handleLongPress = (e) => {
    setIsFocused(true)
    setCursorStyle(e, 'move')
  }

  const handleTouchStart = (e) => {
    touchTimeoutRef.current = setTimeout(() => {
      handleLongPress(e)
    }, 500)
  }

  const handleTouchEnd = (e) => {
    clearTimeout(touchTimeoutRef.current)
    touchTimeoutRef.current = setTimeout(() => {
      handleMouseLeave(e)
    }, 2000)
  }

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => clearTimeout(touchTimeoutRef.current)
  }, [])

  const opacity = (isEditing && editingId === id) || !isEditing ? 1 : 0.5
  return (
    <Group>
      <Rect
        editorWidth={editorWidth}
        opacity={opacity}
        top={top}
        bottom={bottom}
        color={color}
        ref={rectRef}
      />
      <Group ref={labelsRef} y={top}>
        <NRSLabel editorWidth={editorWidth} NRS={NRS} y={0} dy={isHourLong ? 7 : 9} />
        <SettingLabel
          editorWidth={editorWidth}
          label={label}
          y={0}
          dy={isHourLong ? 7 : 11}
        />
        {!isHourLong && (
          <DurationLabel
            editorWidth={editorWidth}
            label={durationLabel}
            y={0}
            dy={26}
          />
        )}
      </Group>
      <HoverOverlayRect
        ref={overlayRef}
        editorWidth={editorWidth}
        top={top}
        bottom={bottom}
        visible={isFocused && !isDragging && !isEditing}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onEdit={onEdit}
        onDelete={onDelete}
        draggable={!isEditing}
        onDragStart={onDragStart}
        onDragEnd={(e) => onDragEnd(...rectToSetting(e.target.y(), bottom - top))}
        dragBoundFunc={(pos) =>
          verticalDragHandler(
            pos,
            start,
            end,
            rectRef.current,
            overlayRef.current,
            labelsRef.current,
            previous,
            next,
          )
        }
      />
      <ResizeHandle
        editorWidth={editorWidth}
        y={top}
        ref={topDragHandleRef}
        draggable={!isEditing}
        onDragStart={onResizeStart}
        onDragEnd={(newStart) => onResizeEnd(newStart, end)}
        dragBoundFunc={(pos, ref) =>
          verticalResizeHandler(
            pos,
            start,
            end,
            ref.current,
            bottomDragHandleRef.current,
            rectRef.current,
            labelsRef.current,
            'top',
            previous,
            next,
          )
        }
      />
      <ResizeHandle
        editorWidth={editorWidth}
        y={bottom - HANDLE_HEIGHT}
        ref={bottomDragHandleRef}
        draggable={!isEditing}
        onDragStart={onResizeStart}
        onDragEnd={(newEnd) => onResizeEnd(start, newEnd)}
        dragBoundFunc={(pos, ref) =>
          verticalResizeHandler(
            pos,
            start,
            end,
            ref.current,
            topDragHandleRef.current,
            rectRef.current,
            labelsRef.current,
            'bottom',
            previous,
            next,
          )
        }
      />
    </Group>
  )
}

ThresholdRect.defaultProps = {
  editingId: null,
  next: undefined,
  previous: undefined,
  onDelete: undefined,
  editorWidth: undefined,
}

ThresholdRect.propTypes = {
  id: PropTypes.number.isRequired,
  NRS: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  next: PropTypes.number,
  previous: PropTypes.number,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onResizeStart: PropTypes.func.isRequired,
  onResizeEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editorWidth: PropTypes.number,
}
