import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { equals, isEmpty, omit, pick } from 'ramda'

import { FilterList as FilterListIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material'

import { pluralize, singularize } from 'inflection'
import { DateTime } from 'luxon'

export default function Filter({
  open,
  dialogOpen,
  dialogOnClose,
  mode,
  children,
  apiParams,
  setApiParams,
  immutableFilters,
  disabled,
  ...rest
}) {
  const [drawerOpen, setDrawerOpen] = useState(open)
  const childComponents = Array.isArray(children) ? children : [children]
  const childComponentsFlat = childComponents.reduce(
    (acc, el) => (el?.props?.children ? [...acc, ...el.props.children] : [...acc, el]),
    [],
  )

  const isFilterTouched = Object.keys(apiParams).length > 2

  const uuidFiltersByEndpoint = {
    users: ['organization'],
    accounts: ['organization'],
    properties: ['organization', 'account', 'propertyGroup'],
    propertyGroups: ['organization', 'account'],
    units: ['organization', 'account', 'property', 'unitGroup'],
    unitGroups: ['account', 'organization', 'property'],
    zones: ['account', 'property', 'unit'],
    devices: ['account', 'property', 'unit'],
  }

  const getFilterName = (el) => el.props?.filterName
  const getEndpointName = (el) =>
    el.props?.endpoint ? el.props?.endpoint : pluralize(getFilterName(el))

  const processPayload = (payload) => {
    let processedPayload = payload

    const payloadIsArray = Array.isArray(payload)
    const payloadIsObj = typeof payload === 'object' && 'id' in payload
    const payloadIsDateTime =
      typeof payload === 'object' &&
      Object.values(payload).some((val) => DateTime.fromISO(val).isValid)

    if (payloadIsArray) processedPayload = payload.join(',')
    else if (payloadIsObj) processedPayload = payload?.id
    else if (payloadIsDateTime) processedPayload = payload?.[Object.keys(payload)[0]]

    return processedPayload
  }

  useEffect(() => {
    const params = {
      pageSize: apiParams.pageSize ?? 25,
      page: 1,
      active: true,
    }

    if (!equals(params, apiParams)) {
      setApiParams(params)
    }
  }, [])

  const getProps = (el) => {
    const isPicker = ['date', 'time', 'dateTime'].includes(el.props?.type)
    const isDynamicSelect = !!el.props?.primaryTextAttr
    const isStasticSelect = !!el.props?.options
    const elProps = { ...el.props, limitTags: 2 }

    if (isStasticSelect) {
      const filter = apiParams?.[getFilterName(el)]

      if (elProps?.multiple) {
        const multipleFilter = filter || []
        elProps.value = elProps.options?.filter((opt) =>
          multipleFilter?.includes(typeof opt === 'string' ? opt : opt?.id),
        )
      } else {
        elProps.value = elProps.options?.find((opt) =>
          typeof opt === 'string' ? opt === filter : opt?.id === filter,
        )
      }
    } else if (isDynamicSelect) {
      const endPointFilters = uuidFiltersByEndpoint[getEndpointName(el)]
      elProps.endpoint = getEndpointName(el).toLowerCase()
      elProps.value = apiParams?.[getFilterName(el)]
      if (endPointFilters) {
        const uuidFilters = pick(endPointFilters, apiParams)
        elProps.filters = { ...elProps.filters, ...uuidFilters }
      }
    } else if (isPicker) {
      elProps.value = pick(
        [
          getFilterName(el),
          `${getFilterName(el)}${el.props?.conditionSeparator}${
            el.props?.lowerCondition
          }`,
          `${getFilterName(el)}${el.props?.conditionSeparator}${
            el.props?.upperCondition
          }`,
        ],
        apiParams,
      )
    }
    return elProps
  }

  const resetDependantParams = (el) => {
    const keysToRemove = Object.entries(uuidFiltersByEndpoint).reduce(
      (acc, [endpoint, filters]) =>
        filters.includes(getFilterName(el)) ? [...acc, singularize(endpoint)] : acc,
      [],
    )
    return omit(keysToRemove, apiParams)
  }

  const handleChange = (el, payload) => {
    const params = { page: 1, ...resetDependantParams(el), ...immutableFilters }

    if (payload === null || isEmpty(payload) || payload === undefined) {
      setApiParams(omit([getFilterName(el)], params))
      return
    }

    const updatedPayload = () =>
      el.props.range ? payload : { [getFilterName(el)]: processPayload(payload) }

    setApiParams({
      ...params,
      ...updatedPayload(),
    })
  }

  const FilterToggle = (
    <IconButton
      p={0}
      color="primary"
      onClick={() => setDrawerOpen((prevState) => !prevState)}
    >
      <FilterListIcon />
    </IconButton>
  )

  const createElement = (el) => (
    <el.type
      key={el.props?.id || el.props?.filterName}
      {...getProps(el)}
      onChange={(value) => handleChange(el, value)}
      disabled={disabled}
      size="small"
    />
  )

  const clearFilters = () =>
    setApiParams({
      pageSize: apiParams.pageSize ?? 25,
      page: 1,
      ...immutableFilters,
    })

  if (mode === 'modal') {
    return (
      <Dialog fullWidth maxWidth="sm" open={dialogOpen} onClose={dialogOnClose}>
        <Box>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={1.5}>
              {childComponentsFlat
                .filter(Boolean)
                .map((element) => createElement(element))}
              {isFilterTouched && (
                <Box textAlign="right">
                  <Button size="small" disabled={disabled} onClick={clearFilters}>
                    Clear Filters
                  </Button>
                </Box>
              )}
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    )
  }

  return (
    <Box
      minWidth={drawerOpen ? '250px' : 0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      px={drawerOpen ? 1 : 0}
      {...rest}
    >
      {!drawerOpen && FilterToggle}
      <Drawer
        open={drawerOpen}
        variant="persistent"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          '& .MuiDrawer-paper': {
            position: 'absolute',
            width: '100%',
            border: 'none',
            gap: '2rem',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
          },
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="primary" textTransform="uppercase">
              Filters
            </Typography>
            {drawerOpen && FilterToggle}
          </Box>
          <Divider sx={{ marginTop: '0.5rem' }} />
        </Box>
        {childComponentsFlat.filter(Boolean).map((element) => createElement(element))}
        {isFilterTouched && (
          <Box textAlign="right" mt={-2}>
            <Button size="small" disabled={disabled} onClick={clearFilters}>
              Clear Filters
            </Button>
          </Box>
        )}
      </Drawer>
    </Box>
  )
}

Filter.defaultProps = {
  open: true,
  dialogOpen: false,
  dialogOnClose: () => {},
  mode: 'drawer',
  immutableFilters: {},
  disabled: false,
}

Filter.propTypes = {
  open: PropTypes.bool,
  dialogOpen: PropTypes.bool,
  dialogOnClose: PropTypes.func,
  mode: PropTypes.oneOf(['drawer', 'modal']),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  apiParams: PropTypes.shape().isRequired,
  setApiParams: PropTypes.func.isRequired,
  immutableFilters: PropTypes.shape(),
  disabled: PropTypes.bool,
}
