/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import HighlightedArea from '../components/HighlightedArea'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.width === next.width &&
    prev.height === next.height &&
    prev.offsetLeft === next.offsetLeft &&
    prev.offsetTop === next.offsetTop &&
    prev.minWidth === next.minWidth &&
    prev.x0 === next.x0 &&
    prev.x1 === next.x1 &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.tooltipData, next.tooltipData) &&
    equals(prev.style, next.style) &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoHighlightedArea = memo(
  ({
    id,
    width,
    height,
    offsetLeft,
    offsetTop,
    minWidth,
    x0,
    x1,
    tooltipData,
    style,
    graphWidth,
    padding,
  }) => (
    <HighlightedArea
      key={id}
      id={id}
      width={width}
      height={height}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
      minWidth={minWidth}
      x0={x0}
      x1={x1}
      tooltipData={tooltipData}
      style={style}
    />
  ),
  propsAreEqual,
)

MemoHighlightedArea.defaultProps = {
  offsetLeft: 0,
  offsetTop: 0,
  minWidth: undefined,
  style: null,
  tooltipData: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoHighlightedArea.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x0: PropTypes.number.isRequired,
  x1: PropTypes.number.isRequired,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  minWidth: PropTypes.number,
  tooltipData: PropTypes.shape({}),
  style: PropTypes.shape({
    dashedBackground: PropTypes.bool,
    handle: PropTypes.shape({
      width: PropTypes.number,
      dashArray: PropTypes.arrayOf(PropTypes.number),
      color: PropTypes.string,
      opacity: PropTypes.number,
    }),
    area: PropTypes.shape({
      color: PropTypes.string,
      opacity: PropTypes.number,
    }),
  }),
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoHighlightedArea
