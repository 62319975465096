import PropTypes from 'prop-types'

import { RectClipPath } from '@visx/clip-path'
import { Group } from '@visx/group'
import { LinePath } from '@visx/shape'

export default function HorizontalThresholdedLine({
  id,
  data,
  color,
  x,
  y,
  upperBound,
  height,
  width,
  curve,
  defined,
}) {
  const positiveHeight = Math.max(0, height)
  const positiveWidth = Math.max(0, width)
  const positiveNowX = Math.max(0, upperBound)

  return (
    <Group>
      <RectClipPath
        id={`after-${id}`}
        x={positiveNowX}
        height={positiveHeight}
        width={positiveWidth}
      />
      <RectClipPath id={`before-${id}`} height={positiveHeight} width={positiveNowX} />
      <LinePath
        key={`${id}_before`}
        data={data}
        defined={defined}
        x={x}
        y={y}
        curve={curve}
        stroke={color}
        strokeWidth={1}
        clipPath={`url(#before-${id})`}
      />
      <LinePath
        key={`${id}_after`}
        data={data}
        defined={defined}
        x={x}
        y={y}
        curve={curve}
        stroke={color}
        strokeWidth={1}
        strokeDasharray={[4, 4]}
        clipPath={`url(#after-${id})`}
      />
    </Group>
  )
}

HorizontalThresholdedLine.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color: PropTypes.string.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  curve: PropTypes.func.isRequired,
  upperBound: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  defined: PropTypes.func.isRequired,
}
