import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Group } from 'react-konva'

import { noop } from '@common/utils'

import { DeleteButton, EditButton } from './Buttons'
import {
  RECT_HORIZONTAL_ORIGIN,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
  VERTICAL_GRID_SPACE,
} from './constants'
import { BottomRect, getRectWidth, TopRect } from './Rects'

export const BottomHoverOverlayRect = forwardRef(
  (
    {
      editorWidth,
      top,
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onEdit,
      onDelete,
      visible,
      draggable,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        draggable={draggable}
        height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
        y={top}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        {...props}
      >
        <BottomRect
          editorWidth={editorWidth}
          color={color}
          top={0}
          height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
          dragDistance={VERTICAL_GRID_SPACE}
        />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={(TIMELINE_GRID_END - top) / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={(TIMELINE_GRID_END - top) / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)

BottomHoverOverlayRect.defaultProps = {
  editorWidth: undefined,
  onTouchStart: noop,
  onTouchEnd: noop,
}

BottomHoverOverlayRect.propTypes = {
  editorWidth: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  draggable: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export const TopHoverOverlayRect = forwardRef(
  (
    {
      editorWidth,
      bottom,
      visible,
      onEdit,
      onDelete,
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      draggable,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        draggable={draggable}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        dragDistance={VERTICAL_GRID_SPACE}
        height={bottom - TIMELINE_GRID_ORIGIN}
        y={0}
        {...props}
      >
        <TopRect editorWidth={editorWidth} color={color} bottom={bottom} />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={bottom / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={bottom / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)

TopHoverOverlayRect.defaultProps = {
  editorWidth: undefined,
  onTouchStart: noop,
  onTouchEnd: noop,
}

TopHoverOverlayRect.propTypes = {
  editorWidth: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  bottom: PropTypes.number.isRequired,
  draggable: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
