import { path } from 'ramda'

import { isAbortError, toCamelCase } from '@common/utils'
import { getMetricsRequestTimeframe } from '@common/utils/propertyDetailMetricsUtils'

import metrics from '../SmokeMetrics/metrics'

const PROPERTY_DETAILS_DATA_LOADING = 'PROPERTY_DETAILS_DATA_LOADING'
const PROPERTY_DETAILS_DATA_LOADED = 'PROPERTY_DETAILS_DATA_LOADED'
const PROPERTY_DETAILS_DATA_FAILED = 'PROPERTY_DETAILS_DATA_FAILED'

const PROPERTY_DETAILS_METRICS_LOADING = 'PROPERTY_DETAILS_METRICS_LOADING'
const PROPERTY_DETAILS_METRICS_LOADED = 'PROPERTY_DETAILS_METRICS_LOADED'
const PROPERTY_DETAILS_METRICS_FAILED = 'PROPERTY_DETAILS_METRICS_FAILED'

const PROPERTY_DETAILS_RESET_STATE = 'PROPERTY_DETAILS_RESET_STATE'

const defaultState = {}

const entityName = 'propertyDetails'

async function fetchMetricsSummary({ apiFetch, payload }) {
  const { property, interval } = payload

  const { startTime, endTime } = getMetricsRequestTimeframe(interval)

  const metricsToFetch = [
    metrics.eventsTotal,
    metrics.eventsCharged,
    metrics.chargeRate,
    metrics.netCharges,
    metrics.netChargesPerEvent,
  ].map((item) => item.apiValue)

  const result = metricsToFetch.reduce(
    (acc, metric) => ({ ...acc, [toCamelCase(metric)]: 'N/A' }),
    {},
  )

  const metricsResponse = await apiFetch(
    `/reports/chart/`,
    {
      properties: [property],
      start: startTime.toFormat('yyyy-MM-dd'),
      end: endTime.toFormat('yyyy-MM-dd'),
      interval: 1,
      intervalType: 'days',
      includeTotals: true,
      metrics: metricsToFetch,
    },
    { method: 'GET', cancelationPrefix: entityName },
  )

  if (Array.isArray(metricsResponse)) {
    metricsResponse.forEach((metricData) => {
      const formattedMetricName = toCamelCase(metricData.metric)
      result[formattedMetricName] = metricData?.total || 'N/A'
    })
  }

  return result
}

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === PROPERTY_DETAILS_RESET_STATE) {
      return defaultState
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_LOADING) {
      return { ...state, metrics: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_LOADED) {
      return { ...state, metrics: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_FAILED) {
      return { ...state, metrics: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectPropertyDetailsIsLoading: ({ propertyDetails }) => {
    const status = path(['data', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetailsMetricsIsLoading: ({ propertyDetails }) => {
    const status = path(['metrics', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetails: ({ propertyDetails }) =>
    path(['data', 'data'], propertyDetails),
  selectPropertyDetailsMetrics: ({ propertyDetails }) =>
    path(['metrics', 'data'], propertyDetails),
  selectPropertyDetailsError: ({ propertyDetails }) =>
    path(['data', 'error'], propertyDetails),
  selectPropertyDetailsMetricsError: ({ propertyDetails }) =>
    path(['metrics', 'error'], propertyDetails),
  doResetPropertyDetailsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: PROPERTY_DETAILS_RESET_STATE }),
  doFetchPropertyMetrics:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_METRICS_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const metricsResult = await fetchMetricsSummary({ apiFetch, payload })

        await dispatch({
          type: PROPERTY_DETAILS_METRICS_LOADED,
          payload: metricsResult,
          meta: { status: 'succeeded' },
        })
        return metricsResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_METRICS_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchPropertyDetails:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const apiResults = await Promise.all([
          apiFetch(`/properties/${payload}`, null, { cancelationPrefix: entityName }),
          apiFetch(`/properties/${payload}/card_summary/`, null, {
            cancelationPrefix: entityName,
          }),
        ])
        const propertyResult = apiResults[0]
        const summaryResult = apiResults[1]

        const data = {
          property: propertyResult,
          summary: summaryResult,
        }

        await dispatch({
          type: PROPERTY_DETAILS_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
