import { useCallback, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { MoreVertRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'

import { Project } from '@common/config'

import MobilePaginationActions from './MobilePaginationActions'
import SearchBox from './SearchBox'

export const getValueFromColumnDef = ({ row, column }) => {
  const value = row[column.field]
  const processedValue = column.valueGetter ? column.valueGetter(value, row) : value
  const formattedValue = column.valueFormatter
    ? column.valueFormatter(processedValue)
    : processedValue

  if (column.renderMobile || column.renderCell) {
    const renderFn = column.renderMobile ?? column.renderCell
    return renderFn({
      row,
      field: column.field,
      headerName: column.headerName,
      formattedValue: column.valueFormatter
        ? column.valueFormatter(processedValue)
        : null,
    })
  }
  if (column.type === 'boolean') {
    return value ? 'Yes' : 'No'
  }

  return formattedValue
}

export default function MobileList({
  title,
  itemBuilder,
  checkbox,
  actions,
  actionsLabels,
  showActions,
  otherActions,
  listActions,
  page,
  pageChange,
  pageSize,
  pageSizeChange,
  pageSizeOptions,
  rows,
  rowCount,
  rowClick,
  setSearch,
  loading,
  queryDrivenSearch,
  actionsPermission,
  onFilterPressed,
  ...rest
}) {
  const { isRest } = Project

  const { doUpdateQuery, queryObject, isAtLeastAdmin } = useConnect(
    'doUpdateQuery',
    'selectQueryObject',
    ...(isRest ? [] : ['selectIsAtLeastAdmin']),
  )

  const [internalSelectedIds, setInternalSelectedIds] = useState([])
  const [actionsAnchor, setActionsAnchor] = useState(null)
  const [menuRowId, setMenuRowId] = useState(null)

  const [menuAnchor, setMenuAnchor] = useState(null)

  const selectedIds = rest?.rowSelectionModel || internalSelectedIds
  const setSelectedIds = rest?.onRowSelectionModelChange || setInternalSelectedIds

  const requiredPermission = actionsPermission?.default || (isAtLeastAdmin ?? false)
  const shouldRenderRowActions = showActions && requiredPermission

  const handleUpdateAction = (row) => actions?.update && actions.update(row)
  const handleDeleteAction = (row) => actions?.delete && actions.delete(row)

  const queryDrivenSearchHandler = (search) => {
    const queryParams = { ...queryObject, page: 1, search }
    if (!queryParams.search) {
      delete queryParams.search
    }
    doUpdateQuery(queryParams)
  }

  const handleActionsClose = useCallback(() => {
    setActionsAnchor(null)
    setMenuRowId(null)
  }, [])

  const getRowActions = useCallback(
    (row) => [
      ...(rowClick
        ? [
            <MenuItem
              key={row.id}
              onClick={() => {
                handleActionsClose()
                rowClick(row)
              }}
            >
              <InfoIcon sx={{ mr: 1 }} />
              {actionsLabels?.view || 'View'}
            </MenuItem>,
          ]
        : []),
      ...(actions?.update
        ? [
            <MenuItem
              key={row.id}
              onClick={() => {
                handleActionsClose()
                handleUpdateAction(row)
              }}
            >
              <EditIcon sx={{ mr: 1 }} />
              {actionsLabels?.update || 'Edit'}
            </MenuItem>,
          ]
        : []),
      ...(actions?.delete
        ? [
            <MenuItem
              key={row.id}
              onClick={() => {
                handleActionsClose()
                handleDeleteAction(row.id)
              }}
            >
              <DeleteIcon sx={{ mr: 1 }} />
              {actionsLabels?.delete || 'Delete'}
            </MenuItem>,
          ]
        : []),
      ...(otherActions.map(({ label, icon, disabled, onClick }) => (
        <MenuItem
          key={label}
          onClick={() => {
            handleActionsClose()
            onClick(row)
          }}
          disabled={disabled ? disabled(row) : false}
        >
          <Box display="flex" sx={{ mr: 1 }}>
            {icon}
          </Box>
          {label}
        </MenuItem>
      )) || []),
    ],
    [rowClick, actions, otherActions],
  )

  const hasActionsMenu = useMemo(
    () =>
      shouldRenderRowActions &&
      (rowClick || actions?.update || actions?.delete || otherActions.length),
    [rowClick, actions, otherActions, shouldRenderRowActions],
  )

  const handleMenuClose = useCallback(() => setMenuAnchor(null), [])

  const NoDataComponent = useCallback(() => {
    const ExternalComponent = rest?.components?.NoRowsOverlay
    return ExternalComponent ? (
      <ExternalComponent />
    ) : (
      <Box display="flex" justifyContent="center" m={2}>
        <Typography variant="body2">No Data</Typography>
      </Box>
    )
  }, [rest])

  return (
    <>
      <Menu
        id="actions_menu"
        anchorEl={actionsAnchor}
        open={!!actionsAnchor}
        onClose={handleActionsClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {menuRowId ? getRowActions(menuRowId) : null}
      </Menu>

      {listActions?.length ? (
        <Menu
          id="list_menu"
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {listActions.map((item) => {
            if (item.render) {
              return item.render({
                label: item.label,
                onClick: item.onClick,
                onClose: handleMenuClose,
              })
            }
            return (
              <MenuItem
                key={item.label}
                onClick={(e) => {
                  item.onClick(e)
                  handleMenuClose()
                }}
              >
                {item.label}
              </MenuItem>
            )
          })}
        </Menu>
      ) : null}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        {setSearch || queryDrivenSearch ? (
          <SearchBox
            title={title}
            onSetSearch={queryDrivenSearch ? queryDrivenSearchHandler : setSearch}
          />
        ) : (
          <Box />
        )}
      </Box>
      {onFilterPressed || listActions?.length ? (
        <Box display="flex" gap={1} mt={1}>
          {onFilterPressed && (
            <Button fullWidth variant="outlined" size="small" onClick={onFilterPressed}>
              Filter
            </Button>
          )}
          {listActions?.length ? (
            <Button
              fullWidth
              variant="outlined"
              size="small"
              onClick={(event) => setMenuAnchor(event.target)}
            >
              Actions
            </Button>
          ) : null}
        </Box>
      ) : null}
      <Box
        display="flex"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mt: 1,
        }}
      >
        {loading && <CircularProgress sx={{ position: 'absolute' }} />}
        <Box
          sx={{
            width: '100%',
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: loading ? 0.35 : 1,
            pointerEvents: loading ? 'none' : 'initial',
          }}
        >
          <Box>
            <List>
              {rows.length === 0 && !loading && <NoDataComponent />}
              {rows.map((row, index) => {
                let key = row.id
                if (rest?.getRowId) {
                  key = rest.getRowId(row)
                }

                return (
                  <ListItem
                    key={key ?? Math.random()}
                    sx={{ px: 0.1, pb: 0, pt: index !== 0 ? 1.5 : 0 }}
                  >
                    <Card sx={{ p: 1, width: '100%' }}>
                      <Box display="flex" alignItems="center">
                        {(actions?.bulk || checkbox) && (
                          <Checkbox
                            size="small"
                            checked={selectedIds?.includes(row.id)}
                            onChange={(event) =>
                              setSelectedIds((prev) => {
                                const newData = [...prev]
                                const isChecked = event.target.checked
                                if (isChecked && !newData.includes(row.id)) {
                                  newData.push(row.id)
                                } else if (!isChecked) {
                                  const indexToRemove = newData.indexOf(row.id)
                                  if (indexToRemove !== -1) {
                                    newData.splice(indexToRemove, 1)
                                  }
                                }
                                return newData
                              })
                            }
                            disabled={!(row?.mutable ?? true)}
                            sx={{ pl: 0.5 }}
                          />
                        )}
                        <Box
                          display="flex"
                          flexDirection="column"
                          sx={{ width: '100%' }}
                        >
                          {itemBuilder(row)}
                        </Box>
                        {hasActionsMenu ? (
                          <IconButton
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={(event) => {
                              setActionsAnchor(event.currentTarget)
                              setMenuRowId(row)
                            }}
                          >
                            <MoreVertRounded sx={{ fontSize: 16 }} />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Card>
                  </ListItem>
                )
              })}
            </List>
            {!rest.hideFooter && (
              <TablePagination
                component="div"
                count={rowCount}
                page={page - 1}
                labelRowsPerPage="Page Size:"
                rowsPerPageOptions={pageSizeOptions}
                onPageChange={(_, newPage) => pageChange(newPage + 1)}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(event) =>
                  pageSizeChange(event.target?.value ?? 25)
                }
                ActionsComponent={MobilePaginationActions}
                sx={{
                  '.MuiTablePagination-selectLabel': { fontSize: 12 },
                  '.MuiTablePagination-displayedRows': { fontSize: 12 },
                  '.MuiTablePagination-input': {
                    marginRight: 1,
                    marginLeft: 0,
                    fontSize: 12,
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

MobileList.defaultProps = {
  checkbox: false,
  actions: {},
  actionsLabels: undefined,
  actionsPermission: null,
  showActions: true,
  pageSize: 25,
  pageChange: () => {},
  pageSizeChange: () => {},
  otherActions: [],
  listActions: [],
  rowClick: null,
  onFilterPressed: undefined,
  setSearch: null,
  queryDrivenSearch: false,
  pageSizeOptions: [10, 25, 50, 100],
}

MobileList.propTypes = {
  title: PropTypes.string.isRequired,
  checkbox: PropTypes.bool,
  itemBuilder: PropTypes.func.isRequired,
  otherActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  listActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  actions: PropTypes.shape({
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    bulk: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  actionsLabels: PropTypes.shape({
    view: PropTypes.string,
    update: PropTypes.string,
    delete: PropTypes.string,
  }),
  actionsPermission: PropTypes.shape({
    default: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
    bulk: PropTypes.bool,
  }),
  showActions: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeChange: PropTypes.func,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowCount: PropTypes.number.isRequired,
  rowClick: PropTypes.func,
  setSearch: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  queryDrivenSearch: PropTypes.bool,
  onFilterPressed: PropTypes.func,
}
