import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import ChartMinimap from '../components/ChartMinimap'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.show === next.show &&
    prev.height === next.height &&
    prev.data === next.data &&
    prev.onChange?.toString() === next.onChange?.toString() &&
    prev.onMove?.toString() === next.onMove?.toString() &&
    equals(prev.selectedArea, next.selectedArea)
  )
}

const MemoChartMinimap = memo(
  ({ id, show, height, data, selectedArea, onChange, onMove }) => (
    <ChartMinimap
      key={id}
      height={height}
      show={show}
      data={data}
      selectedArea={selectedArea}
      onChange={onChange}
      onMove={onMove}
    />
  ),
  propsAreEqual,
)

MemoChartMinimap.defaultProps = {
  height: 70,
  show: true,
  selectedArea: null,
  onChange: undefined,
  onMove: undefined,
}

MemoChartMinimap.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
  show: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({ time: PropTypes.shape({}) })).isRequired,
  selectedArea: PropTypes.shape({
    start: PropTypes.shape({}).isRequired,
    end: PropTypes.shape({}).isRequired,
  }),
  onChange: PropTypes.func,
  onMove: PropTypes.func,
}

export default MemoChartMinimap
