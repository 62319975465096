import PropTypes from 'prop-types'

import { Box, Tooltip } from '@mui/material'

import { capitalize } from 'inflection'

import { NoiseAware, SalesForce, Stripe } from '@common/icons'

export const getIntegrationIcon = (integration) => {
  const icons = {
    SalesForce: <SalesForce />,
    Stripe: <Stripe />,
    v1: <NoiseAware />,
  }
  return (
    <Tooltip title={capitalize(integration)} placement="top">
      <Box> {icons[integration]} </Box>
    </Tooltip>
  )
}

export default function IntegrationCell({ row }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      width="100%"
      height="100%"
    >
      {row?.v1 && getIntegrationIcon('v1')}
      {row?.stripe && getIntegrationIcon('Stripe')}
      {row?.salesForce && getIntegrationIcon('SalesForce')}
    </Box>
  )
}

IntegrationCell.propTypes = {
  row: PropTypes.shape({
    v1: PropTypes.bool,
    stripe: PropTypes.bool,
    salesForce: PropTypes.bool,
  }).isRequired,
}
