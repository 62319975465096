import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

import { ListTextTooltipWrapper } from '@common/components'

export default function MainCell({ label, onClick }) {
  return (
    <ListTextTooltipWrapper tooltip={label}>
      <Typography
        noWrap
        onClick={(e) => {
          e.stopPropagation()
          onClick(e)
        }}
        sx={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
      >
        {label || 'N/A'}
      </Typography>
    </ListTextTooltipWrapper>
  )
}

MainCell.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
