import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

export default function MobileDetailRow({ label, value, renderValue, bold, sx }) {
  return (
    <Box display="flex" justifyContent="space-between" sx={sx}>
      <Typography fontFamily="Degular" fontSize={16} fontWeight={bold ? 600 : 500}>
        {label}
      </Typography>
      {renderValue ? (
        renderValue(value)
      ) : (
        <Typography fontSize={14}>{value}</Typography>
      )}
    </Box>
  )
}

MobileDetailRow.defaultProps = {
  value: undefined,
  renderValue: undefined,
  bold: false,
  sx: {},
}

MobileDetailRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderValue: PropTypes.func,
  bold: PropTypes.bool,
  sx: PropTypes.shape({}),
}
