/* eslint-disable no-unused-vars */
import { memo } from 'react'

import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { AxisRight } from '@visx/axis'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.scale === next.scale &&
    prev.hideAxisLine === next.hideAxisLine &&
    prev.hideTicks === next.hideTicks &&
    prev.numTicks === next.numTicks &&
    prev.tickValues === next.tickValues &&
    prev.tickFormat?.toString() === next.tickFormat?.toString() &&
    prev.left === next.left &&
    prev.orientation === next.orientation &&
    prev.label === next.label &&
    prev.labelOffset === next.labelOffset &&
    prev.stroke === next.stroke &&
    prev.tickStroke === next.tickStroke &&
    prev.tickLabelProps?.toString() === next.tickLabelProps?.toString() &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.labelProps, next.labelProps) &&
    equals(prev.padding, next.padding)
  )
}

// 'graphWidth' and 'padding' not used directly in the component
// but only in `propsAreEqual` function to properly rerender component
const MemoAxisRight = memo(
  // eslint-disable-next-line no-unused-vars
  ({
    id,
    scale,
    hideAxisLine,
    hideTicks,
    numTicks,
    tickValues,
    tickFormat,
    left,
    orientation,
    label,
    labelProps,
    labelOffset,
    stroke,
    tickStroke,
    tickLabelProps,
    graphWidth,
    padding,
  }) => (
    <AxisRight
      key={id}
      orientation={orientation}
      hideAxisLine={hideAxisLine}
      hideTicks={hideTicks}
      numTicks={numTicks}
      tickValues={tickValues}
      tickFormat={tickFormat}
      left={left}
      scale={scale}
      label={label}
      labelProps={labelProps}
      labelOffset={labelOffset}
      stroke={stroke}
      tickStroke={tickStroke}
      tickLabelProps={tickLabelProps}
      pointerEvents="none"
    />
  ),
  propsAreEqual,
)

MemoAxisRight.defaultProps = {
  hideAxisLine: false,
  hideTicks: false,
  numTicks: undefined,
  tickValues: undefined,
  tickFormat: undefined,
  labelProps: undefined,
  labelOffset: undefined,
  stroke: undefined,
  tickStroke: undefined,
  tickLabelProps: undefined,
  graphWidth: undefined,
  padding: undefined,
}

MemoAxisRight.propTypes = {
  id: PropTypes.string.isRequired,
  scale: PropTypes.func.isRequired,
  hideAxisLine: PropTypes.bool,
  hideTicks: PropTypes.bool,
  numTicks: PropTypes.number,
  tickValues: PropTypes.arrayOf(PropTypes.number),
  tickFormat: PropTypes.func,
  left: PropTypes.number.isRequired,
  orientation: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.shape({}),
  labelOffset: PropTypes.number,
  stroke: PropTypes.string,
  tickStroke: PropTypes.string,
  tickLabelProps: PropTypes.func,
  graphWidth: PropTypes.number,
  padding: PropTypes.shape({}),
}

export default MemoAxisRight
