import { memo } from 'react'

import PropTypes from 'prop-types'

import { Grid2 } from '@mui/material'

import MetricCard from '../MetricCard'
import metrics from '../metrics'

const getPositionByApiValue = (value) =>
  Object.values(metrics).find((obj) => obj.apiValue === value)?.position ?? 99

const GroupedByMetricView = memo(
  ({
    smokeMetrics,
    selectedMetrics,
    selectedView,
    hideNonLiveProperties,
    selectedInterval,
    dateRangeValue,
    showTotal,
  }) =>
    Object.keys(smokeMetrics)
      .sort((a, b) => {
        const aPosition = getPositionByApiValue(a)
        const bPosition = getPositionByApiValue(b)
        return aPosition > bPosition ? 1 : -1
      })
      .filter((key) => selectedMetrics[key])
      .map((key) => {
        const data = smokeMetrics[key]
        const size = selectedView === 'graph' ? { xll: 4, sm: 6 } : { xll: 6, sm: 12 }
        return (
          <Grid2 key={key} size={size}>
            <MetricCard
              metric={key}
              selectedView={selectedView}
              data={
                hideNonLiveProperties && data.length && 'live' in data[0]
                  ? data.filter((obj) => obj.live)
                  : data
              }
              dataInterval={selectedInterval}
              requestedDateRange={dateRangeValue}
              showTotal={showTotal}
            />
          </Grid2>
        )
      }),
)

GroupedByMetricView.propTypes = {
  smokeMetrics: PropTypes.shape({}).isRequired,
  selectedMetrics: PropTypes.shape({}).isRequired,
  selectedView: PropTypes.string.isRequired,
  hideNonLiveProperties: PropTypes.bool.isRequired,
  selectedInterval: PropTypes.shape({
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  dateRangeValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTotal: PropTypes.bool.isRequired,
}

export default GroupedByMetricView
