import { useCallback, useEffect, useState } from 'react'

import { LocationOnRounded } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid2,
  TextField,
  Typography,
} from '@mui/material'

import parse from 'autosuggest-highlight/parse'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import { formatErrors } from '@common/components/Form/utils'
import { config } from '@common/config'

function GoogleAutocomplete(formikProps) {
  const { field, form, onSelected, error, variant, label, sx, ...rest } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const { touched } = form.getFieldMeta(field.name)
  const showError = form.dirty && touched && hasError

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: config.GOOGLE_MAPS_KEY || 'dev_placeholder',
    debounce: 500,
    sessionToken: true,
    options: { types: ['address'] },
  })

  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(placePredictions?.length ? placePredictions : [])
  }, [placePredictions])

  const getTimeZone = async (lat, lng) => {
    const timestamp = Math.floor(Date.now() / 1000)
    const apiKey = config.GOOGLE_MAPS_KEY

    const location = `${lat},${lng}`
    const params = { key: apiKey, location, timestamp }
    const locationParams = new URLSearchParams(params)
    const baseTimezoneUrl = 'https://maps.googleapis.com/maps/api/timezone/json'
    const urlWithParams = `${baseTimezoneUrl}?${locationParams.toString()}`

    let tz
    const response = await fetch(urlWithParams)
    if (response.ok) {
      const resJson = await response.json()
      tz = resJson?.timeZoneId
    }
    return tz
  }

  const onPlaceSelected = useCallback(
    async (place) => {
      let streetNumber
      let route
      let country
      let state
      let city
      let postalCode
      let postalCodeSuffix

      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.short_name
        }
        if (component.types.includes('route')) {
          route = component.long_name
        }
        if (component.types.includes('country')) {
          country = component.short_name
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name
        }

        const cityTypes = [
          'locality',
          'sublocality',
          'administrative_area_level_3',
          'postal_town',
        ]
        const cityType = cityTypes.find((type) => component.types.includes(type))
        if (cityType) {
          city = component.long_name
        }

        if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
        if (component.types.includes('postal_code_suffix')) {
          postalCodeSuffix = component.long_name
        }
      })

      const address = streetNumber && route ? `${streetNumber} ${route}` : route
      const zipCode =
        postalCodeSuffix && postalCode
          ? `${postalCode}-${postalCodeSuffix}`
          : postalCode
      const placeRequest = { placeId: place.place_id, fields: ['geometry'] }

      placesService?.getDetails(placeRequest, (placeResponse, status) => {
        if (status !== 'OK') return
        const lat = placeResponse.geometry.location.lat()
        const lng = placeResponse.geometry.location.lng()
        getTimeZone(lat, lng).then((tz) => form.setFieldValue('timezone', tz))
      })

      const data = { address, country, state, city, zipCode }

      form.setFieldValue(field?.name, address)
      form.setFieldValue('googlePlaceId', place.place_id)
      onSelected(data, form)
    },
    [onSelected],
  )

  const buildHelperText = () => {
    if (showError) {
      return hasError ? errorText : error
    }
    return rest?.helperText
  }

  return (
    <Autocomplete
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      id="google_places_autocomplete"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.structured_formatting.main_text
      }
      filterOptions={(x) => x}
      options={options}
      value={form?.getFieldProps(field?.name)?.value}
      noOptionsText="No locations"
      sx={{ minWidth: '150px', ...sx }}
      onChange={(_, selectedPlace) => {
        if (selectedPlace && placesService) {
          placesService.getDetails(
            { placeId: selectedPlace.place_id },
            (placeDetails) => onPlaceSelected(placeDetails),
          )
        }
      }}
      onInputChange={(_, input) => getPlacePredictions({ input })}
      renderInput={(params) => (
        <TextField
          {...params}
          required={rest.required}
          label={label}
          error={showError}
          variant={variant}
          helperText={buildHelperText()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isPlacePredictionsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props} key={option.description}>
            <Grid2 container alignItems="center">
              <Grid2 sx={{ display: 'flex', width: 44 }}>
                <LocationOnRounded sx={{ color: 'text.secondary' }} />
              </Grid2>
              <Grid2 sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part) => (
                  <Box
                    key={Math.random()}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid2>
            </Grid2>
          </li>
        )
      }}
    />
  )
}

export default GoogleAutocomplete
