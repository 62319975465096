import PropTypes from 'prop-types'

import { Text } from 'react-konva'

import { RECT_HORIZONTAL_ORIGIN } from './constants'
import { getRectWidth } from './Rects'

export function DurationLabel({ editorWidth, label, y, dy }) {
  return (
    <Text
      text={label}
      x={RECT_HORIZONTAL_ORIGIN + 17}
      y={y + dy}
      fontFamily="Degular"
      fontSize={editorWidth < 300 ? 18 : 20}
      fill="white"
    />
  )
}

DurationLabel.defaultProps = {
  editorWidth: undefined,
}

DurationLabel.propTypes = {
  editorWidth: PropTypes.number,
  label: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired,
  dy: PropTypes.number.isRequired,
}

export function NRSLabel({ editorWidth, NRS, y, dy }) {
  return (
    <Text
      text={`${NRS} NRS`}
      x={RECT_HORIZONTAL_ORIGIN + getRectWidth(editorWidth) - 60 - 18}
      y={y + dy}
      fontFamily="Inter"
      width={60}
      align="right"
      fontSize={editorWidth < 300 ? 12 : 14}
      fill="white"
    />
  )
}

NRSLabel.defaultProps = {
  editorWidth: undefined,
}

NRSLabel.propTypes = {
  editorWidth: PropTypes.number,
  NRS: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  dy: PropTypes.number.isRequired,
}

export function SettingLabel({ editorWidth, label, y, dy }) {
  return (
    <Text
      text={label.toUpperCase()}
      x={RECT_HORIZONTAL_ORIGIN + 17}
      y={y + dy}
      fontFamily="Inter"
      fontSize={editorWidth ? 12 : 14}
      fill="white"
    />
  )
}

SettingLabel.defaultProps = {
  editorWidth: undefined,
}

SettingLabel.propTypes = {
  editorWidth: PropTypes.number,
  label: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired,
  dy: PropTypes.number.isRequired,
}
