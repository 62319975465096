import { memo } from 'react'

import PropTypes from 'prop-types'

import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { pluralize, titleize } from 'inflection'

import { ToggleButtonWithTooltip } from '@common/components'

const DateRangeSelector = memo(
  ({
    aggregatedDateRanges,
    selectedAggregationRange,
    selectedInterval,
    visibleDatePresetKeys,
    datePreset,
    datePresets,
    dateRangeValue,
    handlePresetChange,
    handleAggregatedDateRangeChange,
    disabled,
  }) => (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Date Range</Typography>
      {!aggregatedDateRanges && (
        <ToggleButtonGroup color="primary" variant="outlined" disabled={disabled}>
          {visibleDatePresetKeys.map((key) => {
            const customTooltipFormat = 'yyyy-MM-dd'
            const { value } = datePresets[key]
            const tooltip = (() => {
              const showCustomDateTooltip =
                value === datePresets.custom.value &&
                datePreset === datePresets.custom.value
              if (showCustomDateTooltip) {
                const start = dateRangeValue[0]?.toFormat(customTooltipFormat)
                const end = dateRangeValue[1]?.toFormat(customTooltipFormat)
                return `${start} - ${end}`
              }
              return datePresets[key]?.tooltip
            })()
            return (
              <ToggleButtonWithTooltip
                key={value}
                tooltip={tooltip}
                value={value}
                selected={datePreset === value}
                onClick={(_, selectedValue) => handlePresetChange(selectedValue)}
                sx={{ py: 0.8 }}
              >
                {value.toUpperCase()}
              </ToggleButtonWithTooltip>
            )
          })}
        </ToggleButtonGroup>
      )}
      {aggregatedDateRanges && (
        <ToggleButtonGroup color="primary" variant="outlined" disabled={disabled}>
          {Object.keys(aggregatedDateRanges).map((key) => {
            const { value, label } = aggregatedDateRanges[key]
            const isCustomValue = !Object.values(aggregatedDateRanges)
              .map((i) => i.value)
              .includes(selectedAggregationRange)
            const tooltip = (() => {
              if (isCustomValue && value === 'custom') {
                const entity = selectedInterval.value === 7 ? 'week' : 'month'
                return `${selectedAggregationRange} ${titleize(pluralize(entity))}`
              }
              return aggregatedDateRanges[key]?.tooltip
            })()
            return (
              <ToggleButtonWithTooltip
                key={value}
                tooltip={tooltip}
                value={value}
                selected={
                  selectedAggregationRange === value ||
                  (isCustomValue && value === 'custom')
                }
                onClick={(_, selectedValue) =>
                  handleAggregatedDateRangeChange(selectedValue)
                }
                sx={{ py: 0.8 }}
              >
                {(label ?? value.toString()).toUpperCase()}
              </ToggleButtonWithTooltip>
            )
          })}
        </ToggleButtonGroup>
      )}
    </Stack>
  ),
)

DateRangeSelector.defaultProps = {
  aggregatedDateRanges: undefined,
}

DateRangeSelector.propTypes = {
  aggregatedDateRanges: PropTypes.shape({}),
  selectedAggregationRange: PropTypes.number.isRequired,
  selectedInterval: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  visibleDatePresetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  datePreset: PropTypes.string.isRequired,
  datePresets: PropTypes.shape({
    custom: PropTypes.shape({ value: PropTypes.string }),
  }).isRequired,
  dateRangeValue: PropTypes.arrayOf(
    PropTypes.shape({
      toFormat: PropTypes.func.isRequired,
    }),
  ).isRequired,
  handlePresetChange: PropTypes.func.isRequired,
  handleAggregatedDateRangeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default DateRangeSelector
