import { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { SsidChartRounded } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { parseApiErrors } from '@common/utils'

import EmbeddedProfileEditor from './EmbeddedProfileEditor'
import AddThreshold from './Wizard/AddThreshold'

export default function PropertyThresholds({ property }) {
  const [loading, setLoading] = useState(true)
  const [profileData, setProfileData] = useState(null)
  const [openThresholdWizard, setOpenThresholdWizard] = useState(false)

  const { doThresholdProfileFetch, doPropertyFetch, doShowSnackbar } = useConnect(
    'doThresholdProfileFetch',
    'doPropertyFetch',
    'doShowSnackbar',
  )

  const fetchTabData = useCallback(async () => {
    try {
      setLoading(true)
      const freshPropertyData = await doPropertyFetch(property.id)
      if (freshPropertyData?.effectiveNoiseThresholdProfile) {
        const data = await doThresholdProfileFetch(
          freshPropertyData.effectiveNoiseThresholdProfile,
        )
        setProfileData(data)
      }
      if (freshPropertyData) {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)

      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [property])

  useEffect(() => {
    fetchTabData()
  }, [property])

  return (
    <>
      <AddThreshold
        open={openThresholdWizard}
        property={property}
        onClose={(success) => {
          setOpenThresholdWizard(false)
          if (success === true) {
            fetchTabData()
          }
        }}
      />
      {loading && (
        <Box display="flex" justifyContent="center" justifyItems="center" m={3}>
          <CircularProgress />
        </Box>
      )}
      {!profileData && !loading && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={3}
          mb={1}
        >
          <SsidChartRounded sx={{ fontSize: 60, mb: 1 }} />
          <Typography variant="h6" mb={1.5}>
            Define thresholds to define the noise level for which noise alerts will be
            generated for this property
          </Typography>
          <Button variant="contained" onClick={() => setOpenThresholdWizard(true)}>
            Create
          </Button>
        </Box>
      )}
      {profileData && !loading && (
        <EmbeddedProfileEditor profileData={profileData} onSuccess={setProfileData} />
      )}
    </>
  )
}

PropertyThresholds.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    effectiveNoiseThresholdProfile: PropTypes.string,
  }).isRequired,
}
