import { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Field } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { merge, parseApiErrors } from '@common/utils'
import { FormDialog } from '@rest/UI/components'

const validationSchema = Yup.object().shape({
  comments: Yup.string().min(1).required(),
})

export default function EventComment({
  eventId,
  propertyId,
  currentFeedback,
  isSmokeEvent,
  onClose,
}) {
  const { doEventFeedbackSave, doNoiseEventFeedbackSave, doShowSnackbar } = useConnect(
    'doEventFeedbackSave',
    'doNoiseEventFeedbackSave',
    'doShowSnackbar',
  )

  const onFeedbackSave = useCallback(
    async (data) => {
      const payload = {
        ...data,
        event: eventId,
        property: propertyId,
        ...(currentFeedback ? { id: currentFeedback.id } : {}),
      }

      const saveFn = isSmokeEvent ? doEventFeedbackSave : doNoiseEventFeedbackSave
      try {
        const result = await saveFn(payload)
        if (result.error) throw result.error
        return result
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
      return null
    },
    [currentFeedback],
  )
  const successMessage = currentFeedback
    ? 'Comment has been changed'
    : 'Comment has been added'

  const initialValues = merge({ comments: '' }, currentFeedback)

  return (
    <FormDialog
      open
      onClose={onClose}
      initialValues={initialValues}
      label="Event Comment"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      successMessage={successMessage}
    >
      <Field
        component={TextField}
        name="comments"
        label="Comments"
        InputProps={{ multiline: true }}
        sx={{ width: '100%' }}
      />
    </FormDialog>
  )
}

EventComment.defaultProps = {
  currentFeedback: undefined,
}

EventComment.propTypes = {
  eventId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  isSmokeEvent: PropTypes.string.isRequired,
  currentFeedback: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comments: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
}
