import PropTypes from 'prop-types'

import { AirRounded } from '@mui/icons-material'
import { Chip, Stack, Tooltip, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

const aqiColors = {
  good: '#a2f061',
  moderate: '#f2e24b',
  unhealthySensitive: '#feb14e',
  unhealthy: '#ff6473',
  veryUnhealthy: '#b36fc1',
  hazardous: '#925273',
}

const aqiToDetailData = (index) => {
  let color = '#9E9E9E'
  let description = 'Unknown'

  if (typeof index !== 'number') {
    return { color, description }
  }

  if (index >= 0 && index <= 50) {
    color = aqiColors.good
    description = 'Good'
  } else if (index >= 51 && index <= 100) {
    color = aqiColors.moderate
    description = 'Moderate'
  } else if (index >= 101 && index <= 150) {
    color = aqiColors.unhealthySensitive
    description = 'Unhealthy for sensitive groups'
  } else if (index >= 151 && index <= 200) {
    color = aqiColors.unhealthy
    description = 'Unhealthy'
  } else if (index >= 201 && index <= 300) {
    color = aqiColors.veryUnhealthy
    description = 'Very Unhealthy'
  } else if (index >= 301) {
    color = aqiColors.hazardous
    description = 'Hazardous'
  } else {
    color = '#9E9E9E'
    description = 'Unknown'
  }
  return { color, description }
}

function AqiDetailItem({ index }) {
  const { color, description } = aqiToDetailData(index)
  const isSmallScreen = useSmallScreen()

  return (
    <Tooltip title={description}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          variant="body1"
          color="black"
          fontSize={isSmallScreen ? 14 : 16}
          whiteSpace="pre-line"
        >
          {index ?? '--'}
        </Typography>
        <AirRounded sx={{ fontSize: 20, color }} />
      </Stack>
    </Tooltip>
  )
}

function AqiChip({ index }) {
  const { color, description } = aqiToDetailData(index)
  const textColor =
    typeof index === 'number' && index >= 0 && index <= 150 ? '#323336' : 'white'

  return (
    <Tooltip title={description}>
      <Chip
        size="small"
        label={index ?? '--'}
        icon={<AirRounded style={{ color: textColor }} />}
        sx={{
          backgroundColor: color,
          '& .MuiChip-label': { color: textColor },
          minWidth: 60,
        }}
      />
    </Tooltip>
  )
}

AqiDetailItem.propTypes = { index: PropTypes.number }
AqiDetailItem.defaultProps = { index: undefined }

AqiChip.propTypes = { index: PropTypes.number }
AqiChip.defaultProps = { index: undefined }

export { AqiChip, AqiDetailItem, aqiToDetailData }
