import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, FreeSoloDynamicSelect } from '@common/components/Selects'
import { getApiFetch } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

export default function UnitForm({ open, onClose, instance }) {
  const { doUnitSave } = useConnect('doUnitSave')

  const [formikProps, setFormikProps] = useState({
    values: {},
    setFieldValue: () => {},
  })

  const validationSchema = yup.object().shape({
    floor: yup.string().max(10),
    name: yup.string().max(50).required('Please enter a name'),
    unitSize: yup.number().integer().positive().nullable(true),
    property: yup.string().uuid().required(),
  })

  const initialValues = {
    id: '',
    floor: '',
    name: '',
    unitSize: '',
    property: '',
    group: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const createGroup = useCallback(async ({ groupName, property, id: unitId }) => {
    const result = await apiFetch(
      '/unit_groups/',
      { name: groupName, units: unitId ? [unitId] : [], property },
      { method: 'POST', cancelationPrefix: 'unit_form' },
    )
    return result?.id
  }, [])

  const saveUnit = async (unit) => {
    const payload = {}

    Object.keys(unit).forEach((field) => {
      const fieldValue = unit[field]

      if (fieldValue === '') {
        const schemaField = validationSchema.fields[field]

        if (schemaField?.spec?.nullable) {
          payload[field] = null
        }
      } else {
        payload[field] = fieldValue
      }
    })

    const newGroupId = unit.groupName ? await createGroup(unit) : null
    payload.group = newGroupId ?? payload.group

    return doUnitSave(payload)
  }

  const sx = { flex: 1 }

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Unit`}
      open={open}
      onSave={saveUnit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field sx={sx} component={TextField} label="Name" name="name" required />
          <Field
            autoSelect
            name="group"
            altField="groupName"
            label="Unit Group"
            endpoint="unit_groups"
            initialValue={initialValues.group}
            filters={{ property: formikProps.values.property || instance?.property }}
            disabled={!formikProps.values.property}
            component={FreeSoloDynamicSelect}
            sx={sx}
          />
        </Box>
        <Box display="flex" justifyContent="space-evently" gap="2rem">
          <Field
            sx={sx}
            name="property"
            label="Property"
            endpoint="properties"
            onChange={() => formikProps.setFieldValue('group', null)}
            component={FormikDynamicSelect}
            disabled={!!instance?.property}
          />
        </Box>
        <Box width="47%" display="flex" justifyContent="space-evently">
          <Field component={TextField} label="Floor" name="floor" />
          <Field sx={sx} component={TextField} label="Unit Size" name="unitSize" />
        </Box>
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}

UnitForm.defaultProps = {
  instance: {},
}

UnitForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    floor: PropTypes.string,
    name: PropTypes.string,
    unitSize: PropTypes.number,
    property: PropTypes.string,
    group: PropTypes.string,
  }),
}
