import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Rect as KonvaRect } from 'react-konva'

import {
  CANVAS_WIDTH,
  RECT_HORIZONTAL_ORIGIN,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
} from './constants'

// horizontal dimension of NRS setting rectangle
export const getRectWidth = (width) => (width ?? CANVAS_WIDTH) - 65

export const TopRect = forwardRef(({ editorWidth, bottom, color, ...props }, ref) => (
  <KonvaRect
    ref={ref}
    y={bottom - RECT_MARGIN}
    x={RECT_HORIZONTAL_ORIGIN + 0.5}
    cornerRadius={[11, 11, 0, 0]}
    height={bottom - TIMELINE_GRID_ORIGIN - RECT_MARGIN}
    width={getRectWidth(editorWidth)}
    fill={color}
    stroke={color}
    strokeWidth={1}
    scaleY={-1}
    {...props}
  />
))

TopRect.defaultProps = {
  editorWidth: undefined,
}

TopRect.propTypes = {
  editorWidth: PropTypes.number,
  bottom: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export const Rect = forwardRef(({ editorWidth, top, bottom, color, ...props }, ref) => (
  <KonvaRect
    x={RECT_HORIZONTAL_ORIGIN + 0.5}
    cornerRadius={11}
    y={top + RECT_MARGIN}
    height={bottom - top - 2 * RECT_MARGIN}
    width={getRectWidth(editorWidth)}
    fill={color}
    stroke={color}
    strokeWidth={1}
    ref={ref}
    {...props}
  />
))

Rect.defaultProps = {
  editorWidth: undefined,
}

Rect.propTypes = {
  editorWidth: PropTypes.number,
  top: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export const BottomRect = forwardRef(({ editorWidth, top, color, ...props }, ref) => (
  <KonvaRect
    ref={ref}
    y={top + RECT_MARGIN}
    height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
    x={RECT_HORIZONTAL_ORIGIN + 0.5}
    cornerRadius={[11, 11, 0, 0]}
    width={getRectWidth(editorWidth)}
    fill={color}
    stroke={color}
    strokeWidth={1}
    {...props}
  />
))

BottomRect.defaultProps = {
  editorWidth: undefined,
}

BottomRect.propTypes = {
  editorWidth: PropTypes.number,
  top: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}
