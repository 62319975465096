import PropTypes from 'prop-types'

import { Group } from '@visx/group'

export default function SelectionRect({ x0, x1, y, height, limitX0, limitX1 }) {
  const color = '#000000'
  const opacity = 0.4

  const hasLimitX0 = limitX0 !== undefined
  const hasLimitX1 = limitX1 !== undefined
  const width =
    (hasLimitX1 ? Math.min(limitX1, x1) : x1) -
    (hasLimitX0 ? Math.max(limitX0, x0) : x0)

  return (
    <>
      {(!hasLimitX0 || (x0 >= limitX0 - 2 && x0 <= limitX1)) && (
        <Group left={x0} top={y} height={height}>
          <g transform="translate(0,-4)">
            <polygon
              points="0,5 4,0 -4,0"
              fill={color}
              stroke={color}
              opacity={opacity}
            />
          </g>
        </Group>
      )}
      {(!hasLimitX1 || (x1 <= limitX1 + 2 && x1 >= limitX0)) && (
        <Group left={x0 + (x1 - x0)} top={y} height={height}>
          <g transform="translate(0,-4)">
            <polygon
              points="0,5 4,0 -4,0"
              fill={color}
              stroke={color}
              opacity={opacity}
            />
          </g>
        </Group>
      )}
      <rect
        x={hasLimitX0 ? Math.max(limitX0, x0) : x0}
        width={width}
        height={height}
        y={y}
        fill={color}
        opacity={opacity}
      />
    </>
  )
}

SelectionRect.defaultProps = {
  limitX0: undefined,
  limitX1: undefined,
}

SelectionRect.propTypes = {
  x0: PropTypes.number.isRequired,
  x1: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  limitX0: PropTypes.number,
  limitX1: PropTypes.number,
}
