import PropTypes from 'prop-types'

import { Group } from '@visx/group'

export default function BrushHandle({ x, height, isBrushActive }) {
  const handleWidth = 4.5
  const handleHeight = 25
  const corner = 2.5

  if (isBrushActive) {
    return (
      <Group left={x} top={(height - handleHeight) / 2}>
        <rect
          style={{
            fill: 'white',
            stroke: 'black',
            strokeWidth: '1px',
            cursor: 'ew-resize',
          }}
          width={handleWidth}
          height={handleHeight}
          rx={corner}
          ry={corner}
        />
      </Group>
    )
  }
  return null
}

BrushHandle.propTypes = {
  x: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isBrushActive: PropTypes.bool.isRequired,
}
