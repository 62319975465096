import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Download as DownloadIcon } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material'

import html2pdf from 'html2pdf.js'
import { DateTime } from 'luxon'

import { sanitizeFileName } from '@common/utils'

import { closeIconStyles, exportButtonStyles } from './Report.styles'
import ReportData from './ReportData'

function NoiseReport({ onClose, instance, property }) {
  const [exporting, setExporting] = useState(false)

  const [loading, setLoading] = useState(true)
  const [chartData, setChartData] = useState(null)
  const [thresholdValue, setThresholdValue] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const [feedback, setFeedback] = useState(instance.noiseFeedback?.comments || '')
  const [initialFeedback, setInitialFeedback] = useState({
    id: instance.noiseFeedback?.id,
    comments: instance.noiseFeedback?.comments || '',
  })

  const {
    doFetchPropertyEventSummary,
    doMarkPropertyEventListAsOutdated,
    doNoiseEventFeedbackSave,
    doShowSnackbar,
    currentOrganizationDetails,
  } = useConnect(
    'doFetchPropertyEventSummary',
    'doMarkPropertyEventListAsOutdated',
    'doNoiseEventFeedbackSave',
    'doShowSnackbar',
    'selectCurrentOrganizationDetails',
  )

  const fetchSummary = useCallback(async () => {
    try {
      setLoading(true)
      const result = await doFetchPropertyEventSummary(instance.id, {
        dataTypes: ['nrs'],
        timeline: 'EVENT',
      })
      const zoneData = result?.data?.find((d) => d.key === `nrs:zone:${instance.zone}`)
      const data = zoneData?.data?.map((d) => ({
        time: DateTime.fromISO(d.time, { zone: property.timezone }),
        nrs: d.value,
      }))
      setThresholdValue(zoneData?.data.find((d) => !!d.highThreshold).highThreshold)
      setChartData(data)
      setNotifications(result?.notifications)

      if (result) {
        setLoading(false)
      }
    } catch (e) {
      doShowSnackbar('Unable to load chart', 'error')
      setLoading(false)
    }
  }, [instance, property])

  useEffect(() => {
    fetchSummary()
  }, [instance])

  const generateReport = useCallback(async () => {
    let clonedElement

    try {
      const element = document.getElementById('printable_canvas')

      clonedElement = element.cloneNode(true)
      clonedElement.style.position = 'static'
      clonedElement.style.left = 'auto'

      const { propertyName, unitName, createdOn } = instance

      const fileName = `noise_report_${propertyName}_${unitName}_${createdOn}`

      await html2pdf()
        .from(clonedElement)
        .set({
          filename: sanitizeFileName(fileName),
          jsPDF: {
            orientation: 'landscape',
            format: 'letter',
          },
        })
        .save()
    } finally {
      clonedElement?.remove()
      setExporting(false)
    }
  }, [instance])

  useEffect(() => {
    if (exporting) {
      // timeout required to render the chart
      setTimeout(generateReport, 200)
    }
  }, [exporting])

  const handleSaveFeedback = async () => {
    const payload = {
      comments: feedback,
      event: instance.id,
      property: instance.property,
    }

    if (initialFeedback.id) {
      payload.id = initialFeedback.id
    }

    const result = await doNoiseEventFeedbackSave(payload)
    if (result.error) {
      doShowSnackbar('Unable to save Feedback', 'error')
      return
    }
    setInitialFeedback({ id: result.id, comments: feedback })
    doShowSnackbar('Feedback saved', 'success')
    doMarkPropertyEventListAsOutdated()
  }

  const getNotificationMarkers = () => {
    if (!notifications) {
      return []
    }

    return notifications
      .map((n) => {
        switch (n.eventClass) {
          case 'NOISE':
            return {
              id: n.eventClass,
              date: DateTime.fromISO(n.ts),
              color: 'green',
            }
          case 'ACTIONABLE_NOISE_1':
            return {
              id: n.eventClass,
              date: DateTime.fromISO(n.ts),
              color: 'green',
            }
          case 'ACTIONABLE_NOISE_2':
            return {
              id: n.eventClass,
              date: DateTime.fromISO(n.ts),
              color: 'orange',
            }
          case 'ACTIONABLE_NOISE_3':
            return {
              id: n.eventClass,
              date: DateTime.fromISO(n.ts),
              color: 'red',
            }
          default:
            return null
        }
      })
      .filter((n) => !!n)
  }

  const markers = useMemo(() => getNotificationMarkers(), [notifications])

  return (
    <Dialog maxWidth="lg" open onClose={onClose} disableScrollLock>
      <DialogContent
        sx={{
          position: 'relative',
          backgroundColor: '#1f2a44',
        }}
      >
        <IconButton onClick={onClose} sx={closeIconStyles} disabled={exporting}>
          <CloseIcon />
        </IconButton>
        <IconButton
          sx={exportButtonStyles}
          onClick={() => setExporting(true)}
          disabled={exporting}
        >
          <DownloadIcon />
        </IconButton>
        <Stack position="relative">
          {exporting && (
            <ReportData
              exporting
              instance={instance}
              organizationName={currentOrganizationDetails.name}
              timezone={property.timezone}
              loading={loading}
              chartData={chartData}
              thresholdValue={thresholdValue}
              markers={markers}
              initialFeedback={initialFeedback}
              feedback={feedback}
              onSaveFeedback={handleSaveFeedback}
              onChangeFeedback={setFeedback}
            />
          )}
          <ReportData
            instance={instance}
            organizationName={currentOrganizationDetails.name}
            timezone={property.timezone}
            loading={loading}
            chartData={chartData}
            thresholdValue={thresholdValue}
            markers={markers}
            initialFeedback={initialFeedback}
            feedback={feedback}
            onSaveFeedback={handleSaveFeedback}
            onChangeFeedback={setFeedback}
            exporting={false}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

NoiseReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    zone: PropTypes.string.isRequired,
    device: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    endTime: PropTypes.string,
    noiseFeedback: PropTypes.shape({
      id: PropTypes.string,
      comments: PropTypes.string,
    }),
  }).isRequired,
  property: PropTypes.shape({
    timezone: PropTypes.string,
  }).isRequired,
}

export default NoiseReport
