import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const entityName = 'system'

const system = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch }) =>
    apiFetch('/system/', null, { ancelationPrefix: entityName }),
})

export default {
  ...system,
  reactSystemFetch: createSelector(
    'selectIsAuthenticated',
    'selectSystemShouldUpdate',
    (authenticated, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchSystem' }
      }
      return undefined
    },
  ),
  selectSystemEventClasses: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.eventClasses,
  ),
  selectSystemEventTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.eventTypes,
  ),
  selectSytemDataCategories: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.dataCategories,
  ),
  selectSystemDataTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.dataTypes,
  ),
  selectSystemDeviceStatus: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.deviceStatus,
  ),
  selectSystemDeviceHealthStatus: createSelector(
    'selectSystem',
    (systemBundle) =>
      systemBundle?.deviceHealthStatus?.reduce(
        (acc, current) => ({ ...acc, [current.value]: current.label }),
        {},
      ) ?? {},
  ),
  selectSystemDeviceStyles: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.deviceStyles,
  ),
  selectSystemDeviceModels: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.deviceModels?.map((model) => ({
      name: model.name,
      label: model.key,
      id: model.id,
    })),
  ),
  selectSystemFeatureFlags: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.featureFlags,
  ),
  selectSystemPropertyTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.propertyTypes,
  ),
  selectSystemUnitStatus: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.unitStatus,
  ),
  selectSystemScenarioTags: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.scenarioTags?.map((obj) => obj.key),
  ),
  selectSystemTimeZones: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.timeZones,
  ),
  selectReportMetrics: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.reportMetrics,
  ),
  selectSystemSmokeAutoChargeTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeAutoChargeTypes,
  ),
  selectSystemSmokeProfiles: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfiles,
  ),
  selectSystemSmokeProfileStatuses: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfileStatuses,
  ),
  selectSystemSmokeModelKeys: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeModelKeys,
  ),
  selectSystemReportClasses: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.reportClasses?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSmokeAdjustmentReasons: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeAdjustmentReasons ?? [],
  ),
  selectSystemPortalRoles: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.portalRoles?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemRoleLevels: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.roleLevels,
  ),
  selectSystemBillingCadences: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.billingCadences?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemBillingTypes: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.billingTypes?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemPermissions: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.permissions,
  ),
  selectSystemPermissionCategories: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.permissionCategories,
  ),
  selectAutochargeParamsSchema: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.autochargeParamsSchema,
  ),
}
